import _ from 'lodash'
import moment from 'moment'
import { getMonthName } from '@/helper/calendar'

moment.suppressDeprecationWarnings = true

const groupingAssets = ({ assets, tag = '' }) => {
  let groupAssets = []

  let tempGrouping = 0
  let tempUrlGallery = 0
  _.sortBy(
    assets,
    (asset) => !isNaN(parseInt(asset.code_number.ms_code)),
    (asset) => asset.code_number.ms_code
  ).forEach((asset, assetIndex) => {
    try {
      const {
        utilization,
        location,
        code_number,
        id: assetIdFirebase,
        rate_card_excl_vat,
        advertiser: industries,
        asset_type: assetType,
        competitor_details: competitorDetails,
        mystery_shopping_details,
        is_filter_result,
        street_survey_details,
        url_gallery,
        last_survey_image
      } = asset
      console.log('🚀 ~ file: grouping.js:33 ~ ).forEach ~ last_survey_image:', last_survey_image)

      if (last_survey_image !== null) {
        tempUrlGallery++
      }

      const { ms_code: msCode, duplicate_ms_code: duplicateMsCode, master_code: masterCode } = code_number
      const { industry_level_1_parent: industryName, industry_level_2_details, company_group, brands } = industries
      const { proposal_month } = mystery_shopping_details
      const { competitor: mediaCompanyName, competitorStatus } = competitorDetails
      const { slot_status } = street_survey_details
      const { type_of_assets } = assetType
      const { utilization_status } = utilization

      const { ms_code, duplicate_ms_code } = code_number

      const slotInformation = {
        id: 1,
        id_firebase: assetIdFirebase,
        ms_code: msCode,
        master_code: masterCode,
        duplicate_ms_code: duplicateMsCode,
        status: slot_status,
        prices: convertPrices(rate_card_excl_vat),
        industries: industryName === '' ? null : industryName,
        industries_children: industry_level_2_details,
        brands: company_group === '' ? null : company_group,
        is_filter_result,
        utilization_status,
        subProduct: brands
      }

      const screenOwnerInformation = {
        id: 1,
        name: mediaCompanyName,
        competitor_status: competitorStatus,
        is_filter_result,
        slots: [slotInformation]
      }

      const parentMsCodeIndex = groupAssets.findIndex(({ parent_ms_code }) => parent_ms_code === ms_code)
      const parentDuplicateMsCodeIndex = groupAssets.findIndex(({ parent_ms_code }) => parent_ms_code === duplicate_ms_code)

      // ! FINAL
      let selectedAssets = null
      if (parentDuplicateMsCodeIndex === -1) {
        // ! ADD NEW PARENT ASSETS
        if (parentMsCodeIndex === -1) {
          groupAssets.push({
            id: assetIndex + 1,
            type: type_of_assets,
            location,
            parent_ms_code: msCode,
            parent_duplicate_ms_code: duplicateMsCode,
            parent_master_code: masterCode,
            latest_survey_date: null,
            latest_survey_screen_onwers_history: [],
            active_survey_date: null,
            active_survey_screen_onwers_history: [],
            survey_date: null,
            asset_type_details: assetType,
            asset_type: assetType,
            markerColor: null,
            is_user_selected: false,
            url_gallery,
            last_survey_image,
            survey_histories: [
              {
                survey_date: proposal_month,
                screen_owners: []
              }
            ]
          })
          selectedAssets = groupAssets[groupAssets.length - 1]
        } else {
          selectedAssets = groupAssets[parentMsCodeIndex]
        }
      } else {
        // ! HANDLE DUPLICATE ASSETS & DUPLICATE SLOTS
        selectedAssets = groupAssets[parentDuplicateMsCodeIndex]
      }

      const { survey_histories } = selectedAssets
      const surveyIndex = survey_histories.findIndex(({ survey_date }) => survey_date === proposal_month)
      if (surveyIndex === -1) {
        survey_histories.push({
          survey_date: proposal_month,
          screen_owners: [screenOwnerInformation]
        })
      } else {
        const { screen_owners } = survey_histories[surveyIndex]
        const ownerIndex = screen_owners.findIndex(({ name }) => name === mediaCompanyName)
        if (ownerIndex === -1) {
          screen_owners.push({
            ...screenOwnerInformation,
            id: screen_owners.length + 1
          })
        } else {
          screen_owners[ownerIndex].slots.push({
            ...slotInformation,
            id: screen_owners[ownerIndex].slots.length + 1
          })
        }
      }
    } catch (error) {
      console.log('error ', error)
      console.log(asset)
    }
  })

  // * CHECK ACTIVE_SURVEY_HISTORY
  // * SELECT THE LAST SURVEY DATE
  groupAssets = groupAssets.map((groupAsset) => {
    let { survey_histories } = groupAsset
    const sortedDates = survey_histories.sort((a, b) => moment(a.survey_date).diff(moment(b.survey_date)))
    const selectedSurveyDate = sortedDates[sortedDates.length - 1]

    groupAsset.active_survey_date = selectedSurveyDate.survey_date
    groupAsset.active_survey_screen_onwers_history = selectedSurveyDate.screen_owners

    groupAsset.latest_survey_date = selectedSurveyDate.survey_date
    groupAsset.latest_survey_screen_onwers_history = selectedSurveyDate.screen_owners
    groupAsset.screen_owners = selectedSurveyDate.screen_owners

    return groupAsset
  })

  return groupAssets
}

const groupingLocations = (listAsset) => {
  const locations = {}
  listAsset.forEach((asset) => {
    const { lat, lng } = asset.location

    if (typeof locations[`${lat}-${lng}`] === 'undefined') {
      locations[`${lat}-${lng}`] = {
        lat,
        lng,
        color: asset.color,
        assets: [asset]
      }
    } else {
      locations[`${lat}-${lng}`].assets.push(asset)
    }
  })

  return locations
}

const groupingAssetsByDate = (listAsset) => {
  // const assetsDate = []
  // listAsset.forEach((asset) => {
  // console.log('🚀 ~ file: grouping.js ~ line 230 ~ listAsset.forEach ~ asset', asset)
  // const { survey_date, latest_survey_date } = asset
  // const date = moment(latest_survey_date)
  // const surveyYear = date.format('YYYY')
  // const surveyMonth = getMonthName(moment(latest_survey_date).month())
  // console.log('groupDate ', {
  //   date: date.toString(),
  //   a: date.format('YYYY'),
  //   surveyYear,
  //   surveyMonth
  // })
  // const assetDateYearIndex = _.findIndex(assetsDate, ({ year }) => year === surveyYear)
  // if (assetDateYearIndex === -1) {
  //   assetsDate.push({ year: surveyYear, months: [{ month: surveyMonth, assets: [asset] }] })
  // } else {
  //   const { months } = assetsDate[assetDateYearIndex]
  //   const monthIndex = months.findIndex(({ month }) => month === surveyMonth)
  //   if (monthIndex === -1) {
  //     months.push({ month: surveyMonth, assets: [asset] })
  //   } else {
  //     months[monthIndex].assets.push(asset)
  //   }
  // }
  // })
  // console.log('groupingAssetsByDate ', assetsDate)
  // return assetsDate
}

const convertPrices = (prices) => {
  /* 
    packages => [ one_month, three_month, six_month, one_year  ]
    per_sqm_offered
    per_face
  */
  const pricesArr = []
  for (const pricesName in prices) {
    const pricesByIntervalTimeArr = [prices[pricesName].one_month, prices[pricesName].three_month, prices[pricesName].six_month, prices[pricesName].one_year]
    pricesArr.push(pricesByIntervalTimeArr)
  }

  return pricesArr
}

const parsedAssetsWithDateRange = (assets, selectedDate) => {
  const { year, month } = selectedDate
  const startDateRange = moment().set({ year, month, date: 1 }).toString()
  const endDateRange = moment().set({ year, month }).endOf('month').toString()

  const cb = _.map(assets, ({ year, months }) => {
    const modifyMonths = months.map(({ month, assets }) => {
      const isBetweenDateRange = moment().set({ year, month, date: 1 }).isBetween(startDateRange, endDateRange)
      const modifyAssets = assets.map((asset) => {
        asset.is_filter_result = isBetweenDateRange ? true : false

        return asset
      })

      return {
        month,
        assets: modifyAssets
      }
    })

    return {
      year,
      months: modifyMonths
    }
  })

  return cb
}

const checkSurveyDate = ({ assets, selectedDate }) => {
  let { month, year } = selectedDate

  return assets.map((asset) => {
    const { survey_histories } = asset
    const surveyDatesBeforeSelectedDate = survey_histories.filter(({ survey_date }) => {
      const surveyAssetDate = moment(survey_date)
      return getMonthName(surveyAssetDate.month()) === month && surveyAssetDate.format('YYYY') === year
    })
    const lastSurveyBeforeSelectedDate = surveyDatesBeforeSelectedDate.pop()

    if (typeof lastSurveyBeforeSelectedDate === 'undefined') {
      const lastSurveyDateBeforeSelectedDate = survey_histories.filter(({ survey_date }) => moment(survey_date).isBefore(moment(`1-${month}-${year}`))).pop()

      if (typeof lastSurveyDateBeforeSelectedDate === 'undefined') {
        // no survey from previous selected date
        asset.screen_owners = []
        asset.survey_date = ''

        asset.active_survey_screen_onwers_history = []
        asset.active_survey_date = ''
      } else {
        // set the latest survey
        asset.screen_owners = lastSurveyDateBeforeSelectedDate.screen_owners
        asset.survey_date = lastSurveyDateBeforeSelectedDate.survey_date

        asset.active_survey_screen_onwers_history = lastSurveyDateBeforeSelectedDate.screen_owners
        asset.active_survey_date = lastSurveyDateBeforeSelectedDate.survey_date
      }
    } else {
      // set survey thath match with selectedDate
      asset.screen_owners = lastSurveyBeforeSelectedDate.screen_owners
      asset.survey_date = lastSurveyBeforeSelectedDate.survey_date

      asset.active_survey_screen_onwers_history = lastSurveyBeforeSelectedDate.screen_owners
      asset.active_survey_date = lastSurveyBeforeSelectedDate.survey_date
    }

    asset.active_survey_screen_onwers_history = asset.screen_owners
    asset.active_survey_date = asset.survey_date

    return asset
  })
}

const checkIsAssetHaveFilterResult = (assets) => {
  /* IF ACTIVE DATE BETWEEN SELECTED DATE & IS CONTAIN FILTER */
  return assets.map((asset) => {
    const modifiedactiveSurveyScreenOnwersHistory = asset.active_survey_screen_onwers_history.map((surveyDateDetails) => {
      return {
        ...surveyDateDetails,
        is_filter_result: surveyDateDetails.slots.some((slot) => slot.is_filter_result === true)
      }
    })

    return {
      ...asset,
      is_filter_result: modifiedactiveSurveyScreenOnwersHistory.some((surveyDateDetails) => surveyDateDetails.is_filter_result === true)
    }
  })
}

export { groupingAssets, groupingLocations, groupingAssetsByDate, parsedAssetsWithDateRange, checkSurveyDate, checkIsAssetHaveFilterResult }
