/* tailwind configs & icon */
import '@fortawesome/fontawesome-free/js/all'

import './assets/main.css'

import { createApp } from 'vue'
import vue3JsonExcel from 'vue3-json-excel'
import VueUniversalModal from 'vue-universal-modal'
import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import Emitter from 'tiny-emitter'

import firebase from 'firebase/app'
import App from './App.vue'
import router from './router'
import store from './store'

import { firebaseConfig } from '@/config/firebase'

firebase.initializeApp(firebaseConfig)

console.log('use ', process.env.STAGE)
console.log('')
console.log('use FIREBASE ENV ', firebaseConfig.projectId)
console.log('')

const app = createApp(App)
app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter()

app
  .use(store)
  .use(router)
  .use(vue3JsonExcel)
  .use(VueUniversalModal, {
    teleportTarget: '#modals'
  })
  .mount('#app')
