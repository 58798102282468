import { createStore } from 'vuex'
import actions from '@/store/modules/msal/msalActions'
import mutations from '@/store/modules/msal/msalMutations'

export default {
  state: () => ({
    msalInstance: null,
    msalConfig: {
      auth: {
        clientId: process.env.VITE_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.VITE_TENANT_ID}`,
        redirectUri: process.env.VITE_WEB_URL,
        postLogoutRedirectUri: process.env.VITE_WEB_URL
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    },
    accessToken: '',
    userAccount: null
  }),
  getters: {},
  actions,
  mutations
}
