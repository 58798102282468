<template>
  <sidebar />

  <!-- MAIN CONTENT  & FOOTER AREA -->
  <div class="bg-gray-100 px-4 pt-4 h-screen w-auto overflow-auto" :style="{ 'margin-left': sidebarWidth }">
    <router-view v-slot="{ Component }">
      <transition>
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <Footer />
  </div>
</template>

<script>
  import Sidebar from '@/components/sidebar/Sidebar.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    components: {
      Sidebar,
      Footer
    },
    computed: {
      sidebarWidth() {
        const { collapsedSidebar } = this.$store.state.layout
        const SIDEBAR_WIDTH = 152
        const SIDEBAR_WIDTH_COLLAPSED = 45

        return `${collapsedSidebar ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`
      }
    }
  }
</script>

<style scoped></style>
