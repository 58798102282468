import '@/components/Maps/style.css'
import _ from 'lodash'
import toRupiah from '@develoka/angka-rupiah-js'
import axios from 'axios'

const generateInfoWindow = (asset) => {
  if (typeof asset === 'undefined') {
    return ''
  }

  const { asset_type_details, screen_owners, location, parent_master_code, parent_ms_code, survey_date, url_gallery, last_survey_image } = asset

  const { area, city, route } = location
  const { format_level_1, type_of_assets } = asset_type_details
  let assetLastImage = 'https://drive.google.com/drive/folders/1H7Ik-aJlQyj3WKm6MkCtpaJK3md8_FKf'
  let googleDriveLink = 'https://photo.cv-id.synology.me/mo/sharing/nIOfJQ9lE'
  assetLastImage = 'https://drive.google.com/file/d/1RNkNLglNv-wKZPOZ1WoWKjQWBVRMmDQ2/view?usp=share_link'
  if (url_gallery !== null && url_gallery !== 'NOT YET UPLOADED') {
    googleDriveLink = url_gallery
  }
  const imageId = last_survey_image.match(/[-\w]{25,}/) !== null ? last_survey_image.match(/[-\w]{25,}/)[0] : 'cang cing men'
  const modifiedAssetImageUrl = `https://drive.google.com/uc?export=view&id=${imageId}`

  const screenOwners = screen_owners.map((owner, index) => {
    const { name, is_filter_result } = owner
    return `    
    <input style="display: none;" type="radio" checked name="tab" id="tab${index + 1}" class="">
      <label class="text-gray-500" for="tab${index + 1}"> ${name} </label>
    `
  })

  const tabDetails = []
  screen_owners.forEach((owner) => {
    const { slots } = owner
    const { prices, ms_code } = slots[0]

    const priceNumber =
      prices[0][0] === 0
        ? 'No ratecard'
        : `Rate card: ${toRupiah(String(prices[0][0]).replaceAll('.', ''), {
            useUnit: true,
            longUnit: true,
            spaceBeforeUnit: true,
            formal: false,
            floatingPoint: 0
          })} / month/ slot `
    const sortedSlots = _.orderBy(slots, 'brands', 'asc')

    tabDetails.push(`
    <div class="tab infowindow-font-small shrink w-full mt-1">
      <div class="flex flex-row items-center justify-start font-medium mb-2 ">
        <p class="font-semibold mr-2">MS Code: </p>
        <p class="font-medium text-gray-500">${ms_code.toUpperCase()}</p>
      </div>

      <div class="flex flex-row items-center justify-start font-medium mb-2 ">
        <p class="font-semibold mr-2">Asset type: </p>
        <p class="font-medium text-gray-500">${format_level_1}</p>
        <p class="font-medium text-gray-500">${`&nbsp | &nbsp`}</p>
        <p class="font-medium text-gray-500">${type_of_assets}</p>
      </div>

      <div class="flex flex-row items-center justify-start font-medium mb-2">
        <p class="font-semibold mr-2">Location: </p>
        <p class="font-medium text-gray-500">${city.replace(/\s+/g, '')}, ${area.replace(/\s+/g, '')}, ${route.replace(/\s+/g, '')}</p>
      </div>
      
      <div class="flex flex-row tab-details">
        <div class="flex-1 basis-1/2 pr-2">
          <table class="table flex">
            <thead class="text-white bg-gray-400 items-center justify-center">
              <tr>
                <th class="border"></th>
                <th class="border">Utilized</th>
                <th class="border">Brands</th>
              </tr>
            </thead>

            <tbody class="items-center justify-center">
              ${sortedSlots
                .map((slot, index) => {
                  const { status, brands } = slot
                  return `<tr class="text-gray-500 text-xs italic font-medium">
                    <th class="border text-gray-500 text-xs italic font-medium">Slot ${index + 1}</th>
                    <th class="border">
                    ${
                      status.trim() === 'Utilized'
                        ? `<i class="fas fa-check text-xs h-5 w-5 text-green-400" />`
                        : `<i class="fas fa-times text-xs h-5 w-5 text-red-400" />`
                    }  
                    </th>
                    <th class="border text-gray-500 text-xs italic font-medium">${brands === null ? `-` : brands}</th>
                  </tr>`
                })
                .join('')}
              </tbody>
            </table> 
        </div>

        <div class="flex-1 h-40 basis-1/2 pl-2 border-1 border-green-400">
            <a href="${url_gallery}" target="blank">
              <img
                src="${modifiedAssetImageUrl}"
                alt="..."
                class="shadow-lg rounded object-cover h-full w-full border-none"
                onerror="javascript: type='video'"
              />
            </a>
        </div>
      </div>

      <div class="w-full mt-2 h-8 bg-blue-500 rounded flex items-center justify-center">
      <p class="text-gray-200">${priceNumber}</p>
      </div>
    </div>
    `)
  })

  let html = null
  if (asset.screen_owners.length > 0) {
    html = `
    <div id='infoWindowContentInMap' class="content w-fit relative bg-transparent px-0 info-container min-w-full">
      <div class="mt-1 mb-1 py-1 flex flex-row items-center justify-start text-gray-500 text-xs italic infowindow-font-small">
        <p class="mr-4">Master Code: ${parent_master_code}</p>
        <p class="font-semibold">Survey Date: ${survey_date}</p>
      </div>

      <div class="asset-information tab-frame">
        <div class="flex flex-wrap font-medium mb-2 items-center justify-start align-content ">
          <p class="font-semibold mr-2">Screen owner:</p>
          ${screenOwners.join('')}
          ${tabDetails.join('')}
        </div>
      </div>
    </div>
    `
  } else {
    html = `
    <div id='infoWindowContentInMap' class="content w-fit relative bg-transparent px-0 info-container min-w-full">
      <div class="mt-1 mb-1 py-1 flex flex-row items-center justify-start text-gray-500 text-xs italic infowindow-font-small">
        <p class="mr-4">Master Code: ${parent_master_code}</p>
        <p class="font-semibold">Survey Date: -</p>
      </div>

      <div class="asset-information tab-frame">
        <div class="flex flex-wrap font-medium mb-2 items-center justify-start align-content ">
          <p class="font-semibold mr-2">Screen owner:</p>
          <input style="display: none;" type="radio" checked name="tab" id="tab${0 + 1}" class="">
          <label class="text-gray-500" for="tab${0 + 1}">No Data</label>
          
          <div class="tab infowindow-font-small shrink w-full mt-1">
            <div class="flex flex-row items-center justify-start font-medium mb-2 ">
              <p class="font-semibold mr-2">MS Code: </p>
              <p class="font-medium text-gray-500">${parent_ms_code}</p>
            </div>

            <div class="flex flex-row items-center justify-start font-medium mb-2 ">
              <p class="font-semibold mr-2">Asset type: </p>
              <p class="font-medium text-gray-500">${format_level_1}</p>
              <p class="font-medium text-gray-500">${`&nbsp | &nbsp`}</p>
              <p class="font-medium text-gray-500">${type_of_assets}</p>
            </div>

            <div class="flex flex-row items-center justify-start font-medium mb-2">
              <p class="font-semibold mr-2">Location: </p>
              <p class="font-medium text-gray-500">${city.replace(/\s+/g, '')}, ${area.replace(/\s+/g, '')}, ${route.replace(/\s+/g, '')}</p>
            </div>

            <div class="flex flex-row tab-details">
              <div class="flex-1 basis-1/2 pr-2">
                <table class="table flex">
                  <thead class="text-white bg-gray-400 items-center justify-center">
                    <tr>
                      <th class="border"></th>
                      <th class="border">Utilized</th>
                      <th class="border">Brands</th>
                    </tr>
                  </thead>

                  <tbody class="items-center justify-center">
                    <tr class="text-gray-500 text-xs italic font-medium">
                      <th class="border text-gray-500 text-xs italic font-medium">Slot ${0 + 1}</th>
                      <th class="border">
                        <i class="fas fa-times text-xs h-5 w-5 text-red-400" />
                      </th>
                      <th class="border text-gray-500 text-xs italic font-medium">-</th>
                    </tr>    
                  </tbody>
                </table> 
              </div>

              <div class="flex-1 h-40 basis-1/2 pl-2">
                <img src="https://cityvision.co.id/cfind/source/thumb/images/property-list/roadside/cover_w375_h340_tw1284_th1166_x1845_y13_senopatiway-217x115.jpg" alt="..." class="shadow-lg rounded object-cover h-full w-full border-none" />
              </div>
            </div>

            <div class="w-full mt-2 h-8 bg-blue-500 rounded flex items-center justify-center">
            <p class="text-gray-200">No ratecard</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    `
  }

  return html
}

export { generateInfoWindow }
