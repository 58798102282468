export default [
  {
    name: 'Kota Tangerang',
    prov: 'Banten',
    shape: [
      {
        lng: 106.56490326,
        lat: -6.21124029
      },
      {
        lng: 106.56617737,
        lat: -6.20898342
      },
      {
        lng: 106.56908417,
        lat: -6.20626783
      },
      {
        lng: 106.56697083,
        lat: -6.20516014
      },
      {
        lng: 106.56446838,
        lat: -6.20441151
      },
      {
        lng: 106.56272125,
        lat: -6.2038765
      },
      {
        lng: 106.5597229,
        lat: -6.20216084
      },
      {
        lng: 106.55804443,
        lat: -6.20137882
      },
      {
        lng: 106.55799866,
        lat: -6.20026827
      },
      {
        lng: 106.55728149,
        lat: -6.19963598
      },
      {
        lng: 106.55579376,
        lat: -6.1987977
      },
      {
        lng: 106.5537262,
        lat: -6.19878578
      },
      {
        lng: 106.552742,
        lat: -6.19851017
      },
      {
        lng: 106.55393982,
        lat: -6.19764948
      },
      {
        lng: 106.5532608,
        lat: -6.19700527
      },
      {
        lng: 106.55323029,
        lat: -6.19604635
      },
      {
        lng: 106.55194855,
        lat: -6.1947813
      },
      {
        lng: 106.55257416,
        lat: -6.19009638
      },
      {
        lng: 106.55164337,
        lat: -6.18904495
      },
      {
        lng: 106.55271912,
        lat: -6.18812895
      },
      {
        lng: 106.55329895,
        lat: -6.18805885
      },
      {
        lng: 106.55265045,
        lat: -6.18730354
      },
      {
        lng: 106.55274963,
        lat: -6.18687296
      },
      {
        lng: 106.55252075,
        lat: -6.18606758
      },
      {
        lng: 106.55258179,
        lat: -6.18535566
      },
      {
        lng: 106.55265045,
        lat: -6.18479395
      },
      {
        lng: 106.55308533,
        lat: -6.18512917
      },
      {
        lng: 106.5532608,
        lat: -6.18453026
      },
      {
        lng: 106.55363464,
        lat: -6.18386126
      },
      {
        lng: 106.55422211,
        lat: -6.18451834
      },
      {
        lng: 106.55484009,
        lat: -6.18412876
      },
      {
        lng: 106.555336,
        lat: -6.18379402
      },
      {
        lng: 106.55606079,
        lat: -6.18341684
      },
      {
        lng: 106.55683899,
        lat: -6.1832118
      },
      {
        lng: 106.55902863,
        lat: -6.18236446
      },
      {
        lng: 106.55952454,
        lat: -6.18203974
      },
      {
        lng: 106.56036377,
        lat: -6.18176317
      },
      {
        lng: 106.56090546,
        lat: -6.18147898
      },
      {
        lng: 106.56058502,
        lat: -6.18055344
      },
      {
        lng: 106.56115723,
        lat: -6.18014336
      },
      {
        lng: 106.56147766,
        lat: -6.18076611
      },
      {
        lng: 106.56204987,
        lat: -6.18038654
      },
      {
        lng: 106.56275177,
        lat: -6.17978239
      },
      {
        lng: 106.56426239,
        lat: -6.17942762
      },
      {
        lng: 106.56487274,
        lat: -6.17978048
      },
      {
        lng: 106.56477356,
        lat: -6.17883539
      },
      {
        lng: 106.56511688,
        lat: -6.17823935
      },
      {
        lng: 106.56535339,
        lat: -6.17764235
      },
      {
        lng: 106.56575775,
        lat: -6.17699862
      },
      {
        lng: 106.56649017,
        lat: -6.17674065
      },
      {
        lng: 106.56794739,
        lat: -6.17574453
      },
      {
        lng: 106.56869507,
        lat: -6.17496824
      },
      {
        lng: 106.57074738,
        lat: -6.17510462
      },
      {
        lng: 106.57218933,
        lat: -6.17485189
      },
      {
        lng: 106.57253265,
        lat: -6.17449808
      },
      {
        lng: 106.57310486,
        lat: -6.17374277
      },
      {
        lng: 106.57401276,
        lat: -6.17372561
      },
      {
        lng: 106.57535553,
        lat: -6.17389393
      },
      {
        lng: 106.57658386,
        lat: -6.17295074
      },
      {
        lng: 106.5774765,
        lat: -6.17240143
      },
      {
        lng: 106.57929993,
        lat: -6.17186785
      },
      {
        lng: 106.57872772,
        lat: -6.17062378
      },
      {
        lng: 106.579216,
        lat: -6.1699338
      },
      {
        lng: 106.57953644,
        lat: -6.16904163
      },
      {
        lng: 106.58036804,
        lat: -6.16742706
      },
      {
        lng: 106.58103943,
        lat: -6.16694736
      },
      {
        lng: 106.58249664,
        lat: -6.16701269
      },
      {
        lng: 106.58265686,
        lat: -6.16638041
      },
      {
        lng: 106.5826416,
        lat: -6.16503906
      },
      {
        lng: 106.58300781,
        lat: -6.16318989
      },
      {
        lng: 106.58384705,
        lat: -6.16221094
      },
      {
        lng: 106.58646393,
        lat: -6.16244507
      },
      {
        lng: 106.58758545,
        lat: -6.16202068
      },
      {
        lng: 106.58836365,
        lat: -6.16130972
      },
      {
        lng: 106.58876038,
        lat: -6.16086912
      },
      {
        lng: 106.59092712,
        lat: -6.15906382
      },
      {
        lng: 106.59102631,
        lat: -6.1578269
      },
      {
        lng: 106.59131622,
        lat: -6.15640736
      },
      {
        lng: 106.59140778,
        lat: -6.15575504
      },
      {
        lng: 106.59106445,
        lat: -6.15523624
      },
      {
        lng: 106.5911026,
        lat: -6.15309477
      },
      {
        lng: 106.59088898,
        lat: -6.15154886
      },
      {
        lng: 106.59064484,
        lat: -6.14951563
      },
      {
        lng: 106.59135437,
        lat: -6.14838123
      },
      {
        lng: 106.59223175,
        lat: -6.14827776
      },
      {
        lng: 106.59289551,
        lat: -6.14774179
      },
      {
        lng: 106.59499359,
        lat: -6.1477356
      },
      {
        lng: 106.59737396,
        lat: -6.14770699
      },
      {
        lng: 106.59863281,
        lat: -6.14768648
      },
      {
        lng: 106.60188293,
        lat: -6.14763403
      },
      {
        lng: 106.60517883,
        lat: -6.14759779
      },
      {
        lng: 106.60595703,
        lat: -6.14750242
      },
      {
        lng: 106.60905457,
        lat: -6.14767075
      },
      {
        lng: 106.61068726,
        lat: -6.14774942
      },
      {
        lng: 106.61343384,
        lat: -6.14602327
      },
      {
        lng: 106.61434174,
        lat: -6.14353228
      },
      {
        lng: 106.61573792,
        lat: -6.14135408
      },
      {
        lng: 106.61496735,
        lat: -6.13973188
      },
      {
        lng: 106.61354065,
        lat: -6.13909149
      },
      {
        lng: 106.61200714,
        lat: -6.13753271
      },
      {
        lng: 106.61114502,
        lat: -6.13656282
      },
      {
        lng: 106.61091614,
        lat: -6.13498592
      },
      {
        lng: 106.61183167,
        lat: -6.13398457
      },
      {
        lng: 106.61343384,
        lat: -6.13281584
      },
      {
        lng: 106.61407471,
        lat: -6.13145494
      },
      {
        lng: 106.614151,
        lat: -6.1297946
      },
      {
        lng: 106.61386108,
        lat: -6.12747288
      },
      {
        lng: 106.61428833,
        lat: -6.12579918
      },
      {
        lng: 106.61582947,
        lat: -6.1251359
      },
      {
        lng: 106.61688232,
        lat: -6.12368345
      },
      {
        lng: 106.61882782,
        lat: -6.12269258
      },
      {
        lng: 106.61995697,
        lat: -6.12282181
      },
      {
        lng: 106.62114716,
        lat: -6.12238693
      },
      {
        lng: 106.6222229,
        lat: -6.12121534
      },
      {
        lng: 106.62399292,
        lat: -6.12072754
      },
      {
        lng: 106.62643433,
        lat: -6.12070036
      },
      {
        lng: 106.6293869,
        lat: -6.12062979
      },
      {
        lng: 106.62976837,
        lat: -6.12096977
      },
      {
        lng: 106.6304245,
        lat: -6.12119293
      },
      {
        lng: 106.63096619,
        lat: -6.12083006
      },
      {
        lng: 106.63181305,
        lat: -6.12030983
      },
      {
        lng: 106.63215637,
        lat: -6.11991167
      },
      {
        lng: 106.63202667,
        lat: -6.11909008
      },
      {
        lng: 106.63155365,
        lat: -6.1177702
      },
      {
        lng: 106.6313324,
        lat: -6.11726999
      },
      {
        lng: 106.63258362,
        lat: -6.11532259
      },
      {
        lng: 106.63265228,
        lat: -6.11270714
      },
      {
        lng: 106.63401794,
        lat: -6.10987663
      },
      {
        lng: 106.63569641,
        lat: -6.10633087
      },
      {
        lng: 106.63756561,
        lat: -6.10559654
      },
      {
        lng: 106.63762665,
        lat: -6.10624981
      },
      {
        lng: 106.63871765,
        lat: -6.10633421
      },
      {
        lng: 106.64055634,
        lat: -6.10717916
      },
      {
        lng: 106.64260101,
        lat: -6.10810614
      },
      {
        lng: 106.64170837,
        lat: -6.11015129
      },
      {
        lng: 106.64228058,
        lat: -6.11460209
      },
      {
        lng: 106.64276123,
        lat: -6.1172328
      },
      {
        lng: 106.64674377,
        lat: -6.11565113
      },
      {
        lng: 106.64931488,
        lat: -6.11466026
      },
      {
        lng: 106.65311432,
        lat: -6.11313677
      },
      {
        lng: 106.65676117,
        lat: -6.11167479
      },
      {
        lng: 106.66136169,
        lat: -6.10987663
      },
      {
        lng: 106.66449738,
        lat: -6.10859823
      },
      {
        lng: 106.66782379,
        lat: -6.10724401
      },
      {
        lng: 106.67134857,
        lat: -6.10586596
      },
      {
        lng: 106.67115021,
        lat: -6.1046524
      },
      {
        lng: 106.67079163,
        lat: -6.10373163
      },
      {
        lng: 106.6701355,
        lat: -6.1032877
      },
      {
        lng: 106.67195892,
        lat: -6.10309362
      },
      {
        lng: 106.67315674,
        lat: -6.10284281
      },
      {
        lng: 106.67485809,
        lat: -6.10256863
      },
      {
        lng: 106.67562866,
        lat: -6.10175705
      },
      {
        lng: 106.67554474,
        lat: -6.10028982
      },
      {
        lng: 106.67560577,
        lat: -6.09958315
      },
      {
        lng: 106.67654419,
        lat: -6.09836626
      },
      {
        lng: 106.67836761,
        lat: -6.09789419
      },
      {
        lng: 106.68723297,
        lat: -6.09711933
      },
      {
        lng: 106.68805695,
        lat: -6.10019684
      },
      {
        lng: 106.6880188,
        lat: -6.10335064
      },
      {
        lng: 106.68817902,
        lat: -6.10469818
      },
      {
        lng: 106.68856049,
        lat: -6.10581636
      },
      {
        lng: 106.69203949,
        lat: -6.10693884
      },
      {
        lng: 106.69406891,
        lat: -6.10748339
      },
      {
        lng: 106.69592285,
        lat: -6.10898495
      },
      {
        lng: 106.69746399,
        lat: -6.11163664
      },
      {
        lng: 106.69593811,
        lat: -6.1129694
      },
      {
        lng: 106.69328308,
        lat: -6.11317348
      },
      {
        lng: 106.68901825,
        lat: -6.11424065
      },
      {
        lng: 106.68921661,
        lat: -6.11790228
      },
      {
        lng: 106.68972015,
        lat: -6.11964989
      },
      {
        lng: 106.68961334,
        lat: -6.12145281
      },
      {
        lng: 106.6889267,
        lat: -6.12255287
      },
      {
        lng: 106.68823242,
        lat: -6.12395334
      },
      {
        lng: 106.68747711,
        lat: -6.12678814
      },
      {
        lng: 106.6872406,
        lat: -6.12883997
      },
      {
        lng: 106.68757629,
        lat: -6.12997818
      },
      {
        lng: 106.68880463,
        lat: -6.13142729
      },
      {
        lng: 106.69002533,
        lat: -6.13284492
      },
      {
        lng: 106.69137573,
        lat: -6.13385773
      },
      {
        lng: 106.69156647,
        lat: -6.13569021
      },
      {
        lng: 106.69133759,
        lat: -6.1373539
      },
      {
        lng: 106.69062042,
        lat: -6.13857126
      },
      {
        lng: 106.68621063,
        lat: -6.1430645
      },
      {
        lng: 106.68661499,
        lat: -6.14626217
      },
      {
        lng: 106.68733215,
        lat: -6.14763737
      },
      {
        lng: 106.68760681,
        lat: -6.1507287
      },
      {
        lng: 106.68753815,
        lat: -6.15382433
      },
      {
        lng: 106.68765259,
        lat: -6.15652943
      },
      {
        lng: 106.68822479,
        lat: -6.15997124
      },
      {
        lng: 106.68894196,
        lat: -6.15993595
      },
      {
        lng: 106.68997192,
        lat: -6.16004515
      },
      {
        lng: 106.68934631,
        lat: -6.16158819
      },
      {
        lng: 106.68908691,
        lat: -6.16404772
      },
      {
        lng: 106.68897247,
        lat: -6.1652379
      },
      {
        lng: 106.68942261,
        lat: -6.16705704
      },
      {
        lng: 106.68876648,
        lat: -6.16972017
      },
      {
        lng: 106.6894455,
        lat: -6.17313576
      },
      {
        lng: 106.6908493,
        lat: -6.17342663
      },
      {
        lng: 106.69155121,
        lat: -6.1736002
      },
      {
        lng: 106.69271088,
        lat: -6.17374992
      },
      {
        lng: 106.69366455,
        lat: -6.17372942
      },
      {
        lng: 106.69438934,
        lat: -6.17416477
      },
      {
        lng: 106.69481659,
        lat: -6.17474508
      },
      {
        lng: 106.69572449,
        lat: -6.17490721
      },
      {
        lng: 106.69687653,
        lat: -6.17520523
      },
      {
        lng: 106.69702148,
        lat: -6.17593002
      },
      {
        lng: 106.69868469,
        lat: -6.17660713
      },
      {
        lng: 106.70135498,
        lat: -6.17754841
      },
      {
        lng: 106.70560455,
        lat: -6.17943621
      },
      {
        lng: 106.70724487,
        lat: -6.18037605
      },
      {
        lng: 106.70916748,
        lat: -6.18138695
      },
      {
        lng: 106.71274567,
        lat: -6.18385029
      },
      {
        lng: 106.71708679,
        lat: -6.18703508
      },
      {
        lng: 106.71733856,
        lat: -6.18797779
      },
      {
        lng: 106.71703339,
        lat: -6.18855143
      },
      {
        lng: 106.71674347,
        lat: -6.189147
      },
      {
        lng: 106.71637726,
        lat: -6.19013596
      },
      {
        lng: 106.71655273,
        lat: -6.19202042
      },
      {
        lng: 106.71691132,
        lat: -6.19312239
      },
      {
        lng: 106.71920013,
        lat: -6.19216633
      },
      {
        lng: 106.72148132,
        lat: -6.19144917
      },
      {
        lng: 106.72279358,
        lat: -6.19169331
      },
      {
        lng: 106.7233963,
        lat: -6.19162321
      },
      {
        lng: 106.72444916,
        lat: -6.19313717
      },
      {
        lng: 106.72383881,
        lat: -6.1960268
      },
      {
        lng: 106.72449493,
        lat: -6.19662189
      },
      {
        lng: 106.72375488,
        lat: -6.19764996
      },
      {
        lng: 106.72357178,
        lat: -6.19884253
      },
      {
        lng: 106.72342682,
        lat: -6.19999123
      },
      {
        lng: 106.72382355,
        lat: -6.20240211
      },
      {
        lng: 106.72422028,
        lat: -6.20432568
      },
      {
        lng: 106.72403717,
        lat: -6.20742989
      },
      {
        lng: 106.72333527,
        lat: -6.20827007
      },
      {
        lng: 106.72016907,
        lat: -6.20960426
      },
      {
        lng: 106.71996307,
        lat: -6.21070814
      },
      {
        lng: 106.71993256,
        lat: -6.21206617
      },
      {
        lng: 106.71990204,
        lat: -6.213027
      },
      {
        lng: 106.71980286,
        lat: -6.21411943
      },
      {
        lng: 106.71926117,
        lat: -6.21442318
      },
      {
        lng: 106.71898651,
        lat: -6.21559048
      },
      {
        lng: 106.71853638,
        lat: -6.21663475
      },
      {
        lng: 106.71782684,
        lat: -6.21865749
      },
      {
        lng: 106.71746063,
        lat: -6.22113609
      },
      {
        lng: 106.71766663,
        lat: -6.22310114
      },
      {
        lng: 106.7186203,
        lat: -6.22416878
      },
      {
        lng: 106.72071838,
        lat: -6.22422361
      },
      {
        lng: 106.72270966,
        lat: -6.22425985
      },
      {
        lng: 106.72576141,
        lat: -6.2243371
      },
      {
        lng: 106.72895813,
        lat: -6.22422981
      },
      {
        lng: 106.73058319,
        lat: -6.22415352
      },
      {
        lng: 106.73217773,
        lat: -6.22390795
      },
      {
        lng: 106.73316193,
        lat: -6.22398138
      },
      {
        lng: 106.73400879,
        lat: -6.22398901
      },
      {
        lng: 106.73579407,
        lat: -6.22392702
      },
      {
        lng: 106.73737335,
        lat: -6.22361326
      },
      {
        lng: 106.73897552,
        lat: -6.22524977
      },
      {
        lng: 106.7396698,
        lat: -6.22607756
      },
      {
        lng: 106.74028015,
        lat: -6.2268939
      },
      {
        lng: 106.74269104,
        lat: -6.22719622
      },
      {
        lng: 106.74321747,
        lat: -6.22806311
      },
      {
        lng: 106.74433899,
        lat: -6.22891092
      },
      {
        lng: 106.74538422,
        lat: -6.23072195
      },
      {
        lng: 106.74497223,
        lat: -6.23176384
      },
      {
        lng: 106.74443054,
        lat: -6.23228979
      },
      {
        lng: 106.74460602,
        lat: -6.23386669
      },
      {
        lng: 106.74391937,
        lat: -6.23624229
      },
      {
        lng: 106.74681854,
        lat: -6.23637295
      },
      {
        lng: 106.74810791,
        lat: -6.23973227
      },
      {
        lng: 106.74861145,
        lat: -6.24095392
      },
      {
        lng: 106.74774933,
        lat: -6.24180651
      },
      {
        lng: 106.74727631,
        lat: -6.24250698
      },
      {
        lng: 106.74699402,
        lat: -6.24371147
      },
      {
        lng: 106.74681854,
        lat: -6.24502945
      },
      {
        lng: 106.74667358,
        lat: -6.24666786
      },
      {
        lng: 106.74708557,
        lat: -6.24797249
      },
      {
        lng: 106.74731445,
        lat: -6.24879694
      },
      {
        lng: 106.74815369,
        lat: -6.24961185
      },
      {
        lng: 106.74528503,
        lat: -6.24915838
      },
      {
        lng: 106.74376678,
        lat: -6.24958229
      },
      {
        lng: 106.74250793,
        lat: -6.25000286
      },
      {
        lng: 106.7407074,
        lat: -6.25027847
      },
      {
        lng: 106.73858643,
        lat: -6.25058031
      },
      {
        lng: 106.73727417,
        lat: -6.25069761
      },
      {
        lng: 106.7352829,
        lat: -6.25053263
      },
      {
        lng: 106.73205566,
        lat: -6.25061226
      },
      {
        lng: 106.73027802,
        lat: -6.25077868
      },
      {
        lng: 106.72815704,
        lat: -6.25134468
      },
      {
        lng: 106.72689819,
        lat: -6.2513361
      },
      {
        lng: 106.72592926,
        lat: -6.25148249
      },
      {
        lng: 106.72463226,
        lat: -6.25115919
      },
      {
        lng: 106.72361755,
        lat: -6.25247049
      },
      {
        lng: 106.72255707,
        lat: -6.25257158
      },
      {
        lng: 106.72153473,
        lat: -6.25109434
      },
      {
        lng: 106.7204361,
        lat: -6.25107718
      },
      {
        lng: 106.71908569,
        lat: -6.2521987
      },
      {
        lng: 106.71196747,
        lat: -6.25204039
      },
      {
        lng: 106.7100296,
        lat: -6.25201416
      },
      {
        lng: 106.70600128,
        lat: -6.25255299
      },
      {
        lng: 106.70397186,
        lat: -6.25563669
      },
      {
        lng: 106.7036972,
        lat: -6.25584126
      },
      {
        lng: 106.70282745,
        lat: -6.2549839
      },
      {
        lng: 106.70153046,
        lat: -6.2545619
      },
      {
        lng: 106.69983673,
        lat: -6.25387573
      },
      {
        lng: 106.69847107,
        lat: -6.25236893
      },
      {
        lng: 106.69781494,
        lat: -6.25170803
      },
      {
        lng: 106.69592285,
        lat: -6.25117207
      },
      {
        lng: 106.69631195,
        lat: -6.2496109
      },
      {
        lng: 106.69684601,
        lat: -6.24641466
      },
      {
        lng: 106.69712067,
        lat: -6.24449968
      },
      {
        lng: 106.69471741,
        lat: -6.2442193
      },
      {
        lng: 106.69277954,
        lat: -6.24404097
      },
      {
        lng: 106.69091034,
        lat: -6.24383831
      },
      {
        lng: 106.69041443,
        lat: -6.24268007
      },
      {
        lng: 106.68978119,
        lat: -6.24167967
      },
      {
        lng: 106.68933105,
        lat: -6.24059296
      },
      {
        lng: 106.68812561,
        lat: -6.24027109
      },
      {
        lng: 106.68724823,
        lat: -6.24045658
      },
      {
        lng: 106.6868515,
        lat: -6.2400403
      },
      {
        lng: 106.68648529,
        lat: -6.23981476
      },
      {
        lng: 106.68664551,
        lat: -6.23938894
      },
      {
        lng: 106.68711853,
        lat: -6.23933077
      },
      {
        lng: 106.68663025,
        lat: -6.23850775
      },
      {
        lng: 106.68686676,
        lat: -6.23795033
      },
      {
        lng: 106.68697357,
        lat: -6.23737669
      },
      {
        lng: 106.68691254,
        lat: -6.23702765
      },
      {
        lng: 106.68802643,
        lat: -6.2361722
      },
      {
        lng: 106.68857574,
        lat: -6.23559761
      },
      {
        lng: 106.68851471,
        lat: -6.23496532
      },
      {
        lng: 106.68846893,
        lat: -6.2344842
      },
      {
        lng: 106.68801117,
        lat: -6.23412132
      },
      {
        lng: 106.68859863,
        lat: -6.23350096
      },
      {
        lng: 106.68897247,
        lat: -6.23287773
      },
      {
        lng: 106.6896286,
        lat: -6.23256445
      },
      {
        lng: 106.68968964,
        lat: -6.23301172
      },
      {
        lng: 106.69000244,
        lat: -6.2331419
      },
      {
        lng: 106.69049835,
        lat: -6.23307753
      },
      {
        lng: 106.69075775,
        lat: -6.23286629
      },
      {
        lng: 106.69088745,
        lat: -6.23210192
      },
      {
        lng: 106.69155884,
        lat: -6.23205519
      },
      {
        lng: 106.691185,
        lat: -6.23163128
      },
      {
        lng: 106.69147491,
        lat: -6.23135185
      },
      {
        lng: 106.69232178,
        lat: -6.23164415
      },
      {
        lng: 106.69275665,
        lat: -6.23093319
      },
      {
        lng: 106.69356537,
        lat: -6.23091936
      },
      {
        lng: 106.69420624,
        lat: -6.23012877
      },
      {
        lng: 106.69341278,
        lat: -6.23027563
      },
      {
        lng: 106.69321442,
        lat: -6.22985744
      },
      {
        lng: 106.69171906,
        lat: -6.22962427
      },
      {
        lng: 106.68939972,
        lat: -6.2301445
      },
      {
        lng: 106.68699646,
        lat: -6.23054171
      },
      {
        lng: 106.68437195,
        lat: -6.23100424
      },
      {
        lng: 106.68248749,
        lat: -6.23045874
      },
      {
        lng: 106.68171692,
        lat: -6.23111963
      },
      {
        lng: 106.67888641,
        lat: -6.23095036
      },
      {
        lng: 106.67645264,
        lat: -6.23095465
      },
      {
        lng: 106.67410278,
        lat: -6.2309227
      },
      {
        lng: 106.67260742,
        lat: -6.23105049
      },
      {
        lng: 106.6710968,
        lat: -6.23188496
      },
      {
        lng: 106.66893005,
        lat: -6.23194027
      },
      {
        lng: 106.66731262,
        lat: -6.23182726
      },
      {
        lng: 106.66408539,
        lat: -6.23155785
      },
      {
        lng: 106.66048431,
        lat: -6.23141432
      },
      {
        lng: 106.6561203,
        lat: -6.23060417
      },
      {
        lng: 106.6506424,
        lat: -6.22936153
      },
      {
        lng: 106.64625549,
        lat: -6.22940969
      },
      {
        lng: 106.64569855,
        lat: -6.22893381
      },
      {
        lng: 106.64300537,
        lat: -6.23012781
      },
      {
        lng: 106.64232635,
        lat: -6.22967768
      },
      {
        lng: 106.64077759,
        lat: -6.22972536
      },
      {
        lng: 106.63718414,
        lat: -6.22914076
      },
      {
        lng: 106.63388824,
        lat: -6.22736454
      },
      {
        lng: 106.63077545,
        lat: -6.22564125
      },
      {
        lng: 106.63017273,
        lat: -6.22612762
      },
      {
        lng: 106.62915039,
        lat: -6.22708273
      },
      {
        lng: 106.62863159,
        lat: -6.22751856
      },
      {
        lng: 106.62605286,
        lat: -6.22721004
      },
      {
        lng: 106.62227631,
        lat: -6.22729683
      },
      {
        lng: 106.61928558,
        lat: -6.2274847
      },
      {
        lng: 106.61875916,
        lat: -6.22670937
      },
      {
        lng: 106.61851501,
        lat: -6.22590351
      },
      {
        lng: 106.61813354,
        lat: -6.22540569
      },
      {
        lng: 106.61639404,
        lat: -6.22507858
      },
      {
        lng: 106.61561584,
        lat: -6.22534752
      },
      {
        lng: 106.61466217,
        lat: -6.22590828
      },
      {
        lng: 106.61412048,
        lat: -6.22641897
      },
      {
        lng: 106.61329651,
        lat: -6.22715139
      },
      {
        lng: 106.61217499,
        lat: -6.22732258
      },
      {
        lng: 106.61147308,
        lat: -6.22699881
      },
      {
        lng: 106.61083221,
        lat: -6.2258997
      },
      {
        lng: 106.6108017,
        lat: -6.22515774
      },
      {
        lng: 106.61151886,
        lat: -6.22442198
      },
      {
        lng: 106.61358643,
        lat: -6.22355223
      },
      {
        lng: 106.61345673,
        lat: -6.22055006
      },
      {
        lng: 106.60974121,
        lat: -6.22033358
      },
      {
        lng: 106.61238861,
        lat: -6.21614075
      },
      {
        lng: 106.61218262,
        lat: -6.21560907
      },
      {
        lng: 106.61070251,
        lat: -6.21539211
      },
      {
        lng: 106.60956573,
        lat: -6.2152853
      },
      {
        lng: 106.60730743,
        lat: -6.21488714
      },
      {
        lng: 106.60568237,
        lat: -6.21471119
      },
      {
        lng: 106.60384369,
        lat: -6.21425533
      },
      {
        lng: 106.60295868,
        lat: -6.21358109
      },
      {
        lng: 106.60292816,
        lat: -6.2114954
      },
      {
        lng: 106.60410309,
        lat: -6.20926476
      },
      {
        lng: 106.60324097,
        lat: -6.20692873
      },
      {
        lng: 106.60149384,
        lat: -6.20589924
      },
      {
        lng: 106.60073853,
        lat: -6.20590305
      },
      {
        lng: 106.6004715,
        lat: -6.20654821
      },
      {
        lng: 106.59931946,
        lat: -6.20732117
      },
      {
        lng: 106.59667206,
        lat: -6.20730829
      },
      {
        lng: 106.59607697,
        lat: -6.20588732
      },
      {
        lng: 106.59561157,
        lat: -6.20726013
      },
      {
        lng: 106.59487152,
        lat: -6.20798016
      },
      {
        lng: 106.59455109,
        lat: -6.2089901
      },
      {
        lng: 106.5947876,
        lat: -6.21044588
      },
      {
        lng: 106.59440613,
        lat: -6.2117691
      },
      {
        lng: 106.59372711,
        lat: -6.21363306
      },
      {
        lng: 106.59474945,
        lat: -6.21457911
      },
      {
        lng: 106.59378815,
        lat: -6.21725416
      },
      {
        lng: 106.5936203,
        lat: -6.21809435
      },
      {
        lng: 106.59312439,
        lat: -6.2183547
      },
      {
        lng: 106.59275818,
        lat: -6.21936321
      },
      {
        lng: 106.59127808,
        lat: -6.22057343
      },
      {
        lng: 106.59156036,
        lat: -6.22167921
      },
      {
        lng: 106.59004211,
        lat: -6.22255707
      },
      {
        lng: 106.58802795,
        lat: -6.22232151
      },
      {
        lng: 106.58712769,
        lat: -6.22147036
      },
      {
        lng: 106.58561707,
        lat: -6.22130156
      },
      {
        lng: 106.58447266,
        lat: -6.22111464
      },
      {
        lng: 106.58313751,
        lat: -6.22022629
      },
      {
        lng: 106.58230591,
        lat: -6.21949148
      },
      {
        lng: 106.58065033,
        lat: -6.2176671
      },
      {
        lng: 106.57944489,
        lat: -6.21616077
      },
      {
        lng: 106.57604218,
        lat: -6.21565628
      },
      {
        lng: 106.57334137,
        lat: -6.21432829
      },
      {
        lng: 106.57055664,
        lat: -6.21325493
      },
      {
        lng: 106.56680298,
        lat: -6.21203518
      },
      {
        lng: 106.56490326,
        lat: -6.21124029
      }
    ]
  },
  {
    name: 'Tangerang Selatan',
    prov: 'Banten',
    shape: [
      {
        lng: 106.77106476,
        lat: -6.35718012
      },
      {
        lng: 106.77008057,
        lat: -6.35679054
      },
      {
        lng: 106.76902771,
        lat: -6.35661316
      },
      {
        lng: 106.7633667,
        lat: -6.35680437
      },
      {
        lng: 106.76249695,
        lat: -6.35703564
      },
      {
        lng: 106.76085663,
        lat: -6.35761166
      },
      {
        lng: 106.7600708,
        lat: -6.3583107
      },
      {
        lng: 106.76007843,
        lat: -6.35942411
      },
      {
        lng: 106.75959015,
        lat: -6.35978746
      },
      {
        lng: 106.75942993,
        lat: -6.36078691
      },
      {
        lng: 106.7556839,
        lat: -6.36074114
      },
      {
        lng: 106.75467682,
        lat: -6.36077785
      },
      {
        lng: 106.75293732,
        lat: -6.36066628
      },
      {
        lng: 106.75232697,
        lat: -6.36035299
      },
      {
        lng: 106.75149536,
        lat: -6.36019659
      },
      {
        lng: 106.75042725,
        lat: -6.36041975
      },
      {
        lng: 106.74936676,
        lat: -6.36081457
      },
      {
        lng: 106.7484436,
        lat: -6.36105776
      },
      {
        lng: 106.74182892,
        lat: -6.36070251
      },
      {
        lng: 106.73589325,
        lat: -6.36037636
      },
      {
        lng: 106.73073578,
        lat: -6.36050034
      },
      {
        lng: 106.72424316,
        lat: -6.36003828
      },
      {
        lng: 106.72179413,
        lat: -6.36041546
      },
      {
        lng: 106.72021484,
        lat: -6.36041784
      },
      {
        lng: 106.71971893,
        lat: -6.36105537
      },
      {
        lng: 106.71917725,
        lat: -6.36207247
      },
      {
        lng: 106.71841431,
        lat: -6.36136675
      },
      {
        lng: 106.71138,
        lat: -6.3601861
      },
      {
        lng: 106.70096588,
        lat: -6.36035585
      },
      {
        lng: 106.69645691,
        lat: -6.35928917
      },
      {
        lng: 106.69039154,
        lat: -6.35907507
      },
      {
        lng: 106.67919159,
        lat: -6.3587079
      },
      {
        lng: 106.66786957,
        lat: -6.35794258
      },
      {
        lng: 106.6636734,
        lat: -6.35820007
      },
      {
        lng: 106.6572113,
        lat: -6.36054754
      },
      {
        lng: 106.65116119,
        lat: -6.36220407
      },
      {
        lng: 106.65356445,
        lat: -6.36056042
      },
      {
        lng: 106.65690613,
        lat: -6.358603
      },
      {
        lng: 106.65531158,
        lat: -6.35508013
      },
      {
        lng: 106.65475464,
        lat: -6.35094595
      },
      {
        lng: 106.64697266,
        lat: -6.33998013
      },
      {
        lng: 106.65765381,
        lat: -6.33068991
      },
      {
        lng: 106.65873718,
        lat: -6.32219982
      },
      {
        lng: 106.66174316,
        lat: -6.31836987
      },
      {
        lng: 106.65538025,
        lat: -6.31590986
      },
      {
        lng: 106.65846252,
        lat: -6.30723
      },
      {
        lng: 106.65387726,
        lat: -6.29466009
      },
      {
        lng: 106.65582275,
        lat: -6.28633022
      },
      {
        lng: 106.64996338,
        lat: -6.28392982
      },
      {
        lng: 106.64904022,
        lat: -6.27425003
      },
      {
        lng: 106.64774323,
        lat: -6.26637983
      },
      {
        lng: 106.64624786,
        lat: -6.25595999
      },
      {
        lng: 106.64395905,
        lat: -6.24751997
      },
      {
        lng: 106.64408112,
        lat: -6.24552011
      },
      {
        lng: 106.64433289,
        lat: -6.24248981
      },
      {
        lng: 106.64189148,
        lat: -6.2407999
      },
      {
        lng: 106.63677979,
        lat: -6.23649979
      },
      {
        lng: 106.63780212,
        lat: -6.23414993
      },
      {
        lng: 106.63921356,
        lat: -6.23087597
      },
      {
        lng: 106.64009857,
        lat: -6.22926664
      },
      {
        lng: 106.64169312,
        lat: -6.22966194
      },
      {
        lng: 106.64278412,
        lat: -6.22994089
      },
      {
        lng: 106.64358521,
        lat: -6.23000669
      },
      {
        lng: 106.64591217,
        lat: -6.22925711
      },
      {
        lng: 106.65054321,
        lat: -6.22954607
      },
      {
        lng: 106.6534729,
        lat: -6.22973156
      },
      {
        lng: 106.65969849,
        lat: -6.23095036
      },
      {
        lng: 106.66301727,
        lat: -6.23153687
      },
      {
        lng: 106.66681671,
        lat: -6.23186588
      },
      {
        lng: 106.6686554,
        lat: -6.23191547
      },
      {
        lng: 106.67050171,
        lat: -6.23212051
      },
      {
        lng: 106.67171478,
        lat: -6.23165655
      },
      {
        lng: 106.67372894,
        lat: -6.23104715
      },
      {
        lng: 106.67533875,
        lat: -6.23091888
      },
      {
        lng: 106.67785645,
        lat: -6.23095369
      },
      {
        lng: 106.68019867,
        lat: -6.23118401
      },
      {
        lng: 106.6818161,
        lat: -6.230371
      },
      {
        lng: 106.68382263,
        lat: -6.23068142
      },
      {
        lng: 106.68579865,
        lat: -6.23044777
      },
      {
        lng: 106.68878174,
        lat: -6.22998381
      },
      {
        lng: 106.69004822,
        lat: -6.22981787
      },
      {
        lng: 106.69338226,
        lat: -6.22956753
      },
      {
        lng: 106.69324493,
        lat: -6.23012495
      },
      {
        lng: 106.6939621,
        lat: -6.23019457
      },
      {
        lng: 106.69391632,
        lat: -6.23041868
      },
      {
        lng: 106.69320679,
        lat: -6.23119926
      },
      {
        lng: 106.69244385,
        lat: -6.23129988
      },
      {
        lng: 106.69181824,
        lat: -6.23136187
      },
      {
        lng: 106.69113159,
        lat: -6.23117828
      },
      {
        lng: 106.69145966,
        lat: -6.23190022
      },
      {
        lng: 106.69106293,
        lat: -6.23216152
      },
      {
        lng: 106.69077301,
        lat: -6.23246241
      },
      {
        lng: 106.6905899,
        lat: -6.233253
      },
      {
        lng: 106.6901474,
        lat: -6.23296118
      },
      {
        lng: 106.68965149,
        lat: -6.23335028
      },
      {
        lng: 106.68985748,
        lat: -6.23261833
      },
      {
        lng: 106.68926239,
        lat: -6.23268557
      },
      {
        lng: 106.68867493,
        lat: -6.2332716
      },
      {
        lng: 106.68796539,
        lat: -6.23398924
      },
      {
        lng: 106.68812561,
        lat: -6.23450708
      },
      {
        lng: 106.68865204,
        lat: -6.23474264
      },
      {
        lng: 106.68869019,
        lat: -6.23543501
      },
      {
        lng: 106.68824768,
        lat: -6.2358222
      },
      {
        lng: 106.68730164,
        lat: -6.23681879
      },
      {
        lng: 106.68673706,
        lat: -6.23728991
      },
      {
        lng: 106.68690491,
        lat: -6.23769903
      },
      {
        lng: 106.68650818,
        lat: -6.23831177
      },
      {
        lng: 106.68695831,
        lat: -6.23888779
      },
      {
        lng: 106.6868515,
        lat: -6.23929501
      },
      {
        lng: 106.68655396,
        lat: -6.23964834
      },
      {
        lng: 106.68663788,
        lat: -6.24000072
      },
      {
        lng: 106.6869812,
        lat: -6.24044991
      },
      {
        lng: 106.68750763,
        lat: -6.24022055
      },
      {
        lng: 106.68916321,
        lat: -6.24033499
      },
      {
        lng: 106.68961334,
        lat: -6.24119759
      },
      {
        lng: 106.69019318,
        lat: -6.24231815
      },
      {
        lng: 106.69064331,
        lat: -6.24383211
      },
      {
        lng: 106.69199371,
        lat: -6.24363852
      },
      {
        lng: 106.6938324,
        lat: -6.24424219
      },
      {
        lng: 106.69692993,
        lat: -6.24428844
      },
      {
        lng: 106.69691467,
        lat: -6.24585485
      },
      {
        lng: 106.69648743,
        lat: -6.24890041
      },
      {
        lng: 106.69610596,
        lat: -6.25032043
      },
      {
        lng: 106.6967926,
        lat: -6.25169516
      },
      {
        lng: 106.69828033,
        lat: -6.25178862
      },
      {
        lng: 106.69946289,
        lat: -6.25368261
      },
      {
        lng: 106.70069122,
        lat: -6.25432253
      },
      {
        lng: 106.7022934,
        lat: -6.25481653
      },
      {
        lng: 106.70360565,
        lat: -6.25569201
      },
      {
        lng: 106.70402527,
        lat: -6.25595188
      },
      {
        lng: 106.70414734,
        lat: -6.25331593
      },
      {
        lng: 106.70796967,
        lat: -6.25215197
      },
      {
        lng: 106.71121979,
        lat: -6.25202513
      },
      {
        lng: 106.71455383,
        lat: -6.25209045
      },
      {
        lng: 106.72020721,
        lat: -6.25106764
      },
      {
        lng: 106.72079468,
        lat: -6.25096035
      },
      {
        lng: 106.72245026,
        lat: -6.25229883
      },
      {
        lng: 106.72325897,
        lat: -6.25283527
      },
      {
        lng: 106.72405243,
        lat: -6.25107908
      },
      {
        lng: 106.72559357,
        lat: -6.25136042
      },
      {
        lng: 106.72649384,
        lat: -6.25138474
      },
      {
        lng: 106.72763824,
        lat: -6.25128555
      },
      {
        lng: 106.72888184,
        lat: -6.25087214
      },
      {
        lng: 106.73091888,
        lat: -6.2507019
      },
      {
        lng: 106.73434448,
        lat: -6.2505846
      },
      {
        lng: 106.73686981,
        lat: -6.25051451
      },
      {
        lng: 106.7377243,
        lat: -6.25063467
      },
      {
        lng: 106.73958588,
        lat: -6.25026512
      },
      {
        lng: 106.74165344,
        lat: -6.25017309
      },
      {
        lng: 106.74372101,
        lat: -6.24978018
      },
      {
        lng: 106.74406433,
        lat: -6.24895191
      },
      {
        lng: 106.74671173,
        lat: -6.24942827
      },
      {
        lng: 106.74808502,
        lat: -6.25000286
      },
      {
        lng: 106.74879456,
        lat: -6.25008011
      },
      {
        lng: 106.75045776,
        lat: -6.25004959
      },
      {
        lng: 106.75214386,
        lat: -6.24997425
      },
      {
        lng: 106.75310516,
        lat: -6.25029278
      },
      {
        lng: 106.7531662,
        lat: -6.25092936
      },
      {
        lng: 106.753479,
        lat: -6.25204086
      },
      {
        lng: 106.7536087,
        lat: -6.25271416
      },
      {
        lng: 106.75387573,
        lat: -6.25345755
      },
      {
        lng: 106.75417328,
        lat: -6.25523138
      },
      {
        lng: 106.75467682,
        lat: -6.25567722
      },
      {
        lng: 106.75357056,
        lat: -6.25596333
      },
      {
        lng: 106.75389862,
        lat: -6.25692797
      },
      {
        lng: 106.75424194,
        lat: -6.25913954
      },
      {
        lng: 106.75281525,
        lat: -6.25974226
      },
      {
        lng: 106.7517395,
        lat: -6.2603426
      },
      {
        lng: 106.75147247,
        lat: -6.26146984
      },
      {
        lng: 106.75217438,
        lat: -6.26196623
      },
      {
        lng: 106.75339508,
        lat: -6.26222944
      },
      {
        lng: 106.75437927,
        lat: -6.26195621
      },
      {
        lng: 106.75548553,
        lat: -6.26186895
      },
      {
        lng: 106.75632477,
        lat: -6.26160002
      },
      {
        lng: 106.75584412,
        lat: -6.26257706
      },
      {
        lng: 106.75494385,
        lat: -6.26345491
      },
      {
        lng: 106.75345612,
        lat: -6.26428843
      },
      {
        lng: 106.75245667,
        lat: -6.26469564
      },
      {
        lng: 106.75282288,
        lat: -6.26565552
      },
      {
        lng: 106.75305176,
        lat: -6.26613951
      },
      {
        lng: 106.75350189,
        lat: -6.2671423
      },
      {
        lng: 106.75410461,
        lat: -6.26791906
      },
      {
        lng: 106.75306702,
        lat: -6.26896811
      },
      {
        lng: 106.75196838,
        lat: -6.27012634
      },
      {
        lng: 106.75029755,
        lat: -6.2718401
      },
      {
        lng: 106.75144196,
        lat: -6.27271938
      },
      {
        lng: 106.75293732,
        lat: -6.27449942
      },
      {
        lng: 106.75456238,
        lat: -6.27446842
      },
      {
        lng: 106.75648499,
        lat: -6.27438498
      },
      {
        lng: 106.75714111,
        lat: -6.2748909
      },
      {
        lng: 106.75718689,
        lat: -6.27783966
      },
      {
        lng: 106.76171112,
        lat: -6.27782488
      },
      {
        lng: 106.76197815,
        lat: -6.27942419
      },
      {
        lng: 106.76148224,
        lat: -6.28184032
      },
      {
        lng: 106.7609024,
        lat: -6.28376055
      },
      {
        lng: 106.76102448,
        lat: -6.2847867
      },
      {
        lng: 106.76256561,
        lat: -6.28534842
      },
      {
        lng: 106.7638855,
        lat: -6.28556156
      },
      {
        lng: 106.76431274,
        lat: -6.28628683
      },
      {
        lng: 106.76556396,
        lat: -6.28629732
      },
      {
        lng: 106.76630402,
        lat: -6.28660297
      },
      {
        lng: 106.76670837,
        lat: -6.2871604
      },
      {
        lng: 106.76704407,
        lat: -6.28796434
      },
      {
        lng: 106.76717377,
        lat: -6.28860092
      },
      {
        lng: 106.76609039,
        lat: -6.28902197
      },
      {
        lng: 106.76647186,
        lat: -6.28953075
      },
      {
        lng: 106.76670837,
        lat: -6.29070568
      },
      {
        lng: 106.76765442,
        lat: -6.29155064
      },
      {
        lng: 106.76815033,
        lat: -6.29190445
      },
      {
        lng: 106.76760101,
        lat: -6.29250908
      },
      {
        lng: 106.76820374,
        lat: -6.29353094
      },
      {
        lng: 106.76714325,
        lat: -6.29499674
      },
      {
        lng: 106.76792908,
        lat: -6.29583454
      },
      {
        lng: 106.76953125,
        lat: -6.29557276
      },
      {
        lng: 106.77019501,
        lat: -6.29581356
      },
      {
        lng: 106.76973724,
        lat: -6.29700422
      },
      {
        lng: 106.77014923,
        lat: -6.29775906
      },
      {
        lng: 106.7707901,
        lat: -6.29742718
      },
      {
        lng: 106.77120209,
        lat: -6.29897833
      },
      {
        lng: 106.77200317,
        lat: -6.29877186
      },
      {
        lng: 106.77359009,
        lat: -6.29834604
      },
      {
        lng: 106.77385712,
        lat: -6.29945707
      },
      {
        lng: 106.77288818,
        lat: -6.29929876
      },
      {
        lng: 106.77150726,
        lat: -6.29945755
      },
      {
        lng: 106.77262878,
        lat: -6.30027676
      },
      {
        lng: 106.77312469,
        lat: -6.30149794
      },
      {
        lng: 106.77163696,
        lat: -6.30158091
      },
      {
        lng: 106.77085876,
        lat: -6.30210543
      },
      {
        lng: 106.77162933,
        lat: -6.30324602
      },
      {
        lng: 106.7726593,
        lat: -6.30300093
      },
      {
        lng: 106.77352142,
        lat: -6.3032155
      },
      {
        lng: 106.77288818,
        lat: -6.30441141
      },
      {
        lng: 106.77332306,
        lat: -6.30479527
      },
      {
        lng: 106.77384949,
        lat: -6.30415916
      },
      {
        lng: 106.77462769,
        lat: -6.30328417
      },
      {
        lng: 106.77559662,
        lat: -6.30407476
      },
      {
        lng: 106.77485657,
        lat: -6.30464649
      },
      {
        lng: 106.77507019,
        lat: -6.30546618
      },
      {
        lng: 106.77526855,
        lat: -6.3061862
      },
      {
        lng: 106.77392578,
        lat: -6.3065629
      },
      {
        lng: 106.77466583,
        lat: -6.3077569
      },
      {
        lng: 106.77471924,
        lat: -6.30870867
      },
      {
        lng: 106.77325439,
        lat: -6.30942678
      },
      {
        lng: 106.77371216,
        lat: -6.31000948
      },
      {
        lng: 106.7752533,
        lat: -6.31041718
      },
      {
        lng: 106.77481842,
        lat: -6.31099033
      },
      {
        lng: 106.77448273,
        lat: -6.31173897
      },
      {
        lng: 106.77506256,
        lat: -6.31205702
      },
      {
        lng: 106.77612305,
        lat: -6.31161499
      },
      {
        lng: 106.77645111,
        lat: -6.31306982
      },
      {
        lng: 106.77677917,
        lat: -6.31522894
      },
      {
        lng: 106.77823639,
        lat: -6.31547308
      },
      {
        lng: 106.77986908,
        lat: -6.31634665
      },
      {
        lng: 106.77906036,
        lat: -6.31685352
      },
      {
        lng: 106.7786026,
        lat: -6.3174839
      },
      {
        lng: 106.77928925,
        lat: -6.31777287
      },
      {
        lng: 106.77935791,
        lat: -6.31839991
      },
      {
        lng: 106.77890778,
        lat: -6.31959915
      },
      {
        lng: 106.77885437,
        lat: -6.32060385
      },
      {
        lng: 106.77919769,
        lat: -6.32149363
      },
      {
        lng: 106.77865601,
        lat: -6.32211208
      },
      {
        lng: 106.77772522,
        lat: -6.32325697
      },
      {
        lng: 106.77854156,
        lat: -6.32356501
      },
      {
        lng: 106.77895355,
        lat: -6.32472229
      },
      {
        lng: 106.77888489,
        lat: -6.3255415
      },
      {
        lng: 106.77884674,
        lat: -6.32630444
      },
      {
        lng: 106.77867889,
        lat: -6.32716131
      },
      {
        lng: 106.77776337,
        lat: -6.32673407
      },
      {
        lng: 106.77679443,
        lat: -6.32700586
      },
      {
        lng: 106.77723694,
        lat: -6.32730484
      },
      {
        lng: 106.77762604,
        lat: -6.32764578
      },
      {
        lng: 106.77770233,
        lat: -6.32882547
      },
      {
        lng: 106.77739716,
        lat: -6.3298893
      },
      {
        lng: 106.77680969,
        lat: -6.33049965
      },
      {
        lng: 106.77607727,
        lat: -6.33150244
      },
      {
        lng: 106.77666473,
        lat: -6.33201265
      },
      {
        lng: 106.77617645,
        lat: -6.33273935
      },
      {
        lng: 106.77528381,
        lat: -6.33316278
      },
      {
        lng: 106.77577209,
        lat: -6.33372831
      },
      {
        lng: 106.77585602,
        lat: -6.33435869
      },
      {
        lng: 106.77571106,
        lat: -6.33544731
      },
      {
        lng: 106.77636719,
        lat: -6.33578634
      },
      {
        lng: 106.77576447,
        lat: -6.33615589
      },
      {
        lng: 106.77449799,
        lat: -6.33606386
      },
      {
        lng: 106.77453613,
        lat: -6.3367157
      },
      {
        lng: 106.77498627,
        lat: -6.33750868
      },
      {
        lng: 106.77282715,
        lat: -6.33794594
      },
      {
        lng: 106.77239227,
        lat: -6.33834457
      },
      {
        lng: 106.77336884,
        lat: -6.33857346
      },
      {
        lng: 106.77353668,
        lat: -6.33904791
      },
      {
        lng: 106.77304077,
        lat: -6.33948517
      },
      {
        lng: 106.77335358,
        lat: -6.34032249
      },
      {
        lng: 106.77313232,
        lat: -6.34108877
      },
      {
        lng: 106.77311707,
        lat: -6.34199715
      },
      {
        lng: 106.77359009,
        lat: -6.3427496
      },
      {
        lng: 106.77304077,
        lat: -6.34326315
      },
      {
        lng: 106.77255249,
        lat: -6.34392309
      },
      {
        lng: 106.77280426,
        lat: -6.34436226
      },
      {
        lng: 106.77381134,
        lat: -6.34475136
      },
      {
        lng: 106.77418518,
        lat: -6.34550571
      },
      {
        lng: 106.7742157,
        lat: -6.34604645
      },
      {
        lng: 106.77341461,
        lat: -6.34634066
      },
      {
        lng: 106.77325439,
        lat: -6.34721565
      },
      {
        lng: 106.77381897,
        lat: -6.34789419
      },
      {
        lng: 106.77378082,
        lat: -6.34884167
      },
      {
        lng: 106.77371216,
        lat: -6.35019493
      },
      {
        lng: 106.77284241,
        lat: -6.3502574
      },
      {
        lng: 106.77204895,
        lat: -6.35016012
      },
      {
        lng: 106.77187347,
        lat: -6.35194254
      },
      {
        lng: 106.77146149,
        lat: -6.35215855
      },
      {
        lng: 106.77077484,
        lat: -6.35174465
      },
      {
        lng: 106.77002716,
        lat: -6.35232592
      },
      {
        lng: 106.77015686,
        lat: -6.35326052
      },
      {
        lng: 106.77105713,
        lat: -6.35442114
      },
      {
        lng: 106.77087402,
        lat: -6.35526943
      },
      {
        lng: 106.77101135,
        lat: -6.35651731
      }
    ]
  },
  {
    name: 'Jakarta Barat',
    prov: 'Jakarta Raya',
    shape: [
      {
        lng: 106.80799866,
        lat: -6.16137981
      },
      {
        lng: 106.8062973,
        lat: -6.16156435
      },
      {
        lng: 106.80457306,
        lat: -6.16179705
      },
      {
        lng: 106.802742,
        lat: -6.16119814
      },
      {
        lng: 106.80197144,
        lat: -6.16019917
      },
      {
        lng: 106.80145264,
        lat: -6.15826225
      },
      {
        lng: 106.80098724,
        lat: -6.15978622
      },
      {
        lng: 106.7986145,
        lat: -6.16118622
      },
      {
        lng: 106.79859924,
        lat: -6.16243744
      },
      {
        lng: 106.79944611,
        lat: -6.16455364
      },
      {
        lng: 106.80053711,
        lat: -6.16632509
      },
      {
        lng: 106.80209351,
        lat: -6.16858482
      },
      {
        lng: 106.80399323,
        lat: -6.17131138
      },
      {
        lng: 106.80547333,
        lat: -6.17348909
      },
      {
        lng: 106.80634308,
        lat: -6.17474079
      },
      {
        lng: 106.80792999,
        lat: -6.17698526
      },
      {
        lng: 106.80926514,
        lat: -6.17882538
      },
      {
        lng: 106.80986023,
        lat: -6.18011189
      },
      {
        lng: 106.81011963,
        lat: -6.18267298
      },
      {
        lng: 106.81033325,
        lat: -6.18495989
      },
      {
        lng: 106.81057739,
        lat: -6.18741703
      },
      {
        lng: 106.81054688,
        lat: -6.18870974
      },
      {
        lng: 106.80830383,
        lat: -6.18879986
      },
      {
        lng: 106.80704498,
        lat: -6.18893242
      },
      {
        lng: 106.80644226,
        lat: -6.19035149
      },
      {
        lng: 106.80550385,
        lat: -6.19275761
      },
      {
        lng: 106.80509186,
        lat: -6.19555235
      },
      {
        lng: 106.80440521,
        lat: -6.19713306
      },
      {
        lng: 106.80356598,
        lat: -6.19788122
      },
      {
        lng: 106.80266571,
        lat: -6.19908333
      },
      {
        lng: 106.80059814,
        lat: -6.200037
      },
      {
        lng: 106.79955292,
        lat: -6.20111465
      },
      {
        lng: 106.79888153,
        lat: -6.20210743
      },
      {
        lng: 106.79771423,
        lat: -6.20313692
      },
      {
        lng: 106.79719543,
        lat: -6.2039156
      },
      {
        lng: 106.79646301,
        lat: -6.20590878
      },
      {
        lng: 106.7950058,
        lat: -6.20764303
      },
      {
        lng: 106.79290771,
        lat: -6.20779562
      },
      {
        lng: 106.79156494,
        lat: -6.20762539
      },
      {
        lng: 106.78952026,
        lat: -6.20829487
      },
      {
        lng: 106.78839111,
        lat: -6.20815754
      },
      {
        lng: 106.78773499,
        lat: -6.20735884
      },
      {
        lng: 106.78632355,
        lat: -6.20689726
      },
      {
        lng: 106.78397369,
        lat: -6.20673943
      },
      {
        lng: 106.78321075,
        lat: -6.20790005
      },
      {
        lng: 106.78347778,
        lat: -6.21043348
      },
      {
        lng: 106.78348541,
        lat: -6.21241426
      },
      {
        lng: 106.78278351,
        lat: -6.21321869
      },
      {
        lng: 106.78070068,
        lat: -6.2133646
      },
      {
        lng: 106.77876282,
        lat: -6.21347189
      },
      {
        lng: 106.77762604,
        lat: -6.21407747
      },
      {
        lng: 106.77754974,
        lat: -6.21538687
      },
      {
        lng: 106.77707672,
        lat: -6.21657848
      },
      {
        lng: 106.77794647,
        lat: -6.21732712
      },
      {
        lng: 106.7784729,
        lat: -6.21832037
      },
      {
        lng: 106.77848053,
        lat: -6.22038651
      },
      {
        lng: 106.77846527,
        lat: -6.22215033
      },
      {
        lng: 106.77845764,
        lat: -6.22438717
      },
      {
        lng: 106.77700806,
        lat: -6.22485399
      },
      {
        lng: 106.77162933,
        lat: -6.22507048
      },
      {
        lng: 106.76808167,
        lat: -6.22521257
      },
      {
        lng: 106.76644135,
        lat: -6.22454882
      },
      {
        lng: 106.76563263,
        lat: -6.22333002
      },
      {
        lng: 106.76593781,
        lat: -6.22258997
      },
      {
        lng: 106.76535034,
        lat: -6.22232771
      },
      {
        lng: 106.76455688,
        lat: -6.22233534
      },
      {
        lng: 106.76193237,
        lat: -6.22238922
      },
      {
        lng: 106.76033783,
        lat: -6.22204018
      },
      {
        lng: 106.76012421,
        lat: -6.2225709
      },
      {
        lng: 106.76009369,
        lat: -6.22312784
      },
      {
        lng: 106.75699615,
        lat: -6.22314978
      },
      {
        lng: 106.75244141,
        lat: -6.22318411
      },
      {
        lng: 106.74825287,
        lat: -6.22314501
      },
      {
        lng: 106.74468231,
        lat: -6.22297955
      },
      {
        lng: 106.73947144,
        lat: -6.22328997
      },
      {
        lng: 106.73686218,
        lat: -6.22377872
      },
      {
        lng: 106.73532867,
        lat: -6.22395134
      },
      {
        lng: 106.73377991,
        lat: -6.2239747
      },
      {
        lng: 106.73283386,
        lat: -6.22397518
      },
      {
        lng: 106.73130035,
        lat: -6.22404861
      },
      {
        lng: 106.73016357,
        lat: -6.22416115
      },
      {
        lng: 106.72759247,
        lat: -6.22431183
      },
      {
        lng: 106.72390747,
        lat: -6.22427607
      },
      {
        lng: 106.72236633,
        lat: -6.22425222
      },
      {
        lng: 106.72016907,
        lat: -6.22420883
      },
      {
        lng: 106.7181015,
        lat: -6.22417355
      },
      {
        lng: 106.71768951,
        lat: -6.22287035
      },
      {
        lng: 106.71746826,
        lat: -6.22031403
      },
      {
        lng: 106.71810913,
        lat: -6.21781397
      },
      {
        lng: 106.71858215,
        lat: -6.21608448
      },
      {
        lng: 106.71913147,
        lat: -6.21485472
      },
      {
        lng: 106.71958923,
        lat: -6.21423197
      },
      {
        lng: 106.71961212,
        lat: -6.21400738
      },
      {
        lng: 106.71987152,
        lat: -6.21261454
      },
      {
        lng: 106.72005463,
        lat: -6.21129704
      },
      {
        lng: 106.72009277,
        lat: -6.21046734
      },
      {
        lng: 106.72122955,
        lat: -6.20911694
      },
      {
        lng: 106.72381592,
        lat: -6.20799875
      },
      {
        lng: 106.72408295,
        lat: -6.20587397
      },
      {
        lng: 106.72415924,
        lat: -6.20402527
      },
      {
        lng: 106.72358704,
        lat: -6.20032167
      },
      {
        lng: 106.72344208,
        lat: -6.19977522
      },
      {
        lng: 106.72395325,
        lat: -6.19829559
      },
      {
        lng: 106.72405243,
        lat: -6.19727468
      },
      {
        lng: 106.72423553,
        lat: -6.19642687
      },
      {
        lng: 106.72427368,
        lat: -6.19520664
      },
      {
        lng: 106.72418976,
        lat: -6.19212914
      },
      {
        lng: 106.72325134,
        lat: -6.19173288
      },
      {
        lng: 106.72235107,
        lat: -6.19144106
      },
      {
        lng: 106.7207489,
        lat: -6.19163179
      },
      {
        lng: 106.71765137,
        lat: -6.19302845
      },
      {
        lng: 106.71682739,
        lat: -6.19283581
      },
      {
        lng: 106.71644592,
        lat: -6.19134951
      },
      {
        lng: 106.71648407,
        lat: -6.18982792
      },
      {
        lng: 106.7169342,
        lat: -6.18902731
      },
      {
        lng: 106.71723938,
        lat: -6.18838644
      },
      {
        lng: 106.71726227,
        lat: -6.187397
      },
      {
        lng: 106.71495819,
        lat: -6.18551779
      },
      {
        lng: 106.7114563,
        lat: -6.18283749
      },
      {
        lng: 106.7085495,
        lat: -6.18112135
      },
      {
        lng: 106.70652771,
        lat: -6.18001747
      },
      {
        lng: 106.70488739,
        lat: -6.17906427
      },
      {
        lng: 106.70043182,
        lat: -6.17710018
      },
      {
        lng: 106.69750214,
        lat: -6.176332
      },
      {
        lng: 106.69729614,
        lat: -6.17530012
      },
      {
        lng: 106.69661713,
        lat: -6.17521572
      },
      {
        lng: 106.69519043,
        lat: -6.17481327
      },
      {
        lng: 106.69470215,
        lat: -6.17460775
      },
      {
        lng: 106.69431305,
        lat: -6.173985
      },
      {
        lng: 106.69316101,
        lat: -6.17367458
      },
      {
        lng: 106.69212341,
        lat: -6.17373228
      },
      {
        lng: 106.69113159,
        lat: -6.17350292
      },
      {
        lng: 106.69042969,
        lat: -6.17334652
      },
      {
        lng: 106.68882751,
        lat: -6.17212677
      },
      {
        lng: 106.68896484,
        lat: -6.16854811
      },
      {
        lng: 106.68937683,
        lat: -6.16640186
      },
      {
        lng: 106.68914032,
        lat: -6.16464615
      },
      {
        lng: 106.68901825,
        lat: -6.16336346
      },
      {
        lng: 106.68959045,
        lat: -6.16126204
      },
      {
        lng: 106.68962097,
        lat: -6.16000891
      },
      {
        lng: 106.68865204,
        lat: -6.15995312
      },
      {
        lng: 106.68780518,
        lat: -6.15997982
      },
      {
        lng: 106.68746185,
        lat: -6.15495825
      },
      {
        lng: 106.68770599,
        lat: -6.15291739
      },
      {
        lng: 106.68755341,
        lat: -6.14967871
      },
      {
        lng: 106.68682098,
        lat: -6.14738989
      },
      {
        lng: 106.68630219,
        lat: -6.1442461
      },
      {
        lng: 106.68836212,
        lat: -6.14164495
      },
      {
        lng: 106.69126892,
        lat: -6.13765812
      },
      {
        lng: 106.69167328,
        lat: -6.13676071
      },
      {
        lng: 106.69125366,
        lat: -6.13489294
      },
      {
        lng: 106.69089508,
        lat: -6.13329649
      },
      {
        lng: 106.68937683,
        lat: -6.13250875
      },
      {
        lng: 106.68844604,
        lat: -6.1306386
      },
      {
        lng: 106.68730927,
        lat: -6.12959719
      },
      {
        lng: 106.68708038,
        lat: -6.12862206
      },
      {
        lng: 106.68797302,
        lat: -6.12464714
      },
      {
        lng: 106.68862915,
        lat: -6.12315607
      },
      {
        lng: 106.68933105,
        lat: -6.1218214
      },
      {
        lng: 106.68972015,
        lat: -6.1209321
      },
      {
        lng: 106.68951416,
        lat: -6.11876869
      },
      {
        lng: 106.68911743,
        lat: -6.11555576
      },
      {
        lng: 106.69106293,
        lat: -6.11369896
      },
      {
        lng: 106.69429016,
        lat: -6.11291313
      },
      {
        lng: 106.6964035,
        lat: -6.11287689
      },
      {
        lng: 106.69763184,
        lat: -6.11064577
      },
      {
        lng: 106.69503021,
        lat: -6.1082077
      },
      {
        lng: 106.69340515,
        lat: -6.10706949
      },
      {
        lng: 106.68909454,
        lat: -6.10648537
      },
      {
        lng: 106.6884079,
        lat: -6.10540676
      },
      {
        lng: 106.68808746,
        lat: -6.10382795
      },
      {
        lng: 106.68811035,
        lat: -6.10160351
      },
      {
        lng: 106.68789673,
        lat: -6.0992012
      },
      {
        lng: 106.68840027,
        lat: -6.09670639
      },
      {
        lng: 106.69139099,
        lat: -6.09652472
      },
      {
        lng: 106.69333649,
        lat: -6.09687567
      },
      {
        lng: 106.69489288,
        lat: -6.09622192
      },
      {
        lng: 106.69665527,
        lat: -6.09641886
      },
      {
        lng: 106.69836426,
        lat: -6.09674215
      },
      {
        lng: 106.70059204,
        lat: -6.09629059
      },
      {
        lng: 106.70214844,
        lat: -6.09676075
      },
      {
        lng: 106.70420074,
        lat: -6.09560633
      },
      {
        lng: 106.70553589,
        lat: -6.09534836
      },
      {
        lng: 106.7064743,
        lat: -6.09609222
      },
      {
        lng: 106.70755005,
        lat: -6.09562874
      },
      {
        lng: 106.70954895,
        lat: -6.09512424
      },
      {
        lng: 106.71133423,
        lat: -6.09591341
      },
      {
        lng: 106.71234894,
        lat: -6.09674454
      },
      {
        lng: 106.7133255,
        lat: -6.09795094
      },
      {
        lng: 106.71424103,
        lat: -6.09886551
      },
      {
        lng: 106.71508789,
        lat: -6.10070372
      },
      {
        lng: 106.71600342,
        lat: -6.10246325
      },
      {
        lng: 106.71730042,
        lat: -6.10475492
      },
      {
        lng: 106.71804047,
        lat: -6.1054883
      },
      {
        lng: 106.71917725,
        lat: -6.10690594
      },
      {
        lng: 106.7203598,
        lat: -6.10856438
      },
      {
        lng: 106.72246552,
        lat: -6.1113472
      },
      {
        lng: 106.72412872,
        lat: -6.11348343
      },
      {
        lng: 106.72545624,
        lat: -6.1150074
      },
      {
        lng: 106.72678375,
        lat: -6.11677551
      },
      {
        lng: 106.72900391,
        lat: -6.11907053
      },
      {
        lng: 106.73000336,
        lat: -6.12000608
      },
      {
        lng: 106.73130798,
        lat: -6.12131453
      },
      {
        lng: 106.73222351,
        lat: -6.12210941
      },
      {
        lng: 106.73325348,
        lat: -6.12316132
      },
      {
        lng: 106.73445129,
        lat: -6.12382793
      },
      {
        lng: 106.73605347,
        lat: -6.12503386
      },
      {
        lng: 106.73752594,
        lat: -6.12614107
      },
      {
        lng: 106.74003601,
        lat: -6.12769651
      },
      {
        lng: 106.74160767,
        lat: -6.12842751
      },
      {
        lng: 106.74419403,
        lat: -6.12964487
      },
      {
        lng: 106.74575043,
        lat: -6.13071823
      },
      {
        lng: 106.74720764,
        lat: -6.13166046
      },
      {
        lng: 106.74856567,
        lat: -6.13272953
      },
      {
        lng: 106.74948883,
        lat: -6.13356352
      },
      {
        lng: 106.75085449,
        lat: -6.13442707
      },
      {
        lng: 106.75231934,
        lat: -6.13486576
      },
      {
        lng: 106.75283813,
        lat: -6.1351552
      },
      {
        lng: 106.75589752,
        lat: -6.13646936
      },
      {
        lng: 106.75843048,
        lat: -6.13732433
      },
      {
        lng: 106.76072693,
        lat: -6.13809633
      },
      {
        lng: 106.76307678,
        lat: -6.13888264
      },
      {
        lng: 106.76545715,
        lat: -6.13973522
      },
      {
        lng: 106.768013,
        lat: -6.14060354
      },
      {
        lng: 106.76916504,
        lat: -6.14087868
      },
      {
        lng: 106.77095795,
        lat: -6.14117718
      },
      {
        lng: 106.77249146,
        lat: -6.14143133
      },
      {
        lng: 106.77394104,
        lat: -6.14139843
      },
      {
        lng: 106.77545929,
        lat: -6.14171648
      },
      {
        lng: 106.77532196,
        lat: -6.14291096
      },
      {
        lng: 106.77526093,
        lat: -6.14429474
      },
      {
        lng: 106.77565002,
        lat: -6.14494038
      },
      {
        lng: 106.77765656,
        lat: -6.14402437
      },
      {
        lng: 106.77924347,
        lat: -6.14393377
      },
      {
        lng: 106.78112793,
        lat: -6.14377117
      },
      {
        lng: 106.78381348,
        lat: -6.14354897
      },
      {
        lng: 106.78733826,
        lat: -6.14323521
      },
      {
        lng: 106.7885437,
        lat: -6.1428318
      },
      {
        lng: 106.7904129,
        lat: -6.14235497
      },
      {
        lng: 106.79217529,
        lat: -6.14280653
      },
      {
        lng: 106.79347229,
        lat: -6.1426878
      },
      {
        lng: 106.79611969,
        lat: -6.14246178
      },
      {
        lng: 106.7974472,
        lat: -6.14232779
      },
      {
        lng: 106.79927826,
        lat: -6.14199734
      },
      {
        lng: 106.80052185,
        lat: -6.14188719
      },
      {
        lng: 106.80036163,
        lat: -6.13897753
      },
      {
        lng: 106.8002243,
        lat: -6.13648272
      },
      {
        lng: 106.80084229,
        lat: -6.13399315
      },
      {
        lng: 106.80432129,
        lat: -6.13241577
      },
      {
        lng: 106.80597687,
        lat: -6.13198566
      },
      {
        lng: 106.80698395,
        lat: -6.13173246
      },
      {
        lng: 106.80927277,
        lat: -6.13113022
      },
      {
        lng: 106.81044006,
        lat: -6.13083982
      },
      {
        lng: 106.81318665,
        lat: -6.13012409
      },
      {
        lng: 106.81481934,
        lat: -6.13019562
      },
      {
        lng: 106.81554413,
        lat: -6.13230896
      },
      {
        lng: 106.81627655,
        lat: -6.13482618
      },
      {
        lng: 106.81674194,
        lat: -6.1370635
      },
      {
        lng: 106.81867981,
        lat: -6.13658428
      },
      {
        lng: 106.82046509,
        lat: -6.13642073
      },
      {
        lng: 106.82164764,
        lat: -6.13731289
      },
      {
        lng: 106.82219696,
        lat: -6.13912153
      },
      {
        lng: 106.82260895,
        lat: -6.14039946
      },
      {
        lng: 106.82388306,
        lat: -6.14270544
      },
      {
        lng: 106.82517242,
        lat: -6.14482307
      },
      {
        lng: 106.82655334,
        lat: -6.14788532
      },
      {
        lng: 106.826828,
        lat: -6.14898586
      },
      {
        lng: 106.82723236,
        lat: -6.15176344
      },
      {
        lng: 106.82737732,
        lat: -6.15544939
      },
      {
        lng: 106.82743073,
        lat: -6.15826845
      },
      {
        lng: 106.82775879,
        lat: -6.16117573
      },
      {
        lng: 106.82792664,
        lat: -6.16214132
      },
      {
        lng: 106.82662964,
        lat: -6.16172361
      },
      {
        lng: 106.82539368,
        lat: -6.16128063
      },
      {
        lng: 106.82364655,
        lat: -6.16066408
      },
      {
        lng: 106.82195282,
        lat: -6.16005945
      },
      {
        lng: 106.82028198,
        lat: -6.15954828
      },
      {
        lng: 106.81882477,
        lat: -6.15978765
      },
      {
        lng: 106.81625366,
        lat: -6.16019583
      },
      {
        lng: 106.81327057,
        lat: -6.16064167
      },
      {
        lng: 106.8123703,
        lat: -6.16069937
      },
      {
        lng: 106.81085205,
        lat: -6.16080379
      },
      {
        lng: 106.80973816,
        lat: -6.16112518
      },
      {
        lng: 106.80815887,
        lat: -6.16134691
      }
    ]
  },
  {
    name: 'Jakarta Pusat',
    prov: 'Jakarta Raya',
    shape: [
      {
        lng: 106.84905243,
        lat: -6.2075429
      },
      {
        lng: 106.84815216,
        lat: -6.20792007
      },
      {
        lng: 106.84739685,
        lat: -6.20850468
      },
      {
        lng: 106.84628296,
        lat: -6.20856762
      },
      {
        lng: 106.84482574,
        lat: -6.20812559
      },
      {
        lng: 106.84285736,
        lat: -6.20744276
      },
      {
        lng: 106.84078217,
        lat: -6.20668173
      },
      {
        lng: 106.83863068,
        lat: -6.20592308
      },
      {
        lng: 106.83744812,
        lat: -6.20547867
      },
      {
        lng: 106.8358078,
        lat: -6.20518446
      },
      {
        lng: 106.833992,
        lat: -6.20492172
      },
      {
        lng: 106.83041382,
        lat: -6.20439863
      },
      {
        lng: 106.82910156,
        lat: -6.20411491
      },
      {
        lng: 106.8272171,
        lat: -6.20369339
      },
      {
        lng: 106.82540131,
        lat: -6.20329475
      },
      {
        lng: 106.82269287,
        lat: -6.20278454
      },
      {
        lng: 106.82238007,
        lat: -6.20423937
      },
      {
        lng: 106.8221283,
        lat: -6.20622826
      },
      {
        lng: 106.82175446,
        lat: -6.20876646
      },
      {
        lng: 106.82098389,
        lat: -6.21066284
      },
      {
        lng: 106.82006836,
        lat: -6.21235514
      },
      {
        lng: 106.81924438,
        lat: -6.21360254
      },
      {
        lng: 106.81851959,
        lat: -6.21443319
      },
      {
        lng: 106.81643677,
        lat: -6.21619129
      },
      {
        lng: 106.81524658,
        lat: -6.21711969
      },
      {
        lng: 106.81288147,
        lat: -6.21900368
      },
      {
        lng: 106.81233978,
        lat: -6.21940804
      },
      {
        lng: 106.81174469,
        lat: -6.21993685
      },
      {
        lng: 106.81008148,
        lat: -6.22124672
      },
      {
        lng: 106.80942535,
        lat: -6.22170925
      },
      {
        lng: 106.8086319,
        lat: -6.22231436
      },
      {
        lng: 106.80647278,
        lat: -6.22374487
      },
      {
        lng: 106.8052063,
        lat: -6.22465181
      },
      {
        lng: 106.80426788,
        lat: -6.225348
      },
      {
        lng: 106.8017807,
        lat: -6.22724009
      },
      {
        lng: 106.8008728,
        lat: -6.22790194
      },
      {
        lng: 106.79930115,
        lat: -6.22900534
      },
      {
        lng: 106.79833221,
        lat: -6.22885323
      },
      {
        lng: 106.7973175,
        lat: -6.22929621
      },
      {
        lng: 106.79575348,
        lat: -6.22915745
      },
      {
        lng: 106.79561615,
        lat: -6.22841501
      },
      {
        lng: 106.7963028,
        lat: -6.22514486
      },
      {
        lng: 106.79663849,
        lat: -6.22006893
      },
      {
        lng: 106.79684448,
        lat: -6.21816826
      },
      {
        lng: 106.79613495,
        lat: -6.21662521
      },
      {
        lng: 106.79624939,
        lat: -6.21581554
      },
      {
        lng: 106.79553986,
        lat: -6.21408033
      },
      {
        lng: 106.79541779,
        lat: -6.21286345
      },
      {
        lng: 106.795578,
        lat: -6.21235704
      },
      {
        lng: 106.79470062,
        lat: -6.21191072
      },
      {
        lng: 106.79401398,
        lat: -6.21193981
      },
      {
        lng: 106.79350281,
        lat: -6.21174526
      },
      {
        lng: 106.7937088,
        lat: -6.21119881
      },
      {
        lng: 106.79296112,
        lat: -6.21045351
      },
      {
        lng: 106.79267883,
        lat: -6.20960855
      },
      {
        lng: 106.79190826,
        lat: -6.20898628
      },
      {
        lng: 106.79212189,
        lat: -6.20817614
      },
      {
        lng: 106.79301453,
        lat: -6.20777512
      },
      {
        lng: 106.79563141,
        lat: -6.20761442
      },
      {
        lng: 106.79659271,
        lat: -6.20549917
      },
      {
        lng: 106.79727936,
        lat: -6.2038002
      },
      {
        lng: 106.79784393,
        lat: -6.2029767
      },
      {
        lng: 106.79895782,
        lat: -6.20205212
      },
      {
        lng: 106.79961395,
        lat: -6.20100403
      },
      {
        lng: 106.80075073,
        lat: -6.19997549
      },
      {
        lng: 106.8028717,
        lat: -6.19894171
      },
      {
        lng: 106.80361938,
        lat: -6.19782734
      },
      {
        lng: 106.80451965,
        lat: -6.19693661
      },
      {
        lng: 106.80527496,
        lat: -6.19441557
      },
      {
        lng: 106.80587769,
        lat: -6.19184017
      },
      {
        lng: 106.80647278,
        lat: -6.19020653
      },
      {
        lng: 106.80714417,
        lat: -6.18890047
      },
      {
        lng: 106.808815,
        lat: -6.18873453
      },
      {
        lng: 106.81065369,
        lat: -6.18869781
      },
      {
        lng: 106.8105011,
        lat: -6.1868391
      },
      {
        lng: 106.81031036,
        lat: -6.18479013
      },
      {
        lng: 106.81005859,
        lat: -6.18219566
      },
      {
        lng: 106.80978394,
        lat: -6.17973423
      },
      {
        lng: 106.80908966,
        lat: -6.17859459
      },
      {
        lng: 106.80754852,
        lat: -6.17647076
      },
      {
        lng: 106.80617523,
        lat: -6.17447853
      },
      {
        lng: 106.80516815,
        lat: -6.17304993
      },
      {
        lng: 106.80378723,
        lat: -6.17103291
      },
      {
        lng: 106.80152893,
        lat: -6.16776896
      },
      {
        lng: 106.80027008,
        lat: -6.16593027
      },
      {
        lng: 106.79925537,
        lat: -6.16415834
      },
      {
        lng: 106.79841614,
        lat: -6.16199446
      },
      {
        lng: 106.80023956,
        lat: -6.1604743
      },
      {
        lng: 106.80119324,
        lat: -6.15938234
      },
      {
        lng: 106.80158997,
        lat: -6.16011238
      },
      {
        lng: 106.80204773,
        lat: -6.16030741
      },
      {
        lng: 106.80309296,
        lat: -6.16146421
      },
      {
        lng: 106.80470276,
        lat: -6.16179085
      },
      {
        lng: 106.80651855,
        lat: -6.16153908
      },
      {
        lng: 106.80815887,
        lat: -6.16134691
      },
      {
        lng: 106.80973816,
        lat: -6.16112518
      },
      {
        lng: 106.81085205,
        lat: -6.16080379
      },
      {
        lng: 106.8123703,
        lat: -6.16069937
      },
      {
        lng: 106.81327057,
        lat: -6.16064167
      },
      {
        lng: 106.81625366,
        lat: -6.16019583
      },
      {
        lng: 106.81882477,
        lat: -6.15978765
      },
      {
        lng: 106.82028198,
        lat: -6.15954828
      },
      {
        lng: 106.82195282,
        lat: -6.16005945
      },
      {
        lng: 106.82364655,
        lat: -6.16066408
      },
      {
        lng: 106.82539368,
        lat: -6.16128063
      },
      {
        lng: 106.82662964,
        lat: -6.16172361
      },
      {
        lng: 106.82792664,
        lat: -6.16214132
      },
      {
        lng: 106.82775879,
        lat: -6.16117573
      },
      {
        lng: 106.82743073,
        lat: -6.15826845
      },
      {
        lng: 106.82737732,
        lat: -6.15544939
      },
      {
        lng: 106.82723236,
        lat: -6.15176344
      },
      {
        lng: 106.826828,
        lat: -6.14898586
      },
      {
        lng: 106.82655334,
        lat: -6.14788532
      },
      {
        lng: 106.82517242,
        lat: -6.14482307
      },
      {
        lng: 106.82388306,
        lat: -6.14270544
      },
      {
        lng: 106.82260895,
        lat: -6.14039946
      },
      {
        lng: 106.82219696,
        lat: -6.13912153
      },
      {
        lng: 106.82164764,
        lat: -6.13731289
      },
      {
        lng: 106.82283783,
        lat: -6.136621
      },
      {
        lng: 106.82553101,
        lat: -6.13624382
      },
      {
        lng: 106.82626343,
        lat: -6.13614655
      },
      {
        lng: 106.82862854,
        lat: -6.13569546
      },
      {
        lng: 106.83005524,
        lat: -6.13546991
      },
      {
        lng: 106.83067322,
        lat: -6.13596725
      },
      {
        lng: 106.83080292,
        lat: -6.13973999
      },
      {
        lng: 106.83246613,
        lat: -6.14137697
      },
      {
        lng: 106.83348846,
        lat: -6.14171696
      },
      {
        lng: 106.83567047,
        lat: -6.14317179
      },
      {
        lng: 106.8371048,
        lat: -6.14594173
      },
      {
        lng: 106.83770752,
        lat: -6.14332628
      },
      {
        lng: 106.83850861,
        lat: -6.14186621
      },
      {
        lng: 106.84111023,
        lat: -6.14329529
      },
      {
        lng: 106.84338379,
        lat: -6.14459515
      },
      {
        lng: 106.84314728,
        lat: -6.14634275
      },
      {
        lng: 106.84345245,
        lat: -6.14718723
      },
      {
        lng: 106.84197235,
        lat: -6.14946842
      },
      {
        lng: 106.8412323,
        lat: -6.1513238
      },
      {
        lng: 106.84259033,
        lat: -6.15293121
      },
      {
        lng: 106.84487915,
        lat: -6.15386152
      },
      {
        lng: 106.84713745,
        lat: -6.153265
      },
      {
        lng: 106.85011292,
        lat: -6.15280724
      },
      {
        lng: 106.85179901,
        lat: -6.15255213
      },
      {
        lng: 106.85356903,
        lat: -6.15224123
      },
      {
        lng: 106.85525513,
        lat: -6.15196133
      },
      {
        lng: 106.85849762,
        lat: -6.15280914
      },
      {
        lng: 106.86094666,
        lat: -6.1509347
      },
      {
        lng: 106.8609848,
        lat: -6.15233183
      },
      {
        lng: 106.86029816,
        lat: -6.15375471
      },
      {
        lng: 106.86235046,
        lat: -6.15504122
      },
      {
        lng: 106.86529541,
        lat: -6.15665436
      },
      {
        lng: 106.86685944,
        lat: -6.15754747
      },
      {
        lng: 106.86920166,
        lat: -6.15887642
      },
      {
        lng: 106.87044525,
        lat: -6.15955925
      },
      {
        lng: 106.87310028,
        lat: -6.16108465
      },
      {
        lng: 106.87535858,
        lat: -6.16249275
      },
      {
        lng: 106.87700653,
        lat: -6.16353703
      },
      {
        lng: 106.8794632,
        lat: -6.16296864
      },
      {
        lng: 106.88051605,
        lat: -6.16271687
      },
      {
        lng: 106.8812027,
        lat: -6.16254854
      },
      {
        lng: 106.88111115,
        lat: -6.16288042
      },
      {
        lng: 106.8797226,
        lat: -6.16511631
      },
      {
        lng: 106.87889862,
        lat: -6.16627026
      },
      {
        lng: 106.87813568,
        lat: -6.16792059
      },
      {
        lng: 106.87659454,
        lat: -6.17355919
      },
      {
        lng: 106.87599945,
        lat: -6.17657328
      },
      {
        lng: 106.87575531,
        lat: -6.18000364
      },
      {
        lng: 106.87548828,
        lat: -6.18561506
      },
      {
        lng: 106.87533569,
        lat: -6.18874311
      },
      {
        lng: 106.87509155,
        lat: -6.19190025
      },
      {
        lng: 106.87385559,
        lat: -6.19215679
      },
      {
        lng: 106.87234497,
        lat: -6.19200134
      },
      {
        lng: 106.86972809,
        lat: -6.19212914
      },
      {
        lng: 106.86708832,
        lat: -6.1923728
      },
      {
        lng: 106.86587524,
        lat: -6.19248676
      },
      {
        lng: 106.86251068,
        lat: -6.19347906
      },
      {
        lng: 106.86120605,
        lat: -6.19420242
      },
      {
        lng: 106.86009216,
        lat: -6.19498682
      },
      {
        lng: 106.85816193,
        lat: -6.19627047
      },
      {
        lng: 106.85682678,
        lat: -6.19722128
      },
      {
        lng: 106.85578156,
        lat: -6.19802284
      },
      {
        lng: 106.85439301,
        lat: -6.19907904
      },
      {
        lng: 106.85205078,
        lat: -6.20088911
      },
      {
        lng: 106.85102844,
        lat: -6.20163012
      },
      {
        lng: 106.85159302,
        lat: -6.20244265
      },
      {
        lng: 106.85271454,
        lat: -6.20190096
      },
      {
        lng: 106.85470581,
        lat: -6.20237064
      },
      {
        lng: 106.85549164,
        lat: -6.20288134
      },
      {
        lng: 106.85569763,
        lat: -6.20444536
      },
      {
        lng: 106.85476685,
        lat: -6.20603991
      },
      {
        lng: 106.85378265,
        lat: -6.2065773
      },
      {
        lng: 106.85308838,
        lat: -6.20621824
      },
      {
        lng: 106.85244751,
        lat: -6.20589352
      },
      {
        lng: 106.85115051,
        lat: -6.20587206
      },
      {
        lng: 106.85031128,
        lat: -6.20552206
      },
      {
        lng: 106.8494339,
        lat: -6.206007
      },
      {
        lng: 106.84909058,
        lat: -6.20742893
      }
    ]
  },
  {
    name: 'Jakarta Selatan',
    prov: 'Jakarta Raya',
    shape: [
      {
        lng: 106.79466248,
        lat: -6.36492348
      },
      {
        lng: 106.79434967,
        lat: -6.36418676
      },
      {
        lng: 106.79359436,
        lat: -6.36389542
      },
      {
        lng: 106.79314423,
        lat: -6.36332893
      },
      {
        lng: 106.79345703,
        lat: -6.3625145
      },
      {
        lng: 106.79351807,
        lat: -6.36115026
      },
      {
        lng: 106.79338837,
        lat: -6.36051035
      },
      {
        lng: 106.79329681,
        lat: -6.35989618
      },
      {
        lng: 106.79246521,
        lat: -6.35917425
      },
      {
        lng: 106.79280853,
        lat: -6.35787678
      },
      {
        lng: 106.79296875,
        lat: -6.35719585
      },
      {
        lng: 106.79318237,
        lat: -6.35646629
      },
      {
        lng: 106.79293823,
        lat: -6.3551054
      },
      {
        lng: 106.7939682,
        lat: -6.35449553
      },
      {
        lng: 106.79362488,
        lat: -6.35395908
      },
      {
        lng: 106.79327393,
        lat: -6.35361814
      },
      {
        lng: 106.7929306,
        lat: -6.35291672
      },
      {
        lng: 106.79264069,
        lat: -6.35219765
      },
      {
        lng: 106.79390717,
        lat: -6.35123158
      },
      {
        lng: 106.79362488,
        lat: -6.35038471
      },
      {
        lng: 106.79284668,
        lat: -6.34924984
      },
      {
        lng: 106.79259491,
        lat: -6.34801817
      },
      {
        lng: 106.79389191,
        lat: -6.34700489
      },
      {
        lng: 106.79515839,
        lat: -6.34609413
      },
      {
        lng: 106.7958374,
        lat: -6.34625721
      },
      {
        lng: 106.79667664,
        lat: -6.34647894
      },
      {
        lng: 106.79730988,
        lat: -6.34569454
      },
      {
        lng: 106.79740906,
        lat: -6.34485865
      },
      {
        lng: 106.79820251,
        lat: -6.34445143
      },
      {
        lng: 106.79890442,
        lat: -6.34390688
      },
      {
        lng: 106.79914856,
        lat: -6.34325266
      },
      {
        lng: 106.79940033,
        lat: -6.34293079
      },
      {
        lng: 106.79971313,
        lat: -6.34210157
      },
      {
        lng: 106.79962921,
        lat: -6.34123993
      },
      {
        lng: 106.80001831,
        lat: -6.34046936
      },
      {
        lng: 106.79955292,
        lat: -6.33938837
      },
      {
        lng: 106.79871368,
        lat: -6.33836889
      },
      {
        lng: 106.79886627,
        lat: -6.3370986
      },
      {
        lng: 106.79882813,
        lat: -6.33610964
      },
      {
        lng: 106.79907227,
        lat: -6.33475637
      },
      {
        lng: 106.79927826,
        lat: -6.33359337
      },
      {
        lng: 106.79979706,
        lat: -6.3333168
      },
      {
        lng: 106.80102539,
        lat: -6.33363914
      },
      {
        lng: 106.80183411,
        lat: -6.33311033
      },
      {
        lng: 106.80154419,
        lat: -6.33213329
      },
      {
        lng: 106.80132294,
        lat: -6.33150339
      },
      {
        lng: 106.80245209,
        lat: -6.33082438
      },
      {
        lng: 106.80332184,
        lat: -6.33007765
      },
      {
        lng: 106.80335236,
        lat: -6.32887745
      },
      {
        lng: 106.80341339,
        lat: -6.3275876
      },
      {
        lng: 106.80412292,
        lat: -6.32643843
      },
      {
        lng: 106.80405426,
        lat: -6.325284
      },
      {
        lng: 106.80452728,
        lat: -6.32447577
      },
      {
        lng: 106.80519867,
        lat: -6.32413292
      },
      {
        lng: 106.80612183,
        lat: -6.32424164
      },
      {
        lng: 106.80596161,
        lat: -6.32338238
      },
      {
        lng: 106.80666351,
        lat: -6.32265186
      },
      {
        lng: 106.80706024,
        lat: -6.32198095
      },
      {
        lng: 106.80713654,
        lat: -6.32119417
      },
      {
        lng: 106.80730438,
        lat: -6.32047749
      },
      {
        lng: 106.80739594,
        lat: -6.31982422
      },
      {
        lng: 106.80768585,
        lat: -6.3189702
      },
      {
        lng: 106.80796814,
        lat: -6.31866312
      },
      {
        lng: 106.80764771,
        lat: -6.31770897
      },
      {
        lng: 106.8082962,
        lat: -6.31687546
      },
      {
        lng: 106.80872345,
        lat: -6.31602859
      },
      {
        lng: 106.80913544,
        lat: -6.31518841
      },
      {
        lng: 106.80716705,
        lat: -6.31441402
      },
      {
        lng: 106.8059082,
        lat: -6.31497955
      },
      {
        lng: 106.80445099,
        lat: -6.31528807
      },
      {
        lng: 106.80369568,
        lat: -6.31525469
      },
      {
        lng: 106.80160522,
        lat: -6.31529188
      },
      {
        lng: 106.79862976,
        lat: -6.31532717
      },
      {
        lng: 106.79576111,
        lat: -6.31548595
      },
      {
        lng: 106.79241943,
        lat: -6.31570101
      },
      {
        lng: 106.79146576,
        lat: -6.31516218
      },
      {
        lng: 106.79010773,
        lat: -6.31474924
      },
      {
        lng: 106.78951263,
        lat: -6.31624222
      },
      {
        lng: 106.78321075,
        lat: -6.31654406
      },
      {
        lng: 106.7817688,
        lat: -6.31592941
      },
      {
        lng: 106.78116608,
        lat: -6.31628418
      },
      {
        lng: 106.77986908,
        lat: -6.31634665
      },
      {
        lng: 106.77823639,
        lat: -6.31547308
      },
      {
        lng: 106.77677917,
        lat: -6.31522894
      },
      {
        lng: 106.77645111,
        lat: -6.31306982
      },
      {
        lng: 106.77612305,
        lat: -6.31161499
      },
      {
        lng: 106.77506256,
        lat: -6.31205702
      },
      {
        lng: 106.77448273,
        lat: -6.31173897
      },
      {
        lng: 106.77481842,
        lat: -6.31099033
      },
      {
        lng: 106.7752533,
        lat: -6.31041718
      },
      {
        lng: 106.77371216,
        lat: -6.31000948
      },
      {
        lng: 106.77325439,
        lat: -6.30942678
      },
      {
        lng: 106.77471924,
        lat: -6.30870867
      },
      {
        lng: 106.77466583,
        lat: -6.3077569
      },
      {
        lng: 106.77392578,
        lat: -6.3065629
      },
      {
        lng: 106.77526855,
        lat: -6.3061862
      },
      {
        lng: 106.77507019,
        lat: -6.30546618
      },
      {
        lng: 106.77485657,
        lat: -6.30464649
      },
      {
        lng: 106.77559662,
        lat: -6.30407476
      },
      {
        lng: 106.77462769,
        lat: -6.30328417
      },
      {
        lng: 106.77384949,
        lat: -6.30415916
      },
      {
        lng: 106.77332306,
        lat: -6.30479527
      },
      {
        lng: 106.77288818,
        lat: -6.30441141
      },
      {
        lng: 106.77352142,
        lat: -6.3032155
      },
      {
        lng: 106.7726593,
        lat: -6.30300093
      },
      {
        lng: 106.77162933,
        lat: -6.30324602
      },
      {
        lng: 106.77085876,
        lat: -6.30210543
      },
      {
        lng: 106.77163696,
        lat: -6.30158091
      },
      {
        lng: 106.77312469,
        lat: -6.30149794
      },
      {
        lng: 106.77262878,
        lat: -6.30027676
      },
      {
        lng: 106.77150726,
        lat: -6.29945755
      },
      {
        lng: 106.77288818,
        lat: -6.29929876
      },
      {
        lng: 106.77385712,
        lat: -6.29945707
      },
      {
        lng: 106.77359009,
        lat: -6.29834604
      },
      {
        lng: 106.77200317,
        lat: -6.29877186
      },
      {
        lng: 106.77120209,
        lat: -6.29897833
      },
      {
        lng: 106.7707901,
        lat: -6.29742718
      },
      {
        lng: 106.77014923,
        lat: -6.29775906
      },
      {
        lng: 106.76973724,
        lat: -6.29700422
      },
      {
        lng: 106.77019501,
        lat: -6.29581356
      },
      {
        lng: 106.76953125,
        lat: -6.29557276
      },
      {
        lng: 106.76792908,
        lat: -6.29583454
      },
      {
        lng: 106.76714325,
        lat: -6.29499674
      },
      {
        lng: 106.76820374,
        lat: -6.29353094
      },
      {
        lng: 106.76760101,
        lat: -6.29250908
      },
      {
        lng: 106.76815033,
        lat: -6.29190445
      },
      {
        lng: 106.76765442,
        lat: -6.29155064
      },
      {
        lng: 106.76670837,
        lat: -6.29070568
      },
      {
        lng: 106.76647186,
        lat: -6.28953075
      },
      {
        lng: 106.76609039,
        lat: -6.28902197
      },
      {
        lng: 106.76717377,
        lat: -6.28860092
      },
      {
        lng: 106.76704407,
        lat: -6.28796434
      },
      {
        lng: 106.76670837,
        lat: -6.2871604
      },
      {
        lng: 106.76630402,
        lat: -6.28660297
      },
      {
        lng: 106.76556396,
        lat: -6.28629732
      },
      {
        lng: 106.76431274,
        lat: -6.28628683
      },
      {
        lng: 106.7638855,
        lat: -6.28556156
      },
      {
        lng: 106.76256561,
        lat: -6.28534842
      },
      {
        lng: 106.76102448,
        lat: -6.2847867
      },
      {
        lng: 106.7609024,
        lat: -6.28376055
      },
      {
        lng: 106.76148224,
        lat: -6.28184032
      },
      {
        lng: 106.76197815,
        lat: -6.27942419
      },
      {
        lng: 106.76171112,
        lat: -6.27782488
      },
      {
        lng: 106.75718689,
        lat: -6.27783966
      },
      {
        lng: 106.75714111,
        lat: -6.2748909
      },
      {
        lng: 106.75648499,
        lat: -6.27438498
      },
      {
        lng: 106.75456238,
        lat: -6.27446842
      },
      {
        lng: 106.75293732,
        lat: -6.27449942
      },
      {
        lng: 106.75144196,
        lat: -6.27271938
      },
      {
        lng: 106.75029755,
        lat: -6.2718401
      },
      {
        lng: 106.75196838,
        lat: -6.27012634
      },
      {
        lng: 106.75306702,
        lat: -6.26896811
      },
      {
        lng: 106.75410461,
        lat: -6.26791906
      },
      {
        lng: 106.75350189,
        lat: -6.2671423
      },
      {
        lng: 106.75305176,
        lat: -6.26613951
      },
      {
        lng: 106.75282288,
        lat: -6.26565552
      },
      {
        lng: 106.75245667,
        lat: -6.26469564
      },
      {
        lng: 106.75345612,
        lat: -6.26428843
      },
      {
        lng: 106.75494385,
        lat: -6.26345491
      },
      {
        lng: 106.75584412,
        lat: -6.26257706
      },
      {
        lng: 106.75632477,
        lat: -6.26160002
      },
      {
        lng: 106.75548553,
        lat: -6.26186895
      },
      {
        lng: 106.75437927,
        lat: -6.26195621
      },
      {
        lng: 106.75339508,
        lat: -6.26222944
      },
      {
        lng: 106.75217438,
        lat: -6.26196623
      },
      {
        lng: 106.75147247,
        lat: -6.26146984
      },
      {
        lng: 106.7517395,
        lat: -6.2603426
      },
      {
        lng: 106.75281525,
        lat: -6.25974226
      },
      {
        lng: 106.75424194,
        lat: -6.25913954
      },
      {
        lng: 106.75389862,
        lat: -6.25692797
      },
      {
        lng: 106.75357056,
        lat: -6.25596333
      },
      {
        lng: 106.75467682,
        lat: -6.25567722
      },
      {
        lng: 106.75417328,
        lat: -6.25523138
      },
      {
        lng: 106.75387573,
        lat: -6.25345755
      },
      {
        lng: 106.7536087,
        lat: -6.25271416
      },
      {
        lng: 106.753479,
        lat: -6.25204086
      },
      {
        lng: 106.7531662,
        lat: -6.25092936
      },
      {
        lng: 106.75310516,
        lat: -6.25029278
      },
      {
        lng: 106.75214386,
        lat: -6.24997425
      },
      {
        lng: 106.75045776,
        lat: -6.25004959
      },
      {
        lng: 106.74879456,
        lat: -6.25008011
      },
      {
        lng: 106.74808502,
        lat: -6.25000286
      },
      {
        lng: 106.74797058,
        lat: -6.24917936
      },
      {
        lng: 106.74723816,
        lat: -6.24866199
      },
      {
        lng: 106.74698639,
        lat: -6.24768829
      },
      {
        lng: 106.74680328,
        lat: -6.24554157
      },
      {
        lng: 106.74691772,
        lat: -6.24462175
      },
      {
        lng: 106.74704742,
        lat: -6.24336481
      },
      {
        lng: 106.74745941,
        lat: -6.24223852
      },
      {
        lng: 106.74823761,
        lat: -6.2414608
      },
      {
        lng: 106.74853516,
        lat: -6.24053526
      },
      {
        lng: 106.74753571,
        lat: -6.23856306
      },
      {
        lng: 106.74501038,
        lat: -6.23651648
      },
      {
        lng: 106.74430084,
        lat: -6.23453903
      },
      {
        lng: 106.74465179,
        lat: -6.23300409
      },
      {
        lng: 106.74448395,
        lat: -6.23200083
      },
      {
        lng: 106.74529266,
        lat: -6.23145962
      },
      {
        lng: 106.74417114,
        lat: -6.22962856
      },
      {
        lng: 106.74336243,
        lat: -6.22882032
      },
      {
        lng: 106.74320984,
        lat: -6.22746611
      },
      {
        lng: 106.74031067,
        lat: -6.22763538
      },
      {
        lng: 106.74031067,
        lat: -6.22627163
      },
      {
        lng: 106.73961639,
        lat: -6.22590256
      },
      {
        lng: 106.73767853,
        lat: -6.22518778
      },
      {
        lng: 106.73867798,
        lat: -6.22342062
      },
      {
        lng: 106.74370575,
        lat: -6.22295618
      },
      {
        lng: 106.74745941,
        lat: -6.22303104
      },
      {
        lng: 106.75202942,
        lat: -6.22316694
      },
      {
        lng: 106.75539398,
        lat: -6.22323084
      },
      {
        lng: 106.75952148,
        lat: -6.22313499
      },
      {
        lng: 106.76014709,
        lat: -6.2227354
      },
      {
        lng: 106.76023865,
        lat: -6.22224092
      },
      {
        lng: 106.76168823,
        lat: -6.22230101
      },
      {
        lng: 106.76423645,
        lat: -6.22234726
      },
      {
        lng: 106.76526642,
        lat: -6.22230673
      },
      {
        lng: 106.76595306,
        lat: -6.22243977
      },
      {
        lng: 106.76554871,
        lat: -6.22297621
      },
      {
        lng: 106.7663269,
        lat: -6.22433853
      },
      {
        lng: 106.7673645,
        lat: -6.22521114
      },
      {
        lng: 106.76990509,
        lat: -6.22517824
      },
      {
        lng: 106.77666473,
        lat: -6.22488689
      },
      {
        lng: 106.77845001,
        lat: -6.22483587
      },
      {
        lng: 106.77846527,
        lat: -6.2222681
      },
      {
        lng: 106.77848053,
        lat: -6.22069311
      },
      {
        lng: 106.77848053,
        lat: -6.21878099
      },
      {
        lng: 106.7782135,
        lat: -6.21744728
      },
      {
        lng: 106.77713776,
        lat: -6.21686506
      },
      {
        lng: 106.77750397,
        lat: -6.21548414
      },
      {
        lng: 106.77762604,
        lat: -6.21424961
      },
      {
        lng: 106.77809143,
        lat: -6.21350336
      },
      {
        lng: 106.78058624,
        lat: -6.21337318
      },
      {
        lng: 106.78252411,
        lat: -6.2132864
      },
      {
        lng: 106.78334808,
        lat: -6.21269608
      },
      {
        lng: 106.78350067,
        lat: -6.21083689
      },
      {
        lng: 106.78323364,
        lat: -6.20826626
      },
      {
        lng: 106.78314209,
        lat: -6.20671988
      },
      {
        lng: 106.78599548,
        lat: -6.20686769
      },
      {
        lng: 106.78756714,
        lat: -6.20719671
      },
      {
        lng: 106.78830719,
        lat: -6.20804644
      },
      {
        lng: 106.78938293,
        lat: -6.20841742
      },
      {
        lng: 106.79084778,
        lat: -6.20750189
      },
      {
        lng: 106.79227448,
        lat: -6.20801544
      },
      {
        lng: 106.79183197,
        lat: -6.20885229
      },
      {
        lng: 106.79259491,
        lat: -6.20939159
      },
      {
        lng: 106.79281616,
        lat: -6.21036005
      },
      {
        lng: 106.79368591,
        lat: -6.21097326
      },
      {
        lng: 106.79342651,
        lat: -6.21171188
      },
      {
        lng: 106.79399109,
        lat: -6.21182442
      },
      {
        lng: 106.79458618,
        lat: -6.21184444
      },
      {
        lng: 106.79538727,
        lat: -6.21228743
      },
      {
        lng: 106.79545593,
        lat: -6.21276855
      },
      {
        lng: 106.79546356,
        lat: -6.21398973
      },
      {
        lng: 106.79620361,
        lat: -6.21557379
      },
      {
        lng: 106.79607391,
        lat: -6.21639967
      },
      {
        lng: 106.79679108,
        lat: -6.21800423
      },
      {
        lng: 106.79681396,
        lat: -6.21917295
      },
      {
        lng: 106.7960434,
        lat: -6.2238822
      },
      {
        lng: 106.79588318,
        lat: -6.22781324
      },
      {
        lng: 106.79572296,
        lat: -6.22905588
      },
      {
        lng: 106.7970047,
        lat: -6.22927475
      },
      {
        lng: 106.79824066,
        lat: -6.22889185
      },
      {
        lng: 106.79924011,
        lat: -6.22907305
      },
      {
        lng: 106.8007431,
        lat: -6.22799635
      },
      {
        lng: 106.80162811,
        lat: -6.22735214
      },
      {
        lng: 106.80381012,
        lat: -6.22566271
      },
      {
        lng: 106.80502319,
        lat: -6.22476912
      },
      {
        lng: 106.80639648,
        lat: -6.22379923
      },
      {
        lng: 106.80828094,
        lat: -6.22260714
      },
      {
        lng: 106.80924988,
        lat: -6.22184277
      },
      {
        lng: 106.8099823,
        lat: -6.2213254
      },
      {
        lng: 106.8116684,
        lat: -6.21998835
      },
      {
        lng: 106.81219482,
        lat: -6.2195406
      },
      {
        lng: 106.81278992,
        lat: -6.2190752
      },
      {
        lng: 106.81497955,
        lat: -6.21735954
      },
      {
        lng: 106.81621552,
        lat: -6.2163887
      },
      {
        lng: 106.81835175,
        lat: -6.21458006
      },
      {
        lng: 106.81912994,
        lat: -6.21373224
      },
      {
        lng: 106.81999969,
        lat: -6.2124567
      },
      {
        lng: 106.82092285,
        lat: -6.21077251
      },
      {
        lng: 106.82159424,
        lat: -6.20922613
      },
      {
        lng: 106.82205963,
        lat: -6.20691872
      },
      {
        lng: 106.82229614,
        lat: -6.20488358
      },
      {
        lng: 106.82266998,
        lat: -6.20290804
      },
      {
        lng: 106.82482147,
        lat: -6.20314598
      },
      {
        lng: 106.82692719,
        lat: -6.20362663
      },
      {
        lng: 106.82878876,
        lat: -6.20405626
      },
      {
        lng: 106.83017731,
        lat: -6.20434523
      },
      {
        lng: 106.83242798,
        lat: -6.20468092
      },
      {
        lng: 106.835495,
        lat: -6.20513153
      },
      {
        lng: 106.83716583,
        lat: -6.20539522
      },
      {
        lng: 106.83856964,
        lat: -6.20587778
      },
      {
        lng: 106.84049988,
        lat: -6.20658112
      },
      {
        lng: 106.84252167,
        lat: -6.20732117
      },
      {
        lng: 106.84394836,
        lat: -6.20783472
      },
      {
        lng: 106.84609222,
        lat: -6.20855856
      },
      {
        lng: 106.84715271,
        lat: -6.2086091
      },
      {
        lng: 106.84811401,
        lat: -6.20804834
      },
      {
        lng: 106.84895325,
        lat: -6.20752048
      },
      {
        lng: 106.8500061,
        lat: -6.20798874
      },
      {
        lng: 106.85094452,
        lat: -6.20887566
      },
      {
        lng: 106.85179138,
        lat: -6.20926523
      },
      {
        lng: 106.85348511,
        lat: -6.21006632
      },
      {
        lng: 106.85449219,
        lat: -6.21113205
      },
      {
        lng: 106.85648346,
        lat: -6.21167231
      },
      {
        lng: 106.85783386,
        lat: -6.21265936
      },
      {
        lng: 106.85818481,
        lat: -6.21367455
      },
      {
        lng: 106.85731506,
        lat: -6.21392775
      },
      {
        lng: 106.85656738,
        lat: -6.21518803
      },
      {
        lng: 106.85630798,
        lat: -6.21639538
      },
      {
        lng: 106.85706329,
        lat: -6.21746159
      },
      {
        lng: 106.85780334,
        lat: -6.21755409
      },
      {
        lng: 106.85855103,
        lat: -6.21637392
      },
      {
        lng: 106.85922241,
        lat: -6.21657038
      },
      {
        lng: 106.85992432,
        lat: -6.21724176
      },
      {
        lng: 106.86045074,
        lat: -6.21732616
      },
      {
        lng: 106.86116791,
        lat: -6.21699286
      },
      {
        lng: 106.86299896,
        lat: -6.21614122
      },
      {
        lng: 106.86345673,
        lat: -6.21655703
      },
      {
        lng: 106.86242676,
        lat: -6.21752739
      },
      {
        lng: 106.86183929,
        lat: -6.21834278
      },
      {
        lng: 106.86125946,
        lat: -6.2190423
      },
      {
        lng: 106.86048889,
        lat: -6.22036266
      },
      {
        lng: 106.8600769,
        lat: -6.22112751
      },
      {
        lng: 106.860466,
        lat: -6.22169495
      },
      {
        lng: 106.86125183,
        lat: -6.22183752
      },
      {
        lng: 106.86177063,
        lat: -6.22123051
      },
      {
        lng: 106.86141205,
        lat: -6.22031164
      },
      {
        lng: 106.86244965,
        lat: -6.21967888
      },
      {
        lng: 106.8631897,
        lat: -6.22039032
      },
      {
        lng: 106.86375427,
        lat: -6.22150183
      },
      {
        lng: 106.86482239,
        lat: -6.22276878
      },
      {
        lng: 106.86543274,
        lat: -6.22381973
      },
      {
        lng: 106.86503601,
        lat: -6.22452307
      },
      {
        lng: 106.86384583,
        lat: -6.2244668
      },
      {
        lng: 106.86352539,
        lat: -6.22486734
      },
      {
        lng: 106.86430359,
        lat: -6.22613621
      },
      {
        lng: 106.86478424,
        lat: -6.22774887
      },
      {
        lng: 106.86483765,
        lat: -6.22888041
      },
      {
        lng: 106.86421967,
        lat: -6.2296567
      },
      {
        lng: 106.86332703,
        lat: -6.23053217
      },
      {
        lng: 106.8635025,
        lat: -6.2313056
      },
      {
        lng: 106.86463928,
        lat: -6.23201418
      },
      {
        lng: 106.86580658,
        lat: -6.23324299
      },
      {
        lng: 106.8663559,
        lat: -6.23441601
      },
      {
        lng: 106.86582947,
        lat: -6.23490334
      },
      {
        lng: 106.86520386,
        lat: -6.23517036
      },
      {
        lng: 106.8658905,
        lat: -6.23616743
      },
      {
        lng: 106.86662292,
        lat: -6.23758602
      },
      {
        lng: 106.86676025,
        lat: -6.23911476
      },
      {
        lng: 106.86597443,
        lat: -6.23867226
      },
      {
        lng: 106.8651886,
        lat: -6.23682022
      },
      {
        lng: 106.86379242,
        lat: -6.23725891
      },
      {
        lng: 106.86314392,
        lat: -6.23848915
      },
      {
        lng: 106.86270142,
        lat: -6.24005985
      },
      {
        lng: 106.86248779,
        lat: -6.24126911
      },
      {
        lng: 106.86248779,
        lat: -6.24285841
      },
      {
        lng: 106.86248779,
        lat: -6.24404049
      },
      {
        lng: 106.86203766,
        lat: -6.24539185
      },
      {
        lng: 106.86215973,
        lat: -6.24670601
      },
      {
        lng: 106.86283112,
        lat: -6.24784708
      },
      {
        lng: 106.86386871,
        lat: -6.24914074
      },
      {
        lng: 106.86388397,
        lat: -6.24962521
      },
      {
        lng: 106.86221313,
        lat: -6.2504158
      },
      {
        lng: 106.86225891,
        lat: -6.25133562
      },
      {
        lng: 106.86268616,
        lat: -6.25349951
      },
      {
        lng: 106.86127472,
        lat: -6.25405121
      },
      {
        lng: 106.8608017,
        lat: -6.25229788
      },
      {
        lng: 106.86018372,
        lat: -6.25098515
      },
      {
        lng: 106.8579483,
        lat: -6.25266933
      },
      {
        lng: 106.8576355,
        lat: -6.25509453
      },
      {
        lng: 106.85920715,
        lat: -6.25450563
      },
      {
        lng: 106.86071014,
        lat: -6.2551856
      },
      {
        lng: 106.8599472,
        lat: -6.25622272
      },
      {
        lng: 106.85934448,
        lat: -6.25694799
      },
      {
        lng: 106.86047363,
        lat: -6.25723553
      },
      {
        lng: 106.86022949,
        lat: -6.25871944
      },
      {
        lng: 106.85961151,
        lat: -6.26162243
      },
      {
        lng: 106.85877228,
        lat: -6.26302528
      },
      {
        lng: 106.85655212,
        lat: -6.26275969
      },
      {
        lng: 106.8554306,
        lat: -6.26180744
      },
      {
        lng: 106.85428619,
        lat: -6.26241636
      },
      {
        lng: 106.85696411,
        lat: -6.26369476
      },
      {
        lng: 106.85682678,
        lat: -6.26430035
      },
      {
        lng: 106.85417175,
        lat: -6.26477814
      },
      {
        lng: 106.85423279,
        lat: -6.26551294
      },
      {
        lng: 106.85543823,
        lat: -6.26677084
      },
      {
        lng: 106.85580444,
        lat: -6.26844454
      },
      {
        lng: 106.85647583,
        lat: -6.27033997
      },
      {
        lng: 106.8560257,
        lat: -6.2705245
      },
      {
        lng: 106.85525513,
        lat: -6.26964521
      },
      {
        lng: 106.85490417,
        lat: -6.27053785
      },
      {
        lng: 106.85658264,
        lat: -6.27185488
      },
      {
        lng: 106.85591888,
        lat: -6.27268934
      },
      {
        lng: 106.85453796,
        lat: -6.27217054
      },
      {
        lng: 106.85341644,
        lat: -6.2721324
      },
      {
        lng: 106.85313416,
        lat: -6.2733655
      },
      {
        lng: 106.85153961,
        lat: -6.27330446
      },
      {
        lng: 106.85166931,
        lat: -6.27468681
      },
      {
        lng: 106.85150909,
        lat: -6.27629995
      },
      {
        lng: 106.85018158,
        lat: -6.27675724
      },
      {
        lng: 106.84879303,
        lat: -6.27710152
      },
      {
        lng: 106.84725189,
        lat: -6.27849674
      },
      {
        lng: 106.84659576,
        lat: -6.27906847
      },
      {
        lng: 106.84706116,
        lat: -6.27972746
      },
      {
        lng: 106.84889221,
        lat: -6.27972126
      },
      {
        lng: 106.85022736,
        lat: -6.27956533
      },
      {
        lng: 106.84962463,
        lat: -6.2803545
      },
      {
        lng: 106.84879303,
        lat: -6.28122187
      },
      {
        lng: 106.84752655,
        lat: -6.28292656
      },
      {
        lng: 106.84765625,
        lat: -6.28348637
      },
      {
        lng: 106.84818268,
        lat: -6.2835207
      },
      {
        lng: 106.84870911,
        lat: -6.28376675
      },
      {
        lng: 106.84758759,
        lat: -6.28495121
      },
      {
        lng: 106.84745026,
        lat: -6.28617334
      },
      {
        lng: 106.84799957,
        lat: -6.28760004
      },
      {
        lng: 106.8499527,
        lat: -6.28824568
      },
      {
        lng: 106.85059357,
        lat: -6.2879858
      },
      {
        lng: 106.85095978,
        lat: -6.28939438
      },
      {
        lng: 106.84998322,
        lat: -6.29007435
      },
      {
        lng: 106.84941864,
        lat: -6.29097509
      },
      {
        lng: 106.85032654,
        lat: -6.29261112
      },
      {
        lng: 106.85134888,
        lat: -6.29269218
      },
      {
        lng: 106.85175323,
        lat: -6.29115582
      },
      {
        lng: 106.85250854,
        lat: -6.29096842
      },
      {
        lng: 106.85383606,
        lat: -6.29026747
      },
      {
        lng: 106.85443878,
        lat: -6.29082918
      },
      {
        lng: 106.85317993,
        lat: -6.29294252
      },
      {
        lng: 106.85231781,
        lat: -6.29427242
      },
      {
        lng: 106.85243225,
        lat: -6.29478407
      },
      {
        lng: 106.85294342,
        lat: -6.29421473
      },
      {
        lng: 106.85414124,
        lat: -6.29401922
      },
      {
        lng: 106.85411835,
        lat: -6.29614067
      },
      {
        lng: 106.85224915,
        lat: -6.29569578
      },
      {
        lng: 106.8506546,
        lat: -6.29585314
      },
      {
        lng: 106.85063934,
        lat: -6.29691601
      },
      {
        lng: 106.85212708,
        lat: -6.2982583
      },
      {
        lng: 106.8516922,
        lat: -6.29936028
      },
      {
        lng: 106.84986877,
        lat: -6.29881668
      },
      {
        lng: 106.84977722,
        lat: -6.29976559
      },
      {
        lng: 106.85121918,
        lat: -6.30087566
      },
      {
        lng: 106.85214996,
        lat: -6.30050755
      },
      {
        lng: 106.85355377,
        lat: -6.2998085
      },
      {
        lng: 106.85488892,
        lat: -6.29989481
      },
      {
        lng: 106.85460663,
        lat: -6.30214214
      },
      {
        lng: 106.85484314,
        lat: -6.30347443
      },
      {
        lng: 106.85845184,
        lat: -6.30537891
      },
      {
        lng: 106.85787201,
        lat: -6.30609322
      },
      {
        lng: 106.85765839,
        lat: -6.3075161
      },
      {
        lng: 106.85722351,
        lat: -6.30853558
      },
      {
        lng: 106.85682678,
        lat: -6.30966139
      },
      {
        lng: 106.85721588,
        lat: -6.31068039
      },
      {
        lng: 106.85832214,
        lat: -6.31108809
      },
      {
        lng: 106.85687256,
        lat: -6.31263113
      },
      {
        lng: 106.85488129,
        lat: -6.31382942
      },
      {
        lng: 106.85470581,
        lat: -6.31268215
      },
      {
        lng: 106.85322571,
        lat: -6.31134415
      },
      {
        lng: 106.85224915,
        lat: -6.31126118
      },
      {
        lng: 106.85190582,
        lat: -6.31305695
      },
      {
        lng: 106.85128784,
        lat: -6.31511927
      },
      {
        lng: 106.85157776,
        lat: -6.31632566
      },
      {
        lng: 106.8493576,
        lat: -6.31771421
      },
      {
        lng: 106.84938049,
        lat: -6.3185997
      },
      {
        lng: 106.84867859,
        lat: -6.31867313
      },
      {
        lng: 106.84748077,
        lat: -6.31829357
      },
      {
        lng: 106.84701538,
        lat: -6.31766701
      },
      {
        lng: 106.84832764,
        lat: -6.31735754
      },
      {
        lng: 106.84889221,
        lat: -6.3159461
      },
      {
        lng: 106.84848785,
        lat: -6.31519556
      },
      {
        lng: 106.84739685,
        lat: -6.31540632
      },
      {
        lng: 106.84683228,
        lat: -6.31600857
      },
      {
        lng: 106.84642029,
        lat: -6.31676626
      },
      {
        lng: 106.84580231,
        lat: -6.31700277
      },
      {
        lng: 106.84513092,
        lat: -6.3167119
      },
      {
        lng: 106.84455109,
        lat: -6.31655836
      },
      {
        lng: 106.84416962,
        lat: -6.31683588
      },
      {
        lng: 106.84395599,
        lat: -6.31831169
      },
      {
        lng: 106.84289551,
        lat: -6.3181715
      },
      {
        lng: 106.84028625,
        lat: -6.31848049
      },
      {
        lng: 106.83856201,
        lat: -6.32026815
      },
      {
        lng: 106.83827209,
        lat: -6.32098103
      },
      {
        lng: 106.83988953,
        lat: -6.32154083
      },
      {
        lng: 106.84081268,
        lat: -6.32183075
      },
      {
        lng: 106.84004211,
        lat: -6.32277632
      },
      {
        lng: 106.8419342,
        lat: -6.32417297
      },
      {
        lng: 106.84305573,
        lat: -6.3268919
      },
      {
        lng: 106.84217834,
        lat: -6.32771635
      },
      {
        lng: 106.84157562,
        lat: -6.32673979
      },
      {
        lng: 106.84052277,
        lat: -6.32856131
      },
      {
        lng: 106.84024048,
        lat: -6.32941389
      },
      {
        lng: 106.84207153,
        lat: -6.33099604
      },
      {
        lng: 106.84339905,
        lat: -6.33159685
      },
      {
        lng: 106.84178925,
        lat: -6.33226395
      },
      {
        lng: 106.84011841,
        lat: -6.33222485
      },
      {
        lng: 106.83976746,
        lat: -6.33403063
      },
      {
        lng: 106.83973694,
        lat: -6.33532429
      },
      {
        lng: 106.84017944,
        lat: -6.33595419
      },
      {
        lng: 106.84025574,
        lat: -6.33720207
      },
      {
        lng: 106.84030914,
        lat: -6.33853197
      },
      {
        lng: 106.83929443,
        lat: -6.34016085
      },
      {
        lng: 106.83930969,
        lat: -6.34065437
      },
      {
        lng: 106.84023285,
        lat: -6.34081078
      },
      {
        lng: 106.84095764,
        lat: -6.34179592
      },
      {
        lng: 106.83932495,
        lat: -6.34235287
      },
      {
        lng: 106.83760834,
        lat: -6.34151554
      },
      {
        lng: 106.83534241,
        lat: -6.34445381
      },
      {
        lng: 106.8366394,
        lat: -6.34492874
      },
      {
        lng: 106.83968353,
        lat: -6.345119
      },
      {
        lng: 106.83760071,
        lat: -6.34693956
      },
      {
        lng: 106.83808136,
        lat: -6.34910202
      },
      {
        lng: 106.83898926,
        lat: -6.35052347
      },
      {
        lng: 106.83532715,
        lat: -6.35200167
      },
      {
        lng: 106.83580017,
        lat: -6.35409927
      },
      {
        lng: 106.8353653,
        lat: -6.35492659
      },
      {
        lng: 106.83422089,
        lat: -6.35528421
      },
      {
        lng: 106.83237457,
        lat: -6.35556126
      },
      {
        lng: 106.83135223,
        lat: -6.35569715
      },
      {
        lng: 106.82907104,
        lat: -6.35634851
      },
      {
        lng: 106.82424927,
        lat: -6.35616827
      },
      {
        lng: 106.82239532,
        lat: -6.35592365
      },
      {
        lng: 106.82110596,
        lat: -6.35587978
      },
      {
        lng: 106.81962585,
        lat: -6.3564744
      },
      {
        lng: 106.81678772,
        lat: -6.35624981
      },
      {
        lng: 106.81591797,
        lat: -6.35603094
      },
      {
        lng: 106.81526184,
        lat: -6.35643196
      },
      {
        lng: 106.81461334,
        lat: -6.35819054
      },
      {
        lng: 106.81430817,
        lat: -6.35922718
      },
      {
        lng: 106.81348419,
        lat: -6.35969257
      },
      {
        lng: 106.81252289,
        lat: -6.36097622
      },
      {
        lng: 106.81218719,
        lat: -6.36178589
      },
      {
        lng: 106.81152344,
        lat: -6.36239481
      },
      {
        lng: 106.81048584,
        lat: -6.36337852
      },
      {
        lng: 106.80854797,
        lat: -6.36401272
      },
      {
        lng: 106.80640411,
        lat: -6.36342621
      },
      {
        lng: 106.80527496,
        lat: -6.36346149
      },
      {
        lng: 106.80396271,
        lat: -6.36349726
      },
      {
        lng: 106.80307007,
        lat: -6.36344624
      },
      {
        lng: 106.80001068,
        lat: -6.36528444
      },
      {
        lng: 106.7990799,
        lat: -6.36532116
      },
      {
        lng: 106.79771423,
        lat: -6.36492252
      },
      {
        lng: 106.796875,
        lat: -6.36491776
      },
      {
        lng: 106.795784,
        lat: -6.36491346
      },
      {
        lng: 106.79466248,
        lat: -6.36492348
      }
    ]
  },
  {
    name: 'Jakarta Timur',
    prov: 'Jakarta Raya',
    shape: [
      {
        lng: 106.88349152,
        lat: -6.37023163
      },
      {
        lng: 106.88266754,
        lat: -6.36987829
      },
      {
        lng: 106.88304901,
        lat: -6.36850405
      },
      {
        lng: 106.88368988,
        lat: -6.36766386
      },
      {
        lng: 106.88371277,
        lat: -6.36647511
      },
      {
        lng: 106.88321686,
        lat: -6.36587334
      },
      {
        lng: 106.8835907,
        lat: -6.36529922
      },
      {
        lng: 106.88292694,
        lat: -6.36482954
      },
      {
        lng: 106.88224792,
        lat: -6.3648901
      },
      {
        lng: 106.88215637,
        lat: -6.36593723
      },
      {
        lng: 106.88188934,
        lat: -6.36633301
      },
      {
        lng: 106.88072968,
        lat: -6.36628103
      },
      {
        lng: 106.8807373,
        lat: -6.36500025
      },
      {
        lng: 106.88013458,
        lat: -6.36422157
      },
      {
        lng: 106.87940216,
        lat: -6.36447906
      },
      {
        lng: 106.87846375,
        lat: -6.36399603
      },
      {
        lng: 106.87792969,
        lat: -6.36287975
      },
      {
        lng: 106.87832642,
        lat: -6.36198044
      },
      {
        lng: 106.87895203,
        lat: -6.36172676
      },
      {
        lng: 106.87882996,
        lat: -6.36103535
      },
      {
        lng: 106.87791443,
        lat: -6.36114073
      },
      {
        lng: 106.87736511,
        lat: -6.36135101
      },
      {
        lng: 106.8767395,
        lat: -6.36163425
      },
      {
        lng: 106.87615967,
        lat: -6.36172009
      },
      {
        lng: 106.87458038,
        lat: -6.36152506
      },
      {
        lng: 106.87373352,
        lat: -6.36099052
      },
      {
        lng: 106.87274933,
        lat: -6.36129045
      },
      {
        lng: 106.87232971,
        lat: -6.3607254
      },
      {
        lng: 106.87158966,
        lat: -6.35958767
      },
      {
        lng: 106.87229919,
        lat: -6.35874844
      },
      {
        lng: 106.87203979,
        lat: -6.35791016
      },
      {
        lng: 106.87294769,
        lat: -6.35749817
      },
      {
        lng: 106.87337494,
        lat: -6.35684299
      },
      {
        lng: 106.87390137,
        lat: -6.35635185
      },
      {
        lng: 106.87321472,
        lat: -6.35565853
      },
      {
        lng: 106.87265778,
        lat: -6.35512209
      },
      {
        lng: 106.87237549,
        lat: -6.35479975
      },
      {
        lng: 106.87217712,
        lat: -6.35427427
      },
      {
        lng: 106.87160492,
        lat: -6.35359764
      },
      {
        lng: 106.87101746,
        lat: -6.35278225
      },
      {
        lng: 106.86907959,
        lat: -6.35374546
      },
      {
        lng: 106.86707306,
        lat: -6.35466671
      },
      {
        lng: 106.86459351,
        lat: -6.35591793
      },
      {
        lng: 106.86221313,
        lat: -6.35733795
      },
      {
        lng: 106.86139679,
        lat: -6.35628748
      },
      {
        lng: 106.86088562,
        lat: -6.35505009
      },
      {
        lng: 106.85987854,
        lat: -6.35354567
      },
      {
        lng: 106.85827637,
        lat: -6.35299635
      },
      {
        lng: 106.85688782,
        lat: -6.35268641
      },
      {
        lng: 106.85572052,
        lat: -6.35189867
      },
      {
        lng: 106.8561554,
        lat: -6.35133123
      },
      {
        lng: 106.85635376,
        lat: -6.35030651
      },
      {
        lng: 106.85668182,
        lat: -6.3491559
      },
      {
        lng: 106.85630798,
        lat: -6.34813595
      },
      {
        lng: 106.85655212,
        lat: -6.34713888
      },
      {
        lng: 106.85538483,
        lat: -6.34725523
      },
      {
        lng: 106.85418701,
        lat: -6.34649611
      },
      {
        lng: 106.85407257,
        lat: -6.34538651
      },
      {
        lng: 106.85477448,
        lat: -6.34422255
      },
      {
        lng: 106.85412598,
        lat: -6.34358501
      },
      {
        lng: 106.85381317,
        lat: -6.34287977
      },
      {
        lng: 106.85300446,
        lat: -6.34283352
      },
      {
        lng: 106.85214996,
        lat: -6.34294033
      },
      {
        lng: 106.85166931,
        lat: -6.34185505
      },
      {
        lng: 106.85118103,
        lat: -6.34118176
      },
      {
        lng: 106.85017395,
        lat: -6.34040689
      },
      {
        lng: 106.8502121,
        lat: -6.33961296
      },
      {
        lng: 106.85064697,
        lat: -6.33893776
      },
      {
        lng: 106.85167694,
        lat: -6.33876371
      },
      {
        lng: 106.85215759,
        lat: -6.33773518
      },
      {
        lng: 106.85157776,
        lat: -6.33680439
      },
      {
        lng: 106.85046387,
        lat: -6.3368206
      },
      {
        lng: 106.85043335,
        lat: -6.33616495
      },
      {
        lng: 106.84901428,
        lat: -6.33660698
      },
      {
        lng: 106.8489151,
        lat: -6.33771181
      },
      {
        lng: 106.84764862,
        lat: -6.3377161
      },
      {
        lng: 106.8467865,
        lat: -6.33840609
      },
      {
        lng: 106.84664154,
        lat: -6.33981562
      },
      {
        lng: 106.84616852,
        lat: -6.34174156
      },
      {
        lng: 106.84339905,
        lat: -6.34201193
      },
      {
        lng: 106.84094238,
        lat: -6.34197664
      },
      {
        lng: 106.84047699,
        lat: -6.34085751
      },
      {
        lng: 106.83943939,
        lat: -6.34079742
      },
      {
        lng: 106.83927917,
        lat: -6.34025621
      },
      {
        lng: 106.8401947,
        lat: -6.33888292
      },
      {
        lng: 106.84030151,
        lat: -6.3376236
      },
      {
        lng: 106.84037781,
        lat: -6.33646202
      },
      {
        lng: 106.83978271,
        lat: -6.33545923
      },
      {
        lng: 106.8397522,
        lat: -6.33419609
      },
      {
        lng: 106.83997345,
        lat: -6.33239079
      },
      {
        lng: 106.84099579,
        lat: -6.33222198
      },
      {
        lng: 106.84339905,
        lat: -6.33174706
      },
      {
        lng: 106.84243011,
        lat: -6.3310585
      },
      {
        lng: 106.84041595,
        lat: -6.32965469
      },
      {
        lng: 106.84030151,
        lat: -6.32900667
      },
      {
        lng: 106.84146118,
        lat: -6.32667637
      },
      {
        lng: 106.84203339,
        lat: -6.32770395
      },
      {
        lng: 106.84291077,
        lat: -6.32714176
      },
      {
        lng: 106.84254456,
        lat: -6.32496691
      },
      {
        lng: 106.84029388,
        lat: -6.32296371
      },
      {
        lng: 106.84011841,
        lat: -6.32206392
      },
      {
        lng: 106.8405838,
        lat: -6.32130814
      },
      {
        lng: 106.83829498,
        lat: -6.32108593
      },
      {
        lng: 106.83846283,
        lat: -6.32045698
      },
      {
        lng: 106.83999634,
        lat: -6.31862545
      },
      {
        lng: 106.84270477,
        lat: -6.31808853
      },
      {
        lng: 106.84383392,
        lat: -6.31845617
      },
      {
        lng: 106.84415436,
        lat: -6.31700373
      },
      {
        lng: 106.84447479,
        lat: -6.31657076
      },
      {
        lng: 106.84500885,
        lat: -6.31665134
      },
      {
        lng: 106.8456955,
        lat: -6.31694841
      },
      {
        lng: 106.84637451,
        lat: -6.31685209
      },
      {
        lng: 106.84674835,
        lat: -6.31619835
      },
      {
        lng: 106.84733582,
        lat: -6.31545877
      },
      {
        lng: 106.84835052,
        lat: -6.31511402
      },
      {
        lng: 106.8489151,
        lat: -6.31574535
      },
      {
        lng: 106.84848022,
        lat: -6.31713867
      },
      {
        lng: 106.84745789,
        lat: -6.31763077
      },
      {
        lng: 106.84710693,
        lat: -6.31825972
      },
      {
        lng: 106.84835052,
        lat: -6.31845331
      },
      {
        lng: 106.84925842,
        lat: -6.3187871
      },
      {
        lng: 106.8493576,
        lat: -6.31793451
      },
      {
        lng: 106.85131836,
        lat: -6.31652403
      },
      {
        lng: 106.85150146,
        lat: -6.31542253
      },
      {
        lng: 106.85168457,
        lat: -6.31330204
      },
      {
        lng: 106.85216522,
        lat: -6.31173801
      },
      {
        lng: 106.85292053,
        lat: -6.31104231
      },
      {
        lng: 106.85462189,
        lat: -6.31240082
      },
      {
        lng: 106.85479736,
        lat: -6.31369972
      },
      {
        lng: 106.85635376,
        lat: -6.31304455
      },
      {
        lng: 106.858284,
        lat: -6.31117964
      },
      {
        lng: 106.85766602,
        lat: -6.31061363
      },
      {
        lng: 106.85677338,
        lat: -6.30988026
      },
      {
        lng: 106.85715485,
        lat: -6.30869484
      },
      {
        lng: 106.85768127,
        lat: -6.30768919
      },
      {
        lng: 106.85761261,
        lat: -6.30616713
      },
      {
        lng: 106.85848999,
        lat: -6.30550623
      },
      {
        lng: 106.85557556,
        lat: -6.30389166
      },
      {
        lng: 106.85443878,
        lat: -6.30234337
      },
      {
        lng: 106.85500336,
        lat: -6.30080748
      },
      {
        lng: 106.85398865,
        lat: -6.29960585
      },
      {
        lng: 106.85231018,
        lat: -6.30006838
      },
      {
        lng: 106.85149384,
        lat: -6.30103922
      },
      {
        lng: 106.84989166,
        lat: -6.29991722
      },
      {
        lng: 106.84967804,
        lat: -6.2988739
      },
      {
        lng: 106.85148621,
        lat: -6.29937983
      },
      {
        lng: 106.85214233,
        lat: -6.29897928
      },
      {
        lng: 106.85145569,
        lat: -6.29744434
      },
      {
        lng: 106.85048676,
        lat: -6.29600573
      },
      {
        lng: 106.85194397,
        lat: -6.295681
      },
      {
        lng: 106.85394287,
        lat: -6.29620218
      },
      {
        lng: 106.85429382,
        lat: -6.29426527
      },
      {
        lng: 106.85315704,
        lat: -6.29398489
      },
      {
        lng: 106.85249329,
        lat: -6.29472733
      },
      {
        lng: 106.85230255,
        lat: -6.29438639
      },
      {
        lng: 106.85294342,
        lat: -6.29324102
      },
      {
        lng: 106.85437012,
        lat: -6.29114914
      },
      {
        lng: 106.85394287,
        lat: -6.29027987
      },
      {
        lng: 106.85263062,
        lat: -6.29091072
      },
      {
        lng: 106.85180664,
        lat: -6.29110193
      },
      {
        lng: 106.85141754,
        lat: -6.29258823
      },
      {
        lng: 106.85036469,
        lat: -6.29268599
      },
      {
        lng: 106.84944153,
        lat: -6.29105377
      },
      {
        lng: 106.84954834,
        lat: -6.29020739
      },
      {
        lng: 106.85082245,
        lat: -6.2895875
      },
      {
        lng: 106.85103607,
        lat: -6.28829479
      },
      {
        lng: 106.85005188,
        lat: -6.28811932
      },
      {
        lng: 106.84848785,
        lat: -6.28806829
      },
      {
        lng: 106.84750366,
        lat: -6.28634262
      },
      {
        lng: 106.8473587,
        lat: -6.28536797
      },
      {
        lng: 106.84863281,
        lat: -6.28389883
      },
      {
        lng: 106.84856415,
        lat: -6.28342915
      },
      {
        lng: 106.8478241,
        lat: -6.28349733
      },
      {
        lng: 106.84745789,
        lat: -6.28303719
      },
      {
        lng: 106.84868622,
        lat: -6.28137732
      },
      {
        lng: 106.84949493,
        lat: -6.28048944
      },
      {
        lng: 106.85031891,
        lat: -6.27967262
      },
      {
        lng: 106.84925079,
        lat: -6.27966547
      },
      {
        lng: 106.84746552,
        lat: -6.27992487
      },
      {
        lng: 106.84661102,
        lat: -6.27916288
      },
      {
        lng: 106.84712982,
        lat: -6.27856636
      },
      {
        lng: 106.84860992,
        lat: -6.27737093
      },
      {
        lng: 106.84972382,
        lat: -6.27660322
      },
      {
        lng: 106.85121155,
        lat: -6.2765131
      },
      {
        lng: 106.85194397,
        lat: -6.27503967
      },
      {
        lng: 106.85137177,
        lat: -6.27341366
      },
      {
        lng: 106.85289001,
        lat: -6.27338266
      },
      {
        lng: 106.85340118,
        lat: -6.27244854
      },
      {
        lng: 106.85441589,
        lat: -6.27207899
      },
      {
        lng: 106.85556793,
        lat: -6.27262688
      },
      {
        lng: 106.85675049,
        lat: -6.27213717
      },
      {
        lng: 106.85499573,
        lat: -6.27072811
      },
      {
        lng: 106.8551712,
        lat: -6.26962852
      },
      {
        lng: 106.85587311,
        lat: -6.2704587
      },
      {
        lng: 106.85644531,
        lat: -6.2704401
      },
      {
        lng: 106.85591888,
        lat: -6.26886034
      },
      {
        lng: 106.85545349,
        lat: -6.26690054
      },
      {
        lng: 106.85462952,
        lat: -6.26581049
      },
      {
        lng: 106.8540802,
        lat: -6.26481724
      },
      {
        lng: 106.85651398,
        lat: -6.26435995
      },
      {
        lng: 106.8571167,
        lat: -6.26378202
      },
      {
        lng: 106.8543396,
        lat: -6.26251364
      },
      {
        lng: 106.85516357,
        lat: -6.26182795
      },
      {
        lng: 106.85601807,
        lat: -6.26250792
      },
      {
        lng: 106.85845947,
        lat: -6.26300669
      },
      {
        lng: 106.85944366,
        lat: -6.26218796
      },
      {
        lng: 106.86009216,
        lat: -6.25928736
      },
      {
        lng: 106.86050415,
        lat: -6.2573905
      },
      {
        lng: 106.85946655,
        lat: -6.2570672
      },
      {
        lng: 106.85958099,
        lat: -6.25645351
      },
      {
        lng: 106.8607254,
        lat: -6.25540257
      },
      {
        lng: 106.85946655,
        lat: -6.25437737
      },
      {
        lng: 106.85768127,
        lat: -6.2551856
      },
      {
        lng: 106.85773468,
        lat: -6.25322294
      },
      {
        lng: 106.85990143,
        lat: -6.25099277
      },
      {
        lng: 106.86075592,
        lat: -6.25202465
      },
      {
        lng: 106.86116791,
        lat: -6.25400639
      },
      {
        lng: 106.86250305,
        lat: -6.253685
      },
      {
        lng: 106.86245728,
        lat: -6.25184631
      },
      {
        lng: 106.8621521,
        lat: -6.25065947
      },
      {
        lng: 106.86351776,
        lat: -6.24977922
      },
      {
        lng: 106.8639679,
        lat: -6.24925518
      },
      {
        lng: 106.86303711,
        lat: -6.24817467
      },
      {
        lng: 106.86227417,
        lat: -6.24696064
      },
      {
        lng: 106.86199188,
        lat: -6.24564266
      },
      {
        lng: 106.86245728,
        lat: -6.24415588
      },
      {
        lng: 106.86249542,
        lat: -6.24309921
      },
      {
        lng: 106.8624649,
        lat: -6.24166393
      },
      {
        lng: 106.86267853,
        lat: -6.24014473
      },
      {
        lng: 106.86301422,
        lat: -6.23889923
      },
      {
        lng: 106.86362457,
        lat: -6.23745203
      },
      {
        lng: 106.86495972,
        lat: -6.23679686
      },
      {
        lng: 106.86579132,
        lat: -6.23827505
      },
      {
        lng: 106.86666107,
        lat: -6.23917532
      },
      {
        lng: 106.866745,
        lat: -6.23806715
      },
      {
        lng: 106.86608887,
        lat: -6.23638344
      },
      {
        lng: 106.86528778,
        lat: -6.23545933
      },
      {
        lng: 106.8655014,
        lat: -6.23483229
      },
      {
        lng: 106.86636353,
        lat: -6.23455095
      },
      {
        lng: 106.8659668,
        lat: -6.2334466
      },
      {
        lng: 106.86489105,
        lat: -6.23218489
      },
      {
        lng: 106.86373901,
        lat: -6.23145199
      },
      {
        lng: 106.8632431,
        lat: -6.23071671
      },
      {
        lng: 106.86397552,
        lat: -6.22984171
      },
      {
        lng: 106.86478424,
        lat: -6.22900343
      },
      {
        lng: 106.86479187,
        lat: -6.22806263
      },
      {
        lng: 106.86453247,
        lat: -6.22648144
      },
      {
        lng: 106.86355591,
        lat: -6.22508335
      },
      {
        lng: 106.86373138,
        lat: -6.22449112
      },
      {
        lng: 106.86476898,
        lat: -6.22457218
      },
      {
        lng: 106.86541748,
        lat: -6.22403765
      },
      {
        lng: 106.86499023,
        lat: -6.22297382
      },
      {
        lng: 106.86397552,
        lat: -6.22174311
      },
      {
        lng: 106.86328888,
        lat: -6.22065592
      },
      {
        lng: 106.86267853,
        lat: -6.219666
      },
      {
        lng: 106.86142731,
        lat: -6.22019053
      },
      {
        lng: 106.86177063,
        lat: -6.22107315
      },
      {
        lng: 106.86133575,
        lat: -6.22183228
      },
      {
        lng: 106.86054993,
        lat: -6.22173643
      },
      {
        lng: 106.86006165,
        lat: -6.22121239
      },
      {
        lng: 106.86042786,
        lat: -6.22043753
      },
      {
        lng: 106.86112213,
        lat: -6.21925402
      },
      {
        lng: 106.86154175,
        lat: -6.21868229
      },
      {
        lng: 106.86235809,
        lat: -6.2176013
      },
      {
        lng: 106.86321259,
        lat: -6.21683741
      },
      {
        lng: 106.8632431,
        lat: -6.21615458
      },
      {
        lng: 106.86183167,
        lat: -6.21662045
      },
      {
        lng: 106.86053467,
        lat: -6.21729279
      },
      {
        lng: 106.86006927,
        lat: -6.21729231
      },
      {
        lng: 106.85942078,
        lat: -6.21676636
      },
      {
        lng: 106.85866547,
        lat: -6.21632147
      },
      {
        lng: 106.85788727,
        lat: -6.2174058
      },
      {
        lng: 106.85722351,
        lat: -6.21757698
      },
      {
        lng: 106.85635376,
        lat: -6.21658707
      },
      {
        lng: 106.8564682,
        lat: -6.21552134
      },
      {
        lng: 106.85704041,
        lat: -6.21432638
      },
      {
        lng: 106.85810852,
        lat: -6.21373701
      },
      {
        lng: 106.85810852,
        lat: -6.21310854
      },
      {
        lng: 106.85682678,
        lat: -6.21175003
      },
      {
        lng: 106.85497284,
        lat: -6.21128273
      },
      {
        lng: 106.85374451,
        lat: -6.21028852
      },
      {
        lng: 106.85229492,
        lat: -6.20938396
      },
      {
        lng: 106.85113525,
        lat: -6.20900106
      },
      {
        lng: 106.85022736,
        lat: -6.20825195
      },
      {
        lng: 106.84905243,
        lat: -6.2075429
      },
      {
        lng: 106.8493576,
        lat: -6.20614529
      },
      {
        lng: 106.85012054,
        lat: -6.20554829
      },
      {
        lng: 106.85092926,
        lat: -6.20579529
      },
      {
        lng: 106.8522644,
        lat: -6.20590258
      },
      {
        lng: 106.85294342,
        lat: -6.2060895
      },
      {
        lng: 106.85364532,
        lat: -6.20656919
      },
      {
        lng: 106.85462952,
        lat: -6.20614386
      },
      {
        lng: 106.85568237,
        lat: -6.20466566
      },
      {
        lng: 106.85559082,
        lat: -6.20318651
      },
      {
        lng: 106.85498047,
        lat: -6.20239449
      },
      {
        lng: 106.85292053,
        lat: -6.20193672
      },
      {
        lng: 106.85184479,
        lat: -6.20234394
      },
      {
        lng: 106.85105896,
        lat: -6.20170593
      },
      {
        lng: 106.85168457,
        lat: -6.20123768
      },
      {
        lng: 106.85381317,
        lat: -6.19948244
      },
      {
        lng: 106.8556366,
        lat: -6.19810963
      },
      {
        lng: 106.85663605,
        lat: -6.1973238
      },
      {
        lng: 106.85765076,
        lat: -6.19660854
      },
      {
        lng: 106.85942841,
        lat: -6.19544458
      },
      {
        lng: 106.86100769,
        lat: -6.19436169
      },
      {
        lng: 106.86220551,
        lat: -6.1936264
      },
      {
        lng: 106.86515045,
        lat: -6.19259167
      },
      {
        lng: 106.86669922,
        lat: -6.19241333
      },
      {
        lng: 106.86842346,
        lat: -6.19223595
      },
      {
        lng: 106.87199402,
        lat: -6.1919899
      },
      {
        lng: 106.87361908,
        lat: -6.19212151
      },
      {
        lng: 106.87506104,
        lat: -6.19209671
      },
      {
        lng: 106.87534332,
        lat: -6.18918037
      },
      {
        lng: 106.87541199,
        lat: -6.18740416
      },
      {
        lng: 106.87571716,
        lat: -6.18125629
      },
      {
        lng: 106.87598419,
        lat: -6.17706347
      },
      {
        lng: 106.87619781,
        lat: -6.17532444
      },
      {
        lng: 106.87785339,
        lat: -6.16886282
      },
      {
        lng: 106.87924957,
        lat: -6.1664834
      },
      {
        lng: 106.8813324,
        lat: -6.16729259
      },
      {
        lng: 106.8840332,
        lat: -6.16891146
      },
      {
        lng: 106.88572693,
        lat: -6.16998482
      },
      {
        lng: 106.88858032,
        lat: -6.17175102
      },
      {
        lng: 106.89064026,
        lat: -6.17298269
      },
      {
        lng: 106.89165497,
        lat: -6.17373419
      },
      {
        lng: 106.89323425,
        lat: -6.17520475
      },
      {
        lng: 106.89508057,
        lat: -6.17691326
      },
      {
        lng: 106.89663696,
        lat: -6.17806816
      },
      {
        lng: 106.89749908,
        lat: -6.17808819
      },
      {
        lng: 106.89911652,
        lat: -6.17828608
      },
      {
        lng: 106.90000153,
        lat: -6.17835712
      },
      {
        lng: 106.90200043,
        lat: -6.17876005
      },
      {
        lng: 106.90415192,
        lat: -6.17960072
      },
      {
        lng: 106.90571594,
        lat: -6.18025351
      },
      {
        lng: 106.90888214,
        lat: -6.18177891
      },
      {
        lng: 106.91054535,
        lat: -6.1822896
      },
      {
        lng: 106.91122437,
        lat: -6.18244219
      },
      {
        lng: 106.91205597,
        lat: -6.1827774
      },
      {
        lng: 106.91398621,
        lat: -6.18247461
      },
      {
        lng: 106.91608429,
        lat: -6.1826582
      },
      {
        lng: 106.91870117,
        lat: -6.18282557
      },
      {
        lng: 106.92097473,
        lat: -6.18301678
      },
      {
        lng: 106.92201996,
        lat: -6.18152332
      },
      {
        lng: 106.92163086,
        lat: -6.18058014
      },
      {
        lng: 106.92186737,
        lat: -6.17935896
      },
      {
        lng: 106.92144775,
        lat: -6.17689657
      },
      {
        lng: 106.92097473,
        lat: -6.17357588
      },
      {
        lng: 106.92058563,
        lat: -6.17237329
      },
      {
        lng: 106.92037964,
        lat: -6.17009068
      },
      {
        lng: 106.92073822,
        lat: -6.16811419
      },
      {
        lng: 106.92093658,
        lat: -6.16687059
      },
      {
        lng: 106.92160034,
        lat: -6.16358423
      },
      {
        lng: 106.92229462,
        lat: -6.16213799
      },
      {
        lng: 106.92722321,
        lat: -6.16067886
      },
      {
        lng: 106.92942047,
        lat: -6.16066647
      },
      {
        lng: 106.93146515,
        lat: -6.1604557
      },
      {
        lng: 106.93175507,
        lat: -6.15916204
      },
      {
        lng: 106.93319702,
        lat: -6.15908527
      },
      {
        lng: 106.93367767,
        lat: -6.1583457
      },
      {
        lng: 106.93537903,
        lat: -6.15848398
      },
      {
        lng: 106.93621063,
        lat: -6.15737772
      },
      {
        lng: 106.93730927,
        lat: -6.15526104
      },
      {
        lng: 106.93824768,
        lat: -6.15342331
      },
      {
        lng: 106.93911743,
        lat: -6.15202951
      },
      {
        lng: 106.93978882,
        lat: -6.1515646
      },
      {
        lng: 106.94015503,
        lat: -6.15526867
      },
      {
        lng: 106.94194031,
        lat: -6.15513754
      },
      {
        lng: 106.9442215,
        lat: -6.15507698
      },
      {
        lng: 106.94438171,
        lat: -6.15682554
      },
      {
        lng: 106.94464874,
        lat: -6.15818405
      },
      {
        lng: 106.94455719,
        lat: -6.16127348
      },
      {
        lng: 106.94477844,
        lat: -6.16301012
      },
      {
        lng: 106.94818115,
        lat: -6.16316414
      },
      {
        lng: 106.94956207,
        lat: -6.16322994
      },
      {
        lng: 106.95085907,
        lat: -6.16332293
      },
      {
        lng: 106.95188904,
        lat: -6.16270065
      },
      {
        lng: 106.95227814,
        lat: -6.16215992
      },
      {
        lng: 106.95267487,
        lat: -6.16150045
      },
      {
        lng: 106.95301819,
        lat: -6.16080284
      },
      {
        lng: 106.95288086,
        lat: -6.1599884
      },
      {
        lng: 106.95358276,
        lat: -6.15806007
      },
      {
        lng: 106.95613861,
        lat: -6.15817165
      },
      {
        lng: 106.95959473,
        lat: -6.15808678
      },
      {
        lng: 106.95974731,
        lat: -6.15564156
      },
      {
        lng: 106.96195221,
        lat: -6.15604019
      },
      {
        lng: 106.96379089,
        lat: -6.15626669
      },
      {
        lng: 106.96420288,
        lat: -6.15586805
      },
      {
        lng: 106.96707153,
        lat: -6.15498114
      },
      {
        lng: 106.96880341,
        lat: -6.1545105
      },
      {
        lng: 106.97022247,
        lat: -6.15429687
      },
      {
        lng: 106.97169495,
        lat: -6.15616941
      },
      {
        lng: 106.97106171,
        lat: -6.16563845
      },
      {
        lng: 106.97173309,
        lat: -6.17454004
      },
      {
        lng: 106.97132874,
        lat: -6.18600988
      },
      {
        lng: 106.9712677,
        lat: -6.18763351
      },
      {
        lng: 106.97098541,
        lat: -6.18859673
      },
      {
        lng: 106.97028351,
        lat: -6.18982506
      },
      {
        lng: 106.96961975,
        lat: -6.19165707
      },
      {
        lng: 106.96944427,
        lat: -6.19405079
      },
      {
        lng: 106.96880341,
        lat: -6.19483089
      },
      {
        lng: 106.96685028,
        lat: -6.1948185
      },
      {
        lng: 106.9664917,
        lat: -6.19569588
      },
      {
        lng: 106.96695709,
        lat: -6.19754696
      },
      {
        lng: 106.96723938,
        lat: -6.19893026
      },
      {
        lng: 106.96639252,
        lat: -6.1998539
      },
      {
        lng: 106.96581268,
        lat: -6.20074558
      },
      {
        lng: 106.96608734,
        lat: -6.20158482
      },
      {
        lng: 106.96640778,
        lat: -6.20235014
      },
      {
        lng: 106.96641541,
        lat: -6.20294285
      },
      {
        lng: 106.96611023,
        lat: -6.20371103
      },
      {
        lng: 106.96592712,
        lat: -6.20458126
      },
      {
        lng: 106.96621704,
        lat: -6.20523405
      },
      {
        lng: 106.96582794,
        lat: -6.20572138
      },
      {
        lng: 106.96631622,
        lat: -6.20628023
      },
      {
        lng: 106.96570587,
        lat: -6.20737791
      },
      {
        lng: 106.96400452,
        lat: -6.20971441
      },
      {
        lng: 106.96324158,
        lat: -6.21075678
      },
      {
        lng: 106.96274567,
        lat: -6.21315193
      },
      {
        lng: 106.96098328,
        lat: -6.21445751
      },
      {
        lng: 106.96066284,
        lat: -6.21361446
      },
      {
        lng: 106.96013641,
        lat: -6.21297359
      },
      {
        lng: 106.95889282,
        lat: -6.21318197
      },
      {
        lng: 106.95832062,
        lat: -6.21511126
      },
      {
        lng: 106.95780182,
        lat: -6.21746683
      },
      {
        lng: 106.95676422,
        lat: -6.21808386
      },
      {
        lng: 106.95608521,
        lat: -6.21798229
      },
      {
        lng: 106.95585632,
        lat: -6.21923876
      },
      {
        lng: 106.95495605,
        lat: -6.21944857
      },
      {
        lng: 106.95357513,
        lat: -6.21929407
      },
      {
        lng: 106.95244598,
        lat: -6.21918058
      },
      {
        lng: 106.95126343,
        lat: -6.21916914
      },
      {
        lng: 106.94992065,
        lat: -6.22036552
      },
      {
        lng: 106.94894409,
        lat: -6.22177315
      },
      {
        lng: 106.94831848,
        lat: -6.22307253
      },
      {
        lng: 106.94760132,
        lat: -6.22512722
      },
      {
        lng: 106.94703674,
        lat: -6.22706652
      },
      {
        lng: 106.94685364,
        lat: -6.22872925
      },
      {
        lng: 106.947052,
        lat: -6.22971344
      },
      {
        lng: 106.94684601,
        lat: -6.23071861
      },
      {
        lng: 106.94632721,
        lat: -6.23305464
      },
      {
        lng: 106.94567871,
        lat: -6.23552132
      },
      {
        lng: 106.94548798,
        lat: -6.23682117
      },
      {
        lng: 106.94398499,
        lat: -6.23682165
      },
      {
        lng: 106.94254303,
        lat: -6.23760557
      },
      {
        lng: 106.94172668,
        lat: -6.23854685
      },
      {
        lng: 106.94288635,
        lat: -6.23870659
      },
      {
        lng: 106.943573,
        lat: -6.23903084
      },
      {
        lng: 106.94364929,
        lat: -6.24070549
      },
      {
        lng: 106.94374084,
        lat: -6.24243975
      },
      {
        lng: 106.94377899,
        lat: -6.24591541
      },
      {
        lng: 106.94382477,
        lat: -6.24965763
      },
      {
        lng: 106.94368744,
        lat: -6.25053501
      },
      {
        lng: 106.94386292,
        lat: -6.25225687
      },
      {
        lng: 106.94275665,
        lat: -6.25259161
      },
      {
        lng: 106.94152832,
        lat: -6.2526598
      },
      {
        lng: 106.940979,
        lat: -6.25323248
      },
      {
        lng: 106.93843842,
        lat: -6.25332832
      },
      {
        lng: 106.93701935,
        lat: -6.25320053
      },
      {
        lng: 106.93582153,
        lat: -6.25308609
      },
      {
        lng: 106.93396759,
        lat: -6.25384092
      },
      {
        lng: 106.93171692,
        lat: -6.25377226
      },
      {
        lng: 106.93008423,
        lat: -6.25332546
      },
      {
        lng: 106.92888641,
        lat: -6.25375319
      },
      {
        lng: 106.9284668,
        lat: -6.25431776
      },
      {
        lng: 106.92760468,
        lat: -6.2543664
      },
      {
        lng: 106.92389679,
        lat: -6.25384998
      },
      {
        lng: 106.92195892,
        lat: -6.25381899
      },
      {
        lng: 106.92166138,
        lat: -6.25320244
      },
      {
        lng: 106.92164612,
        lat: -6.25230742
      },
      {
        lng: 106.9217453,
        lat: -6.25161123
      },
      {
        lng: 106.92138672,
        lat: -6.25112724
      },
      {
        lng: 106.92061615,
        lat: -6.25042009
      },
      {
        lng: 106.91980743,
        lat: -6.25037336
      },
      {
        lng: 106.91920471,
        lat: -6.25111866
      },
      {
        lng: 106.91803741,
        lat: -6.25177574
      },
      {
        lng: 106.91730499,
        lat: -6.25261545
      },
      {
        lng: 106.91621399,
        lat: -6.25354815
      },
      {
        lng: 106.91651154,
        lat: -6.25613308
      },
      {
        lng: 106.91609955,
        lat: -6.2573514
      },
      {
        lng: 106.915802,
        lat: -6.25823879
      },
      {
        lng: 106.91373444,
        lat: -6.25803566
      },
      {
        lng: 106.91133118,
        lat: -6.25673437
      },
      {
        lng: 106.9101944,
        lat: -6.2556076
      },
      {
        lng: 106.90991974,
        lat: -6.25673866
      },
      {
        lng: 106.90995026,
        lat: -6.25795603
      },
      {
        lng: 106.90858459,
        lat: -6.25861597
      },
      {
        lng: 106.90745544,
        lat: -6.25912619
      },
      {
        lng: 106.90531921,
        lat: -6.26009369
      },
      {
        lng: 106.90525055,
        lat: -6.26080465
      },
      {
        lng: 106.90457153,
        lat: -6.26156569
      },
      {
        lng: 106.90435791,
        lat: -6.26216125
      },
      {
        lng: 106.90483093,
        lat: -6.26263571
      },
      {
        lng: 106.90550232,
        lat: -6.26335716
      },
      {
        lng: 106.9050827,
        lat: -6.26416111
      },
      {
        lng: 106.90476227,
        lat: -6.26461267
      },
      {
        lng: 106.90489197,
        lat: -6.2655468
      },
      {
        lng: 106.90471649,
        lat: -6.26633501
      },
      {
        lng: 106.90503693,
        lat: -6.26710033
      },
      {
        lng: 106.90487671,
        lat: -6.26753807
      },
      {
        lng: 106.90525055,
        lat: -6.26786041
      },
      {
        lng: 106.90567017,
        lat: -6.26757622
      },
      {
        lng: 106.90544128,
        lat: -6.26846838
      },
      {
        lng: 106.9050827,
        lat: -6.26917124
      },
      {
        lng: 106.90450287,
        lat: -6.26978493
      },
      {
        lng: 106.90446472,
        lat: -6.27022648
      },
      {
        lng: 106.90522003,
        lat: -6.27033186
      },
      {
        lng: 106.90558624,
        lat: -6.2705102
      },
      {
        lng: 106.90565491,
        lat: -6.27122593
      },
      {
        lng: 106.90608978,
        lat: -6.27172375
      },
      {
        lng: 106.90663147,
        lat: -6.27160454
      },
      {
        lng: 106.90695953,
        lat: -6.27214622
      },
      {
        lng: 106.90769196,
        lat: -6.27197504
      },
      {
        lng: 106.90842438,
        lat: -6.27182436
      },
      {
        lng: 106.90847015,
        lat: -6.27240086
      },
      {
        lng: 106.90918732,
        lat: -6.27265596
      },
      {
        lng: 106.90973663,
        lat: -6.27221727
      },
      {
        lng: 106.91004181,
        lat: -6.27270746
      },
      {
        lng: 106.90963745,
        lat: -6.27321959
      },
      {
        lng: 106.90925598,
        lat: -6.27392817
      },
      {
        lng: 106.90930939,
        lat: -6.27493191
      },
      {
        lng: 106.90937042,
        lat: -6.27598238
      },
      {
        lng: 106.90910339,
        lat: -6.27708483
      },
      {
        lng: 106.90886688,
        lat: -6.27817726
      },
      {
        lng: 106.90872192,
        lat: -6.27972221
      },
      {
        lng: 106.9090271,
        lat: -6.2804203
      },
      {
        lng: 106.90883636,
        lat: -6.28130579
      },
      {
        lng: 106.90814972,
        lat: -6.28217697
      },
      {
        lng: 106.90810394,
        lat: -6.2826252
      },
      {
        lng: 106.90860748,
        lat: -6.28362179
      },
      {
        lng: 106.90904236,
        lat: -6.28570461
      },
      {
        lng: 106.90963745,
        lat: -6.28636742
      },
      {
        lng: 106.9103241,
        lat: -6.28686619
      },
      {
        lng: 106.90997314,
        lat: -6.28756762
      },
      {
        lng: 106.90959167,
        lat: -6.2887001
      },
      {
        lng: 106.91007996,
        lat: -6.28924274
      },
      {
        lng: 106.90958405,
        lat: -6.29017019
      },
      {
        lng: 106.9093399,
        lat: -6.29087305
      },
      {
        lng: 106.91046143,
        lat: -6.28993368
      },
      {
        lng: 106.91083527,
        lat: -6.2908597
      },
      {
        lng: 106.91156006,
        lat: -6.29027319
      },
      {
        lng: 106.9108963,
        lat: -6.29119825
      },
      {
        lng: 106.91039276,
        lat: -6.29178429
      },
      {
        lng: 106.91122437,
        lat: -6.29229641
      },
      {
        lng: 106.9111557,
        lat: -6.29167652
      },
      {
        lng: 106.91143036,
        lat: -6.29241943
      },
      {
        lng: 106.9105072,
        lat: -6.2928009
      },
      {
        lng: 106.91022491,
        lat: -6.29383469
      },
      {
        lng: 106.91040802,
        lat: -6.29421949
      },
      {
        lng: 106.91146088,
        lat: -6.29465294
      },
      {
        lng: 106.912323,
        lat: -6.29455662
      },
      {
        lng: 106.91208649,
        lat: -6.29508352
      },
      {
        lng: 106.91139984,
        lat: -6.29564619
      },
      {
        lng: 106.91120148,
        lat: -6.29627705
      },
      {
        lng: 106.91172028,
        lat: -6.29715633
      },
      {
        lng: 106.91188812,
        lat: -6.2983079
      },
      {
        lng: 106.91275024,
        lat: -6.29857302
      },
      {
        lng: 106.91333771,
        lat: -6.29892063
      },
      {
        lng: 106.91368866,
        lat: -6.29848289
      },
      {
        lng: 106.91419983,
        lat: -6.29811716
      },
      {
        lng: 106.91480255,
        lat: -6.29707289
      },
      {
        lng: 106.91606903,
        lat: -6.29763269
      },
      {
        lng: 106.91709137,
        lat: -6.2981863
      },
      {
        lng: 106.91777039,
        lat: -6.29811764
      },
      {
        lng: 106.91789246,
        lat: -6.29968262
      },
      {
        lng: 106.91827393,
        lat: -6.29945993
      },
      {
        lng: 106.91918945,
        lat: -6.2990818
      },
      {
        lng: 106.91914368,
        lat: -6.30014324
      },
      {
        lng: 106.91925812,
        lat: -6.30068874
      },
      {
        lng: 106.92033386,
        lat: -6.30003643
      },
      {
        lng: 106.92100525,
        lat: -6.30097961
      },
      {
        lng: 106.92057037,
        lat: -6.30149651
      },
      {
        lng: 106.92079163,
        lat: -6.30186367
      },
      {
        lng: 106.92125702,
        lat: -6.30296516
      },
      {
        lng: 106.92182922,
        lat: -6.30338717
      },
      {
        lng: 106.92163086,
        lat: -6.30431032
      },
      {
        lng: 106.92173767,
        lat: -6.3048811
      },
      {
        lng: 106.92139435,
        lat: -6.30552244
      },
      {
        lng: 106.92152405,
        lat: -6.30676985
      },
      {
        lng: 106.92112732,
        lat: -6.30729389
      },
      {
        lng: 106.92099762,
        lat: -6.30793047
      },
      {
        lng: 106.92066956,
        lat: -6.30839872
      },
      {
        lng: 106.92098236,
        lat: -6.31032848
      },
      {
        lng: 106.9214325,
        lat: -6.31079769
      },
      {
        lng: 106.92047119,
        lat: -6.31133556
      },
      {
        lng: 106.92024994,
        lat: -6.31170464
      },
      {
        lng: 106.92100525,
        lat: -6.31234121
      },
      {
        lng: 106.92209625,
        lat: -6.31241274
      },
      {
        lng: 106.92197418,
        lat: -6.31317949
      },
      {
        lng: 106.92248535,
        lat: -6.31330252
      },
      {
        lng: 106.9224472,
        lat: -6.3140955
      },
      {
        lng: 106.92276001,
        lat: -6.31431627
      },
      {
        lng: 106.92195129,
        lat: -6.31581879
      },
      {
        lng: 106.92201233,
        lat: -6.31679249
      },
      {
        lng: 106.92201233,
        lat: -6.31766367
      },
      {
        lng: 106.92114258,
        lat: -6.31801176
      },
      {
        lng: 106.92072296,
        lat: -6.31843424
      },
      {
        lng: 106.92089844,
        lat: -6.31964588
      },
      {
        lng: 106.92048645,
        lat: -6.32072496
      },
      {
        lng: 106.92099762,
        lat: -6.32162523
      },
      {
        lng: 106.92134094,
        lat: -6.32262516
      },
      {
        lng: 106.92154694,
        lat: -6.32363415
      },
      {
        lng: 106.92073822,
        lat: -6.3247571
      },
      {
        lng: 106.91940308,
        lat: -6.32510519
      },
      {
        lng: 106.91894531,
        lat: -6.32568645
      },
      {
        lng: 106.91889954,
        lat: -6.32662296
      },
      {
        lng: 106.91815948,
        lat: -6.32790899
      },
      {
        lng: 106.91877747,
        lat: -6.32870626
      },
      {
        lng: 106.9201889,
        lat: -6.32860184
      },
      {
        lng: 106.92029572,
        lat: -6.3294735
      },
      {
        lng: 106.92088318,
        lat: -6.33065557
      },
      {
        lng: 106.92069244,
        lat: -6.33287001
      },
      {
        lng: 106.91983795,
        lat: -6.33357
      },
      {
        lng: 106.91942596,
        lat: -6.33516932
      },
      {
        lng: 106.91953278,
        lat: -6.3367691
      },
      {
        lng: 106.91912842,
        lat: -6.33782673
      },
      {
        lng: 106.91886139,
        lat: -6.33891058
      },
      {
        lng: 106.91822052,
        lat: -6.33932209
      },
      {
        lng: 106.91895294,
        lat: -6.33999157
      },
      {
        lng: 106.91960907,
        lat: -6.3415494
      },
      {
        lng: 106.91880035,
        lat: -6.34162855
      },
      {
        lng: 106.91850281,
        lat: -6.34244776
      },
      {
        lng: 106.91925049,
        lat: -6.34296465
      },
      {
        lng: 106.91915131,
        lat: -6.34363794
      },
      {
        lng: 106.91784668,
        lat: -6.34384918
      },
      {
        lng: 106.91716766,
        lat: -6.34451532
      },
      {
        lng: 106.91699982,
        lat: -6.3455224
      },
      {
        lng: 106.91718292,
        lat: -6.34612465
      },
      {
        lng: 106.91655731,
        lat: -6.34623194
      },
      {
        lng: 106.9159317,
        lat: -6.34641218
      },
      {
        lng: 106.91721344,
        lat: -6.34787273
      },
      {
        lng: 106.91683197,
        lat: -6.34821272
      },
      {
        lng: 106.91618347,
        lat: -6.34868193
      },
      {
        lng: 106.91675568,
        lat: -6.34914207
      },
      {
        lng: 106.91718292,
        lat: -6.34958792
      },
      {
        lng: 106.91778564,
        lat: -6.35048866
      },
      {
        lng: 106.91705322,
        lat: -6.35088873
      },
      {
        lng: 106.91647339,
        lat: -6.35158825
      },
      {
        lng: 106.91677856,
        lat: -6.35262918
      },
      {
        lng: 106.91690826,
        lat: -6.35336828
      },
      {
        lng: 106.91616821,
        lat: -6.35397291
      },
      {
        lng: 106.9154892,
        lat: -6.35489702
      },
      {
        lng: 106.91668701,
        lat: -6.35571623
      },
      {
        lng: 106.91713715,
        lat: -6.35643387
      },
      {
        lng: 106.91663361,
        lat: -6.35660839
      },
      {
        lng: 106.91574097,
        lat: -6.35647917
      },
      {
        lng: 106.91493225,
        lat: -6.35744095
      },
      {
        lng: 106.91477966,
        lat: -6.35839176
      },
      {
        lng: 106.91360474,
        lat: -6.35881615
      },
      {
        lng: 106.91433716,
        lat: -6.35907412
      },
      {
        lng: 106.91433716,
        lat: -6.35976648
      },
      {
        lng: 106.9148407,
        lat: -6.36046696
      },
      {
        lng: 106.91545105,
        lat: -6.36052322
      },
      {
        lng: 106.91519165,
        lat: -6.36103582
      },
      {
        lng: 106.9146347,
        lat: -6.36162138
      },
      {
        lng: 106.916008,
        lat: -6.36256361
      },
      {
        lng: 106.91599274,
        lat: -6.36317348
      },
      {
        lng: 106.91501617,
        lat: -6.36318827
      },
      {
        lng: 106.91500092,
        lat: -6.36401176
      },
      {
        lng: 106.9144516,
        lat: -6.36527443
      },
      {
        lng: 106.91368103,
        lat: -6.36528301
      },
      {
        lng: 106.91287231,
        lat: -6.36454344
      },
      {
        lng: 106.91204071,
        lat: -6.36431217
      },
      {
        lng: 106.91021729,
        lat: -6.36356401
      },
      {
        lng: 106.90957642,
        lat: -6.36274719
      },
      {
        lng: 106.90870667,
        lat: -6.36213732
      },
      {
        lng: 106.90687561,
        lat: -6.36083937
      },
      {
        lng: 106.9056778,
        lat: -6.36115694
      },
      {
        lng: 106.90517426,
        lat: -6.36192751
      },
      {
        lng: 106.9048233,
        lat: -6.36268187
      },
      {
        lng: 106.90415192,
        lat: -6.36344481
      },
      {
        lng: 106.90363312,
        lat: -6.36417484
      },
      {
        lng: 106.90255737,
        lat: -6.366117
      },
      {
        lng: 106.90116882,
        lat: -6.36951971
      },
      {
        lng: 106.89844513,
        lat: -6.36909056
      },
      {
        lng: 106.8961792,
        lat: -6.37022543
      },
      {
        lng: 106.89391327,
        lat: -6.37047243
      },
      {
        lng: 106.89263153,
        lat: -6.36977816
      },
      {
        lng: 106.89029694,
        lat: -6.36894941
      },
      {
        lng: 106.88937378,
        lat: -6.36813593
      },
      {
        lng: 106.88870239,
        lat: -6.36840868
      },
      {
        lng: 106.8871994,
        lat: -6.36969042
      },
      {
        lng: 106.88566589,
        lat: -6.3697319
      },
      {
        lng: 106.8839798,
        lat: -6.36976862
      }
    ]
  },
  {
    name: 'Jakarta Utara',
    prov: 'Jakarta Raya',
    shape: [
      {
        lng: 106.78061676,
        lat: -6.14381313
      },
      {
        lng: 106.77867126,
        lat: -6.14396095
      },
      {
        lng: 106.77710724,
        lat: -6.14410877
      },
      {
        lng: 106.7752533,
        lat: -6.14521742
      },
      {
        lng: 106.77531433,
        lat: -6.14397526
      },
      {
        lng: 106.77531433,
        lat: -6.14264822
      },
      {
        lng: 106.77521515,
        lat: -6.14159775
      },
      {
        lng: 106.77385712,
        lat: -6.14140415
      },
      {
        lng: 106.77212524,
        lat: -6.1413765
      },
      {
        lng: 106.7707901,
        lat: -6.14113998
      },
      {
        lng: 106.76906586,
        lat: -6.140872
      },
      {
        lng: 106.76699829,
        lat: -6.14026499
      },
      {
        lng: 106.76483154,
        lat: -6.13949871
      },
      {
        lng: 106.76265717,
        lat: -6.13875914
      },
      {
        lng: 106.7602005,
        lat: -6.13791132
      },
      {
        lng: 106.75790405,
        lat: -6.13714075
      },
      {
        lng: 106.7544632,
        lat: -6.13588381
      },
      {
        lng: 106.75268555,
        lat: -6.13506556
      },
      {
        lng: 106.75224304,
        lat: -6.13482666
      },
      {
        lng: 106.75067902,
        lat: -6.13432932
      },
      {
        lng: 106.74931335,
        lat: -6.13338852
      },
      {
        lng: 106.74845123,
        lat: -6.13264656
      },
      {
        lng: 106.74703217,
        lat: -6.13155222
      },
      {
        lng: 106.74565125,
        lat: -6.1306448
      },
      {
        lng: 106.744133,
        lat: -6.12960625
      },
      {
        lng: 106.7414856,
        lat: -6.12837839
      },
      {
        lng: 106.73989868,
        lat: -6.12761736
      },
      {
        lng: 106.73717499,
        lat: -6.12588501
      },
      {
        lng: 106.73586273,
        lat: -6.12491083
      },
      {
        lng: 106.73439026,
        lat: -6.12378454
      },
      {
        lng: 106.73303223,
        lat: -6.12297153
      },
      {
        lng: 106.73210144,
        lat: -6.12198639
      },
      {
        lng: 106.73121643,
        lat: -6.12118769
      },
      {
        lng: 106.72983551,
        lat: -6.11983252
      },
      {
        lng: 106.72880554,
        lat: -6.11889839
      },
      {
        lng: 106.72657013,
        lat: -6.11650419
      },
      {
        lng: 106.7253418,
        lat: -6.11482525
      },
      {
        lng: 106.72332764,
        lat: -6.11240101
      },
      {
        lng: 106.72180176,
        lat: -6.11050797
      },
      {
        lng: 106.71994019,
        lat: -6.10808706
      },
      {
        lng: 106.71895599,
        lat: -6.10665751
      },
      {
        lng: 106.71789551,
        lat: -6.10534477
      },
      {
        lng: 106.71717072,
        lat: -6.10460281
      },
      {
        lng: 106.7158432,
        lat: -6.10216188
      },
      {
        lng: 106.7148819,
        lat: -6.10039043
      },
      {
        lng: 106.71413422,
        lat: -6.09879112
      },
      {
        lng: 106.71309662,
        lat: -6.09760094
      },
      {
        lng: 106.71205902,
        lat: -6.09650898
      },
      {
        lng: 106.71097565,
        lat: -6.09561586
      },
      {
        lng: 106.71208191,
        lat: -6.09459877
      },
      {
        lng: 106.71396637,
        lat: -6.09395838
      },
      {
        lng: 106.71651459,
        lat: -6.09289837
      },
      {
        lng: 106.71973419,
        lat: -6.09211111
      },
      {
        lng: 106.72222137,
        lat: -6.09168196
      },
      {
        lng: 106.72348022,
        lat: -6.09027815
      },
      {
        lng: 106.72423553,
        lat: -6.08970642
      },
      {
        lng: 106.72451019,
        lat: -6.08916759
      },
      {
        lng: 106.72521973,
        lat: -6.08919621
      },
      {
        lng: 106.72618103,
        lat: -6.08967972
      },
      {
        lng: 106.72696686,
        lat: -6.08988619
      },
      {
        lng: 106.72706604,
        lat: -6.09209108
      },
      {
        lng: 106.72814178,
        lat: -6.09398842
      },
      {
        lng: 106.72922516,
        lat: -6.09451485
      },
      {
        lng: 106.72993469,
        lat: -6.09522772
      },
      {
        lng: 106.73078918,
        lat: -6.09611845
      },
      {
        lng: 106.73184967,
        lat: -6.09733152
      },
      {
        lng: 106.73396301,
        lat: -6.09827566
      },
      {
        lng: 106.73449707,
        lat: -6.09915781
      },
      {
        lng: 106.73564148,
        lat: -6.09955311
      },
      {
        lng: 106.73686218,
        lat: -6.09996748
      },
      {
        lng: 106.73801422,
        lat: -6.10078096
      },
      {
        lng: 106.7388382,
        lat: -6.10121584
      },
      {
        lng: 106.7399826,
        lat: -6.1019249
      },
      {
        lng: 106.74095917,
        lat: -6.10180759
      },
      {
        lng: 106.74175262,
        lat: -6.10208082
      },
      {
        lng: 106.74228668,
        lat: -6.10208845
      },
      {
        lng: 106.74499512,
        lat: -6.10193729
      },
      {
        lng: 106.74550629,
        lat: -6.10173607
      },
      {
        lng: 106.74617767,
        lat: -6.10128689
      },
      {
        lng: 106.74659729,
        lat: -6.10006905
      },
      {
        lng: 106.74819183,
        lat: -6.09983587
      },
      {
        lng: 106.74971771,
        lat: -6.10031557
      },
      {
        lng: 106.75065613,
        lat: -6.10136747
      },
      {
        lng: 106.75151825,
        lat: -6.102283
      },
      {
        lng: 106.75293732,
        lat: -6.10274029
      },
      {
        lng: 106.75509644,
        lat: -6.10345936
      },
      {
        lng: 106.75818634,
        lat: -6.10380983
      },
      {
        lng: 106.76226807,
        lat: -6.10361004
      },
      {
        lng: 106.76390839,
        lat: -6.10310936
      },
      {
        lng: 106.7647171,
        lat: -6.10228062
      },
      {
        lng: 106.765625,
        lat: -6.101336
      },
      {
        lng: 106.76685333,
        lat: -6.1023736
      },
      {
        lng: 106.76778412,
        lat: -6.10200882
      },
      {
        lng: 106.76918793,
        lat: -6.10307789
      },
      {
        lng: 106.77121735,
        lat: -6.10379124
      },
      {
        lng: 106.77127075,
        lat: -6.10470009
      },
      {
        lng: 106.77203369,
        lat: -6.10499001
      },
      {
        lng: 106.77282715,
        lat: -6.10484648
      },
      {
        lng: 106.77400208,
        lat: -6.10490608
      },
      {
        lng: 106.7744751,
        lat: -6.10471487
      },
      {
        lng: 106.77555084,
        lat: -6.10390329
      },
      {
        lng: 106.77644348,
        lat: -6.10415983
      },
      {
        lng: 106.77489471,
        lat: -6.10699606
      },
      {
        lng: 106.77480316,
        lat: -6.1084218
      },
      {
        lng: 106.77465057,
        lat: -6.10896301
      },
      {
        lng: 106.77449036,
        lat: -6.11009645
      },
      {
        lng: 106.77513885,
        lat: -6.10867977
      },
      {
        lng: 106.77553558,
        lat: -6.10810137
      },
      {
        lng: 106.77584839,
        lat: -6.10749626
      },
      {
        lng: 106.77610016,
        lat: -6.10697603
      },
      {
        lng: 106.77693939,
        lat: -6.10614681
      },
      {
        lng: 106.78015137,
        lat: -6.10761023
      },
      {
        lng: 106.78444672,
        lat: -6.10859013
      },
      {
        lng: 106.78517914,
        lat: -6.10996056
      },
      {
        lng: 106.78514099,
        lat: -6.11113787
      },
      {
        lng: 106.7855072,
        lat: -6.11011934
      },
      {
        lng: 106.78684998,
        lat: -6.10810423
      },
      {
        lng: 106.787117,
        lat: -6.10809565
      },
      {
        lng: 106.79034424,
        lat: -6.10804415
      },
      {
        lng: 106.79046631,
        lat: -6.10673571
      },
      {
        lng: 106.78981018,
        lat: -6.10682201
      },
      {
        lng: 106.78961945,
        lat: -6.10561275
      },
      {
        lng: 106.78961182,
        lat: -6.10479307
      },
      {
        lng: 106.78956604,
        lat: -6.10377884
      },
      {
        lng: 106.78954315,
        lat: -6.10215902
      },
      {
        lng: 106.78961182,
        lat: -6.10066223
      },
      {
        lng: 106.78986359,
        lat: -6.09961557
      },
      {
        lng: 106.78993988,
        lat: -6.09877443
      },
      {
        lng: 106.78996277,
        lat: -6.09740257
      },
      {
        lng: 106.79007721,
        lat: -6.09518194
      },
      {
        lng: 106.79074097,
        lat: -6.0933938
      },
      {
        lng: 106.79269409,
        lat: -6.09239769
      },
      {
        lng: 106.79636383,
        lat: -6.09370852
      },
      {
        lng: 106.79659271,
        lat: -6.10107994
      },
      {
        lng: 106.79656219,
        lat: -6.1033659
      },
      {
        lng: 106.79667664,
        lat: -6.10409212
      },
      {
        lng: 106.7962265,
        lat: -6.10446453
      },
      {
        lng: 106.79599762,
        lat: -6.10509729
      },
      {
        lng: 106.79584503,
        lat: -6.10657787
      },
      {
        lng: 106.79603577,
        lat: -6.10864115
      },
      {
        lng: 106.79618073,
        lat: -6.1092453
      },
      {
        lng: 106.79624939,
        lat: -6.10906029
      },
      {
        lng: 106.79820251,
        lat: -6.10889769
      },
      {
        lng: 106.79932404,
        lat: -6.10874557
      },
      {
        lng: 106.80059052,
        lat: -6.10815001
      },
      {
        lng: 106.79953003,
        lat: -6.1019702
      },
      {
        lng: 106.79876709,
        lat: -6.09743977
      },
      {
        lng: 106.8004837,
        lat: -6.09781981
      },
      {
        lng: 106.80222321,
        lat: -6.10198116
      },
      {
        lng: 106.80336761,
        lat: -6.10206985
      },
      {
        lng: 106.80397797,
        lat: -6.10196018
      },
      {
        lng: 106.80432129,
        lat: -6.10146189
      },
      {
        lng: 106.80378723,
        lat: -6.09512997
      },
      {
        lng: 106.80439758,
        lat: -6.09854984
      },
      {
        lng: 106.80487061,
        lat: -6.10106945
      },
      {
        lng: 106.80606079,
        lat: -6.10732985
      },
      {
        lng: 106.80667877,
        lat: -6.11086416
      },
      {
        lng: 106.80687714,
        lat: -6.11198282
      },
      {
        lng: 106.80708313,
        lat: -6.11297941
      },
      {
        lng: 106.80718994,
        lat: -6.11368036
      },
      {
        lng: 106.80734253,
        lat: -6.11438084
      },
      {
        lng: 106.80758667,
        lat: -6.11529684
      },
      {
        lng: 106.80828094,
        lat: -6.11632013
      },
      {
        lng: 106.81249237,
        lat: -6.12255001
      },
      {
        lng: 106.81228638,
        lat: -6.11953974
      },
      {
        lng: 106.81285858,
        lat: -6.11988878
      },
      {
        lng: 106.813797,
        lat: -6.1207099
      },
      {
        lng: 106.81491852,
        lat: -6.1195302
      },
      {
        lng: 106.81602478,
        lat: -6.11980772
      },
      {
        lng: 106.81690216,
        lat: -6.11705017
      },
      {
        lng: 106.81719971,
        lat: -6.11490011
      },
      {
        lng: 106.82059479,
        lat: -6.1153717
      },
      {
        lng: 106.82037354,
        lat: -6.11695099
      },
      {
        lng: 106.82019806,
        lat: -6.118361
      },
      {
        lng: 106.82061005,
        lat: -6.11916065
      },
      {
        lng: 106.82223511,
        lat: -6.12040663
      },
      {
        lng: 106.82215118,
        lat: -6.12149429
      },
      {
        lng: 106.82271576,
        lat: -6.12047529
      },
      {
        lng: 106.82317352,
        lat: -6.11784792
      },
      {
        lng: 106.82603455,
        lat: -6.11736441
      },
      {
        lng: 106.82933044,
        lat: -6.11873007
      },
      {
        lng: 106.82948303,
        lat: -6.12015009
      },
      {
        lng: 106.83054352,
        lat: -6.12099981
      },
      {
        lng: 106.83209991,
        lat: -6.12082338
      },
      {
        lng: 106.83452606,
        lat: -6.12155867
      },
      {
        lng: 106.8364563,
        lat: -6.12109995
      },
      {
        lng: 106.83739471,
        lat: -6.12141037
      },
      {
        lng: 106.83857727,
        lat: -6.12131977
      },
      {
        lng: 106.8391037,
        lat: -6.12088013
      },
      {
        lng: 106.84158325,
        lat: -6.12090015
      },
      {
        lng: 106.84298706,
        lat: -6.12153006
      },
      {
        lng: 106.84243011,
        lat: -6.12253952
      },
      {
        lng: 106.84130859,
        lat: -6.12265015
      },
      {
        lng: 106.83988953,
        lat: -6.12220526
      },
      {
        lng: 106.83940125,
        lat: -6.12257099
      },
      {
        lng: 106.8393631,
        lat: -6.12339973
      },
      {
        lng: 106.84038544,
        lat: -6.12391901
      },
      {
        lng: 106.84116364,
        lat: -6.12371016
      },
      {
        lng: 106.84226227,
        lat: -6.12365723
      },
      {
        lng: 106.84329987,
        lat: -6.1230998
      },
      {
        lng: 106.84339905,
        lat: -6.12185431
      },
      {
        lng: 106.84393311,
        lat: -6.12100983
      },
      {
        lng: 106.84553528,
        lat: -6.12041187
      },
      {
        lng: 106.84664917,
        lat: -6.12007332
      },
      {
        lng: 106.84776306,
        lat: -6.12025976
      },
      {
        lng: 106.84879303,
        lat: -6.1198802
      },
      {
        lng: 106.85399628,
        lat: -6.11828995
      },
      {
        lng: 106.85510254,
        lat: -6.11710978
      },
      {
        lng: 106.85598755,
        lat: -6.11694908
      },
      {
        lng: 106.85717773,
        lat: -6.11651421
      },
      {
        lng: 106.85915375,
        lat: -6.11457014
      },
      {
        lng: 106.8588028,
        lat: -6.11292315
      },
      {
        lng: 106.85774994,
        lat: -6.11115694
      },
      {
        lng: 106.8584671,
        lat: -6.11083651
      },
      {
        lng: 106.85904694,
        lat: -6.11070061
      },
      {
        lng: 106.86079407,
        lat: -6.11056471
      },
      {
        lng: 106.8608551,
        lat: -6.11107922
      },
      {
        lng: 106.86096191,
        lat: -6.11177301
      },
      {
        lng: 106.8610611,
        lat: -6.11240053
      },
      {
        lng: 106.86122894,
        lat: -6.11313629
      },
      {
        lng: 106.8611908,
        lat: -6.11387682
      },
      {
        lng: 106.86149597,
        lat: -6.11450338
      },
      {
        lng: 106.86273193,
        lat: -6.11415195
      },
      {
        lng: 106.86327362,
        lat: -6.11323977
      },
      {
        lng: 106.86358643,
        lat: -6.11191893
      },
      {
        lng: 106.8656311,
        lat: -6.11029005
      },
      {
        lng: 106.86695862,
        lat: -6.10937595
      },
      {
        lng: 106.8677063,
        lat: -6.10872507
      },
      {
        lng: 106.87023926,
        lat: -6.10674143
      },
      {
        lng: 106.87187958,
        lat: -6.10502434
      },
      {
        lng: 106.87239838,
        lat: -6.10661793
      },
      {
        lng: 106.87207794,
        lat: -6.10803175
      },
      {
        lng: 106.87093353,
        lat: -6.10911989
      },
      {
        lng: 106.8688736,
        lat: -6.11023998
      },
      {
        lng: 106.86564636,
        lat: -6.11194992
      },
      {
        lng: 106.86463165,
        lat: -6.11365986
      },
      {
        lng: 106.86476898,
        lat: -6.11612988
      },
      {
        lng: 106.8647995,
        lat: -6.11773014
      },
      {
        lng: 106.86472321,
        lat: -6.12018013
      },
      {
        lng: 106.86321259,
        lat: -6.12115431
      },
      {
        lng: 106.86366272,
        lat: -6.12143993
      },
      {
        lng: 106.8639679,
        lat: -6.12150097
      },
      {
        lng: 106.86585999,
        lat: -6.11945391
      },
      {
        lng: 106.86566925,
        lat: -6.11829805
      },
      {
        lng: 106.86551666,
        lat: -6.11679983
      },
      {
        lng: 106.8653183,
        lat: -6.11623383
      },
      {
        lng: 106.86532593,
        lat: -6.11535597
      },
      {
        lng: 106.86535645,
        lat: -6.11370993
      },
      {
        lng: 106.86595917,
        lat: -6.11331987
      },
      {
        lng: 106.86706543,
        lat: -6.11270571
      },
      {
        lng: 106.86768341,
        lat: -6.11240005
      },
      {
        lng: 106.86832428,
        lat: -6.11239958
      },
      {
        lng: 106.86885834,
        lat: -6.11213017
      },
      {
        lng: 106.87004852,
        lat: -6.11106014
      },
      {
        lng: 106.87169647,
        lat: -6.1107502
      },
      {
        lng: 106.87158966,
        lat: -6.11128998
      },
      {
        lng: 106.87026215,
        lat: -6.11470985
      },
      {
        lng: 106.87205505,
        lat: -6.11459923
      },
      {
        lng: 106.87281799,
        lat: -6.1143899
      },
      {
        lng: 106.87238312,
        lat: -6.11393976
      },
      {
        lng: 106.87381744,
        lat: -6.10780001
      },
      {
        lng: 106.87429047,
        lat: -6.10680008
      },
      {
        lng: 106.87425995,
        lat: -6.10590982
      },
      {
        lng: 106.87489319,
        lat: -6.1038599
      },
      {
        lng: 106.87615967,
        lat: -6.10238981
      },
      {
        lng: 106.87683868,
        lat: -6.10177994
      },
      {
        lng: 106.87667084,
        lat: -6.10668993
      },
      {
        lng: 106.87619019,
        lat: -6.10902023
      },
      {
        lng: 106.87561798,
        lat: -6.11112022
      },
      {
        lng: 106.87584686,
        lat: -6.11102009
      },
      {
        lng: 106.87634277,
        lat: -6.11076021
      },
      {
        lng: 106.87663269,
        lat: -6.10867023
      },
      {
        lng: 106.87798309,
        lat: -6.10732985
      },
      {
        lng: 106.87741852,
        lat: -6.11004019
      },
      {
        lng: 106.8782196,
        lat: -6.11005402
      },
      {
        lng: 106.87864685,
        lat: -6.1063199
      },
      {
        lng: 106.87859344,
        lat: -6.10411978
      },
      {
        lng: 106.87869263,
        lat: -6.10290194
      },
      {
        lng: 106.87960815,
        lat: -6.10236931
      },
      {
        lng: 106.87991333,
        lat: -6.10206985
      },
      {
        lng: 106.87872314,
        lat: -6.10137987
      },
      {
        lng: 106.87915039,
        lat: -6.09501982
      },
      {
        lng: 106.87983704,
        lat: -6.09853983
      },
      {
        lng: 106.88005066,
        lat: -6.09696007
      },
      {
        lng: 106.88034058,
        lat: -6.09672022
      },
      {
        lng: 106.88105011,
        lat: -6.09676981
      },
      {
        lng: 106.88137817,
        lat: -6.09383011
      },
      {
        lng: 106.88208008,
        lat: -6.09406328
      },
      {
        lng: 106.88260651,
        lat: -6.09569311
      },
      {
        lng: 106.8837738,
        lat: -6.10644007
      },
      {
        lng: 106.88697052,
        lat: -6.09729004
      },
      {
        lng: 106.88796997,
        lat: -6.10655022
      },
      {
        lng: 106.89087677,
        lat: -6.10046005
      },
      {
        lng: 106.89142609,
        lat: -6.1067214
      },
      {
        lng: 106.89233398,
        lat: -6.10682678
      },
      {
        lng: 106.89256287,
        lat: -6.09932804
      },
      {
        lng: 106.89261627,
        lat: -6.09735155
      },
      {
        lng: 106.89331055,
        lat: -6.0973959
      },
      {
        lng: 106.89466858,
        lat: -6.09744453
      },
      {
        lng: 106.8963089,
        lat: -6.09749222
      },
      {
        lng: 106.89732361,
        lat: -6.09752893
      },
      {
        lng: 106.900177,
        lat: -6.09764004
      },
      {
        lng: 106.90448761,
        lat: -6.09779882
      },
      {
        lng: 106.90526581,
        lat: -6.0978322
      },
      {
        lng: 106.90500641,
        lat: -6.09847736
      },
      {
        lng: 106.90538025,
        lat: -6.09858799
      },
      {
        lng: 106.90548706,
        lat: -6.10089207
      },
      {
        lng: 106.90543365,
        lat: -6.10099936
      },
      {
        lng: 106.90529633,
        lat: -6.10279417
      },
      {
        lng: 106.90564728,
        lat: -6.10353565
      },
      {
        lng: 106.90525818,
        lat: -6.10383272
      },
      {
        lng: 106.90518951,
        lat: -6.10552883
      },
      {
        lng: 106.90555573,
        lat: -6.10576916
      },
      {
        lng: 106.90518188,
        lat: -6.1059804
      },
      {
        lng: 106.905159,
        lat: -6.10700083
      },
      {
        lng: 106.9057312,
        lat: -6.10773277
      },
      {
        lng: 106.90646362,
        lat: -6.10784626
      },
      {
        lng: 106.90668488,
        lat: -6.10834694
      },
      {
        lng: 106.90692902,
        lat: -6.10840225
      },
      {
        lng: 106.90710449,
        lat: -6.10717487
      },
      {
        lng: 106.90725708,
        lat: -6.10573006
      },
      {
        lng: 106.90714264,
        lat: -6.10486746
      },
      {
        lng: 106.90760803,
        lat: -6.10380077
      },
      {
        lng: 106.9103775,
        lat: -6.10343981
      },
      {
        lng: 106.90940094,
        lat: -6.1014185
      },
      {
        lng: 106.90830994,
        lat: -6.10089922
      },
      {
        lng: 106.90818787,
        lat: -6.09969997
      },
      {
        lng: 106.9099884,
        lat: -6.09891796
      },
      {
        lng: 106.91230774,
        lat: -6.09932995
      },
      {
        lng: 106.91316223,
        lat: -6.09931278
      },
      {
        lng: 106.91236115,
        lat: -6.09894562
      },
      {
        lng: 106.91384888,
        lat: -6.09952688
      },
      {
        lng: 106.91465759,
        lat: -6.10017347
      },
      {
        lng: 106.9152298,
        lat: -6.10032463
      },
      {
        lng: 106.91605377,
        lat: -6.09974003
      },
      {
        lng: 106.91664886,
        lat: -6.09951019
      },
      {
        lng: 106.9162674,
        lat: -6.09895897
      },
      {
        lng: 106.91876221,
        lat: -6.10085344
      },
      {
        lng: 106.91893005,
        lat: -6.10285521
      },
      {
        lng: 106.91903687,
        lat: -6.10435009
      },
      {
        lng: 106.92034912,
        lat: -6.10417604
      },
      {
        lng: 106.92137909,
        lat: -6.10392857
      },
      {
        lng: 106.92058563,
        lat: -6.10351658
      },
      {
        lng: 106.91970062,
        lat: -6.10322857
      },
      {
        lng: 106.91976929,
        lat: -6.10205984
      },
      {
        lng: 106.92034149,
        lat: -6.100667
      },
      {
        lng: 106.92195129,
        lat: -6.10071564
      },
      {
        lng: 106.92468262,
        lat: -6.10017729
      },
      {
        lng: 106.92529297,
        lat: -6.10013533
      },
      {
        lng: 106.92593384,
        lat: -6.10000706
      },
      {
        lng: 106.92658997,
        lat: -6.09850979
      },
      {
        lng: 106.92671967,
        lat: -6.09808254
      },
      {
        lng: 106.92847443,
        lat: -6.09763384
      },
      {
        lng: 106.92977142,
        lat: -6.09753084
      },
      {
        lng: 106.93128967,
        lat: -6.09736156
      },
      {
        lng: 106.93310547,
        lat: -6.09692907
      },
      {
        lng: 106.93440247,
        lat: -6.09654713
      },
      {
        lng: 106.93572998,
        lat: -6.09644985
      },
      {
        lng: 106.93838501,
        lat: -6.09601164
      },
      {
        lng: 106.94046783,
        lat: -6.09811687
      },
      {
        lng: 106.94140625,
        lat: -6.09608841
      },
      {
        lng: 106.96046448,
        lat: -6.09247541
      },
      {
        lng: 106.96188354,
        lat: -6.09218597
      },
      {
        lng: 106.96264648,
        lat: -6.09230995
      },
      {
        lng: 106.96433258,
        lat: -6.09182978
      },
      {
        lng: 106.96643066,
        lat: -6.09141016
      },
      {
        lng: 106.96884918,
        lat: -6.09130812
      },
      {
        lng: 106.96857452,
        lat: -6.09513187
      },
      {
        lng: 106.96874237,
        lat: -6.09854078
      },
      {
        lng: 106.96876526,
        lat: -6.0997963
      },
      {
        lng: 106.96923828,
        lat: -6.09998798
      },
      {
        lng: 106.9690094,
        lat: -6.10133696
      },
      {
        lng: 106.97033691,
        lat: -6.10395861
      },
      {
        lng: 106.97034454,
        lat: -6.10599899
      },
      {
        lng: 106.97040558,
        lat: -6.10773945
      },
      {
        lng: 106.97014618,
        lat: -6.1090312
      },
      {
        lng: 106.9704361,
        lat: -6.11085129
      },
      {
        lng: 106.96996307,
        lat: -6.11204815
      },
      {
        lng: 106.97000122,
        lat: -6.12235832
      },
      {
        lng: 106.97045135,
        lat: -6.12644958
      },
      {
        lng: 106.97143555,
        lat: -6.14143944
      },
      {
        lng: 106.9727478,
        lat: -6.14504385
      },
      {
        lng: 106.97201538,
        lat: -6.14603424
      },
      {
        lng: 106.97174835,
        lat: -6.146698
      },
      {
        lng: 106.97184753,
        lat: -6.14838552
      },
      {
        lng: 106.97181702,
        lat: -6.15029812
      },
      {
        lng: 106.97215271,
        lat: -6.15297747
      },
      {
        lng: 106.9706192,
        lat: -6.15432835
      },
      {
        lng: 106.96907806,
        lat: -6.15451813
      },
      {
        lng: 106.96715546,
        lat: -6.15495253
      },
      {
        lng: 106.9644928,
        lat: -6.15583086
      },
      {
        lng: 106.96392822,
        lat: -6.15619898
      },
      {
        lng: 106.96260834,
        lat: -6.15622711
      },
      {
        lng: 106.96044159,
        lat: -6.15568256
      },
      {
        lng: 106.95959473,
        lat: -6.15731192
      },
      {
        lng: 106.95755768,
        lat: -6.15874672
      },
      {
        lng: 106.95370483,
        lat: -6.15798521
      },
      {
        lng: 106.95246887,
        lat: -6.15997124
      },
      {
        lng: 106.95305634,
        lat: -6.16069889
      },
      {
        lng: 106.95272827,
        lat: -6.16126871
      },
      {
        lng: 106.95233917,
        lat: -6.1619401
      },
      {
        lng: 106.95215607,
        lat: -6.16271496
      },
      {
        lng: 106.95127869,
        lat: -6.16335726
      },
      {
        lng: 106.94986725,
        lat: -6.1632452
      },
      {
        lng: 106.94872284,
        lat: -6.16315508
      },
      {
        lng: 106.94560242,
        lat: -6.16306162
      },
      {
        lng: 106.94451904,
        lat: -6.161798
      },
      {
        lng: 106.94464874,
        lat: -6.15891218
      },
      {
        lng: 106.94441223,
        lat: -6.15692282
      },
      {
        lng: 106.94428253,
        lat: -6.15524387
      },
      {
        lng: 106.94293976,
        lat: -6.15508509
      },
      {
        lng: 106.94036865,
        lat: -6.15525961
      },
      {
        lng: 106.93997192,
        lat: -6.15142298
      },
      {
        lng: 106.9392395,
        lat: -6.15193892
      },
      {
        lng: 106.93830109,
        lat: -6.15333796
      },
      {
        lng: 106.93740082,
        lat: -6.15508413
      },
      {
        lng: 106.9362793,
        lat: -6.15726328
      },
      {
        lng: 106.9355545,
        lat: -6.15854502
      },
      {
        lng: 106.934021,
        lat: -6.158319
      },
      {
        lng: 106.93327332,
        lat: -6.1590786
      },
      {
        lng: 106.93201447,
        lat: -6.1591115
      },
      {
        lng: 106.93133545,
        lat: -6.1599884
      },
      {
        lng: 106.9295578,
        lat: -6.16072655
      },
      {
        lng: 106.92818451,
        lat: -6.1604948
      },
      {
        lng: 106.92469025,
        lat: -6.16118717
      },
      {
        lng: 106.92170715,
        lat: -6.16290426
      },
      {
        lng: 106.92111206,
        lat: -6.16613722
      },
      {
        lng: 106.92086029,
        lat: -6.16789007
      },
      {
        lng: 106.92036438,
        lat: -6.16980457
      },
      {
        lng: 106.9205246,
        lat: -6.1719346
      },
      {
        lng: 106.92095184,
        lat: -6.17344093
      },
      {
        lng: 106.92136383,
        lat: -6.17609644
      },
      {
        lng: 106.92176819,
        lat: -6.17906475
      },
      {
        lng: 106.92182159,
        lat: -6.18020821
      },
      {
        lng: 106.92191315,
        lat: -6.18148518
      },
      {
        lng: 106.92230988,
        lat: -6.18310547
      },
      {
        lng: 106.91947937,
        lat: -6.18289518
      },
      {
        lng: 106.91675568,
        lat: -6.18269873
      },
      {
        lng: 106.91407776,
        lat: -6.18247747
      },
      {
        lng: 106.91214752,
        lat: -6.18276644
      },
      {
        lng: 106.91139984,
        lat: -6.18252087
      },
      {
        lng: 106.91066742,
        lat: -6.18227959
      },
      {
        lng: 106.90911865,
        lat: -6.18190956
      },
      {
        lng: 106.90654755,
        lat: -6.18060684
      },
      {
        lng: 106.90437317,
        lat: -6.17968941
      },
      {
        lng: 106.90247345,
        lat: -6.17897034
      },
      {
        lng: 106.90024567,
        lat: -6.17835426
      },
      {
        lng: 106.89918518,
        lat: -6.17831612
      },
      {
        lng: 106.89787292,
        lat: -6.17809105
      },
      {
        lng: 106.89700317,
        lat: -6.17810202
      },
      {
        lng: 106.89546967,
        lat: -6.17725229
      },
      {
        lng: 106.89346313,
        lat: -6.17541075
      },
      {
        lng: 106.89214325,
        lat: -6.17423582
      },
      {
        lng: 106.89095306,
        lat: -6.17316246
      },
      {
        lng: 106.88868713,
        lat: -6.17182112
      },
      {
        lng: 106.88719177,
        lat: -6.17089367
      },
      {
        lng: 106.88451385,
        lat: -6.16920948
      },
      {
        lng: 106.88227844,
        lat: -6.16785336
      },
      {
        lng: 106.8796463,
        lat: -6.16653824
      },
      {
        lng: 106.87906647,
        lat: -6.16612864
      },
      {
        lng: 106.8799057,
        lat: -6.16490078
      },
      {
        lng: 106.88117981,
        lat: -6.16279459
      },
      {
        lng: 106.88105011,
        lat: -6.16259813
      },
      {
        lng: 106.88027191,
        lat: -6.16277456
      },
      {
        lng: 106.87888336,
        lat: -6.16313362
      },
      {
        lng: 106.87687683,
        lat: -6.16349411
      },
      {
        lng: 106.87528992,
        lat: -6.1624465
      },
      {
        lng: 106.87275696,
        lat: -6.1608777
      },
      {
        lng: 106.87020874,
        lat: -6.15942574
      },
      {
        lng: 106.86885834,
        lat: -6.15867901
      },
      {
        lng: 106.866539,
        lat: -6.1573329
      },
      {
        lng: 106.86489868,
        lat: -6.15644979
      },
      {
        lng: 106.86209869,
        lat: -6.15494919
      },
      {
        lng: 106.86044312,
        lat: -6.15357733
      },
      {
        lng: 106.86108398,
        lat: -6.15196705
      },
      {
        lng: 106.86091614,
        lat: -6.15179586
      },
      {
        lng: 106.85749817,
        lat: -6.15226746
      },
      {
        lng: 106.85510254,
        lat: -6.15198183
      },
      {
        lng: 106.85340118,
        lat: -6.15225983
      },
      {
        lng: 106.8510437,
        lat: -6.15267086
      },
      {
        lng: 106.84988403,
        lat: -6.1528554
      },
      {
        lng: 106.84661865,
        lat: -6.15335083
      },
      {
        lng: 106.8446579,
        lat: -6.15403557
      },
      {
        lng: 106.8420639,
        lat: -6.15262318
      },
      {
        lng: 106.84119415,
        lat: -6.15106964
      },
      {
        lng: 106.8422699,
        lat: -6.14866114
      },
      {
        lng: 106.84334564,
        lat: -6.1469717
      },
      {
        lng: 106.84328461,
        lat: -6.14605999
      },
      {
        lng: 106.84309387,
        lat: -6.14442635
      },
      {
        lng: 106.84035492,
        lat: -6.14288664
      },
      {
        lng: 106.83841705,
        lat: -6.14174843
      },
      {
        lng: 106.8374176,
        lat: -6.14409781
      },
      {
        lng: 106.83691406,
        lat: -6.14541769
      },
      {
        lng: 106.83520508,
        lat: -6.14275837
      },
      {
        lng: 106.83338165,
        lat: -6.14167166
      },
      {
        lng: 106.83213043,
        lat: -6.14123392
      },
      {
        lng: 106.83072662,
        lat: -6.1395402
      },
      {
        lng: 106.83067322,
        lat: -6.13542747
      },
      {
        lng: 106.8294754,
        lat: -6.13556242
      },
      {
        lng: 106.82843018,
        lat: -6.13574839
      },
      {
        lng: 106.82616425,
        lat: -6.13615608
      },
      {
        lng: 106.82514191,
        lat: -6.13632107
      },
      {
        lng: 106.82237244,
        lat: -6.13669109
      },
      {
        lng: 106.82046509,
        lat: -6.13642073
      },
      {
        lng: 106.81867981,
        lat: -6.13658428
      },
      {
        lng: 106.81674194,
        lat: -6.1370635
      },
      {
        lng: 106.81627655,
        lat: -6.13482618
      },
      {
        lng: 106.81554413,
        lat: -6.13230896
      },
      {
        lng: 106.81481934,
        lat: -6.13019562
      },
      {
        lng: 106.81318665,
        lat: -6.13012409
      },
      {
        lng: 106.81044006,
        lat: -6.13083982
      },
      {
        lng: 106.80927277,
        lat: -6.13113022
      },
      {
        lng: 106.80698395,
        lat: -6.13173246
      },
      {
        lng: 106.80597687,
        lat: -6.13198566
      },
      {
        lng: 106.80432129,
        lat: -6.13241577
      },
      {
        lng: 106.80084229,
        lat: -6.13399315
      },
      {
        lng: 106.8002243,
        lat: -6.13648272
      },
      {
        lng: 106.80036163,
        lat: -6.13897753
      },
      {
        lng: 106.80052185,
        lat: -6.14188719
      },
      {
        lng: 106.79927826,
        lat: -6.14199734
      },
      {
        lng: 106.7974472,
        lat: -6.14232779
      },
      {
        lng: 106.79611969,
        lat: -6.14246178
      },
      {
        lng: 106.79347229,
        lat: -6.1426878
      },
      {
        lng: 106.79217529,
        lat: -6.14280653
      },
      {
        lng: 106.7904129,
        lat: -6.14235497
      },
      {
        lng: 106.7885437,
        lat: -6.1428318
      },
      {
        lng: 106.78733826,
        lat: -6.14323521
      },
      {
        lng: 106.78381348,
        lat: -6.14354897
      },
      {
        lng: 106.78112793,
        lat: -6.14377117
      }
    ]
  },
  {
    name: 'Depok',
    prov: 'Jawa Barat',
    shape: [
      {
        lng: 106.83224487,
        lat: -6.45343828
      },
      {
        lng: 106.83226776,
        lat: -6.45444965
      },
      {
        lng: 106.83165741,
        lat: -6.45483255
      },
      {
        lng: 106.83083344,
        lat: -6.45493889
      },
      {
        lng: 106.82994843,
        lat: -6.45533991
      },
      {
        lng: 106.82902527,
        lat: -6.4552846
      },
      {
        lng: 106.82816315,
        lat: -6.45607805
      },
      {
        lng: 106.8278656,
        lat: -6.45762062
      },
      {
        lng: 106.82743835,
        lat: -6.45878029
      },
      {
        lng: 106.82667542,
        lat: -6.45960093
      },
      {
        lng: 106.8264389,
        lat: -6.46043825
      },
      {
        lng: 106.82595062,
        lat: -6.46056175
      },
      {
        lng: 106.82558441,
        lat: -6.46101332
      },
      {
        lng: 106.8252182,
        lat: -6.46118784
      },
      {
        lng: 106.82466888,
        lat: -6.46101093
      },
      {
        lng: 106.8241806,
        lat: -6.46098518
      },
      {
        lng: 106.82414246,
        lat: -6.46035242
      },
      {
        lng: 106.82437897,
        lat: -6.45959949
      },
      {
        lng: 106.82458496,
        lat: -6.45917559
      },
      {
        lng: 106.82480621,
        lat: -6.45824146
      },
      {
        lng: 106.82537842,
        lat: -6.45561266
      },
      {
        lng: 106.82500458,
        lat: -6.45475817
      },
      {
        lng: 106.82437134,
        lat: -6.4545188
      },
      {
        lng: 106.82376099,
        lat: -6.4549613
      },
      {
        lng: 106.82300568,
        lat: -6.45490551
      },
      {
        lng: 106.82261658,
        lat: -6.45445585
      },
      {
        lng: 106.82198334,
        lat: -6.45426702
      },
      {
        lng: 106.82109833,
        lat: -6.45386934
      },
      {
        lng: 106.82089996,
        lat: -6.4528017
      },
      {
        lng: 106.82058716,
        lat: -6.45237064
      },
      {
        lng: 106.82056427,
        lat: -6.45177126
      },
      {
        lng: 106.82079315,
        lat: -6.45122862
      },
      {
        lng: 106.82115936,
        lat: -6.45056248
      },
      {
        lng: 106.82136536,
        lat: -6.44992828
      },
      {
        lng: 106.82184601,
        lat: -6.44925499
      },
      {
        lng: 106.8227005,
        lat: -6.44914865
      },
      {
        lng: 106.82293701,
        lat: -6.44865561
      },
      {
        lng: 106.82299805,
        lat: -6.44818783
      },
      {
        lng: 106.82352448,
        lat: -6.44759846
      },
      {
        lng: 106.8237915,
        lat: -6.44678497
      },
      {
        lng: 106.82369232,
        lat: -6.44577456
      },
      {
        lng: 106.82339478,
        lat: -6.4452405
      },
      {
        lng: 106.82312775,
        lat: -6.44453144
      },
      {
        lng: 106.82260132,
        lat: -6.44429159
      },
      {
        lng: 106.8214035,
        lat: -6.44401217
      },
      {
        lng: 106.8203125,
        lat: -6.44385481
      },
      {
        lng: 106.81962585,
        lat: -6.44388914
      },
      {
        lng: 106.8190155,
        lat: -6.44431782
      },
      {
        lng: 106.81822968,
        lat: -6.44445562
      },
      {
        lng: 106.81739044,
        lat: -6.44476128
      },
      {
        lng: 106.81681061,
        lat: -6.44503117
      },
      {
        lng: 106.81604004,
        lat: -6.44497347
      },
      {
        lng: 106.81465912,
        lat: -6.44456339
      },
      {
        lng: 106.81359863,
        lat: -6.44442034
      },
      {
        lng: 106.81292725,
        lat: -6.44396639
      },
      {
        lng: 106.81160736,
        lat: -6.44374752
      },
      {
        lng: 106.8108139,
        lat: -6.44365644
      },
      {
        lng: 106.80873108,
        lat: -6.44358873
      },
      {
        lng: 106.80722046,
        lat: -6.44353771
      },
      {
        lng: 106.80461121,
        lat: -6.44372988
      },
      {
        lng: 106.80409241,
        lat: -6.44480562
      },
      {
        lng: 106.80367279,
        lat: -6.44573927
      },
      {
        lng: 106.80310059,
        lat: -6.44708347
      },
      {
        lng: 106.80260468,
        lat: -6.44814062
      },
      {
        lng: 106.80206299,
        lat: -6.45005465
      },
      {
        lng: 106.80172729,
        lat: -6.45157528
      },
      {
        lng: 106.79905701,
        lat: -6.4517808
      },
      {
        lng: 106.79463959,
        lat: -6.45171499
      },
      {
        lng: 106.79343414,
        lat: -6.45069265
      },
      {
        lng: 106.79364014,
        lat: -6.44943619
      },
      {
        lng: 106.79423523,
        lat: -6.44785023
      },
      {
        lng: 106.79408264,
        lat: -6.44641161
      },
      {
        lng: 106.79384613,
        lat: -6.44560575
      },
      {
        lng: 106.79404449,
        lat: -6.44441271
      },
      {
        lng: 106.79421997,
        lat: -6.44351053
      },
      {
        lng: 106.79409027,
        lat: -6.44184732
      },
      {
        lng: 106.79224396,
        lat: -6.44182825
      },
      {
        lng: 106.79157257,
        lat: -6.44081306
      },
      {
        lng: 106.79064941,
        lat: -6.44052362
      },
      {
        lng: 106.78894043,
        lat: -6.44055462
      },
      {
        lng: 106.78876495,
        lat: -6.4390707
      },
      {
        lng: 106.78843689,
        lat: -6.43864346
      },
      {
        lng: 106.78650665,
        lat: -6.43895006
      },
      {
        lng: 106.78366089,
        lat: -6.43931007
      },
      {
        lng: 106.78240967,
        lat: -6.43908024
      },
      {
        lng: 106.78125763,
        lat: -6.43929005
      },
      {
        lng: 106.78005219,
        lat: -6.43918991
      },
      {
        lng: 106.77855682,
        lat: -6.43865013
      },
      {
        lng: 106.77754211,
        lat: -6.43848991
      },
      {
        lng: 106.77600098,
        lat: -6.43834019
      },
      {
        lng: 106.77458191,
        lat: -6.4383502
      },
      {
        lng: 106.77292633,
        lat: -6.43824005
      },
      {
        lng: 106.77166748,
        lat: -6.43837023
      },
      {
        lng: 106.76689148,
        lat: -6.43805981
      },
      {
        lng: 106.76515961,
        lat: -6.43806982
      },
      {
        lng: 106.76400757,
        lat: -6.43802023
      },
      {
        lng: 106.75842285,
        lat: -6.43897009
      },
      {
        lng: 106.75550079,
        lat: -6.43908978
      },
      {
        lng: 106.75449371,
        lat: -6.43900013
      },
      {
        lng: 106.75299835,
        lat: -6.43872976
      },
      {
        lng: 106.75196075,
        lat: -6.43877983
      },
      {
        lng: 106.75,
        lat: -6.43818998
      },
      {
        lng: 106.74858856,
        lat: -6.43807983
      },
      {
        lng: 106.74793243,
        lat: -6.4384799
      },
      {
        lng: 106.74687958,
        lat: -6.43845987
      },
      {
        lng: 106.74514771,
        lat: -6.43888998
      },
      {
        lng: 106.74330902,
        lat: -6.43889523
      },
      {
        lng: 106.7419281,
        lat: -6.43888998
      },
      {
        lng: 106.74035645,
        lat: -6.43891001
      },
      {
        lng: 106.73924255,
        lat: -6.43896008
      },
      {
        lng: 106.7383728,
        lat: -6.43879986
      },
      {
        lng: 106.73731232,
        lat: -6.43706989
      },
      {
        lng: 106.73706818,
        lat: -6.43615007
      },
      {
        lng: 106.7368927,
        lat: -6.43551016
      },
      {
        lng: 106.7360611,
        lat: -6.43432999
      },
      {
        lng: 106.73597717,
        lat: -6.43261003
      },
      {
        lng: 106.73612213,
        lat: -6.4318099
      },
      {
        lng: 106.7353363,
        lat: -6.43084002
      },
      {
        lng: 106.73438263,
        lat: -6.42987013
      },
      {
        lng: 106.73342133,
        lat: -6.42942572
      },
      {
        lng: 106.73290253,
        lat: -6.42912006
      },
      {
        lng: 106.73348236,
        lat: -6.42849016
      },
      {
        lng: 106.7326889,
        lat: -6.4271698
      },
      {
        lng: 106.73303986,
        lat: -6.42654991
      },
      {
        lng: 106.73518372,
        lat: -6.42612982
      },
      {
        lng: 106.73561096,
        lat: -6.42497158
      },
      {
        lng: 106.73667908,
        lat: -6.42458773
      },
      {
        lng: 106.73667908,
        lat: -6.42375135
      },
      {
        lng: 106.73567963,
        lat: -6.4229598
      },
      {
        lng: 106.73548889,
        lat: -6.42247009
      },
      {
        lng: 106.73596191,
        lat: -6.42187023
      },
      {
        lng: 106.73605347,
        lat: -6.42116022
      },
      {
        lng: 106.73558044,
        lat: -6.42069006
      },
      {
        lng: 106.73588562,
        lat: -6.42019701
      },
      {
        lng: 106.7371521,
        lat: -6.41948986
      },
      {
        lng: 106.73693848,
        lat: -6.41852427
      },
      {
        lng: 106.73628998,
        lat: -6.41848993
      },
      {
        lng: 106.7353363,
        lat: -6.4177599
      },
      {
        lng: 106.73448181,
        lat: -6.41648006
      },
      {
        lng: 106.7336731,
        lat: -6.41606998
      },
      {
        lng: 106.7332077,
        lat: -6.41506004
      },
      {
        lng: 106.73248291,
        lat: -6.4144702
      },
      {
        lng: 106.73065186,
        lat: -6.41472006
      },
      {
        lng: 106.73036957,
        lat: -6.41400003
      },
      {
        lng: 106.73088074,
        lat: -6.41198015
      },
      {
        lng: 106.73072052,
        lat: -6.41037989
      },
      {
        lng: 106.73140717,
        lat: -6.40973997
      },
      {
        lng: 106.73197937,
        lat: -6.40837717
      },
      {
        lng: 106.73139191,
        lat: -6.40760994
      },
      {
        lng: 106.73171997,
        lat: -6.40676022
      },
      {
        lng: 106.73249817,
        lat: -6.40665007
      },
      {
        lng: 106.73271179,
        lat: -6.40572977
      },
      {
        lng: 106.73227692,
        lat: -6.40551996
      },
      {
        lng: 106.7317276,
        lat: -6.40459013
      },
      {
        lng: 106.73117065,
        lat: -6.40382004
      },
      {
        lng: 106.73145294,
        lat: -6.40269995
      },
      {
        lng: 106.73058319,
        lat: -6.40216017
      },
      {
        lng: 106.72985077,
        lat: -6.40214014
      },
      {
        lng: 106.72969818,
        lat: -6.40158987
      },
      {
        lng: 106.72962952,
        lat: -6.40049982
      },
      {
        lng: 106.73084259,
        lat: -6.3994298
      },
      {
        lng: 106.73029327,
        lat: -6.39900017
      },
      {
        lng: 106.72885132,
        lat: -6.39882994
      },
      {
        lng: 106.7286911,
        lat: -6.39776993
      },
      {
        lng: 106.72905731,
        lat: -6.39690018
      },
      {
        lng: 106.72910309,
        lat: -6.39618015
      },
      {
        lng: 106.72718048,
        lat: -6.39617014
      },
      {
        lng: 106.72648621,
        lat: -6.39640999
      },
      {
        lng: 106.72662354,
        lat: -6.39494991
      },
      {
        lng: 106.72715759,
        lat: -6.39421988
      },
      {
        lng: 106.72763824,
        lat: -6.39441013
      },
      {
        lng: 106.72847748,
        lat: -6.39457989
      },
      {
        lng: 106.72810364,
        lat: -6.39387989
      },
      {
        lng: 106.72738647,
        lat: -6.39319992
      },
      {
        lng: 106.72759247,
        lat: -6.3922801
      },
      {
        lng: 106.72694397,
        lat: -6.39183664
      },
      {
        lng: 106.7270813,
        lat: -6.38992023
      },
      {
        lng: 106.72737122,
        lat: -6.38944006
      },
      {
        lng: 106.72862244,
        lat: -6.38977003
      },
      {
        lng: 106.72860718,
        lat: -6.388659
      },
      {
        lng: 106.728302,
        lat: -6.38769007
      },
      {
        lng: 106.7280426,
        lat: -6.38807011
      },
      {
        lng: 106.7268219,
        lat: -6.38835001
      },
      {
        lng: 106.72646332,
        lat: -6.38729
      },
      {
        lng: 106.72727966,
        lat: -6.38686991
      },
      {
        lng: 106.7280426,
        lat: -6.38603497
      },
      {
        lng: 106.72679901,
        lat: -6.38586998
      },
      {
        lng: 106.72554779,
        lat: -6.38550997
      },
      {
        lng: 106.72502136,
        lat: -6.38606977
      },
      {
        lng: 106.72438049,
        lat: -6.38683987
      },
      {
        lng: 106.72364044,
        lat: -6.3866601
      },
      {
        lng: 106.72299194,
        lat: -6.38665533
      },
      {
        lng: 106.72228241,
        lat: -6.38605022
      },
      {
        lng: 106.72177124,
        lat: -6.38581991
      },
      {
        lng: 106.7224884,
        lat: -6.38395977
      },
      {
        lng: 106.72267914,
        lat: -6.38256979
      },
      {
        lng: 106.72316742,
        lat: -6.38167
      },
      {
        lng: 106.72325134,
        lat: -6.38107014
      },
      {
        lng: 106.72215271,
        lat: -6.38005018
      },
      {
        lng: 106.72173309,
        lat: -6.37963009
      },
      {
        lng: 106.72258759,
        lat: -6.37845993
      },
      {
        lng: 106.72357178,
        lat: -6.37709999
      },
      {
        lng: 106.72366333,
        lat: -6.3764801
      },
      {
        lng: 106.72250366,
        lat: -6.37650013
      },
      {
        lng: 106.72093964,
        lat: -6.37644005
      },
      {
        lng: 106.72119141,
        lat: -6.37576008
      },
      {
        lng: 106.72187042,
        lat: -6.37501001
      },
      {
        lng: 106.72270966,
        lat: -6.37460995
      },
      {
        lng: 106.72229767,
        lat: -6.37395
      },
      {
        lng: 106.72099304,
        lat: -6.3735733
      },
      {
        lng: 106.72045135,
        lat: -6.37314987
      },
      {
        lng: 106.72003937,
        lat: -6.37261009
      },
      {
        lng: 106.71920776,
        lat: -6.37182999
      },
      {
        lng: 106.7188797,
        lat: -6.37118006
      },
      {
        lng: 106.71826935,
        lat: -6.37135983
      },
      {
        lng: 106.71763611,
        lat: -6.37098265
      },
      {
        lng: 106.71736908,
        lat: -6.3704958
      },
      {
        lng: 106.7168808,
        lat: -6.36970043
      },
      {
        lng: 106.71743774,
        lat: -6.36858559
      },
      {
        lng: 106.71823883,
        lat: -6.36752224
      },
      {
        lng: 106.71827698,
        lat: -6.36690331
      },
      {
        lng: 106.71753693,
        lat: -6.36646414
      },
      {
        lng: 106.71674347,
        lat: -6.36530733
      },
      {
        lng: 106.71762848,
        lat: -6.36462593
      },
      {
        lng: 106.71801758,
        lat: -6.36403179
      },
      {
        lng: 106.7191925,
        lat: -6.36429834
      },
      {
        lng: 106.71990204,
        lat: -6.36424303
      },
      {
        lng: 106.72037506,
        lat: -6.36382008
      },
      {
        lng: 106.72111511,
        lat: -6.36289549
      },
      {
        lng: 106.7208786,
        lat: -6.36243486
      },
      {
        lng: 106.72055054,
        lat: -6.3616786
      },
      {
        lng: 106.72025299,
        lat: -6.36076212
      },
      {
        lng: 106.72179413,
        lat: -6.36041546
      },
      {
        lng: 106.72424316,
        lat: -6.36003828
      },
      {
        lng: 106.73073578,
        lat: -6.36050034
      },
      {
        lng: 106.73589325,
        lat: -6.36037636
      },
      {
        lng: 106.74182892,
        lat: -6.36070251
      },
      {
        lng: 106.7484436,
        lat: -6.36105776
      },
      {
        lng: 106.74936676,
        lat: -6.36081457
      },
      {
        lng: 106.75042725,
        lat: -6.36041975
      },
      {
        lng: 106.75149536,
        lat: -6.36019659
      },
      {
        lng: 106.75232697,
        lat: -6.36035299
      },
      {
        lng: 106.75293732,
        lat: -6.36066628
      },
      {
        lng: 106.75467682,
        lat: -6.36077785
      },
      {
        lng: 106.7556839,
        lat: -6.36074114
      },
      {
        lng: 106.75942993,
        lat: -6.36078691
      },
      {
        lng: 106.75959015,
        lat: -6.35978746
      },
      {
        lng: 106.76007843,
        lat: -6.35942411
      },
      {
        lng: 106.7600708,
        lat: -6.3583107
      },
      {
        lng: 106.76085663,
        lat: -6.35761166
      },
      {
        lng: 106.76249695,
        lat: -6.35703564
      },
      {
        lng: 106.7633667,
        lat: -6.35680437
      },
      {
        lng: 106.76902771,
        lat: -6.35661316
      },
      {
        lng: 106.77008057,
        lat: -6.35679054
      },
      {
        lng: 106.77106476,
        lat: -6.35718012
      },
      {
        lng: 106.77088928,
        lat: -6.35568285
      },
      {
        lng: 106.77112579,
        lat: -6.35463905
      },
      {
        lng: 106.77051544,
        lat: -6.35389519
      },
      {
        lng: 106.77003479,
        lat: -6.35260725
      },
      {
        lng: 106.77033234,
        lat: -6.35191965
      },
      {
        lng: 106.77112579,
        lat: -6.35189962
      },
      {
        lng: 106.77185822,
        lat: -6.35238504
      },
      {
        lng: 106.77185822,
        lat: -6.35118818
      },
      {
        lng: 106.77237701,
        lat: -6.34998322
      },
      {
        lng: 106.77340698,
        lat: -6.35051632
      },
      {
        lng: 106.77375031,
        lat: -6.34948921
      },
      {
        lng: 106.77386475,
        lat: -6.34814501
      },
      {
        lng: 106.77338409,
        lat: -6.34745026
      },
      {
        lng: 106.77325439,
        lat: -6.34658432
      },
      {
        lng: 106.77370453,
        lat: -6.34614229
      },
      {
        lng: 106.77443695,
        lat: -6.34579945
      },
      {
        lng: 106.77436829,
        lat: -6.34487915
      },
      {
        lng: 106.77326202,
        lat: -6.34464693
      },
      {
        lng: 106.77256012,
        lat: -6.34409428
      },
      {
        lng: 106.77280426,
        lat: -6.34352016
      },
      {
        lng: 106.77352142,
        lat: -6.34292316
      },
      {
        lng: 106.77350616,
        lat: -6.34235001
      },
      {
        lng: 106.77297211,
        lat: -6.34153461
      },
      {
        lng: 106.77328491,
        lat: -6.34064245
      },
      {
        lng: 106.77320862,
        lat: -6.33984327
      },
      {
        lng: 106.77333832,
        lat: -6.33914137
      },
      {
        lng: 106.77362061,
        lat: -6.33871746
      },
      {
        lng: 106.77252197,
        lat: -6.33858299
      },
      {
        lng: 106.77261353,
        lat: -6.33800411
      },
      {
        lng: 106.77478027,
        lat: -6.33786297
      },
      {
        lng: 106.77479553,
        lat: -6.33707428
      },
      {
        lng: 106.77436829,
        lat: -6.336236
      },
      {
        lng: 106.77539063,
        lat: -6.33623791
      },
      {
        lng: 106.77635193,
        lat: -6.33598185
      },
      {
        lng: 106.77583313,
        lat: -6.33559799
      },
      {
        lng: 106.77574158,
        lat: -6.3348527
      },
      {
        lng: 106.77593994,
        lat: -6.33402586
      },
      {
        lng: 106.77542877,
        lat: -6.33336306
      },
      {
        lng: 106.77563477,
        lat: -6.33285189
      },
      {
        lng: 106.77664185,
        lat: -6.33250332
      },
      {
        lng: 106.77632904,
        lat: -6.33167839
      },
      {
        lng: 106.77622986,
        lat: -6.33091784
      },
      {
        lng: 106.7771759,
        lat: -6.33020163
      },
      {
        lng: 106.77764893,
        lat: -6.3291297
      },
      {
        lng: 106.77767181,
        lat: -6.32805538
      },
      {
        lng: 106.77750397,
        lat: -6.32732773
      },
      {
        lng: 106.7767868,
        lat: -6.32719898
      },
      {
        lng: 106.77741241,
        lat: -6.32658815
      },
      {
        lng: 106.77838135,
        lat: -6.32721138
      },
      {
        lng: 106.77884674,
        lat: -6.3268714
      },
      {
        lng: 106.778862,
        lat: -6.32577372
      },
      {
        lng: 106.77894592,
        lat: -6.32501411
      },
      {
        lng: 106.77881622,
        lat: -6.32398701
      },
      {
        lng: 106.77780151,
        lat: -6.32340097
      },
      {
        lng: 106.77819061,
        lat: -6.32258272
      },
      {
        lng: 106.77905273,
        lat: -6.32165766
      },
      {
        lng: 106.77900696,
        lat: -6.32097912
      },
      {
        lng: 106.77876282,
        lat: -6.31981468
      },
      {
        lng: 106.77924347,
        lat: -6.31884527
      },
      {
        lng: 106.77941895,
        lat: -6.31804371
      },
      {
        lng: 106.77877045,
        lat: -6.31757689
      },
      {
        lng: 106.77883148,
        lat: -6.31704998
      },
      {
        lng: 106.77979279,
        lat: -6.31663513
      },
      {
        lng: 106.78116608,
        lat: -6.31628418
      },
      {
        lng: 106.7817688,
        lat: -6.31592941
      },
      {
        lng: 106.78321075,
        lat: -6.31654406
      },
      {
        lng: 106.78951263,
        lat: -6.31624222
      },
      {
        lng: 106.79010773,
        lat: -6.31474924
      },
      {
        lng: 106.79146576,
        lat: -6.31516218
      },
      {
        lng: 106.79241943,
        lat: -6.31570101
      },
      {
        lng: 106.79576111,
        lat: -6.31548595
      },
      {
        lng: 106.79862976,
        lat: -6.31532717
      },
      {
        lng: 106.80160522,
        lat: -6.31529188
      },
      {
        lng: 106.80369568,
        lat: -6.31525469
      },
      {
        lng: 106.80445099,
        lat: -6.31528807
      },
      {
        lng: 106.8059082,
        lat: -6.31497955
      },
      {
        lng: 106.80716705,
        lat: -6.31441402
      },
      {
        lng: 106.80913544,
        lat: -6.31518841
      },
      {
        lng: 106.80872345,
        lat: -6.31602859
      },
      {
        lng: 106.8082962,
        lat: -6.31687546
      },
      {
        lng: 106.80764771,
        lat: -6.31770897
      },
      {
        lng: 106.80796814,
        lat: -6.31866312
      },
      {
        lng: 106.80768585,
        lat: -6.3189702
      },
      {
        lng: 106.80739594,
        lat: -6.31982422
      },
      {
        lng: 106.80730438,
        lat: -6.32047749
      },
      {
        lng: 106.80713654,
        lat: -6.32119417
      },
      {
        lng: 106.80706024,
        lat: -6.32198095
      },
      {
        lng: 106.80666351,
        lat: -6.32265186
      },
      {
        lng: 106.80596161,
        lat: -6.32338238
      },
      {
        lng: 106.80612183,
        lat: -6.32424164
      },
      {
        lng: 106.80519867,
        lat: -6.32413292
      },
      {
        lng: 106.80452728,
        lat: -6.32447577
      },
      {
        lng: 106.80405426,
        lat: -6.325284
      },
      {
        lng: 106.80412292,
        lat: -6.32643843
      },
      {
        lng: 106.80341339,
        lat: -6.3275876
      },
      {
        lng: 106.80335236,
        lat: -6.32887745
      },
      {
        lng: 106.80332184,
        lat: -6.33007765
      },
      {
        lng: 106.80245209,
        lat: -6.33082438
      },
      {
        lng: 106.80132294,
        lat: -6.33150339
      },
      {
        lng: 106.80154419,
        lat: -6.33213329
      },
      {
        lng: 106.80183411,
        lat: -6.33311033
      },
      {
        lng: 106.80102539,
        lat: -6.33363914
      },
      {
        lng: 106.79979706,
        lat: -6.3333168
      },
      {
        lng: 106.79927826,
        lat: -6.33359337
      },
      {
        lng: 106.79907227,
        lat: -6.33475637
      },
      {
        lng: 106.79882813,
        lat: -6.33610964
      },
      {
        lng: 106.79886627,
        lat: -6.3370986
      },
      {
        lng: 106.79871368,
        lat: -6.33836889
      },
      {
        lng: 106.79955292,
        lat: -6.33938837
      },
      {
        lng: 106.80001831,
        lat: -6.34046936
      },
      {
        lng: 106.79962921,
        lat: -6.34123993
      },
      {
        lng: 106.79971313,
        lat: -6.34210157
      },
      {
        lng: 106.79940033,
        lat: -6.34293079
      },
      {
        lng: 106.79914856,
        lat: -6.34325266
      },
      {
        lng: 106.79890442,
        lat: -6.34390688
      },
      {
        lng: 106.79820251,
        lat: -6.34445143
      },
      {
        lng: 106.79740906,
        lat: -6.34485865
      },
      {
        lng: 106.79730988,
        lat: -6.34569454
      },
      {
        lng: 106.79667664,
        lat: -6.34647894
      },
      {
        lng: 106.7958374,
        lat: -6.34625721
      },
      {
        lng: 106.79515839,
        lat: -6.34609413
      },
      {
        lng: 106.79389191,
        lat: -6.34700489
      },
      {
        lng: 106.79259491,
        lat: -6.34801817
      },
      {
        lng: 106.79284668,
        lat: -6.34924984
      },
      {
        lng: 106.79362488,
        lat: -6.35038471
      },
      {
        lng: 106.79390717,
        lat: -6.35123158
      },
      {
        lng: 106.79264069,
        lat: -6.35219765
      },
      {
        lng: 106.7929306,
        lat: -6.35291672
      },
      {
        lng: 106.79327393,
        lat: -6.35361814
      },
      {
        lng: 106.79362488,
        lat: -6.35395908
      },
      {
        lng: 106.7939682,
        lat: -6.35449553
      },
      {
        lng: 106.79293823,
        lat: -6.3551054
      },
      {
        lng: 106.79318237,
        lat: -6.35646629
      },
      {
        lng: 106.79296875,
        lat: -6.35719585
      },
      {
        lng: 106.79280853,
        lat: -6.35787678
      },
      {
        lng: 106.79246521,
        lat: -6.35917425
      },
      {
        lng: 106.79329681,
        lat: -6.35989618
      },
      {
        lng: 106.79338837,
        lat: -6.36051035
      },
      {
        lng: 106.79351807,
        lat: -6.36115026
      },
      {
        lng: 106.79345703,
        lat: -6.3625145
      },
      {
        lng: 106.79314423,
        lat: -6.36332893
      },
      {
        lng: 106.79359436,
        lat: -6.36389542
      },
      {
        lng: 106.79434967,
        lat: -6.36418676
      },
      {
        lng: 106.79466248,
        lat: -6.36492348
      },
      {
        lng: 106.795784,
        lat: -6.36491346
      },
      {
        lng: 106.796875,
        lat: -6.36491776
      },
      {
        lng: 106.79771423,
        lat: -6.36492252
      },
      {
        lng: 106.7990799,
        lat: -6.36532116
      },
      {
        lng: 106.80001068,
        lat: -6.36528444
      },
      {
        lng: 106.80307007,
        lat: -6.36344624
      },
      {
        lng: 106.80396271,
        lat: -6.36349726
      },
      {
        lng: 106.80527496,
        lat: -6.36346149
      },
      {
        lng: 106.80640411,
        lat: -6.36342621
      },
      {
        lng: 106.80854797,
        lat: -6.36401272
      },
      {
        lng: 106.81048584,
        lat: -6.36337852
      },
      {
        lng: 106.81152344,
        lat: -6.36239481
      },
      {
        lng: 106.81218719,
        lat: -6.36178589
      },
      {
        lng: 106.81252289,
        lat: -6.36097622
      },
      {
        lng: 106.81348419,
        lat: -6.35969257
      },
      {
        lng: 106.81430817,
        lat: -6.35922718
      },
      {
        lng: 106.81461334,
        lat: -6.35819054
      },
      {
        lng: 106.81526184,
        lat: -6.35643196
      },
      {
        lng: 106.81591797,
        lat: -6.35603094
      },
      {
        lng: 106.81678772,
        lat: -6.35624981
      },
      {
        lng: 106.81962585,
        lat: -6.3564744
      },
      {
        lng: 106.82110596,
        lat: -6.35587978
      },
      {
        lng: 106.82239532,
        lat: -6.35592365
      },
      {
        lng: 106.82424927,
        lat: -6.35616827
      },
      {
        lng: 106.82907104,
        lat: -6.35634851
      },
      {
        lng: 106.83135223,
        lat: -6.35569715
      },
      {
        lng: 106.83237457,
        lat: -6.35556126
      },
      {
        lng: 106.83422089,
        lat: -6.35528421
      },
      {
        lng: 106.8353653,
        lat: -6.35492659
      },
      {
        lng: 106.83580017,
        lat: -6.35409927
      },
      {
        lng: 106.83532715,
        lat: -6.35200167
      },
      {
        lng: 106.83898926,
        lat: -6.35052347
      },
      {
        lng: 106.83808136,
        lat: -6.34910202
      },
      {
        lng: 106.83760071,
        lat: -6.34693956
      },
      {
        lng: 106.83968353,
        lat: -6.345119
      },
      {
        lng: 106.8366394,
        lat: -6.34492874
      },
      {
        lng: 106.83534241,
        lat: -6.34445381
      },
      {
        lng: 106.83760834,
        lat: -6.34151554
      },
      {
        lng: 106.83932495,
        lat: -6.34235287
      },
      {
        lng: 106.84222412,
        lat: -6.3420887
      },
      {
        lng: 106.84549713,
        lat: -6.34195042
      },
      {
        lng: 106.84624481,
        lat: -6.34127331
      },
      {
        lng: 106.84671021,
        lat: -6.33924675
      },
      {
        lng: 106.84683228,
        lat: -6.33824205
      },
      {
        lng: 106.84812927,
        lat: -6.3378191
      },
      {
        lng: 106.84902954,
        lat: -6.3375783
      },
      {
        lng: 106.84906769,
        lat: -6.33646488
      },
      {
        lng: 106.85044861,
        lat: -6.33631325
      },
      {
        lng: 106.85063171,
        lat: -6.33682251
      },
      {
        lng: 106.85155487,
        lat: -6.33708811
      },
      {
        lng: 106.85217285,
        lat: -6.33798027
      },
      {
        lng: 106.85139465,
        lat: -6.33891582
      },
      {
        lng: 106.85050201,
        lat: -6.33897877
      },
      {
        lng: 106.8501358,
        lat: -6.33976126
      },
      {
        lng: 106.85041809,
        lat: -6.34063148
      },
      {
        lng: 106.85147095,
        lat: -6.3412528
      },
      {
        lng: 106.85165405,
        lat: -6.34200287
      },
      {
        lng: 106.85228729,
        lat: -6.34298849
      },
      {
        lng: 106.85314941,
        lat: -6.34287643
      },
      {
        lng: 106.85391235,
        lat: -6.34295034
      },
      {
        lng: 106.85430145,
        lat: -6.34363174
      },
      {
        lng: 106.8547821,
        lat: -6.34455967
      },
      {
        lng: 106.8540802,
        lat: -6.34545994
      },
      {
        lng: 106.8544693,
        lat: -6.34685135
      },
      {
        lng: 106.85559845,
        lat: -6.34692764
      },
      {
        lng: 106.85665894,
        lat: -6.34717512
      },
      {
        lng: 106.85617065,
        lat: -6.34856033
      },
      {
        lng: 106.85673523,
        lat: -6.34927082
      },
      {
        lng: 106.85617065,
        lat: -6.35053015
      },
      {
        lng: 106.85612488,
        lat: -6.35153055
      },
      {
        lng: 106.85575867,
        lat: -6.35202169
      },
      {
        lng: 106.85719299,
        lat: -6.35275698
      },
      {
        lng: 106.85884094,
        lat: -6.35314417
      },
      {
        lng: 106.8600235,
        lat: -6.35361862
      },
      {
        lng: 106.8610611,
        lat: -6.35527229
      },
      {
        lng: 106.86162567,
        lat: -6.35657454
      },
      {
        lng: 106.86281586,
        lat: -6.35698843
      },
      {
        lng: 106.86515808,
        lat: -6.35582066
      },
      {
        lng: 106.86717224,
        lat: -6.35461855
      },
      {
        lng: 106.87006378,
        lat: -6.35293007
      },
      {
        lng: 106.87121582,
        lat: -6.3528924
      },
      {
        lng: 106.87166595,
        lat: -6.3536706
      },
      {
        lng: 106.87223053,
        lat: -6.35436583
      },
      {
        lng: 106.87237549,
        lat: -6.35497427
      },
      {
        lng: 106.87289429,
        lat: -6.35497856
      },
      {
        lng: 106.87329865,
        lat: -6.35570335
      },
      {
        lng: 106.87388611,
        lat: -6.35646629
      },
      {
        lng: 106.87326813,
        lat: -6.3570118
      },
      {
        lng: 106.8728714,
        lat: -6.35763645
      },
      {
        lng: 106.87198639,
        lat: -6.35799932
      },
      {
        lng: 106.87226105,
        lat: -6.35885572
      },
      {
        lng: 106.8714447,
        lat: -6.35984039
      },
      {
        lng: 106.87241364,
        lat: -6.36082506
      },
      {
        lng: 106.87291718,
        lat: -6.36119986
      },
      {
        lng: 106.87388611,
        lat: -6.36114025
      },
      {
        lng: 106.87477875,
        lat: -6.36162996
      },
      {
        lng: 106.87639618,
        lat: -6.36182785
      },
      {
        lng: 106.87680054,
        lat: -6.36159182
      },
      {
        lng: 106.87739563,
        lat: -6.3611021
      },
      {
        lng: 106.87802887,
        lat: -6.36111736
      },
      {
        lng: 106.87891388,
        lat: -6.36111355
      },
      {
        lng: 106.8788681,
        lat: -6.36176586
      },
      {
        lng: 106.87834167,
        lat: -6.36213303
      },
      {
        lng: 106.87792206,
        lat: -6.36301327
      },
      {
        lng: 106.87858582,
        lat: -6.36398268
      },
      {
        lng: 106.87961578,
        lat: -6.36463594
      },
      {
        lng: 106.88024902,
        lat: -6.3642664
      },
      {
        lng: 106.88071442,
        lat: -6.36549902
      },
      {
        lng: 106.88096619,
        lat: -6.36654663
      },
      {
        lng: 106.88204956,
        lat: -6.36636686
      },
      {
        lng: 106.88214111,
        lat: -6.36582899
      },
      {
        lng: 106.88233948,
        lat: -6.36487818
      },
      {
        lng: 106.88301086,
        lat: -6.3647809
      },
      {
        lng: 106.88345337,
        lat: -6.36543989
      },
      {
        lng: 106.88327026,
        lat: -6.36602402
      },
      {
        lng: 106.88374329,
        lat: -6.36655283
      },
      {
        lng: 106.88366699,
        lat: -6.36785507
      },
      {
        lng: 106.88285065,
        lat: -6.36914873
      },
      {
        lng: 106.88273621,
        lat: -6.36993027
      },
      {
        lng: 106.88357544,
        lat: -6.37008762
      },
      {
        lng: 106.88471985,
        lat: -6.36974239
      },
      {
        lng: 106.88677216,
        lat: -6.36964655
      },
      {
        lng: 106.88791656,
        lat: -6.36866045
      },
      {
        lng: 106.88925171,
        lat: -6.3676796
      },
      {
        lng: 106.88974762,
        lat: -6.36869287
      },
      {
        lng: 106.89176178,
        lat: -6.3694663
      },
      {
        lng: 106.89328003,
        lat: -6.36995125
      },
      {
        lng: 106.89575958,
        lat: -6.37072992
      },
      {
        lng: 106.89728546,
        lat: -6.36912155
      },
      {
        lng: 106.90036774,
        lat: -6.36975908
      },
      {
        lng: 106.90240479,
        lat: -6.36761522
      },
      {
        lng: 106.90341187,
        lat: -6.36450768
      },
      {
        lng: 106.90395355,
        lat: -6.3637042
      },
      {
        lng: 106.90449524,
        lat: -6.36306572
      },
      {
        lng: 106.90503693,
        lat: -6.36226273
      },
      {
        lng: 106.9054718,
        lat: -6.36143446
      },
      {
        lng: 106.90631104,
        lat: -6.36087322
      },
      {
        lng: 106.90773773,
        lat: -6.36129189
      },
      {
        lng: 106.90913391,
        lat: -6.3625536
      },
      {
        lng: 106.91008759,
        lat: -6.36321068
      },
      {
        lng: 106.91136169,
        lat: -6.36415863
      },
      {
        lng: 106.9124527,
        lat: -6.36440277
      },
      {
        lng: 106.91345215,
        lat: -6.36509609
      },
      {
        lng: 106.91434479,
        lat: -6.36554575
      },
      {
        lng: 106.91394043,
        lat: -6.36652851
      },
      {
        lng: 106.91280365,
        lat: -6.36724615
      },
      {
        lng: 106.91248322,
        lat: -6.36825418
      },
      {
        lng: 106.91210937,
        lat: -6.36832047
      },
      {
        lng: 106.91218567,
        lat: -6.36760712
      },
      {
        lng: 106.91130829,
        lat: -6.36735964
      },
      {
        lng: 106.90859222,
        lat: -6.37036896
      },
      {
        lng: 106.90824127,
        lat: -6.37238503
      },
      {
        lng: 106.90855408,
        lat: -6.37317181
      },
      {
        lng: 106.90795898,
        lat: -6.37369251
      },
      {
        lng: 106.90825653,
        lat: -6.3742919
      },
      {
        lng: 106.90843964,
        lat: -6.37515211
      },
      {
        lng: 106.90759277,
        lat: -6.37592793
      },
      {
        lng: 106.90820313,
        lat: -6.37670517
      },
      {
        lng: 106.90740204,
        lat: -6.37749529
      },
      {
        lng: 106.90660858,
        lat: -6.37761259
      },
      {
        lng: 106.90614319,
        lat: -6.3780961
      },
      {
        lng: 106.90570831,
        lat: -6.37861013
      },
      {
        lng: 106.90512085,
        lat: -6.37916183
      },
      {
        lng: 106.90451813,
        lat: -6.37947845
      },
      {
        lng: 106.90442657,
        lat: -6.37999392
      },
      {
        lng: 106.90418243,
        lat: -6.38050508
      },
      {
        lng: 106.90457916,
        lat: -6.38208961
      },
      {
        lng: 106.90445709,
        lat: -6.38308477
      },
      {
        lng: 106.90479279,
        lat: -6.38407326
      },
      {
        lng: 106.90503693,
        lat: -6.38464546
      },
      {
        lng: 106.90409088,
        lat: -6.38605118
      },
      {
        lng: 106.90445709,
        lat: -6.38699389
      },
      {
        lng: 106.90390778,
        lat: -6.38779116
      },
      {
        lng: 106.90370178,
        lat: -6.38869762
      },
      {
        lng: 106.90398407,
        lat: -6.38968945
      },
      {
        lng: 106.90409088,
        lat: -6.39059067
      },
      {
        lng: 106.90440369,
        lat: -6.39144945
      },
      {
        lng: 106.90356445,
        lat: -6.39292431
      },
      {
        lng: 106.90287018,
        lat: -6.39366007
      },
      {
        lng: 106.90221405,
        lat: -6.39395666
      },
      {
        lng: 106.90125275,
        lat: -6.39457893
      },
      {
        lng: 106.90042877,
        lat: -6.39432001
      },
      {
        lng: 106.89968872,
        lat: -6.39488745
      },
      {
        lng: 106.89903259,
        lat: -6.39444876
      },
      {
        lng: 106.8983078,
        lat: -6.39472151
      },
      {
        lng: 106.89841461,
        lat: -6.39565086
      },
      {
        lng: 106.89787292,
        lat: -6.39591455
      },
      {
        lng: 106.89792633,
        lat: -6.39676094
      },
      {
        lng: 106.89835358,
        lat: -6.39743996
      },
      {
        lng: 106.89931488,
        lat: -6.39782429
      },
      {
        lng: 106.90048218,
        lat: -6.39766598
      },
      {
        lng: 106.90127563,
        lat: -6.39700031
      },
      {
        lng: 106.90213776,
        lat: -6.39720058
      },
      {
        lng: 106.90275574,
        lat: -6.39620018
      },
      {
        lng: 106.90376282,
        lat: -6.39604568
      },
      {
        lng: 106.9045639,
        lat: -6.39544106
      },
      {
        lng: 106.90538025,
        lat: -6.39447212
      },
      {
        lng: 106.90609741,
        lat: -6.39439249
      },
      {
        lng: 106.9074173,
        lat: -6.39484072
      },
      {
        lng: 106.90813446,
        lat: -6.39489412
      },
      {
        lng: 106.91079712,
        lat: -6.39519548
      },
      {
        lng: 106.91295624,
        lat: -6.39536953
      },
      {
        lng: 106.91426849,
        lat: -6.39525843
      },
      {
        lng: 106.9159317,
        lat: -6.39519787
      },
      {
        lng: 106.91836548,
        lat: -6.39566469
      },
      {
        lng: 106.91824341,
        lat: -6.39671326
      },
      {
        lng: 106.91764832,
        lat: -6.39741945
      },
      {
        lng: 106.91825867,
        lat: -6.39782143
      },
      {
        lng: 106.91798401,
        lat: -6.39838886
      },
      {
        lng: 106.9168396,
        lat: -6.39882231
      },
      {
        lng: 106.91608429,
        lat: -6.39987803
      },
      {
        lng: 106.91716003,
        lat: -6.39996958
      },
      {
        lng: 106.9180603,
        lat: -6.40020037
      },
      {
        lng: 106.91799164,
        lat: -6.40071106
      },
      {
        lng: 106.91666412,
        lat: -6.40062046
      },
      {
        lng: 106.91601563,
        lat: -6.40100813
      },
      {
        lng: 106.916008,
        lat: -6.40199995
      },
      {
        lng: 106.91679382,
        lat: -6.40325356
      },
      {
        lng: 106.91692352,
        lat: -6.40395546
      },
      {
        lng: 106.91585541,
        lat: -6.4047513
      },
      {
        lng: 106.91662598,
        lat: -6.40513468
      },
      {
        lng: 106.91721344,
        lat: -6.40552807
      },
      {
        lng: 106.91681671,
        lat: -6.40630198
      },
      {
        lng: 106.91533661,
        lat: -6.40640306
      },
      {
        lng: 106.91519165,
        lat: -6.40684509
      },
      {
        lng: 106.91603088,
        lat: -6.4074235
      },
      {
        lng: 106.91586304,
        lat: -6.40827417
      },
      {
        lng: 106.91705322,
        lat: -6.40861893
      },
      {
        lng: 106.91696167,
        lat: -6.40983343
      },
      {
        lng: 106.91654205,
        lat: -6.41035604
      },
      {
        lng: 106.91583252,
        lat: -6.41133404
      },
      {
        lng: 106.91551971,
        lat: -6.41296148
      },
      {
        lng: 106.91481018,
        lat: -6.41426182
      },
      {
        lng: 106.91399384,
        lat: -6.41490364
      },
      {
        lng: 106.91407013,
        lat: -6.4139204
      },
      {
        lng: 106.91449738,
        lat: -6.41280127
      },
      {
        lng: 106.91309357,
        lat: -6.4120388
      },
      {
        lng: 106.91255188,
        lat: -6.41253996
      },
      {
        lng: 106.91194153,
        lat: -6.41341972
      },
      {
        lng: 106.91066742,
        lat: -6.41357183
      },
      {
        lng: 106.91052246,
        lat: -6.41299868
      },
      {
        lng: 106.90927887,
        lat: -6.41259527
      },
      {
        lng: 106.90887451,
        lat: -6.41161633
      },
      {
        lng: 106.90847778,
        lat: -6.41273212
      },
      {
        lng: 106.90826416,
        lat: -6.41475487
      },
      {
        lng: 106.9077301,
        lat: -6.41514063
      },
      {
        lng: 106.90674591,
        lat: -6.41371298
      },
      {
        lng: 106.90559387,
        lat: -6.41270018
      },
      {
        lng: 106.90513611,
        lat: -6.41304159
      },
      {
        lng: 106.90597534,
        lat: -6.41465378
      },
      {
        lng: 106.90598297,
        lat: -6.41610336
      },
      {
        lng: 106.90506744,
        lat: -6.41777563
      },
      {
        lng: 106.90390015,
        lat: -6.41917562
      },
      {
        lng: 106.90336609,
        lat: -6.41826344
      },
      {
        lng: 106.9022522,
        lat: -6.41840744
      },
      {
        lng: 106.90062714,
        lat: -6.41893148
      },
      {
        lng: 106.90032959,
        lat: -6.41950464
      },
      {
        lng: 106.90008545,
        lat: -6.42066908
      },
      {
        lng: 106.89975739,
        lat: -6.42007256
      },
      {
        lng: 106.89949799,
        lat: -6.41957474
      },
      {
        lng: 106.89894104,
        lat: -6.4199214
      },
      {
        lng: 106.89928436,
        lat: -6.42101574
      },
      {
        lng: 106.89923096,
        lat: -6.4219451
      },
      {
        lng: 106.89865875,
        lat: -6.42241287
      },
      {
        lng: 106.89917755,
        lat: -6.42267036
      },
      {
        lng: 106.89996338,
        lat: -6.42300987
      },
      {
        lng: 106.89904785,
        lat: -6.4235301
      },
      {
        lng: 106.89920807,
        lat: -6.42451286
      },
      {
        lng: 106.89922333,
        lat: -6.42581272
      },
      {
        lng: 106.89855957,
        lat: -6.42607117
      },
      {
        lng: 106.89745331,
        lat: -6.42592907
      },
      {
        lng: 106.89657593,
        lat: -6.42596292
      },
      {
        lng: 106.89559174,
        lat: -6.42515898
      },
      {
        lng: 106.89474487,
        lat: -6.42497683
      },
      {
        lng: 106.89433289,
        lat: -6.42517328
      },
      {
        lng: 106.8952179,
        lat: -6.42720509
      },
      {
        lng: 106.89521027,
        lat: -6.42788029
      },
      {
        lng: 106.8946991,
        lat: -6.42800713
      },
      {
        lng: 106.8937912,
        lat: -6.42702055
      },
      {
        lng: 106.89277649,
        lat: -6.4264822
      },
      {
        lng: 106.89179993,
        lat: -6.4275198
      },
      {
        lng: 106.89212799,
        lat: -6.42793751
      },
      {
        lng: 106.89282227,
        lat: -6.42812061
      },
      {
        lng: 106.89232635,
        lat: -6.42933035
      },
      {
        lng: 106.89083862,
        lat: -6.430686
      },
      {
        lng: 106.89022064,
        lat: -6.43213272
      },
      {
        lng: 106.89074707,
        lat: -6.4323616
      },
      {
        lng: 106.8914032,
        lat: -6.43272448
      },
      {
        lng: 106.89180756,
        lat: -6.43368196
      },
      {
        lng: 106.89122772,
        lat: -6.43459511
      },
      {
        lng: 106.89032745,
        lat: -6.43457365
      },
      {
        lng: 106.88903809,
        lat: -6.43441439
      },
      {
        lng: 106.88802338,
        lat: -6.43485641
      },
      {
        lng: 106.88811493,
        lat: -6.43544006
      },
      {
        lng: 106.88843536,
        lat: -6.43604898
      },
      {
        lng: 106.88777161,
        lat: -6.43650866
      },
      {
        lng: 106.88751221,
        lat: -6.43737411
      },
      {
        lng: 106.88685608,
        lat: -6.43734598
      },
      {
        lng: 106.88607025,
        lat: -6.43721485
      },
      {
        lng: 106.88587189,
        lat: -6.4381156
      },
      {
        lng: 106.88570404,
        lat: -6.44009686
      },
      {
        lng: 106.88544464,
        lat: -6.44071198
      },
      {
        lng: 106.88620758,
        lat: -6.44069433
      },
      {
        lng: 106.88726044,
        lat: -6.44055033
      },
      {
        lng: 106.88719177,
        lat: -6.44137001
      },
      {
        lng: 106.88706207,
        lat: -6.4429059
      },
      {
        lng: 106.88651276,
        lat: -6.4430542
      },
      {
        lng: 106.88519287,
        lat: -6.44212723
      },
      {
        lng: 106.88478851,
        lat: -6.44307899
      },
      {
        lng: 106.88452148,
        lat: -6.4441762
      },
      {
        lng: 106.88514709,
        lat: -6.44464874
      },
      {
        lng: 106.8853302,
        lat: -6.44539833
      },
      {
        lng: 106.88344574,
        lat: -6.44517899
      },
      {
        lng: 106.88370514,
        lat: -6.44431877
      },
      {
        lng: 106.88280487,
        lat: -6.44343185
      },
      {
        lng: 106.88214111,
        lat: -6.44464922
      },
      {
        lng: 106.88197327,
        lat: -6.44575071
      },
      {
        lng: 106.88236237,
        lat: -6.44688272
      },
      {
        lng: 106.88339996,
        lat: -6.44658995
      },
      {
        lng: 106.88381958,
        lat: -6.44868755
      },
      {
        lng: 106.88336182,
        lat: -6.44901037
      },
      {
        lng: 106.88244629,
        lat: -6.448596
      },
      {
        lng: 106.88192749,
        lat: -6.44759035
      },
      {
        lng: 106.88100433,
        lat: -6.44814205
      },
      {
        lng: 106.88110352,
        lat: -6.44897604
      },
      {
        lng: 106.88253021,
        lat: -6.44959831
      },
      {
        lng: 106.88302612,
        lat: -6.44996691
      },
      {
        lng: 106.88223267,
        lat: -6.45049095
      },
      {
        lng: 106.88252258,
        lat: -6.45136213
      },
      {
        lng: 106.88215637,
        lat: -6.45214558
      },
      {
        lng: 106.88162994,
        lat: -6.45342016
      },
      {
        lng: 106.88194275,
        lat: -6.45398474
      },
      {
        lng: 106.88170624,
        lat: -6.45443726
      },
      {
        lng: 106.88071442,
        lat: -6.45429564
      },
      {
        lng: 106.87993622,
        lat: -6.45309782
      },
      {
        lng: 106.87917328,
        lat: -6.45322371
      },
      {
        lng: 106.87898254,
        lat: -6.45416784
      },
      {
        lng: 106.88076019,
        lat: -6.45545816
      },
      {
        lng: 106.88178253,
        lat: -6.45558834
      },
      {
        lng: 106.88222504,
        lat: -6.45619583
      },
      {
        lng: 106.88169098,
        lat: -6.45681286
      },
      {
        lng: 106.88082123,
        lat: -6.45663929
      },
      {
        lng: 106.88036346,
        lat: -6.45611715
      },
      {
        lng: 106.88031006,
        lat: -6.45692158
      },
      {
        lng: 106.8802948,
        lat: -6.45773554
      },
      {
        lng: 106.87928772,
        lat: -6.45833349
      },
      {
        lng: 106.87851715,
        lat: -6.45833349
      },
      {
        lng: 106.87882996,
        lat: -6.45731831
      },
      {
        lng: 106.87624359,
        lat: -6.45824242
      },
      {
        lng: 106.87586975,
        lat: -6.45688486
      },
      {
        lng: 106.875,
        lat: -6.45594358
      },
      {
        lng: 106.87377167,
        lat: -6.45636797
      },
      {
        lng: 106.87150574,
        lat: -6.45607567
      },
      {
        lng: 106.87016296,
        lat: -6.45649004
      },
      {
        lng: 106.86680603,
        lat: -6.45659256
      },
      {
        lng: 106.86342621,
        lat: -6.45603418
      },
      {
        lng: 106.86280823,
        lat: -6.45597172
      },
      {
        lng: 106.85969543,
        lat: -6.45596313
      },
      {
        lng: 106.85535431,
        lat: -6.45526075
      },
      {
        lng: 106.85475922,
        lat: -6.45352745
      },
      {
        lng: 106.85435486,
        lat: -6.45251846
      },
      {
        lng: 106.85397339,
        lat: -6.45167398
      },
      {
        lng: 106.85364532,
        lat: -6.45068932
      },
      {
        lng: 106.85338593,
        lat: -6.44935513
      },
      {
        lng: 106.85337067,
        lat: -6.44780016
      },
      {
        lng: 106.853508,
        lat: -6.44657993
      },
      {
        lng: 106.8535614,
        lat: -6.44444704
      },
      {
        lng: 106.85343933,
        lat: -6.44254017
      },
      {
        lng: 106.85316467,
        lat: -6.440763
      },
      {
        lng: 106.85268402,
        lat: -6.43941975
      },
      {
        lng: 106.85223389,
        lat: -6.43788958
      },
      {
        lng: 106.85235596,
        lat: -6.43706703
      },
      {
        lng: 106.85277557,
        lat: -6.43627262
      },
      {
        lng: 106.85260773,
        lat: -6.43604374
      },
      {
        lng: 106.85150146,
        lat: -6.43598461
      },
      {
        lng: 106.85040283,
        lat: -6.43595839
      },
      {
        lng: 106.84957886,
        lat: -6.43607235
      },
      {
        lng: 106.84879303,
        lat: -6.43634272
      },
      {
        lng: 106.84767151,
        lat: -6.43673563
      },
      {
        lng: 106.84677887,
        lat: -6.43695498
      },
      {
        lng: 106.84537506,
        lat: -6.43672371
      },
      {
        lng: 106.84447479,
        lat: -6.43687296
      },
      {
        lng: 106.84384155,
        lat: -6.4375205
      },
      {
        lng: 106.84334564,
        lat: -6.43821239
      },
      {
        lng: 106.84323883,
        lat: -6.43889189
      },
      {
        lng: 106.84313965,
        lat: -6.44001245
      },
      {
        lng: 106.84288788,
        lat: -6.44105244
      },
      {
        lng: 106.84445953,
        lat: -6.4429121
      },
      {
        lng: 106.84518433,
        lat: -6.44296217
      },
      {
        lng: 106.84590149,
        lat: -6.44323206
      },
      {
        lng: 106.84571075,
        lat: -6.44381332
      },
      {
        lng: 106.8451767,
        lat: -6.44426966
      },
      {
        lng: 106.84501648,
        lat: -6.44488049
      },
      {
        lng: 106.8445816,
        lat: -6.44556618
      },
      {
        lng: 106.84406281,
        lat: -6.4462676
      },
      {
        lng: 106.84440613,
        lat: -6.4468503
      },
      {
        lng: 106.84420776,
        lat: -6.44738865
      },
      {
        lng: 106.84317017,
        lat: -6.44760227
      },
      {
        lng: 106.84194946,
        lat: -6.44858551
      },
      {
        lng: 106.84072113,
        lat: -6.44909
      },
      {
        lng: 106.83947754,
        lat: -6.44952917
      },
      {
        lng: 106.83844757,
        lat: -6.4494133
      },
      {
        lng: 106.83811188,
        lat: -6.45018148
      },
      {
        lng: 106.83708191,
        lat: -6.45059299
      },
      {
        lng: 106.83598328,
        lat: -6.45052052
      },
      {
        lng: 106.83555603,
        lat: -6.44938755
      },
      {
        lng: 106.83518219,
        lat: -6.44964695
      },
      {
        lng: 106.8348999,
        lat: -6.45014524
      },
      {
        lng: 106.83380127,
        lat: -6.4512105
      },
      {
        lng: 106.83412933,
        lat: -6.45245886
      },
      {
        lng: 106.835495,
        lat: -6.45287323
      },
      {
        lng: 106.83276367,
        lat: -6.45310354
      }
    ]
  },
  {
    name: 'Kota Bekasi',
    prov: 'Jawa Barat',
    shape: [
      {
        lng: 106.9797287,
        lat: -6.36427927
      },
      {
        lng: 106.97978973,
        lat: -6.36351681
      },
      {
        lng: 106.97919464,
        lat: -6.36232996
      },
      {
        lng: 106.98027039,
        lat: -6.36110878
      },
      {
        lng: 106.98143005,
        lat: -6.35954523
      },
      {
        lng: 106.98200226,
        lat: -6.35797739
      },
      {
        lng: 106.98148346,
        lat: -6.35755682
      },
      {
        lng: 106.98046875,
        lat: -6.35752249
      },
      {
        lng: 106.98001099,
        lat: -6.35709572
      },
      {
        lng: 106.979599,
        lat: -6.35677958
      },
      {
        lng: 106.97892761,
        lat: -6.35696793
      },
      {
        lng: 106.97838593,
        lat: -6.35677814
      },
      {
        lng: 106.97840881,
        lat: -6.35606289
      },
      {
        lng: 106.97698212,
        lat: -6.35530519
      },
      {
        lng: 106.9768219,
        lat: -6.35470629
      },
      {
        lng: 106.97711182,
        lat: -6.3533144
      },
      {
        lng: 106.97610474,
        lat: -6.35272932
      },
      {
        lng: 106.97382355,
        lat: -6.35181236
      },
      {
        lng: 106.97271729,
        lat: -6.35139608
      },
      {
        lng: 106.97125244,
        lat: -6.35033274
      },
      {
        lng: 106.96997833,
        lat: -6.34953547
      },
      {
        lng: 106.97078705,
        lat: -6.34847116
      },
      {
        lng: 106.97207642,
        lat: -6.3478756
      },
      {
        lng: 106.97263336,
        lat: -6.34666061
      },
      {
        lng: 106.97122955,
        lat: -6.34656477
      },
      {
        lng: 106.96874237,
        lat: -6.34546661
      },
      {
        lng: 106.9682312,
        lat: -6.34433126
      },
      {
        lng: 106.96788788,
        lat: -6.34285593
      },
      {
        lng: 106.96652985,
        lat: -6.34265471
      },
      {
        lng: 106.96639252,
        lat: -6.34176922
      },
      {
        lng: 106.96842957,
        lat: -6.3414588
      },
      {
        lng: 106.97000885,
        lat: -6.34208012
      },
      {
        lng: 106.97096252,
        lat: -6.3429184
      },
      {
        lng: 106.97195435,
        lat: -6.34281111
      },
      {
        lng: 106.97290039,
        lat: -6.34239006
      },
      {
        lng: 106.97361755,
        lat: -6.34193373
      },
      {
        lng: 106.97504425,
        lat: -6.34151459
      },
      {
        lng: 106.97600555,
        lat: -6.34027052
      },
      {
        lng: 106.97557068,
        lat: -6.33941555
      },
      {
        lng: 106.97405243,
        lat: -6.33988667
      },
      {
        lng: 106.97283936,
        lat: -6.34007597
      },
      {
        lng: 106.97279358,
        lat: -6.33883572
      },
      {
        lng: 106.97317505,
        lat: -6.33794832
      },
      {
        lng: 106.97411346,
        lat: -6.33645344
      },
      {
        lng: 106.97441864,
        lat: -6.33569908
      },
      {
        lng: 106.97542572,
        lat: -6.3353405
      },
      {
        lng: 106.97499084,
        lat: -6.3339839
      },
      {
        lng: 106.97401428,
        lat: -6.33290958
      },
      {
        lng: 106.97244263,
        lat: -6.33275318
      },
      {
        lng: 106.97283936,
        lat: -6.33117151
      },
      {
        lng: 106.97409058,
        lat: -6.33013296
      },
      {
        lng: 106.97502136,
        lat: -6.33077908
      },
      {
        lng: 106.97517395,
        lat: -6.33172369
      },
      {
        lng: 106.97667694,
        lat: -6.33170319
      },
      {
        lng: 106.97702789,
        lat: -6.33048964
      },
      {
        lng: 106.97709656,
        lat: -6.32796574
      },
      {
        lng: 106.97711945,
        lat: -6.32588339
      },
      {
        lng: 106.97675323,
        lat: -6.32457066
      },
      {
        lng: 106.97683716,
        lat: -6.32335949
      },
      {
        lng: 106.97719574,
        lat: -6.32134867
      },
      {
        lng: 106.97644043,
        lat: -6.31981707
      },
      {
        lng: 106.97645569,
        lat: -6.31885719
      },
      {
        lng: 106.97719574,
        lat: -6.31796074
      },
      {
        lng: 106.97883606,
        lat: -6.31663275
      },
      {
        lng: 106.98043823,
        lat: -6.31559086
      },
      {
        lng: 106.98079681,
        lat: -6.31479454
      },
      {
        lng: 106.98015594,
        lat: -6.31412315
      },
      {
        lng: 106.9780426,
        lat: -6.31332827
      },
      {
        lng: 106.97557831,
        lat: -6.31273079
      },
      {
        lng: 106.97328186,
        lat: -6.31160355
      },
      {
        lng: 106.97280121,
        lat: -6.3100996
      },
      {
        lng: 106.97252655,
        lat: -6.30763435
      },
      {
        lng: 106.97222137,
        lat: -6.30582857
      },
      {
        lng: 106.97202301,
        lat: -6.30421019
      },
      {
        lng: 106.97107697,
        lat: -6.30431032
      },
      {
        lng: 106.97042847,
        lat: -6.3038969
      },
      {
        lng: 106.97089386,
        lat: -6.30253696
      },
      {
        lng: 106.97019958,
        lat: -6.30225182
      },
      {
        lng: 106.96834564,
        lat: -6.30305099
      },
      {
        lng: 106.96855164,
        lat: -6.30335712
      },
      {
        lng: 106.96946716,
        lat: -6.30289364
      },
      {
        lng: 106.9691391,
        lat: -6.30371714
      },
      {
        lng: 106.9695282,
        lat: -6.30421782
      },
      {
        lng: 106.97044373,
        lat: -6.30496788
      },
      {
        lng: 106.96997833,
        lat: -6.30564737
      },
      {
        lng: 106.96857452,
        lat: -6.30491734
      },
      {
        lng: 106.96749115,
        lat: -6.30435467
      },
      {
        lng: 106.96723938,
        lat: -6.30347872
      },
      {
        lng: 106.96665192,
        lat: -6.30300713
      },
      {
        lng: 106.96605682,
        lat: -6.30423117
      },
      {
        lng: 106.96696472,
        lat: -6.30470991
      },
      {
        lng: 106.96687317,
        lat: -6.30523968
      },
      {
        lng: 106.96614075,
        lat: -6.30499554
      },
      {
        lng: 106.96507263,
        lat: -6.3049469
      },
      {
        lng: 106.96469116,
        lat: -6.30533028
      },
      {
        lng: 106.96593475,
        lat: -6.30611324
      },
      {
        lng: 106.96653748,
        lat: -6.30705833
      },
      {
        lng: 106.96553802,
        lat: -6.30681133
      },
      {
        lng: 106.96491241,
        lat: -6.30829287
      },
      {
        lng: 106.96484375,
        lat: -6.30937719
      },
      {
        lng: 106.9649353,
        lat: -6.31020737
      },
      {
        lng: 106.96385193,
        lat: -6.31021166
      },
      {
        lng: 106.96405792,
        lat: -6.30934668
      },
      {
        lng: 106.96407318,
        lat: -6.30854654
      },
      {
        lng: 106.96327972,
        lat: -6.30890465
      },
      {
        lng: 106.9622345,
        lat: -6.30880165
      },
      {
        lng: 106.96134186,
        lat: -6.30831194
      },
      {
        lng: 106.96096802,
        lat: -6.30961895
      },
      {
        lng: 106.96192932,
        lat: -6.30974102
      },
      {
        lng: 106.96270752,
        lat: -6.31060553
      },
      {
        lng: 106.96328735,
        lat: -6.31251907
      },
      {
        lng: 106.96138,
        lat: -6.31221914
      },
      {
        lng: 106.960289,
        lat: -6.31241989
      },
      {
        lng: 106.96065521,
        lat: -6.31395435
      },
      {
        lng: 106.96150208,
        lat: -6.31557083
      },
      {
        lng: 106.96334076,
        lat: -6.31664848
      },
      {
        lng: 106.96360016,
        lat: -6.3179493
      },
      {
        lng: 106.96263123,
        lat: -6.31800604
      },
      {
        lng: 106.96228027,
        lat: -6.31867075
      },
      {
        lng: 106.96195221,
        lat: -6.31970978
      },
      {
        lng: 106.96095276,
        lat: -6.32080173
      },
      {
        lng: 106.96025085,
        lat: -6.32139444
      },
      {
        lng: 106.96089172,
        lat: -6.32229614
      },
      {
        lng: 106.96185303,
        lat: -6.32265568
      },
      {
        lng: 106.96156311,
        lat: -6.32394218
      },
      {
        lng: 106.96168518,
        lat: -6.32502556
      },
      {
        lng: 106.96286011,
        lat: -6.32489634
      },
      {
        lng: 106.96452332,
        lat: -6.32473421
      },
      {
        lng: 106.96440887,
        lat: -6.32578802
      },
      {
        lng: 106.9631958,
        lat: -6.3264966
      },
      {
        lng: 106.96129608,
        lat: -6.32583237
      },
      {
        lng: 106.96078491,
        lat: -6.32636356
      },
      {
        lng: 106.96126556,
        lat: -6.32758284
      },
      {
        lng: 106.96179962,
        lat: -6.32839441
      },
      {
        lng: 106.9619751,
        lat: -6.32731962
      },
      {
        lng: 106.96248627,
        lat: -6.32707739
      },
      {
        lng: 106.96310425,
        lat: -6.32828522
      },
      {
        lng: 106.96324158,
        lat: -6.32929039
      },
      {
        lng: 106.96229553,
        lat: -6.32952976
      },
      {
        lng: 106.96199036,
        lat: -6.32989311
      },
      {
        lng: 106.96235657,
        lat: -6.3309207
      },
      {
        lng: 106.96118927,
        lat: -6.33064461
      },
      {
        lng: 106.96019745,
        lat: -6.33171225
      },
      {
        lng: 106.96031189,
        lat: -6.33344698
      },
      {
        lng: 106.96083069,
        lat: -6.333251
      },
      {
        lng: 106.96141052,
        lat: -6.33254004
      },
      {
        lng: 106.96237946,
        lat: -6.33319616
      },
      {
        lng: 106.96159363,
        lat: -6.33381939
      },
      {
        lng: 106.96180725,
        lat: -6.33571005
      },
      {
        lng: 106.96141052,
        lat: -6.33601904
      },
      {
        lng: 106.96125031,
        lat: -6.33518791
      },
      {
        lng: 106.9604187,
        lat: -6.33559752
      },
      {
        lng: 106.95942688,
        lat: -6.33542109
      },
      {
        lng: 106.95831299,
        lat: -6.33658981
      },
      {
        lng: 106.95689392,
        lat: -6.33754396
      },
      {
        lng: 106.95714569,
        lat: -6.33907318
      },
      {
        lng: 106.956604,
        lat: -6.33938789
      },
      {
        lng: 106.95537567,
        lat: -6.33904314
      },
      {
        lng: 106.95506287,
        lat: -6.3396163
      },
      {
        lng: 106.95381927,
        lat: -6.33992624
      },
      {
        lng: 106.95278931,
        lat: -6.34029961
      },
      {
        lng: 106.95294952,
        lat: -6.3418355
      },
      {
        lng: 106.95310211,
        lat: -6.34276676
      },
      {
        lng: 106.95430756,
        lat: -6.34310913
      },
      {
        lng: 106.95487213,
        lat: -6.34385729
      },
      {
        lng: 106.95392609,
        lat: -6.34456682
      },
      {
        lng: 106.95166016,
        lat: -6.34421253
      },
      {
        lng: 106.95040894,
        lat: -6.343997
      },
      {
        lng: 106.95029449,
        lat: -6.34459066
      },
      {
        lng: 106.95106506,
        lat: -6.34593725
      },
      {
        lng: 106.95118713,
        lat: -6.34695482
      },
      {
        lng: 106.9519577,
        lat: -6.34812975
      },
      {
        lng: 106.95235443,
        lat: -6.34878969
      },
      {
        lng: 106.95334625,
        lat: -6.34831572
      },
      {
        lng: 106.95400238,
        lat: -6.34945297
      },
      {
        lng: 106.95346069,
        lat: -6.35108995
      },
      {
        lng: 106.95285797,
        lat: -6.35085487
      },
      {
        lng: 106.95240021,
        lat: -6.3493576
      },
      {
        lng: 106.95133972,
        lat: -6.34940863
      },
      {
        lng: 106.95042419,
        lat: -6.35052681
      },
      {
        lng: 106.95027924,
        lat: -6.35161352
      },
      {
        lng: 106.95227814,
        lat: -6.35283613
      },
      {
        lng: 106.95226288,
        lat: -6.35377359
      },
      {
        lng: 106.95114136,
        lat: -6.35343313
      },
      {
        lng: 106.94999695,
        lat: -6.35362291
      },
      {
        lng: 106.94946289,
        lat: -6.355021
      },
      {
        lng: 106.94808197,
        lat: -6.35625219
      },
      {
        lng: 106.94742584,
        lat: -6.35729218
      },
      {
        lng: 106.94747925,
        lat: -6.35817289
      },
      {
        lng: 106.94866943,
        lat: -6.35843325
      },
      {
        lng: 106.94786835,
        lat: -6.35871649
      },
      {
        lng: 106.94664764,
        lat: -6.35940266
      },
      {
        lng: 106.94604492,
        lat: -6.35929155
      },
      {
        lng: 106.94698334,
        lat: -6.3583746
      },
      {
        lng: 106.9457016,
        lat: -6.35821056
      },
      {
        lng: 106.94417572,
        lat: -6.35778522
      },
      {
        lng: 106.94373322,
        lat: -6.35869598
      },
      {
        lng: 106.94398499,
        lat: -6.35958576
      },
      {
        lng: 106.94476318,
        lat: -6.3589983
      },
      {
        lng: 106.94561768,
        lat: -6.359972
      },
      {
        lng: 106.94660187,
        lat: -6.36021185
      },
      {
        lng: 106.94714355,
        lat: -6.36027813
      },
      {
        lng: 106.94649506,
        lat: -6.36082268
      },
      {
        lng: 106.94628906,
        lat: -6.36186886
      },
      {
        lng: 106.94585419,
        lat: -6.36239052
      },
      {
        lng: 106.945755,
        lat: -6.36348629
      },
      {
        lng: 106.94464874,
        lat: -6.36406088
      },
      {
        lng: 106.9447937,
        lat: -6.36502695
      },
      {
        lng: 106.94456482,
        lat: -6.36570692
      },
      {
        lng: 106.94393921,
        lat: -6.36655855
      },
      {
        lng: 106.94477844,
        lat: -6.36630774
      },
      {
        lng: 106.94519806,
        lat: -6.36724758
      },
      {
        lng: 106.94545746,
        lat: -6.36849165
      },
      {
        lng: 106.94467163,
        lat: -6.36842346
      },
      {
        lng: 106.94393158,
        lat: -6.36901045
      },
      {
        lng: 106.94320679,
        lat: -6.36904049
      },
      {
        lng: 106.94337463,
        lat: -6.36731339
      },
      {
        lng: 106.94202423,
        lat: -6.36773348
      },
      {
        lng: 106.94216919,
        lat: -6.36887693
      },
      {
        lng: 106.9413147,
        lat: -6.36966324
      },
      {
        lng: 106.94015503,
        lat: -6.37048769
      },
      {
        lng: 106.93864441,
        lat: -6.37058401
      },
      {
        lng: 106.93858337,
        lat: -6.37216473
      },
      {
        lng: 106.93914032,
        lat: -6.37174463
      },
      {
        lng: 106.94049072,
        lat: -6.37132168
      },
      {
        lng: 106.93955994,
        lat: -6.37222242
      },
      {
        lng: 106.93865204,
        lat: -6.37342691
      },
      {
        lng: 106.93786621,
        lat: -6.3741231
      },
      {
        lng: 106.93847656,
        lat: -6.37478876
      },
      {
        lng: 106.93864441,
        lat: -6.37565422
      },
      {
        lng: 106.93830872,
        lat: -6.37646389
      },
      {
        lng: 106.93750763,
        lat: -6.37748909
      },
      {
        lng: 106.93635559,
        lat: -6.37713671
      },
      {
        lng: 106.93521881,
        lat: -6.37653303
      },
      {
        lng: 106.93295288,
        lat: -6.37610722
      },
      {
        lng: 106.93009949,
        lat: -6.37954092
      },
      {
        lng: 106.92951202,
        lat: -6.38230991
      },
      {
        lng: 106.93267822,
        lat: -6.38289356
      },
      {
        lng: 106.93376923,
        lat: -6.38355637
      },
      {
        lng: 106.93228912,
        lat: -6.38374662
      },
      {
        lng: 106.93126678,
        lat: -6.38405323
      },
      {
        lng: 106.93070221,
        lat: -6.38508558
      },
      {
        lng: 106.92945862,
        lat: -6.38612747
      },
      {
        lng: 106.92738342,
        lat: -6.38635206
      },
      {
        lng: 106.92688751,
        lat: -6.38546515
      },
      {
        lng: 106.92630768,
        lat: -6.38583565
      },
      {
        lng: 106.92639923,
        lat: -6.38723183
      },
      {
        lng: 106.92610931,
        lat: -6.38816929
      },
      {
        lng: 106.92534637,
        lat: -6.38800526
      },
      {
        lng: 106.92544556,
        lat: -6.38644171
      },
      {
        lng: 106.92485046,
        lat: -6.38607025
      },
      {
        lng: 106.92422485,
        lat: -6.38713312
      },
      {
        lng: 106.92382813,
        lat: -6.38809299
      },
      {
        lng: 106.92325592,
        lat: -6.38938856
      },
      {
        lng: 106.92288208,
        lat: -6.38984251
      },
      {
        lng: 106.9221344,
        lat: -6.38875771
      },
      {
        lng: 106.92178345,
        lat: -6.38922024
      },
      {
        lng: 106.92201996,
        lat: -6.39029551
      },
      {
        lng: 106.92129517,
        lat: -6.39175606
      },
      {
        lng: 106.92098236,
        lat: -6.39320898
      },
      {
        lng: 106.92234039,
        lat: -6.39414406
      },
      {
        lng: 106.92250061,
        lat: -6.39466858
      },
      {
        lng: 106.9213562,
        lat: -6.39541817
      },
      {
        lng: 106.92107391,
        lat: -6.39681864
      },
      {
        lng: 106.92028809,
        lat: -6.39607048
      },
      {
        lng: 106.91884613,
        lat: -6.39560318
      },
      {
        lng: 106.91660309,
        lat: -6.39525652
      },
      {
        lng: 106.91446686,
        lat: -6.39514399
      },
      {
        lng: 106.9131546,
        lat: -6.39535093
      },
      {
        lng: 106.91130829,
        lat: -6.39523649
      },
      {
        lng: 106.90822601,
        lat: -6.39489126
      },
      {
        lng: 106.90758514,
        lat: -6.39489412
      },
      {
        lng: 106.90618134,
        lat: -6.39436007
      },
      {
        lng: 106.90547943,
        lat: -6.3944664
      },
      {
        lng: 106.90500641,
        lat: -6.39513731
      },
      {
        lng: 106.90385437,
        lat: -6.39588451
      },
      {
        lng: 106.9029541,
        lat: -6.39594746
      },
      {
        lng: 106.90224457,
        lat: -6.39715481
      },
      {
        lng: 106.90155792,
        lat: -6.39703083
      },
      {
        lng: 106.90066528,
        lat: -6.39747047
      },
      {
        lng: 106.89958191,
        lat: -6.39774179
      },
      {
        lng: 106.89847565,
        lat: -6.39745951
      },
      {
        lng: 106.89807892,
        lat: -6.39682007
      },
      {
        lng: 106.89778137,
        lat: -6.39597511
      },
      {
        lng: 106.89833832,
        lat: -6.39569378
      },
      {
        lng: 106.89836121,
        lat: -6.39484787
      },
      {
        lng: 106.89888,
        lat: -6.39443636
      },
      {
        lng: 106.8993454,
        lat: -6.39491224
      },
      {
        lng: 106.90032196,
        lat: -6.39434814
      },
      {
        lng: 106.90109253,
        lat: -6.39462328
      },
      {
        lng: 106.9021225,
        lat: -6.39397478
      },
      {
        lng: 106.90283966,
        lat: -6.39374733
      },
      {
        lng: 106.90338898,
        lat: -6.39300632
      },
      {
        lng: 106.90437317,
        lat: -6.39161015
      },
      {
        lng: 106.90408325,
        lat: -6.39074707
      },
      {
        lng: 106.90402985,
        lat: -6.38975716
      },
      {
        lng: 106.9041214,
        lat: -6.38922167
      },
      {
        lng: 106.90352631,
        lat: -6.38821363
      },
      {
        lng: 106.90450287,
        lat: -6.38707209
      },
      {
        lng: 106.90390015,
        lat: -6.38647795
      },
      {
        lng: 106.90496063,
        lat: -6.38487434
      },
      {
        lng: 106.90488434,
        lat: -6.38409281
      },
      {
        lng: 106.90449524,
        lat: -6.38322401
      },
      {
        lng: 106.90464783,
        lat: -6.38222885
      },
      {
        lng: 106.90421295,
        lat: -6.3806262
      },
      {
        lng: 106.90403748,
        lat: -6.38003016
      },
      {
        lng: 106.90457153,
        lat: -6.3795743
      },
      {
        lng: 106.90496063,
        lat: -6.37921381
      },
      {
        lng: 106.90557098,
        lat: -6.37854719
      },
      {
        lng: 106.90601349,
        lat: -6.37819624
      },
      {
        lng: 106.90650177,
        lat: -6.3776536
      },
      {
        lng: 106.90731812,
        lat: -6.37750101
      },
      {
        lng: 106.90787506,
        lat: -6.37737942
      },
      {
        lng: 106.90766144,
        lat: -6.37601233
      },
      {
        lng: 106.90835571,
        lat: -6.37540388
      },
      {
        lng: 106.9085083,
        lat: -6.3744483
      },
      {
        lng: 106.90792847,
        lat: -6.37385464
      },
      {
        lng: 106.90847778,
        lat: -6.37329149
      },
      {
        lng: 106.9084549,
        lat: -6.37273645
      },
      {
        lng: 106.90763092,
        lat: -6.37075233
      },
      {
        lng: 106.91031647,
        lat: -6.36782885
      },
      {
        lng: 106.91215515,
        lat: -6.36750507
      },
      {
        lng: 106.9119873,
        lat: -6.36812782
      },
      {
        lng: 106.91247559,
        lat: -6.36837578
      },
      {
        lng: 106.91265106,
        lat: -6.36738062
      },
      {
        lng: 106.91382599,
        lat: -6.36660147
      },
      {
        lng: 106.91433716,
        lat: -6.36571741
      },
      {
        lng: 106.91453552,
        lat: -6.36467314
      },
      {
        lng: 106.91492462,
        lat: -6.36353207
      },
      {
        lng: 106.91545868,
        lat: -6.36320114
      },
      {
        lng: 106.91620636,
        lat: -6.36288881
      },
      {
        lng: 106.91558075,
        lat: -6.36242914
      },
      {
        lng: 106.91457367,
        lat: -6.36135626
      },
      {
        lng: 106.91542053,
        lat: -6.36077976
      },
      {
        lng: 106.91526031,
        lat: -6.36032915
      },
      {
        lng: 106.91452789,
        lat: -6.36032486
      },
      {
        lng: 106.91468048,
        lat: -6.35948563
      },
      {
        lng: 106.91413116,
        lat: -6.35893679
      },
      {
        lng: 106.91372681,
        lat: -6.35859966
      },
      {
        lng: 106.91474152,
        lat: -6.35787392
      },
      {
        lng: 106.91518402,
        lat: -6.35705614
      },
      {
        lng: 106.91607666,
        lat: -6.35643911
      },
      {
        lng: 106.91688538,
        lat: -6.35663652
      },
      {
        lng: 106.91706848,
        lat: -6.35608912
      },
      {
        lng: 106.91587067,
        lat: -6.35528469
      },
      {
        lng: 106.91563416,
        lat: -6.35471106
      },
      {
        lng: 106.9165802,
        lat: -6.35368919
      },
      {
        lng: 106.91732025,
        lat: -6.35288334
      },
      {
        lng: 106.91676331,
        lat: -6.35185194
      },
      {
        lng: 106.91664886,
        lat: -6.35135078
      },
      {
        lng: 106.91745758,
        lat: -6.35083199
      },
      {
        lng: 106.91777802,
        lat: -6.35028505
      },
      {
        lng: 106.91699219,
        lat: -6.34934855
      },
      {
        lng: 106.91623688,
        lat: -6.34895086
      },
      {
        lng: 106.91649628,
        lat: -6.34831762
      },
      {
        lng: 106.91703796,
        lat: -6.34818459
      },
      {
        lng: 106.91661072,
        lat: -6.34680843
      },
      {
        lng: 106.91599274,
        lat: -6.34622955
      },
      {
        lng: 106.91678619,
        lat: -6.3462348
      },
      {
        lng: 106.91702271,
        lat: -6.34581852
      },
      {
        lng: 106.91707611,
        lat: -6.34517002
      },
      {
        lng: 106.91729736,
        lat: -6.34385204
      },
      {
        lng: 106.91851044,
        lat: -6.34371614
      },
      {
        lng: 106.91942596,
        lat: -6.34360123
      },
      {
        lng: 106.91864777,
        lat: -6.34278822
      },
      {
        lng: 106.91857147,
        lat: -6.34224272
      },
      {
        lng: 106.91902161,
        lat: -6.34156942
      },
      {
        lng: 106.91957092,
        lat: -6.34111309
      },
      {
        lng: 106.91848755,
        lat: -6.33994436
      },
      {
        lng: 106.91838837,
        lat: -6.33907366
      },
      {
        lng: 106.91950226,
        lat: -6.33862734
      },
      {
        lng: 106.91908264,
        lat: -6.33727598
      },
      {
        lng: 106.91957855,
        lat: -6.33606958
      },
      {
        lng: 106.91963196,
        lat: -6.3342309
      },
      {
        lng: 106.92015839,
        lat: -6.33336973
      },
      {
        lng: 106.92088318,
        lat: -6.33150625
      },
      {
        lng: 106.92069244,
        lat: -6.33049059
      },
      {
        lng: 106.9202652,
        lat: -6.32919121
      },
      {
        lng: 106.91984558,
        lat: -6.32840872
      },
      {
        lng: 106.91837311,
        lat: -6.32855225
      },
      {
        lng: 106.91829681,
        lat: -6.32719374
      },
      {
        lng: 106.9190979,
        lat: -6.32625151
      },
      {
        lng: 106.91903687,
        lat: -6.32530499
      },
      {
        lng: 106.92015076,
        lat: -6.32508039
      },
      {
        lng: 106.92095184,
        lat: -6.32447481
      },
      {
        lng: 106.92169189,
        lat: -6.32296753
      },
      {
        lng: 106.92123413,
        lat: -6.32218313
      },
      {
        lng: 106.92051697,
        lat: -6.32138395
      },
      {
        lng: 106.92087555,
        lat: -6.32018805
      },
      {
        lng: 106.9211731,
        lat: -6.31931639
      },
      {
        lng: 106.92076874,
        lat: -6.31813812
      },
      {
        lng: 106.92140961,
        lat: -6.31809187
      },
      {
        lng: 106.92199707,
        lat: -6.3172965
      },
      {
        lng: 106.92221069,
        lat: -6.31650019
      },
      {
        lng: 106.92201233,
        lat: -6.31551647
      },
      {
        lng: 106.92272949,
        lat: -6.31413603
      },
      {
        lng: 106.92230225,
        lat: -6.31384325
      },
      {
        lng: 106.92224884,
        lat: -6.31335831
      },
      {
        lng: 106.92180634,
        lat: -6.31293726
      },
      {
        lng: 106.92178345,
        lat: -6.31224871
      },
      {
        lng: 106.92076111,
        lat: -6.31229544
      },
      {
        lng: 106.92012787,
        lat: -6.31141424
      },
      {
        lng: 106.92108154,
        lat: -6.31097507
      },
      {
        lng: 106.92108917,
        lat: -6.31063557
      },
      {
        lng: 106.9211731,
        lat: -6.3093996
      },
      {
        lng: 106.92066956,
        lat: -6.30822563
      },
      {
        lng: 106.92098236,
        lat: -6.30750322
      },
      {
        lng: 106.92153931,
        lat: -6.30733252
      },
      {
        lng: 106.92157745,
        lat: -6.30601549
      },
      {
        lng: 106.92163849,
        lat: -6.30522156
      },
      {
        lng: 106.92144012,
        lat: -6.30465221
      },
      {
        lng: 106.92164612,
        lat: -6.30404043
      },
      {
        lng: 106.92150879,
        lat: -6.30332756
      },
      {
        lng: 106.92103577,
        lat: -6.30217648
      },
      {
        lng: 106.92056274,
        lat: -6.30182457
      },
      {
        lng: 106.92092896,
        lat: -6.30145693
      },
      {
        lng: 106.9206543,
        lat: -6.30019808
      },
      {
        lng: 106.91962433,
        lat: -6.3006587
      },
      {
        lng: 106.91899109,
        lat: -6.30059958
      },
      {
        lng: 106.91919708,
        lat: -6.29970551
      },
      {
        lng: 106.91866302,
        lat: -6.29912663
      },
      {
        lng: 106.91825104,
        lat: -6.29970217
      },
      {
        lng: 106.91777039,
        lat: -6.29927444
      },
      {
        lng: 106.91762543,
        lat: -6.29795313
      },
      {
        lng: 106.91680908,
        lat: -6.29800892
      },
      {
        lng: 106.91550446,
        lat: -6.29719353
      },
      {
        lng: 106.91451263,
        lat: -6.29789305
      },
      {
        lng: 106.91370392,
        lat: -6.29815197
      },
      {
        lng: 106.91371918,
        lat: -6.29867411
      },
      {
        lng: 106.91301727,
        lat: -6.29884291
      },
      {
        lng: 106.91233826,
        lat: -6.2982955
      },
      {
        lng: 106.91178131,
        lat: -6.29812431
      },
      {
        lng: 106.91186523,
        lat: -6.29659414
      },
      {
        lng: 106.91109467,
        lat: -6.29612303
      },
      {
        lng: 106.91171265,
        lat: -6.29523611
      },
      {
        lng: 106.91235352,
        lat: -6.29490757
      },
      {
        lng: 106.91189575,
        lat: -6.29443073
      },
      {
        lng: 106.91100311,
        lat: -6.29453659
      },
      {
        lng: 106.9101944,
        lat: -6.2942667
      },
      {
        lng: 106.91026306,
        lat: -6.29331398
      },
      {
        lng: 106.91110229,
        lat: -6.29293776
      },
      {
        lng: 106.91155243,
        lat: -6.29167557
      },
      {
        lng: 106.91120911,
        lat: -6.29202223
      },
      {
        lng: 106.91093445,
        lat: -6.29232025
      },
      {
        lng: 106.91040802,
        lat: -6.29142141
      },
      {
        lng: 106.91181183,
        lat: -6.29030609
      },
      {
        lng: 106.91114807,
        lat: -6.29054308
      },
      {
        lng: 106.91046906,
        lat: -6.29061842
      },
      {
        lng: 106.90983582,
        lat: -6.29066753
      },
      {
        lng: 106.90937042,
        lat: -6.29061604
      },
      {
        lng: 106.90985107,
        lat: -6.28986025
      },
      {
        lng: 106.91000366,
        lat: -6.2890029
      },
      {
        lng: 106.90977478,
        lat: -6.28837204
      },
      {
        lng: 106.91020203,
        lat: -6.28727913
      },
      {
        lng: 106.90966034,
        lat: -6.2866168
      },
      {
        lng: 106.90999603,
        lat: -6.28605509
      },
      {
        lng: 106.90860748,
        lat: -6.28437662
      },
      {
        lng: 106.90851593,
        lat: -6.28301144
      },
      {
        lng: 106.90797424,
        lat: -6.2825017
      },
      {
        lng: 106.90843964,
        lat: -6.28196096
      },
      {
        lng: 106.90904236,
        lat: -6.28077602
      },
      {
        lng: 106.90873718,
        lat: -6.28027868
      },
      {
        lng: 106.90860748,
        lat: -6.27914286
      },
      {
        lng: 106.90880585,
        lat: -6.27774096
      },
      {
        lng: 106.90921783,
        lat: -6.27663708
      },
      {
        lng: 106.90924835,
        lat: -6.27571249
      },
      {
        lng: 106.90917206,
        lat: -6.27444267
      },
      {
        lng: 106.90958405,
        lat: -6.27353001
      },
      {
        lng: 106.90985107,
        lat: -6.27295876
      },
      {
        lng: 106.90988159,
        lat: -6.27241993
      },
      {
        lng: 106.90953064,
        lat: -6.27225876
      },
      {
        lng: 106.90885162,
        lat: -6.27273703
      },
      {
        lng: 106.90861511,
        lat: -6.2719841
      },
      {
        lng: 106.90823364,
        lat: -6.27196074
      },
      {
        lng: 106.90739441,
        lat: -6.27207422
      },
      {
        lng: 106.9066925,
        lat: -6.27197886
      },
      {
        lng: 106.90650177,
        lat: -6.27154589
      },
      {
        lng: 106.90605164,
        lat: -6.27154016
      },
      {
        lng: 106.9056015,
        lat: -6.27097368
      },
      {
        lng: 106.90563965,
        lat: -6.27017069
      },
      {
        lng: 106.904953,
        lat: -6.27027416
      },
      {
        lng: 106.90422821,
        lat: -6.27023506
      },
      {
        lng: 106.90475464,
        lat: -6.26964235
      },
      {
        lng: 106.90557098,
        lat: -6.26885223
      },
      {
        lng: 106.90551758,
        lat: -6.26804829
      },
      {
        lng: 106.90548706,
        lat: -6.2675724
      },
      {
        lng: 106.90497589,
        lat: -6.26783371
      },
      {
        lng: 106.90516663,
        lat: -6.26728821
      },
      {
        lng: 106.90503693,
        lat: -6.2665863
      },
      {
        lng: 106.90459442,
        lat: -6.26597691
      },
      {
        lng: 106.90486145,
        lat: -6.26533556
      },
      {
        lng: 106.90522003,
        lat: -6.26458168
      },
      {
        lng: 106.90500641,
        lat: -6.26350641
      },
      {
        lng: 106.90541077,
        lat: -6.26303005
      },
      {
        lng: 106.90471649,
        lat: -6.26239014
      },
      {
        lng: 106.90444946,
        lat: -6.26191092
      },
      {
        lng: 106.90477753,
        lat: -6.2611289
      },
      {
        lng: 106.9053421,
        lat: -6.26048946
      },
      {
        lng: 106.90624237,
        lat: -6.25967026
      },
      {
        lng: 106.90798187,
        lat: -6.25888395
      },
      {
        lng: 106.90910339,
        lat: -6.25837183
      },
      {
        lng: 106.91005707,
        lat: -6.25771999
      },
      {
        lng: 106.90982819,
        lat: -6.25613832
      },
      {
        lng: 106.91047668,
        lat: -6.25569773
      },
      {
        lng: 106.91223907,
        lat: -6.25737429
      },
      {
        lng: 106.91514587,
        lat: -6.25828886
      },
      {
        lng: 106.91596985,
        lat: -6.25795507
      },
      {
        lng: 106.91627502,
        lat: -6.25647211
      },
      {
        lng: 106.91658783,
        lat: -6.25481844
      },
      {
        lng: 106.91624451,
        lat: -6.25250864
      },
      {
        lng: 106.91761017,
        lat: -6.25227594
      },
      {
        lng: 106.91837311,
        lat: -6.25149488
      },
      {
        lng: 106.91918945,
        lat: -6.25056458
      },
      {
        lng: 106.92009735,
        lat: -6.25035572
      },
      {
        lng: 106.92095184,
        lat: -6.25077438
      },
      {
        lng: 106.92160797,
        lat: -6.25124216
      },
      {
        lng: 106.92164612,
        lat: -6.25171947
      },
      {
        lng: 106.92180634,
        lat: -6.25275898
      },
      {
        lng: 106.92184448,
        lat: -6.2533989
      },
      {
        lng: 106.92211151,
        lat: -6.25388336
      },
      {
        lng: 106.92469788,
        lat: -6.25369692
      },
      {
        lng: 106.92806244,
        lat: -6.25459909
      },
      {
        lng: 106.92863464,
        lat: -6.25406218
      },
      {
        lng: 106.92944336,
        lat: -6.25318909
      },
      {
        lng: 106.93087006,
        lat: -6.25364256
      },
      {
        lng: 106.93238831,
        lat: -6.25382519
      },
      {
        lng: 106.93417358,
        lat: -6.25379086
      },
      {
        lng: 106.93655396,
        lat: -6.25316763
      },
      {
        lng: 106.93779755,
        lat: -6.25327444
      },
      {
        lng: 106.93932343,
        lat: -6.25337458
      },
      {
        lng: 106.94132996,
        lat: -6.25305891
      },
      {
        lng: 106.94223785,
        lat: -6.25265932
      },
      {
        lng: 106.94322968,
        lat: -6.25238514
      },
      {
        lng: 106.94372559,
        lat: -6.25146961
      },
      {
        lng: 106.94373322,
        lat: -6.25005054
      },
      {
        lng: 106.94379425,
        lat: -6.2479353
      },
      {
        lng: 106.94378662,
        lat: -6.24511433
      },
      {
        lng: 106.94366455,
        lat: -6.2415204
      },
      {
        lng: 106.94356537,
        lat: -6.24045038
      },
      {
        lng: 106.94359589,
        lat: -6.23870897
      },
      {
        lng: 106.94229889,
        lat: -6.23874998
      },
      {
        lng: 106.94184875,
        lat: -6.2380538
      },
      {
        lng: 106.94287109,
        lat: -6.23741961
      },
      {
        lng: 106.9446106,
        lat: -6.23663855
      },
      {
        lng: 106.94556427,
        lat: -6.2361784
      },
      {
        lng: 106.94607544,
        lat: -6.23386192
      },
      {
        lng: 106.94654846,
        lat: -6.23217916
      },
      {
        lng: 106.94689178,
        lat: -6.2305336
      },
      {
        lng: 106.9469986,
        lat: -6.22923946
      },
      {
        lng: 106.94685364,
        lat: -6.22792053
      },
      {
        lng: 106.94717407,
        lat: -6.22642803
      },
      {
        lng: 106.94788361,
        lat: -6.22434425
      },
      {
        lng: 106.94853973,
        lat: -6.22245121
      },
      {
        lng: 106.94920349,
        lat: -6.2215476
      },
      {
        lng: 106.95028687,
        lat: -6.21992636
      },
      {
        lng: 106.95153046,
        lat: -6.21907425
      },
      {
        lng: 106.95278931,
        lat: -6.21922016
      },
      {
        lng: 106.95448303,
        lat: -6.21939135
      },
      {
        lng: 106.95550537,
        lat: -6.21949196
      },
      {
        lng: 106.95595551,
        lat: -6.21848822
      },
      {
        lng: 106.95625305,
        lat: -6.21791697
      },
      {
        lng: 106.95736694,
        lat: -6.21784925
      },
      {
        lng: 106.95804596,
        lat: -6.21690035
      },
      {
        lng: 106.95841217,
        lat: -6.2145133
      },
      {
        lng: 106.95904541,
        lat: -6.21289396
      },
      {
        lng: 106.96046448,
        lat: -6.21301031
      },
      {
        lng: 106.96068573,
        lat: -6.21409512
      },
      {
        lng: 106.96253204,
        lat: -6.21384859
      },
      {
        lng: 106.96279907,
        lat: -6.2118783
      },
      {
        lng: 106.96343994,
        lat: -6.2104907
      },
      {
        lng: 106.96508789,
        lat: -6.20786142
      },
      {
        lng: 106.96620178,
        lat: -6.20691824
      },
      {
        lng: 106.96601868,
        lat: -6.20608473
      },
      {
        lng: 106.96616364,
        lat: -6.2054801
      },
      {
        lng: 106.96582794,
        lat: -6.20511723
      },
      {
        lng: 106.96614075,
        lat: -6.20428038
      },
      {
        lng: 106.96629333,
        lat: -6.20353079
      },
      {
        lng: 106.96617889,
        lat: -6.20288992
      },
      {
        lng: 106.96640015,
        lat: -6.20211887
      },
      {
        lng: 106.96607208,
        lat: -6.20122194
      },
      {
        lng: 106.96617126,
        lat: -6.20051432
      },
      {
        lng: 106.96659088,
        lat: -6.19963932
      },
      {
        lng: 106.96741486,
        lat: -6.19830465
      },
      {
        lng: 106.96659851,
        lat: -6.19743156
      },
      {
        lng: 106.96669006,
        lat: -6.19538879
      },
      {
        lng: 106.96743774,
        lat: -6.19472551
      },
      {
        lng: 106.9695816,
        lat: -6.19495344
      },
      {
        lng: 106.96936798,
        lat: -6.19306231
      },
      {
        lng: 106.96974945,
        lat: -6.19122839
      },
      {
        lng: 106.97049713,
        lat: -6.18937349
      },
      {
        lng: 106.97111511,
        lat: -6.18850756
      },
      {
        lng: 106.97122955,
        lat: -6.18706226
      },
      {
        lng: 106.97176361,
        lat: -6.18095922
      },
      {
        lng: 106.97697449,
        lat: -6.17214108
      },
      {
        lng: 106.98239136,
        lat: -6.17230034
      },
      {
        lng: 106.99004364,
        lat: -6.17233324
      },
      {
        lng: 106.99189758,
        lat: -6.17231607
      },
      {
        lng: 106.99518585,
        lat: -6.17255163
      },
      {
        lng: 106.99669647,
        lat: -6.1726284
      },
      {
        lng: 106.99858093,
        lat: -6.17223644
      },
      {
        lng: 106.99934387,
        lat: -6.17225885
      },
      {
        lng: 107.00018311,
        lat: -6.17231846
      },
      {
        lng: 107.00148773,
        lat: -6.17238283
      },
      {
        lng: 107.0032196,
        lat: -6.17236805
      },
      {
        lng: 107.00476074,
        lat: -6.17231274
      },
      {
        lng: 107.00551605,
        lat: -6.17236805
      },
      {
        lng: 107.00717926,
        lat: -6.17238188
      },
      {
        lng: 107.01070404,
        lat: -6.17259073
      },
      {
        lng: 107.01076508,
        lat: -6.17454195
      },
      {
        lng: 107.00830078,
        lat: -6.17457771
      },
      {
        lng: 107.00834656,
        lat: -6.17766285
      },
      {
        lng: 107.00707245,
        lat: -6.1814332
      },
      {
        lng: 107.00669098,
        lat: -6.18278551
      },
      {
        lng: 107.0072937,
        lat: -6.1867919
      },
      {
        lng: 107.0069809,
        lat: -6.18892908
      },
      {
        lng: 107.00624847,
        lat: -6.18993378
      },
      {
        lng: 107.00595093,
        lat: -6.19562149
      },
      {
        lng: 107.00732422,
        lat: -6.19627905
      },
      {
        lng: 107.0087204,
        lat: -6.19649315
      },
      {
        lng: 107.0100174,
        lat: -6.19640446
      },
      {
        lng: 107.01261902,
        lat: -6.19554758
      },
      {
        lng: 107.01368713,
        lat: -6.19488764
      },
      {
        lng: 107.01540375,
        lat: -6.19593334
      },
      {
        lng: 107.01583099,
        lat: -6.19673014
      },
      {
        lng: 107.0162735,
        lat: -6.19810009
      },
      {
        lng: 107.01711273,
        lat: -6.19886065
      },
      {
        lng: 107.01792145,
        lat: -6.19971991
      },
      {
        lng: 107.0194931,
        lat: -6.19844198
      },
      {
        lng: 107.01986694,
        lat: -6.19675589
      },
      {
        lng: 107.02071381,
        lat: -6.1964941
      },
      {
        lng: 107.02178955,
        lat: -6.1969986
      },
      {
        lng: 107.0228653,
        lat: -6.19711876
      },
      {
        lng: 107.02472687,
        lat: -6.19687843
      },
      {
        lng: 107.02623749,
        lat: -6.19724703
      },
      {
        lng: 107.02685547,
        lat: -6.19744444
      },
      {
        lng: 107.02809143,
        lat: -6.19717789
      },
      {
        lng: 107.02968597,
        lat: -6.19807959
      },
      {
        lng: 107.03071594,
        lat: -6.19804764
      },
      {
        lng: 107.03229523,
        lat: -6.19830036
      },
      {
        lng: 107.03404999,
        lat: -6.20473766
      },
      {
        lng: 107.03388214,
        lat: -6.20631838
      },
      {
        lng: 107.03314972,
        lat: -6.20738983
      },
      {
        lng: 107.03216553,
        lat: -6.20859003
      },
      {
        lng: 107.0327301,
        lat: -6.20959425
      },
      {
        lng: 107.03354645,
        lat: -6.21080971
      },
      {
        lng: 107.03368378,
        lat: -6.21267366
      },
      {
        lng: 107.03214264,
        lat: -6.21359396
      },
      {
        lng: 107.03102112,
        lat: -6.21464252
      },
      {
        lng: 107.03097534,
        lat: -6.21649599
      },
      {
        lng: 107.0321579,
        lat: -6.21815729
      },
      {
        lng: 107.03305054,
        lat: -6.21974373
      },
      {
        lng: 107.03201294,
        lat: -6.22084618
      },
      {
        lng: 107.03151703,
        lat: -6.22290945
      },
      {
        lng: 107.03094482,
        lat: -6.22357988
      },
      {
        lng: 107.03075409,
        lat: -6.22492695
      },
      {
        lng: 107.03041077,
        lat: -6.22655296
      },
      {
        lng: 107.03193665,
        lat: -6.22707415
      },
      {
        lng: 107.03325653,
        lat: -6.22702503
      },
      {
        lng: 107.03399658,
        lat: -6.22672367
      },
      {
        lng: 107.03559875,
        lat: -6.22698069
      },
      {
        lng: 107.03583527,
        lat: -6.22799063
      },
      {
        lng: 107.03631592,
        lat: -6.22838402
      },
      {
        lng: 107.03701019,
        lat: -6.22888422
      },
      {
        lng: 107.03804779,
        lat: -6.23020506
      },
      {
        lng: 107.03870392,
        lat: -6.23230553
      },
      {
        lng: 107.04116821,
        lat: -6.23265696
      },
      {
        lng: 107.04240417,
        lat: -6.23344803
      },
      {
        lng: 107.04227448,
        lat: -6.23434114
      },
      {
        lng: 107.04225159,
        lat: -6.23646879
      },
      {
        lng: 107.04202271,
        lat: -6.23796225
      },
      {
        lng: 107.04195404,
        lat: -6.23970652
      },
      {
        lng: 107.04177856,
        lat: -6.24075603
      },
      {
        lng: 107.04186249,
        lat: -6.24260855
      },
      {
        lng: 107.04176331,
        lat: -6.24437618
      },
      {
        lng: 107.04148102,
        lat: -6.24599171
      },
      {
        lng: 107.03964233,
        lat: -6.24648714
      },
      {
        lng: 107.03868866,
        lat: -6.24710274
      },
      {
        lng: 107.03747559,
        lat: -6.24668455
      },
      {
        lng: 107.03651428,
        lat: -6.24672699
      },
      {
        lng: 107.03539276,
        lat: -6.24667168
      },
      {
        lng: 107.03497314,
        lat: -6.24727249
      },
      {
        lng: 107.03426361,
        lat: -6.24768019
      },
      {
        lng: 107.03382111,
        lat: -6.24833727
      },
      {
        lng: 107.03314972,
        lat: -6.249053
      },
      {
        lng: 107.03255463,
        lat: -6.24944067
      },
      {
        lng: 107.03132629,
        lat: -6.24963808
      },
      {
        lng: 107.03049469,
        lat: -6.2500267
      },
      {
        lng: 107.03018951,
        lat: -6.25054836
      },
      {
        lng: 107.02979279,
        lat: -6.25155115
      },
      {
        lng: 107.02987671,
        lat: -6.25253296
      },
      {
        lng: 107.02929688,
        lat: -6.25310421
      },
      {
        lng: 107.02854156,
        lat: -6.25262165
      },
      {
        lng: 107.02674866,
        lat: -6.25370693
      },
      {
        lng: 107.02555847,
        lat: -6.25436354
      },
      {
        lng: 107.02439117,
        lat: -6.25500727
      },
      {
        lng: 107.02396393,
        lat: -6.25594425
      },
      {
        lng: 107.02307129,
        lat: -6.25705051
      },
      {
        lng: 107.02214813,
        lat: -6.25841236
      },
      {
        lng: 107.02183533,
        lat: -6.25914812
      },
      {
        lng: 107.02088928,
        lat: -6.26178074
      },
      {
        lng: 107.0205307,
        lat: -6.26283503
      },
      {
        lng: 107.0177536,
        lat: -6.26293325
      },
      {
        lng: 107.01681519,
        lat: -6.26435089
      },
      {
        lng: 107.01639557,
        lat: -6.26557159
      },
      {
        lng: 107.01704407,
        lat: -6.26802206
      },
      {
        lng: 107.01667786,
        lat: -6.26932764
      },
      {
        lng: 107.01638031,
        lat: -6.2702117
      },
      {
        lng: 107.01696014,
        lat: -6.27064037
      },
      {
        lng: 107.01778412,
        lat: -6.27116442
      },
      {
        lng: 107.01750946,
        lat: -6.27205086
      },
      {
        lng: 107.01757812,
        lat: -6.27314615
      },
      {
        lng: 107.01700592,
        lat: -6.27519369
      },
      {
        lng: 107.01715088,
        lat: -6.27639961
      },
      {
        lng: 107.01863861,
        lat: -6.27672672
      },
      {
        lng: 107.02069855,
        lat: -6.2774601
      },
      {
        lng: 107.02142334,
        lat: -6.27812004
      },
      {
        lng: 107.02185822,
        lat: -6.27907276
      },
      {
        lng: 107.02298737,
        lat: -6.28016329
      },
      {
        lng: 107.025177,
        lat: -6.27893257
      },
      {
        lng: 107.02707672,
        lat: -6.27840662
      },
      {
        lng: 107.02819061,
        lat: -6.27835989
      },
      {
        lng: 107.02925873,
        lat: -6.27882814
      },
      {
        lng: 107.03012848,
        lat: -6.28054571
      },
      {
        lng: 107.03143311,
        lat: -6.28206587
      },
      {
        lng: 107.03398895,
        lat: -6.28258324
      },
      {
        lng: 107.03691101,
        lat: -6.28349876
      },
      {
        lng: 107.0381546,
        lat: -6.28467751
      },
      {
        lng: 107.03965759,
        lat: -6.28740215
      },
      {
        lng: 107.04007721,
        lat: -6.28871727
      },
      {
        lng: 107.04038239,
        lat: -6.29123783
      },
      {
        lng: 107.04019928,
        lat: -6.29348612
      },
      {
        lng: 107.04050446,
        lat: -6.29514551
      },
      {
        lng: 107.04077911,
        lat: -6.29669523
      },
      {
        lng: 107.04029083,
        lat: -6.29901409
      },
      {
        lng: 107.04004669,
        lat: -6.30055189
      },
      {
        lng: 107.03892517,
        lat: -6.30366468
      },
      {
        lng: 107.03838348,
        lat: -6.30619812
      },
      {
        lng: 107.0411911,
        lat: -6.30748749
      },
      {
        lng: 107.04089355,
        lat: -6.30875111
      },
      {
        lng: 107.03945923,
        lat: -6.30921316
      },
      {
        lng: 107.03890228,
        lat: -6.31068611
      },
      {
        lng: 107.0381012,
        lat: -6.31182623
      },
      {
        lng: 107.03788757,
        lat: -6.31328297
      },
      {
        lng: 107.03717804,
        lat: -6.31400299
      },
      {
        lng: 107.0369873,
        lat: -6.31513357
      },
      {
        lng: 107.03825378,
        lat: -6.31680202
      },
      {
        lng: 107.03786469,
        lat: -6.31734657
      },
      {
        lng: 107.03679657,
        lat: -6.31752443
      },
      {
        lng: 107.03684998,
        lat: -6.3198061
      },
      {
        lng: 107.03672791,
        lat: -6.32121897
      },
      {
        lng: 107.03651428,
        lat: -6.3218956
      },
      {
        lng: 107.03497314,
        lat: -6.32245016
      },
      {
        lng: 107.03424835,
        lat: -6.32283401
      },
      {
        lng: 107.03289032,
        lat: -6.32341337
      },
      {
        lng: 107.03186798,
        lat: -6.324265
      },
      {
        lng: 107.03102112,
        lat: -6.32502222
      },
      {
        lng: 107.03019714,
        lat: -6.32577705
      },
      {
        lng: 107.02999115,
        lat: -6.326406
      },
      {
        lng: 107.02941132,
        lat: -6.32912302
      },
      {
        lng: 107.02796936,
        lat: -6.33030653
      },
      {
        lng: 107.0242691,
        lat: -6.32906294
      },
      {
        lng: 107.02089691,
        lat: -6.32807255
      },
      {
        lng: 107.0196991,
        lat: -6.32906055
      },
      {
        lng: 107.01934814,
        lat: -6.32959604
      },
      {
        lng: 107.01644897,
        lat: -6.33040667
      },
      {
        lng: 107.01631927,
        lat: -6.33700371
      },
      {
        lng: 107.01662445,
        lat: -6.34095573
      },
      {
        lng: 107.01612091,
        lat: -6.34364271
      },
      {
        lng: 107.01673126,
        lat: -6.34664297
      },
      {
        lng: 107.01721954,
        lat: -6.34747076
      },
      {
        lng: 107.01810455,
        lat: -6.34838533
      },
      {
        lng: 107.01727295,
        lat: -6.34911776
      },
      {
        lng: 107.01724243,
        lat: -6.35043335
      },
      {
        lng: 107.01730347,
        lat: -6.35242128
      },
      {
        lng: 107.0172348,
        lat: -6.35357809
      },
      {
        lng: 107.01667023,
        lat: -6.35406971
      },
      {
        lng: 107.01490784,
        lat: -6.35480356
      },
      {
        lng: 107.01438904,
        lat: -6.35548019
      },
      {
        lng: 107.01384735,
        lat: -6.35614395
      },
      {
        lng: 107.01333618,
        lat: -6.3569808
      },
      {
        lng: 107.01235199,
        lat: -6.35757351
      },
      {
        lng: 107.0116806,
        lat: -6.35845566
      },
      {
        lng: 107.00996399,
        lat: -6.35915613
      },
      {
        lng: 107.00849915,
        lat: -6.36082745
      },
      {
        lng: 107.00720215,
        lat: -6.36203957
      },
      {
        lng: 107.0067215,
        lat: -6.36254978
      },
      {
        lng: 107.00541687,
        lat: -6.36172009
      },
      {
        lng: 107.00479126,
        lat: -6.36051989
      },
      {
        lng: 107.00392151,
        lat: -6.35974979
      },
      {
        lng: 107.00386047,
        lat: -6.35803986
      },
      {
        lng: 107.00434113,
        lat: -6.3566699
      },
      {
        lng: 107.00440979,
        lat: -6.35557985
      },
      {
        lng: 107.00330353,
        lat: -6.35484838
      },
      {
        lng: 107.00287628,
        lat: -6.35464478
      },
      {
        lng: 107.00247955,
        lat: -6.35468912
      },
      {
        lng: 107.0020752,
        lat: -6.35443449
      },
      {
        lng: 107.00193787,
        lat: -6.35419559
      },
      {
        lng: 107.00078583,
        lat: -6.35462666
      },
      {
        lng: 106.99971771,
        lat: -6.35537338
      },
      {
        lng: 106.99879456,
        lat: -6.35603666
      },
      {
        lng: 106.99771118,
        lat: -6.35614634
      },
      {
        lng: 106.99565887,
        lat: -6.35479593
      },
      {
        lng: 106.99456787,
        lat: -6.35526848
      },
      {
        lng: 106.99369812,
        lat: -6.35641956
      },
      {
        lng: 106.99336243,
        lat: -6.35830593
      },
      {
        lng: 106.99301147,
        lat: -6.3603096
      },
      {
        lng: 106.99362183,
        lat: -6.36100864
      },
      {
        lng: 106.99282837,
        lat: -6.36251593
      },
      {
        lng: 106.99358368,
        lat: -6.36321688
      },
      {
        lng: 106.9929657,
        lat: -6.36423492
      },
      {
        lng: 106.99375916,
        lat: -6.36567736
      },
      {
        lng: 106.99462891,
        lat: -6.36616325
      },
      {
        lng: 106.99389648,
        lat: -6.36690187
      },
      {
        lng: 106.99351501,
        lat: -6.36623001
      },
      {
        lng: 106.99259949,
        lat: -6.36544418
      },
      {
        lng: 106.99142456,
        lat: -6.3655448
      },
      {
        lng: 106.990448,
        lat: -6.36523771
      },
      {
        lng: 106.99015045,
        lat: -6.36431408
      },
      {
        lng: 106.98908234,
        lat: -6.36426497
      },
      {
        lng: 106.98856354,
        lat: -6.36396027
      },
      {
        lng: 106.98776245,
        lat: -6.36360836
      },
      {
        lng: 106.98699951,
        lat: -6.36371946
      },
      {
        lng: 106.98617554,
        lat: -6.36346722
      },
      {
        lng: 106.98474884,
        lat: -6.36374855
      },
      {
        lng: 106.98327637,
        lat: -6.36370897
      },
      {
        lng: 106.98012543,
        lat: -6.3642478
      }
    ]
  }
]
