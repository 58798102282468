import axios from 'axios'
import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

export default {
  SET_MSAL_VALUE_ACTION({ commit }, { key, value }) {
    commit('SET_MSAL_ACTION', { key, value })
  },

  CREATE_MSAL_INSTANCE_ACTION({ state, commit }) {
    const msalInstance = new PublicClientApplication(state.msalConfig)
    commit('SET_MSAL_ACTION', {
      key: 'msalInstance',
      value: msalInstance
    })
  },

  // SET ACTIVE LOCATION FROM SEARCH BAR
  async setActiveLocation({ commit }, { longitude, latitude }) {
    commit('SET_ACTIVE_LOCATION', { latitude, longitude })
  }
}
