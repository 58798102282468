import axios from 'axios'

export default {
  // GET INITIAL LOCATION
  setUserLocation({ commit }) {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async ({ coords }) => {
          const { latitude, longitude } = coords
          const baseUrl = process.env.GMAPS_BASE_URL
          const apiKey = process.env.GMAPS_API_KEY

          const url = `${baseUrl}?latlng=${latitude},${longitude}&key=${apiKey}`
          const {
            data: { results, error_message }
          } = await axios.get(url)
          commit('SET_USER_LOCATION', { latitude, longitude, address: results[0].formatted_address })
          resolve({ latitude, longitude })
        })
      } else {
        console.log('Browser not support')
      }
    })
  },

  // SET ACTIVE LOCATION FROM SEARCH BAR
  async setActiveLocation({ commit }, { longitude, latitude }) {
    commit('SET_ACTIVE_LOCATION', { latitude, longitude })
  }
}
