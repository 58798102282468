const colors = [
  { name: 'gray', color: 'rgb(107 114 128)', className: 'bg-gray-500' },
  { name: 'red', color: 'rgb(239 68 68)', className: 'bg-red-500' },
  { name: 'green', color: 'rgb(34 197 94)', className: 'bg-green-500' },
  { name: 'cyan', color: 'rgb(6 182 212)', className: 'bg-cyan-500' },
  { name: 'sky', color: 'rgb(14 165 233)', className: 'bg-sky-500' },
  { name: 'indigo', color: 'rgb(99 102 241)', className: 'bg-indigo-500' },
  { name: 'violet', color: 'rgb(139 92 246)', className: 'bg-violet-500' },
  { name: 'fuchsia', color: 'rgb(217 70 239)', className: 'bg-fuchsia-500' },
  { name: 'rose', color: 'rgb(244 63 94)', className: 'bg-rose-500' },
  { name: 'amber', color: 'rgb(245 158 11)', className: 'bg-amber-500' },
  { name: 'lime', color: 'rgb(132 204 22)', className: 'bg-lime-500' },
  { name: 'emerald', color: 'rgb(16 185 129)', className: 'bg-emerald-500' },
  { name: 'rose', color: '#f43f5e', className: 'bg-rode-500' }
]

const generateRandomColor = () => {
  const randomNumber = Math.floor(Math.random() * (colors.length - 1 + 1))
  return colors[randomNumber].color
}

export { generateRandomColor, colors }
