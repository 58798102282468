export default {
  SET_FILTER_VALUE(state, { criteria, newFilterCriteria }) {
    state.filterCriteria[criteria] = newFilterCriteria
  },

  SET_ACTIVE_FILTER_VALUE(state, { criteria, newActiveFilterCriteria }) {
    state.activeFilters[criteria] = newActiveFilterCriteria
  },

  SET_VALUE(state, { key, value }) {
    state[key] = value
  },

  SET_FILTER_VALUE_MUTATION(state, { criteria, criteriaValues }) {
    state.filterCriteria[criteria] = criteriaValues
  }
}
