<template>
  <div class="flex justify-start items-center border-t-2 h-8 my-4 text-sm text-gray-500">
    <p>
      Copyright © {{ date }} by

      <a href="https://cityvision.co.id/" class="text-gray-500 hover:text-gray-400" target="_blank"> City Vision {{ stage.toUpperCase() }} </a>
    </p>
  </div>
</template>

<script>
  export default {
    computed: {
      date() {
        return new Date().getFullYear()
      },
      stage() {
        return process.env.STAGE
      }
    }
  }
</script>

<style></style>
