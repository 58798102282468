<template>
  <div class="flex h-screen w-full justify-center bg-white">
    <div class="flex w-full lg:w-1/2 justify-center items-center space-y-8">
      <div class="w-full px-8 md:px-32 lg:px-24">
        <h1 class="text-gray-800 font-bold text-2xl mb-1">Welcome to BIPT</h1>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-universal-modal/dist/index.css'
  import '@vuepic/vue-datepicker/dist/main.css'
  import { mapMutations, mapActions } from 'vuex'

  export default {
    components: {},
    data() {
      return {
        account: undefined,
        containers: []
      }
    },
    async created() {},
    mounted() {},
    computed: {},
    methods: {
      // ...mapActions(['CREATE_MSAL_INSTANCE_ACTION']),
      ...mapMutations(['setAccessToken']),
      async SignIn() {},
      async SignOut() {}
    }
  }
</script>

<style scoped></style>
