import actions from '@/store/modules/layout/layoutActions'
import mutations from '@/store/modules/layout/layoutMutations'

export default {
  state: () => ({
    showModal: false,
    collapsedSidebar: true,
    activeTab: '/home'
  }),
  getters: {
    layout: (state) => {
      return () => {
        return {
          ...state,
          get activeTab() {
            return state.activeTab
          }
        }
      }
    }
  },
  actions,
  mutations
}
