const dotFormat = (value) => value.replace(/,/g, '.')

function convertToLocalCurrency(number) {
  let currency = ''
  let numberRev = number.toString().replace(/[.,]/g, '').split('').reverse().join('')
  for (let i = 0; i < numberRev.length; i++) if (i % 3 == 0) currency += numberRev.substr(i, 3) + '.'
  return (
    'Rp. ' +
    currency
      .split('', currency.length - 1)
      .reverse()
      .join('')
  )
}

function convertToNumber(currency) {
  return parseInt(currency.replace(/,.*|[^0-9]/g, ''), 10)
}

export { dotFormat, convertToNumber, convertToLocalCurrency }
