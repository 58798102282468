import { createStore } from 'vuex'

import asset from '@/store/modules/asset'
import filter from '@/store/modules/filter'
import layout from '@/store/modules/layout'
import map from '@/store/modules/map'
import msal from '@/store/modules/msal'

export default createStore({
  modules: {
    asset,
    filter,
    layout,
    map,
    msal
  },
  state: () => {},
  actions: {},
  mutations: {},
  plugins: []
})
