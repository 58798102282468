let firebaseConfig = {
  // CV-BIPT-TEMPORARY
  apiKey: 'AIzaSyCvj_GjIF1MrdAhe716H5rYIphqQRK26ww',
  authDomain: 'cv-bipt-temporary.firebaseapp.com',
  projectId: 'cv-bipt-temporary',
  storageBucket: 'cv-bipt-temporary.appspot.com',
  messagingSenderId: '633329316907',
  appId: '1:633329316907:web:effa65fa7c381f51d5a66e',
  measurementId: 'G-E2EYQBR0YJ'
}

if (process.env.STAGE === 'prod') {
  firebaseConfig = {
    // CV-DATA-COLLECTION -> PROBING
    apiKey: 'AIzaSyA_bd-Z9P4h2HnOOHR7uBkQjGPLjuKsMu4',
    authDomain: 'cv-data-collection.firebaseapp.com',
    projectId: 'cv-data-collection',
    storageBucket: 'cv-data-collection.appspot.com',
    messagingSenderId: '319922047328',
    appId: '1:319922047328:web:b979376192a883236ef605',
    measurementId: 'G-4J14KLG9VX'
  }
}

export { firebaseConfig }
