import _ from 'lodash'

import actions from '@/store/modules/asset/assetActions'
import mutations from '@/store/modules/asset/assetMutations'

export default {
  state: () => ({
    isLoading: false,
    error: null,
    items: {
      assets: [],
      industries: [],
      mediaCompetitors: [],
      industryList: []
    }
  }),
  getters: {
    // assets: (state) => {
    //   return () => {
    //     return {
    //       ...state.items,
    //       get isHaveAssets() {
    //         return state.items.assets.length > 0
    //       },
    //       get isLoading() {
    //         return state.isLoading
    //       },
    //       get industryListSorted() {
    //         return _.sortBy(state.items.industryList, 'level2')
    //       }
    //     }
    //   }
    // }
    assets: (state) => {
      return () => {
        return {
          ...state,
          get isHaveAssets() {
            return state.items.assets.length > 0
          },
          get isLoading() {
            return state.isLoading
          },
          get industryListSorted() {
            return _.sortBy(state.items.industryList, 'level2')
          }
        }
      }
    }
  },
  actions,
  mutations
}
