<template>
  <div class="sidebar flex flex-col fixed -inset-0 z-10 float-left p-0 h-screen" :style="{ width: sidebarWidth }">
    <div class="px-5 pt-4 font-bold text-gray-600" flex justify-center items-center>
      <h1 v-if="!collapsedSidebar">BI Tools</h1>
    </div>

    <div v-if="!collapsedSidebar" class="border-t-2 mx-5 my-4 pt-4">
      <p v-if="isSignin && !collapsedSidebar" class="text-xs font-bold text-gray-600">Hi.. {{ userName }}</p>
    </div>

    <SidebarLink v-if="isSignin" to="/home" icon="fas fa-map" @click="setActiveTabs('/home')">Map</SidebarLink>
    <SidebarLink v-if="isSignin" to="/upload" icon="fas fa-upload" @click="setActiveTabs('/upload')">Upload</SidebarLink>

    <SidebarLink v-if="isSignin" to="" icon="fas fa-sign-out" @click="signout()">Logout</SidebarLink>
    <SidebarLink v-else to="" icon="fas fa-right-to-bracket" @click="signin()">Login</SidebarLink>

    <span
      class="collapse-icon mx-4 my-4 flex justify-center items-center absolute bottom-0"
      :class="{ 'rotate-180': collapsedSidebar }"
      @click="setToggleSidebar"
    >
      <i class="fas fa-angle-double-left text-lg text-gray-600" />
    </span>
  </div>
</template>

<script>
  import SidebarLink from '@/components/sidebar/SidebarLink.vue'
  import { PublicClientApplication } from '@azure/msal-browser'
  import { mapMutations, mapActions } from 'vuex'
  export default {
    components: {
      SidebarLink
    },
    data() {
      return {
        account: undefined,
        containers: []
      }
    },
    async created() {
      this.$msalInstance = new PublicClientApplication(this.$store.state.msal.msalConfig)

      this.$emitter.on('login', (account) => {
        this.account = account
        this.SET_MSAL_VALUE_ACTION({ key: 'userAccount', value: account })
      })

      this.$emitter.on('logout', () => {
        console.log('logging out')
        this.SET_MSAL_VALUE_ACTION({ key: 'userAccount', value: null })
        this.account = undefined
      })
    },
    async mounted() {
      await this.$msalInstance.handleRedirectPromise()
      const accounts = this.$msalInstance.getAllAccounts()
      if (accounts.length == 0) {
        return
      }
      this.account = accounts[0]
      this.$emitter.emit('login', this.account)
      this.SET_MSAL_VALUE_ACTION({ key: 'userAccount', value: this.account })
    },
    watch: {
      // '$store.state.msal.userAccount': {
      //   handler: function () {
      //     console.log('refresh after login bang')
      //     // this.$router.go('/home')
      //   },
      //   immediate: true,
      //   deep: true
      // }
    },
    computed: {
      collapsedSidebar() {
        return this.$store.state.layout.collapsedSidebar
      },
      sidebarWidth() {
        const { collapsedSidebar } = this.$store.state.layout
        const SIDEBAR_WIDTH = 152
        const SIDEBAR_WIDTH_COLLAPSED = 45

        return `${collapsedSidebar ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`
      },
      isSignin() {
        console.log(this.$store.state.msal)
        if (this.$store.state.msal.userAccount) {
          return true
        }

        return false
      },
      userName() {
        const { msal } = this.$store.state
        if (msal.userAccount) {
          return msal.userAccount.name.split(' ')[0]
        }

        return ''
      }
    },
    methods: {
      ...mapActions(['SET_MSAL_VALUE_ACTION']),
      setToggleSidebar() {
        this.$store.dispatch('setToggleSideBar')
      },
      setActiveTabs(path) {
        this.$store.dispatch('setActiveTabs', { path })
      },
      async signin() {
        await this.$msalInstance
          .loginPopup({})
          .then(async () => {
            await this.$msalInstance.handleRedirectPromise()
            const myAccounts = await this.$msalInstance.getAllAccounts()
            this.account = myAccounts[0]
            this.$emitter.emit('login', this.account)
            console.log('SET_MSAL_VALUE_ACTION ke executed')
          })
          .catch((error) => {
            console.error(`error during authentication: ${error}`)
          })
      },
      async signout() {
        await this.$msalInstance
          .logout({})
          .then(() => {
            this.$emitter.emit('logout', 'logging out')
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }
</script>

<style scoped>
  .sidebar {
    transition: 0.3s ease;
  }
  .collapse-icon {
    transition: 0.2s linear;
  }
  .rotate-180 {
    transform: rotate(180deg);
    transition: 0.2s linear;
  }
</style>
