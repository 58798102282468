export default {
  // SIDE BAR & MODAL
  SET_TOGGLE_SIDE_BAR(state) {
    const { collapsedSidebar } = state
    state.collapsedSidebar = !collapsedSidebar
  },
  SET_TOGGLE_MODAL(state) {
    const { showModal } = state
    state.showModal = !showModal
  },
  SET_ACTIVE_TABS(state, { path }) {
    state.activeTab = path
  }
}
