import actions from '@/store/modules/filter/filterActions'
import mutations from '@/store/modules/filter/filterMutations'

export default {
  state: () => ({
    groupAssets: [],
    groupAssetsByDate: [],
    totalAssetsInDateRange: 0,
    filtersResults: [],
    filtersValue: [],
    filterCriteria: {
      locations: [],
      industries: [],
      mediaCompetitors: [],
      assetsTypes: [],
      oohTypes: []
    },
    selectedDate: {
      month: new Date().getMonth(),
      year: new Date().getFullYear()
    },
    hideUnselected: false
  }),
  getters: {
    filters: (state) => {
      return () => {
        return {
          ...state,
          get isHaveLocations() {
            return state.filterCriteria.locations.length > 0
          }
        }
      }
    }
  },
  actions,
  mutations
}
