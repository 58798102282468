<template>
  <div class="relative inline-block text-left w-auto mb-1" v-on:clickout="closed">
    <div
      class="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
    >
      <label class="inline-flex justify-between w-full items-center" @click="toggleOptions()">
        <span class="">{{ label }}</span>

        <div class="flex item-center justify-center h-5 w-5">
          <div :class="{ 'rotate-180': isOpen }">
            <i class="fas fa-caret-down text-2xl h-5 w-5 text-gray-500" />
          </div>
        </div>
      </label>

      <div class="flex flex-row">
        <div class="border h-max border-red-300 bg-red-300 mx-2" />

        <button class="h-full w-5 justify-center items-center flex" @click="setToggleValue">
          <div v-if="isAllSelected" class="h-full w-5 justify-center items-center flex">
            <i class="fas fa-check text-2xl h-5 w-5 text-gray-500" />
          </div>
          <div v-else :style="{ 'bapckground-color': selectedColor }" class="border h-full w-5 border-gray-400 justify-center items-center flex" />
        </button>
      </div>
    </div>

    <!-- OPTIONS LIST -->
    <div
      class="origin-top-right z-20 right-0 flex-wrap overflow-auto mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      v-if="isOpen"
    >
      <div v-if="showSearch" class="flex justify-center items-center">
        <input
          type="text"
          v-model="keyword"
          class="mt-1 block w-full text-gray-700 px-4 py-2 text-lg focus:outline-none"
          placeholder="Search here..."
          @change="filterByKeyword"
        />
        <button class="mt-1 px-6 py-2 flex justify-center items-center" @click="resetKeyword">
          <i class="fas fa-times text-xs text-red-400 hover:text-red-900" />
        </button>
      </div>

      <div v-for="(option, index) in filteredOptions" :key="option.name" class="py-1">
        <label class="items-center text-gray-400 justify-between flex px-4 py-2 text-xs hover:text-gray-900">
          <span class="ml-2">{{ this.showLabel(option.value) }}</span>
          <input type="checkbox" class="form-checkbox" :checked="option.isSelected" @click="setValue(index)" />
        </label>
      </div>
    </div>

    <!-- CHIPS  -->
    <div class="my-2 flex flex-wrap flex-row">
      <p v-if="isDisabled" class="text-xs text-red-400">{{ warningMessage }}</p>
      <chips v-for="chip in listChip" :key="chip.value" :label="chip.value" :criteria="criteria" :details="chip" />
    </div>
  </div>
</template>

<script>
  import 'clickout-event'
  import { mapActions } from 'vuex'

  import Chips from '@/components/Chips.vue'
  export default {
    components: {
      Chips
    },
    props: {
      label: { required: true, type: String, default: () => '' },
      criteria: { required: true, type: String, default: () => '' },
      options: { required: true, type: Array, default: () => [] },
      isDisabled: { required: false, type: Boolean, default: () => false },
      parent: { required: false, type: String, default: () => null },
      showSearch: { required: false, type: Boolean, default: () => false }
    },
    data() {
      return {
        keyword: '',
        filteredOptions: this.options,
        isOpen: false,
        isAllSelected: true,
        selectedColor: 'transparent',
        warningMessage: `Please select ${this.parent} first`
      }
    },
    watch: {
      keyword: function () {
        this.filterByKeyword()
      },
      options: function (newOptions) {
        this.filteredOptions = newOptions
      },
      '$store.state.filter.filterCriteria': {
        handler: function (newValue) {
          const selectedFilterCriteria = newValue[this.criteria]
          const totalOptionsValues = selectedFilterCriteria.length
          const totalActive = selectedFilterCriteria.filter((item) => item.isSelected).length
          this.selectedColor = totalActive < totalOptionsValues && totalActive !== 0 ? 'rgb(214 211 209)' : 'transparent'
          this.isAllSelected = totalOptionsValues === totalActive ? true : false
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      listChip() {
        const chips = []
        const filters = this.$store.state.filter.filterCriteria[this.criteria]
        filters.forEach((filter) => {
          filter.isSelected && chips.push(filter)
        })
        return chips
      }
    },
    methods: {
      ...mapActions(['SET_FILTER_VALUE_ACTION', 'SET_TOGGLE_FILTER_VALUE_ACTION']),

      setToggleValue() {
        this.SET_TOGGLE_FILTER_VALUE_ACTION({ criteria: this.criteria })
      },

      setValue(index) {
        this.SET_FILTER_VALUE_ACTION({ criteria: this.criteria, index })
      },

      toggleOptions() {
        const { isOpen } = this
        this.isOpen = !isOpen
        this.keyword = ''
      },

      filterByKeyword() {
        const { options, keyword } = this
        this.filteredOptions = options.filter((option) => option.label.toLowerCase().includes(keyword))
      },

      resetKeyword() {
        this.keyword = ''
      },

      closed() {
        this.isOpen = false
      },

      showLabel(string) {
        return string
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }
    }
  }
</script>

<style scoped>
  .rotate-180 {
    transform: rotate(180deg);
    transition: 0.2s linear;
  }

  .chip-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
  }
</style>
