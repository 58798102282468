import _ from 'lodash'
import moment from 'moment'

import { colors } from '@/helper/colors'
import geoProvincesDetails from '@/config/provinces'
import { groupingAssets, groupingAssetsByDate, checkSurveyDate, checkIsAssetHaveFilterResult } from '@/helper/grouping'
import { getMonthName } from '@/helper/calendar'

export default {
  SET_FILTERS_VALUE_ACTION({ commit }, { key, value }) {
    commit('SET_VALUE', { key, value })
  },

  /*
   * ACTION FOR INIT FILTER OPTIONS
   */
  GENERATE_FILTERS_OPTIONS_ACTION({ state, commit, rootState }) {
    const locationsDetails = []
    const assetTypes = []
    const oohTypes = []
    const mediaOwners = []
    const industryList = [
      {
        id: 1,
        label: 'Not Utilized',
        value: 'Not Utilized',
        isSelected: true,
        isAllChildrenActive: true,
        selectedColor: 'transparent',
        childrens: [{ label: 'Not Utilized', value: 'Not Utilized', isOpen: true, isSelected: true, parent: 'Not Utilized' }]
      },
      {
        id: 2,
        label: 'Not Found',
        value: 'Not Found',
        isSelected: true,
        isAllChildrenActive: true,
        selectedColor: 'transparent',
        childrens: [{ label: 'Not Found', value: 'Not Found', isOpen: true, isSelected: true, parent: 'Not Found' }]
      }
    ]

    rootState.asset.items.assets.forEach((asset) => {
      const { location, asset_type, competitor_details, advertiser } = asset

      // * add location option
      let { province, kabupaten_and_kotamadya: kabupatenAndKotamadya } = location
      kabupatenAndKotamadya = kabupatenAndKotamadya.toLowerCase()
      const geoLocationIndex = geoProvincesDetails.findIndex(({ name }) => name.toLowerCase() === kabupatenAndKotamadya)
      if (geoLocationIndex !== -1) {
        const { name, prov, shape } = geoProvincesDetails[geoLocationIndex]
        const geoColor = colors[geoLocationIndex].color
        const children = { label: name, value: name, isOpen: true, isSelected: true, shapes: shape, parent: prov, color: geoColor }

        const provIndex = locationsDetails.findIndex(({ value }) => value === prov)
        if (provIndex === -1) {
          locationsDetails.push({
            id: locationsDetails.length + 1,
            label: prov,
            value: prov,
            isSelected: true,
            isAllChildrenActive: true,
            selectedColor: 'transparent',
            childrens: [{ id: 1, ...children }]
          })
        } else {
          const childrenLocations = locationsDetails[provIndex].childrens
          const isChildrenExist = childrenLocations.findIndex(({ value }) => value.toLowerCase() === kabupatenAndKotamadya)
          if (isChildrenExist === -1) {
            const id = childrenLocations.length + 1
            childrenLocations.push({ id, ...children })
          }
        }
      } else {
        // ! ADD LOCATIONS THAT DOESNT HAVE GEOMETRY
        const children = {
          label: kabupatenAndKotamadya,
          value: kabupatenAndKotamadya,
          isOpen: true,
          isSelected: true,
          shapes: [],
          parent: province,
          color: 'transparent'
        }
        const provIndex = locationsDetails.findIndex(({ value }) => value === province)
        if (provIndex === -1) {
          locationsDetails.push({
            id: locationsDetails.length + 1,
            label: province,
            value: province,
            isSelected: true,
            isAllChildrenActive: true,
            selectedColor: 'transparent',
            childrens: [{ id: 1, ...children }]
          })
        } else {
          const childrenLocations = locationsDetails[provIndex].childrens
          const isChildrenExist = childrenLocations.findIndex(({ value }) => value.toLowerCase() === kabupatenAndKotamadya)
          if (isChildrenExist === -1) {
            const id = childrenLocations.length + 1
            childrenLocations.push({ id, ...children })
          }
        }
      }

      // * add asset types
      const { type_of_assets, format_level_1, format_level_2 } = asset_type
      const typeOfAsset = type_of_assets.toLowerCase()
      const assetTypeIndex = assetTypes.findIndex(({ value }) => value === typeOfAsset)
      if (assetTypeIndex === -1) {
        assetTypes.push({
          id: assetTypes.length + 1,
          isSelected: true,
          label: typeOfAsset,
          value: typeOfAsset,
          selectedColor: 'transparent'
        })
      }

      // * add OOH types
      const oohTypeParent = format_level_1.toLowerCase()
      const oohType = format_level_2.toLowerCase()
      const oohTypeIndex = oohTypes.findIndex(({ value }) => value === oohTypeParent)

      const oohChildren = { label: oohType, value: oohType, isOpen: true, isSelected: true, parent: oohTypeParent }
      if (oohTypeIndex === -1) {
        oohTypes.push({
          id: oohTypes.length + 1,
          isAllChildrenActive: true,
          isSelected: true,
          label: oohTypeParent,
          value: oohTypeParent,
          selectedColor: 'transparent',
          childrens: [{ ...oohChildren }]
        })
      } else {
        const oohTypeChildrenIndex = oohTypes[oohTypeIndex].childrens.findIndex(({ value }) => value.toLowerCase() === oohType)
        if (oohTypeChildrenIndex === -1) {
          oohTypes[oohTypeIndex].childrens.push({ ...oohChildren })
        }
      }

      // * add Media owner
      const screenOwnerName = competitor_details.competitor.toLowerCase()
      const mediaOwnerIndex = mediaOwners.findIndex((mediaOwner) => mediaOwner.value.toLowerCase() === screenOwnerName)
      if (mediaOwnerIndex === -1) {
        const id = mediaOwners.length + 1
        mediaOwners.push({
          id,
          label: screenOwnerName,
          value: screenOwnerName,
          isSelected: true
        })
      }

      // * add Industry
      const { brands, industry_level_1_parent, industry_level_2_details, company_group } = advertiser
      const industryParent = industry_level_1_parent.toLowerCase()
      const industryChild = industry_level_2_details.toLowerCase()
      const brand = brands.toLowerCase()
      const companyGroup = company_group.toLowerCase()
      const industryIndex = industryList.findIndex(({ value }) => value.toLowerCase() === industryParent)

      const industryChildren = {
        label: companyGroup,
        value: companyGroup,
        isOpen: true,
        isSelected: true,
        parent: industryParent,
        industry_level_2: industryChild
      }
      if (industryIndex === -1) {
        if (companyGroup !== '') {
          industryList.push({
            id: industryList.length + 1,
            isAllChildrenActive: true,
            isSelected: true,
            label: industryParent,
            value: industryParent,
            selectedColor: 'transparent',
            childrens: [{ ...industryChildren }]
          })
        }
      } else {
        const industryChildrenIndex = industryList[industryIndex].childrens.findIndex(({ value }) => value.toLowerCase() === companyGroup)
        if (industryChildrenIndex === -1) {
          industryList[industryIndex].childrens.push({ ...industryChildren })
        }
      }
    })

    // ! SET FILTERS OPTIONS
    commit('SET_FILTER_VALUE_MUTATION', { criteria: 'locations', criteriaValues: locationsDetails })
    commit('SET_FILTER_VALUE_MUTATION', { criteria: 'assetsTypes', criteriaValues: assetTypes })
    commit('SET_FILTER_VALUE_MUTATION', { criteria: 'oohTypes', criteriaValues: oohTypes })
    commit('SET_FILTER_VALUE_MUTATION', { criteria: 'mediaCompetitors', criteriaValues: mediaOwners })
    commit('SET_FILTER_VALUE_MUTATION', { criteria: 'industries', criteriaValues: industryList })
  },

  /*
   * FILTER THE ASSETS EVERYTIME USER CLICK / UNCLICK
   */
  RECHECK_FILTER_RESULTS_ACTION({ state, commit, dispatch, rootState }) {
    commit('SET_LOADING_FETCH_ACTION', { value: true })
    const { selectedDate } = rootState.filter
    const rawAssets = rootState.asset.items.assets
    const selectedFilterValues = {
      locations: [],
      assetsTypes: [],
      oohTypes: [],
      mediaCompetitors: [],
      industries: []
    }

    Object.entries(state.filterCriteria).forEach(([filterName, filterOptions], index) => {
      filterOptions.forEach(({ isSelected, value, childrens, isAllChildrenActive }) => {
        if (['locations', 'oohTypes', 'industries'].includes(filterName)) {
          !isAllChildrenActive && true
          childrens.forEach(({ isSelected, value }) => {
            if (isSelected && value !== '') {
              isSelected && selectedFilterValues[filterName].push(value.toLowerCase())
            }
          })
        }

        if (['assetsTypes', 'mediaCompetitors'].includes(filterName)) {
          isSelected && selectedFilterValues[filterName].push(value.toLowerCase())
        }
      })
    })

    let filtersResults = []

    // ! FILTER PROCESS
    const filteredData = rawAssets.filter((item) => {
      const { location, asset_type, competitor_details, advertiser, utilization, street_survey_details } = item
      const { survey_date } = street_survey_details

      const { kabupaten_and_kotamadya } = location
      const { type_of_assets, format_level_2 } = asset_type
      const { competitor } = competitor_details
      const { brands, company_group } = advertiser
      const { utilization_status, slot_status } = utilization

      const selectedFilterValuesArr = Object.entries(selectedFilterValues)
      const filterCriteriaArrLength = selectedFilterValuesArr.length
      let isAllSelectedLength = 0

      selectedFilterValuesArr.forEach((filterValues) => {
        const [filterName, filterOptions] = filterValues

        if (filterName === 'locations') {
          const isSelected = filterOptions.includes(kabupaten_and_kotamadya.toLowerCase())
          isSelected && isAllSelectedLength++
        }

        if (filterName === 'assetsTypes') {
          const isSelected = filterOptions.includes(type_of_assets.toLowerCase())
          isSelected && isAllSelectedLength++
        }

        if (filterName === 'oohTypes') {
          const isSelected = filterOptions.includes(format_level_2.toLowerCase())
          isSelected && isAllSelectedLength++
        }

        if (filterName === 'mediaCompetitors') {
          const isSelected = filterOptions.includes(competitor.toLowerCase())
          isSelected && isAllSelectedLength++
        }

        if (filterName === 'industries') {
          const isSelected = filterOptions.includes(company_group.toLowerCase())
          isSelected && isAllSelectedLength++

          // ['Utilized', 'Not Utilized', 'Not Found']
          const isNotUtilized = filterOptions.includes(slot_status.toLowerCase())
          isNotUtilized && isAllSelectedLength++
        }
      })

      if (isAllSelectedLength === filterCriteriaArrLength) {
        item.is_filter_result = true
        filtersResults.push(item)
      } else {
        item.is_filter_result = false
      }

      return item
    })

    const selectedUserDate = {
      month: getMonthName(selectedDate.month),
      year: String(selectedDate.year)
    }

    // * FOR MARKERS - use groupAssets
    let groupAssets = groupingAssets({ assets: filteredData, tag: 'RECHECK_FILTER_RESULTS_ACTION' })
    groupAssets = checkSurveyDate({ assets: groupAssets, selectedDate: selectedUserDate })
    groupAssets = checkIsAssetHaveFilterResult(groupAssets)
    const assetFromFiltersOnly = groupAssets.filter((asset) => asset.is_filter_result)

    dispatch('SET_FILTERS_VALUE_ACTION', { key: 'groupAssets', value: groupAssets })

    // * FOR TABLE CALCULATIONS - use filtersResults
    filtersResults = groupingAssets({ assets: filtersResults, tag: 'FOR TABLE CALCULATION' })
    filtersResults = checkSurveyDate({ assets: filtersResults, selectedDate: selectedUserDate })
    filtersResults = checkIsAssetHaveFilterResult(filtersResults)

    const filtersResultsTemp = filtersResults.filter((asset) => {
      const isAssetAvailableOnMap = assetFromFiltersOnly.filter(({ parent_ms_code }) => parent_ms_code === asset.parent_ms_code).length !== 0
      return asset.is_filter_result === true && isAssetAvailableOnMap
    })
    // console.log(JSON.stringify(filtersResultsTemp))
    filtersResults = filtersResults.filter((asset) => asset.is_filter_result)

    dispatch('SET_FILTERS_VALUE_ACTION', { key: 'filtersResults', value: filtersResultsTemp.length === 0 ? filtersResults : filtersResultsTemp })
    commit('SET_LOADING_FETCH_ACTION', { value: false })
  },

  RESET_ALL_FILTERS_VALUE_ACTION({ state, commit, dispatch }) {
    let filters = { ...state.filterCriteria }
    Object.entries(filters).forEach(([filterName]) => {
      if (['locations', 'industries', 'oohTypes'].includes(filterName)) {
        filters[filterName] = filters[filterName].map((parent) => {
          parent.isSelected = false
          parent.isAllChildrenActive = false
          parent.selectedColor = 'transparent'
          parent.childrens = parent.childrens.map((children) => {
            return {
              ...children,
              isSelected: false
            }
          })

          return parent
        })
      } else {
        filters[filterName] = filters[filterName].map((parent) => {
          parent.isSelected = false

          return parent
        })
      }
    })

    commit('SET_VALUE', { key: 'filterCriteria', value: filters })
    dispatch('RECHECK_FILTER_RESULTS_ACTION')
  },

  /*
   * ONE LEVEL DROPDOWN SECTION
   * function to add & remove the parent(industry) & children(client) dropdown case
   * the add & remove will be based on index element (parent / children) in state.filterCriteria
   */

  SET_FILTER_VALUE_ACTION({ state, commit, dispatch }, { criteria, index }) {
    let filterCriteria = state.filterCriteria[criteria]
    const selectedFilter = filterCriteria[index]

    const previousState = selectedFilter.isSelected
    selectedFilter.isSelected = !previousState

    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: filterCriteria })
    dispatch('RECHECK_FILTER_RESULTS_ACTION', { criteria })
  },

  SET_TOGGLE_FILTER_VALUE_ACTION({ state, commit, dispatch }, { criteria, isMultiLevelOptions }) {
    let filterCriteria = [...state.filterCriteria[criteria]]

    if (isMultiLevelOptions) {
      const isAllSelected = filterCriteria.filter((item) => item.isSelected).length === filterCriteria.length
      const isAllUnSelected = filterCriteria.filter((item) => !item.isSelected).length === filterCriteria.length
      let newSelectedState = isAllSelected && false

      if ((!isAllSelected && !isAllUnSelected) || isAllUnSelected) {
        newSelectedState = true
      } else {
        newSelectedState = false
      }

      filterCriteria = filterCriteria.map((parent) => {
        const previousState = parent.isAllChildrenActive
        parent.childrens = parent.childrens.map((children) => ({ ...children, isSelected: newSelectedState }))

        const totalActiveChildrens = parent.childrens.filter(({ isSelected }) => isSelected).length
        const totalChildrens = parent.childrens.length
        parent.isSelected = totalActiveChildrens > 0
        parent.isAllChildrenActive = totalActiveChildrens === totalChildrens ? true : false
        parent.selectedColor = totalActiveChildrens < totalChildrens && totalActiveChildrens !== 0 ? 'rgb(214 211 209)' : 'transparent'

        return parent
      })
    } else {
      const isAllSelected = filterCriteria.filter((item) => item.isSelected).length === filterCriteria.length
      const newSelectedState = isAllSelected ? false : true

      filterCriteria = filterCriteria.map((item) => ({ ...item, isSelected: newSelectedState }))
    }
    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: filterCriteria })
    dispatch('RECHECK_FILTER_RESULTS_ACTION', { criteria })
  },

  REMOVE_CHIPS_ACTION({ state, commit, dispatch }, { criteria, value }) {
    let filterCriteria = state.filterCriteria[criteria]
    const valueIndex = _.findIndex(filterCriteria, ['value', value.value])
    const selectedFilter = filterCriteria[valueIndex]

    const previousState = selectedFilter.isSelected
    selectedFilter.isSelected = !previousState

    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: filterCriteria })
    dispatch('RECHECK_FILTER_RESULTS_ACTION', { criteria })
  },

  /*
   * MULTI LEVEL DROPDOWN SECTION
   * function to add & remove the parent(industry) & children(client) dropdown case
   * the add & remove will be based on index element (parent / children) in state.filterCriteria
   */
  SET_MULTI_VALUE_CHILDREN_ACTION({ state, commit, dispatch }, { criteria, parentIndex, childrenIndex }) {
    let filterCriteria = state.filterCriteria[criteria]
    const selectedParent = filterCriteria[parentIndex]
    const selectedChildren = selectedParent.childrens[childrenIndex]

    const previousStateChildren = selectedChildren.isSelected
    selectedChildren.isSelected = !previousStateChildren

    const totalActiveChildrens = selectedParent.childrens.filter(({ isSelected }) => isSelected).length
    const totalChildrens = selectedParent.childrens.length

    selectedParent.isSelected = totalActiveChildrens > 0
    selectedParent.isAllChildrenActive = totalActiveChildrens === totalChildrens ? true : false
    selectedParent.selectedColor = totalActiveChildrens < totalChildrens && totalActiveChildrens !== 0 ? 'rgb(214 211 209)' : 'transparent'

    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: filterCriteria })
    dispatch('RECHECK_FILTER_RESULTS_ACTION', { criteria })
  },

  SET_MULTI_VALUE_PARENT_ACTION({ state, commit, dispatch }, { criteria, parentIndex }) {
    const filterCriteria = state.filterCriteria[criteria]
    const selectedParent = filterCriteria[parentIndex]
    const previousState = selectedParent.isAllChildrenActive

    selectedParent.childrens = selectedParent.childrens.map((children) => ({ ...children, isSelected: !previousState }))
    selectedParent.isAllChildrenActive = !previousState
    selectedParent.isSelected = !previousState
    selectedParent.selectedColor = 'transparent'

    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: filterCriteria })
    dispatch('RECHECK_FILTER_RESULTS_ACTION', { criteria })
  },

  REMOVE_MULTI_VALUE_CHIPS_ACTION({ state, commit, dispatch }, { criteria, value }) {
    let newFilterCriteria = state.filterCriteria[criteria]
    const parentIndex = _.findIndex(newFilterCriteria, ['value', value.parent])
    const selectedParent = newFilterCriteria[parentIndex]

    selectedParent.childrens = selectedParent.childrens.map((children) => {
      if (children.label === value.label) {
        children.isSelected = false
      }
      return children
    })

    const totalActiveChildrens = selectedParent.childrens.filter(({ isSelected }) => isSelected).length
    const totalChildrens = selectedParent.childrens.length

    selectedParent.isSelected = totalActiveChildrens > 0
    selectedParent.isAllChildrenActive = totalActiveChildrens === totalChildrens ? true : false
    selectedParent.selectedColor = totalActiveChildrens < totalChildrens && totalActiveChildrens !== 0 ? 'rgb(214 211 209)' : 'transparent'

    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: newFilterCriteria })
    dispatch('RECHECK_FILTER_RESULTS_ACTION', { criteria })
  },

  /*
   * ACTION A TOGGLE BOOLEAN FOR SHOWING DROPDOWN
   */
  TOGGLE_MULTI_DROPDOWN_ACTION({ state, commit }, { criteria, parentIndex }) {
    let filterCriteria = state.filterCriteria[criteria]
    const selectedParent = filterCriteria[parentIndex]
    const previousState = selectedParent.isOpen
    selectedParent.isOpen = !previousState

    commit('SET_FILTER_VALUE_MUTATION', { criteria, criteriaValues: filterCriteria })
  },

  /*
   * ACTION A TOGGLE SELECTED ASSETS
   */
  TOGGLE_SELECTED_ASSET_ACTION({ state, commit, dispatch }, { assetDetails }) {
    const { filtersResults, groupAssets } = state
    const { parent_ms_code } = assetDetails

    // ! enhance filterResults for table calculation
    const filtersResultsIndex = filtersResults.findIndex((filterAsset) => filterAsset.parent_ms_code === parent_ms_code)
    let newFiltersResults = []

    if (filtersResultsIndex === -1) {
      newFiltersResults = filtersResults
      newFiltersResults.push(assetDetails)
    } else {
      newFiltersResults = filtersResults.filter((asset) => asset.parent_ms_code !== parent_ms_code)
    }
    dispatch('SET_FILTERS_VALUE_ACTION', { key: 'filtersResults', value: newFiltersResults })

    // ! enhance groupAssets for make markers
    const groupAssetsIndex = groupAssets.findIndex((asset) => asset.parent_ms_code === parent_ms_code)
    const newGroupAssets = groupAssets

    newGroupAssets[groupAssetsIndex].is_filter_result = !newGroupAssets[groupAssetsIndex].is_filter_result
    dispatch('SET_FILTERS_VALUE_ACTION', { key: 'groupAssets', value: newGroupAssets })
  }
}
