<template>
  <!-- LEVEL 1 -->
  <div class="relative inline-block text-left w-auto mb-1" v-on:clickout="closed">
    <div
      class="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
    >
      <label class="inline-flex justify-between w-full items-center" @click="toggleOptions('root')">
        <span class="">{{ label }}</span>

        <div class="flex item-center justify-center h-5 w-5">
          <div :class="{ 'rotate-180': isOpen }">
            <i class="fas fa-caret-down text-2xl h-5 w-5 text-gray-500" />
          </div>
        </div>
      </label>

      <div class="flex flex-row">
        <div class="border h-max border-red-300 bg-red-300 mx-2" />

        <button class="h-full w-5 justify-center items-center flex" @click="setToggleValue">
          <div v-if="isAllSelected" class="h-full w-5 justify-center items-center flex">
            <i class="fas fa-check text-2xl h-5 w-5 text-gray-500" />
          </div>
          <div v-else :style="{ 'background-color': selectedColor }" class="border h-full w-5 border-gray-400 justify-center items-center flex" />
        </button>
      </div>
    </div>

    <!-- OPTIONS LIST LEVEL 1-->
    <div
      class="h-auto origin-top-right z-20 right-0 flex-wrap overflow-auto mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      v-if="isOpen"
    >
      <!-- SEARCH BAR -->
      <div class="flex justify-center items-center">
        <input
          type="text"
          v-model="keyword"
          class="mt-1 block w-full text-gray-700 px-4 py-2 text-lg focus:outline-none"
          placeholder="Search here..."
          @change="filterByKeyword"
        />
        <button class="mt-1 px-6 py-2 flex justify-center items-center" @click="resetKeyword">
          <i class="fas fa-times text-xs text-red-400 hover:text-red-900" />
        </button>
      </div>

      <div v-for="(option, parentIndex) in filteredOptions" :key="option.name" class="py-1">
        <!-- LEVEL 2 -->
        <div class="flex flex-row">
          <label
            class="inline-flex justify-between w-full items-center text-gray-400 pl-4 py-2 text-xs hover:text-gray-900"
            @click="toggleOptions('parent', parentIndex)"
          >
            <span class="ml-2">{{ this.showLabel(option.label) }}</span>

            <div class="flex item-center justify-center h-5 w-5">
              <div v-if="isOthersOption(option)" :class="{ 'rotate-180': option.isOpen }">
                <i class="fas fa-caret-down text-2xl h-5 w-5 text-gray-500" />
              </div>
            </div>
          </label>

          <div class="flex flex-row pr-4 py-2">
            <div class="border h-max border-red-300 bg-red-300 mx-2" />

            <button class="h-full w-5 justify-center items-center flex" @click="setParentValue(parentIndex)">
              <div v-if="option.isAllChildrenActive" class="h-full w-5 justify-center items-center flex">
                <i class="fas fa-check text-2xl h-5 w-5 text-gray-500" />
              </div>
              <div v-else :style="{ 'background-color': option.selectedColor }" class="border h-full w-5 border-gray-400 justify-center items-center flex" />
            </button>
          </div>
        </div>

        <!-- OPTIONS LIST LEVEL 2-->
        <div v-if="isOthersOption(option)">
          <div
            class="origin-top-right right-0 flex-wrap bg-gray-100 shadow overflow-auto mt-2 mb-3 w-full ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
            v-if="option.isOpen"
          >
            <div v-for="(subOptions, childrenIndex) in option.childrens" :key="subOptions.label" class="py-1">
              <label class="flex justify-between items-center text-gray-400 px-4 py-2 text-xs hover:text-gray-900">
                <span class="ml-8">{{ this.showLabel(subOptions.label) }}</span>
                <div class="h-max w-5 ring-red-400 justify-center items-center flex">
                  <input type="checkbox" class="form-checkbox" :checked="subOptions.isSelected" @click="setChildrenValue(parentIndex, childrenIndex)" />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CHIPS  -->
    <div class="my-2 flex flex-wrap flex-row">
      <p v-if="isDisabled" class="text-xs text-red-400">{{ warningMessage }}</p>
      <chips v-for="chip in chips" :key="chip.value" :label="chip.value" :criteria="criteria" :details="chip" :multiDropdown="true" />
    </div>
  </div>
</template>

<script>
  import 'clickout-event'
  import { mapActions } from 'vuex'
  import _ from 'lodash'

  import Chips from '@/components/Chips.vue'
  export default {
    components: {
      Chips
    },
    props: {
      label: { required: true, type: String, default: () => '' },
      criteria: { required: true, type: String, default: () => '' },
      options: { required: true, type: Array, default: () => [] },
      isDisabled: { required: false, type: Boolean, default: () => false },
      parent: { required: false, type: String, default: () => null }
    },
    data() {
      return {
        keyword: '',
        filteredOptions: this.options,
        isOpen: false,
        isAllSelected: true,
        selectedColor: 'transparent',
        warningMessage: `Please select ${this.parent} first`
      }
    },
    watch: {
      keyword: function () {
        this.filterByKeyword()
      },
      options: function (newOptions) {
        this.filteredOptions = newOptions
      },
      '$store.state.filter.filterCriteria': {
        handler: function (newValue) {
          const selectedFilterCriteria = newValue[this.criteria]
          const totalParents = selectedFilterCriteria.length
          const totalParentsActive = selectedFilterCriteria.filter((item) => item.isSelected).length
          this.selectedColor = totalParentsActive < totalParents && totalParentsActive !== 0 ? 'rgb(214 211 209)' : 'transparent'
          this.isAllSelected = totalParents === totalParentsActive ? true : false
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      chips() {
        const { criteria } = this
        const filter = this.$store.state.filter.filterCriteria[criteria]
        const chips = []
        filter.forEach((location) => {
          location.childrens.forEach((children) => {
            children.isSelected && chips.push(children)
          })
        })
        return chips
      }
    },
    methods: {
      ...mapActions(['SET_MULTI_VALUE_CHILDREN_ACTION', 'SET_MULTI_VALUE_PARENT_ACTION', 'TOGGLE_MULTI_DROPDOWN_ACTION', 'SET_TOGGLE_FILTER_VALUE_ACTION']),

      setToggleValue() {
        this.SET_TOGGLE_FILTER_VALUE_ACTION({ criteria: this.criteria, isMultiLevelOptions: true })
      },

      setChildrenValue(parentIndex, childrenIndex) {
        this.SET_MULTI_VALUE_CHILDREN_ACTION({
          criteria: this.criteria,
          parentIndex,
          childrenIndex
        })
      },

      setParentValue(parentIndex) {
        this.SET_MULTI_VALUE_PARENT_ACTION({
          criteria: this.criteria,
          parentIndex
        })
      },

      toggleOptions(type, parentIndex) {
        const { isOpen, criteria } = this
        if (type === 'root') {
          this.isOpen = !isOpen
          this.keyword = ''
        }

        if (type === 'parent') {
          this.TOGGLE_MULTI_DROPDOWN_ACTION({ criteria, parentIndex })
        }
      },

      filterByKeyword() {
        const { options, keyword } = this
        this.filteredOptions = options.filter((option) => option.label.toLowerCase().includes(keyword))
      },

      resetKeyword() {
        this.keyword = ''
      },

      closed() {
        this.isOpen = false
      },

      isOthersOption(parent) {
        const { value } = parent
        return value !== 'Others' || value !== 'Not Utilized'
      },

      showLabel(string) {
        return string
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }
    }
  }
</script>

<style scoped>
  .rotate-180 {
    transform: rotate(180deg);
    transition: 0.2s linear;
  }

  .chip-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
  }
</style>
