<template>
  <div class="chip-container justify-center items-center m-1 px-2 py-1 font-medium rounded-full" :class="backgroundColor">
    <p class="text-xs font-normal leading-none text-gray-200">{{ this.showLabel(label) }}</p>
    <button class="flex justify-center items-center pl-2" @click="setValue()">
      <i class="fas fa-times text-xs text-gray-200" />
    </button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      label: { required: true, type: String, default: () => '' },
      criteria: { required: true, type: String, default: () => '' },
      details: { required: true, type: Object, default: () => {} },
      multiDropdown: { required: false, type: Boolean, default: () => false }
    },
    computed: {
      backgroundColor: function () {
        const colors = ['gray', 'red', 'green', 'blue', 'indigo', 'purple', 'pink']
        const randomNumber = Math.floor(Math.random() * (colors.length - 1 + 1))
        return `bg-${colors[randomNumber]}-500`
      }
    },
    methods: {
      ...mapActions(['REMOVE_CHIPS_ACTION', 'REMOVE_MULTI_VALUE_CHIPS_ACTION']),
      setValue() {
        const { criteria, details } = this
        const payload = { criteria, value: details }
        if (this.multiDropdown) {
          this.REMOVE_MULTI_VALUE_CHIPS_ACTION(payload)
          return true
        }
        this.REMOVE_CHIPS_ACTION(payload)
      },
      showLabel(string) {
        return string
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }
    }
  }
</script>

<style></style>
