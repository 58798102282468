<template>
  <router-link
    class="link container flex items-center py-2 px-4"
    :to="to"
    :style="[collapsedSidebar ? 'justify-content: center' : 'justify-content: flex-start']"
    :class="{ active: isActive }"
  >
    <div class="flex justify-center w-8 h-full items-center">
      <i class="icon text-xs text-gray-300" :class="[icon]" />
    </div>

    <transition name="fade">
      <span v-if="!collapsedSidebar">
        <div class="uppercase text-xs flex justify-center items-center font-bold mx-2 text-gray-500 hover:text-gray-400" :class="{ active: isActive }">
          <slot />
        </div>
      </span>
    </transition>
  </router-link>
</template>

<script>
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    props: {
      to: { type: String, required: true },
      icon: { type: String, required: true }
    },
    setup(props) {
      const route = useRoute()
      const isActive = computed(() => route.path === props.to)

      return { isActive }
    },
    data() {
      const route = useRoute()
      return {
        active: 'text-blue-300',
        iconColor: 'red'
      }
    },
    computed: {
      ...mapGetters(['layout']),
      collapsedSidebar() {
        return this.$store.state.layout.collapsedSidebar
      },
      isActiveTab() {
        const route = useRoute()

        return this.layout().activeTab === this.to
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .active {
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .router-link-active {
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
</style>
