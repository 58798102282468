<template>
  <div class="flex flex-wrap relative">
    <Spinner :isShow="isLoading" />

    <Modal v-model="isShowodal" :close="closeModal">
      <div class="modal">
        <div class="justify-between items-center flex flex-row">
          <p v-if="response !== null" class="mb-2 text-gray-700">Success add {{ response.results.length }} assets</p>
          <p v-if="error" class="mb-2 text-gray-700">{{ error }}</p>

          <button @click="closeModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2">
            <i class="fas fa-times text-lg text-white" />
          </button>
        </div>
      </div>
    </Modal>

    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words bg-white h-screen mb-6 shadow-lg rounded p-4">
        <div class="flex">
          <div class="mb-3 w-96">
            <label for="formFile" class="form-label inline-block mb-2 text-gray-700">Please upload the BI CSV in here</label>
            <Datepicker class="mb-2" v-model="month" monthPicker />

            <input
              class="form-control block mb-2 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              type="file"
              id="formFile"
              @change="handleFileUpload"
            />
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" :disabled="isDisabled" @click="uploadFile()">
              Upload
            </button>
          </div>
        </div>
        <a class="text-xs text-red-400" href="/files/bipt-template.csv" download
          >Please download the CSV template here. The row with the <span class="text-yellow-400">Yellow</span> background is the data example</a
        >
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-universal-modal/dist/index.css'
  import '@vuepic/vue-datepicker/dist/main.css'
  import Datepicker from '@vuepic/vue-datepicker'

  import axios from 'axios'
  import Spinner from '@/components/Spinner.vue'
  import { ref } from 'vue'

  import { getMonthName } from '../helper/calendar'

  export default {
    components: {
      Spinner,
      Datepicker
    },
    data() {
      return {
        file: null,
        isLoading: false,
        error: null,
        isShowodal: false,
        response: null,
        month: ref({
          month: new Date().getMonth(),
          year: new Date().getFullYear()
        })
      }
    },
    computed: {
      isDisabled: function () {
        return this.file === null
      }
    },
    methods: {
      handleFileUpload(event) {
        this.file = event.target.files[0]
      },
      async uploadFile() {
        try {
          const month = getMonthName(this.month.month)
          const year = this.month.year

          this.isLoading = true
          this.response = null

          const formData = new FormData()

          formData.append('file_asset', this.file)
          formData.append('month', month)
          formData.append('year', year)

          const response = await axios.post(`${process.env.BASE_URL_API}/asset`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          this.response = response.data
        } catch (err) {
          this.error = err
        } finally {
          this.isShowodal = true
          this.isLoading = false
        }
      },
      showModal() {
        this.isShowodal = true
      },
      closeModal() {
        this.isShowodal = false
      }
    }
  }
</script>

<style scoped>
  .modal {
    width: 50%;
    height: auto;
    padding: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 20px;
    text-align: center;
    overflow-y: auto;
  }

  .modal::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  .modal::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
  }

  .modal::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
  }
</style>
