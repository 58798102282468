export default {
  // SIDE BAR & MODAL
  setToggleSideBar({ commit, state }) {
    commit('SET_TOGGLE_SIDE_BAR', {
      collapsedSidebar: !state.collapsedSidebar
    })
  },
  setToggleModal({ commit, state }) {
    commit('SET_TOGGLE_MODAL', {
      showModal: !state.showModal
    })
  },
  setActiveTabs({ commit }, { path }) {
    commit('SET_ACTIVE_TABS', { path })
  }
}
