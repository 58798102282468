import firebase from 'firebase/app'
import axios from 'axios'
import 'firebase/firestore'
import _ from 'lodash'

const dummyAssets = [
  {
    id: 2909,
    type: 'digital',
    location: {
      island: 'Jawa',
      route: 'tangerang - mh thamrin',
      lng: '106.6339949',
      lat: '-6.194922954',
      kabupaten_and_kotamadya: 'kota tangerang',
      area: 'tangerang - focus road',
      province: 'jawa barat',
      city: 'tangerang'
    },
    parent_ms_code: '2642',
    parent_duplicate_ms_code: '0',
    parent_master_code: 'tg-sd-d-7-1',
    latest_survey_date: '06-Dec-2022',
    latest_survey_screen_onwers_history: [
      {
        id: 1,
        name: 'ming',
        is_filter_result: true,
        slots: [
          {
            id: 1,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-1',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: '-',
            industries_children: '-',
            brands: 'No Client',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'snack video'
          },
          {
            id: 2,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-2',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'government',
            industries_children: 'ngos',
            brands: 'benihbaik.com',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'benihbaik.com'
          },
          {
            id: 3,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-3',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'financials',
            industries_children: 'multifinance',
            brands: 'home credit',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'home credit'
          },
          {
            id: 4,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-4',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'fmcg',
            industries_children: 'food & beverage',
            brands: 'coca cola',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'coca cola'
          }
        ]
      },
      {
        id: 2,
        name: 'eye',
        is_filter_result: true,
        slots: [
          {
            id: 1,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-13',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: '-',
            industries_children: '-',
            brands: 'No Client',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'snack video'
          },
          {
            id: 2,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-14',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'government',
            industries_children: 'ngos',
            brands: 'benihbaik.com',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'benihbaik.com'
          },
          {
            id: 3,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-15',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'financials',
            industries_children: 'multifinance',
            brands: 'home credit',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'home credit'
          },
          {
            id: 4,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-16',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'fmcg',
            industries_children: 'food & beverage',
            brands: 'coca cola',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'coca cola'
          }
        ]
      }
    ],
    active_survey_date: '06-Dec-2022',
    active_survey_screen_onwers_history: [
      {
        id: 1,
        name: 'ming',
        is_filter_result: true,
        slots: [
          {
            id: 1,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-1',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: '-',
            industries_children: '-',
            brands: 'No Client',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'snack video'
          },
          {
            id: 2,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-2',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'government',
            industries_children: 'ngos',
            brands: 'benihbaik.com',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'benihbaik.com'
          },
          {
            id: 3,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-3',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'financials',
            industries_children: 'multifinance',
            brands: 'home credit',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'home credit'
          },
          {
            id: 4,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-4',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'fmcg',
            industries_children: 'food & beverage',
            brands: 'coca cola',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'coca cola'
          }
        ]
      },
      {
        id: 2,
        name: 'eye',
        is_filter_result: true,
        slots: [
          {
            id: 1,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-13',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: '-',
            industries_children: '-',
            brands: 'No Client',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'snack video'
          },
          {
            id: 2,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-14',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'government',
            industries_children: 'ngos',
            brands: 'benihbaik.com',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'benihbaik.com'
          },
          {
            id: 3,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-15',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'financials',
            industries_children: 'multifinance',
            brands: 'home credit',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'home credit'
          },
          {
            id: 4,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-16',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'fmcg',
            industries_children: 'food & beverage',
            brands: 'coca cola',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'coca cola'
          }
        ]
      }
    ],
    survey_date: '06-Dec-2022',
    asset_type_details: {
      format_level_1: 'roadside',
      type_of_assets: 'digital',
      orientation: 'vertical',
      format_level_2: 'roadside',
      format_level_3: 'roadside billboard'
    },
    asset_type: {
      format_level_1: 'roadside',
      type_of_assets: 'digital',
      orientation: 'vertical',
      format_level_2: 'roadside',
      format_level_3: 'roadside billboard'
    },
    markerColor: null,
    is_user_selected: false,
    survey_histories: [
      {
        survey_date: '03-Oct-2022',
        screen_owners: [
          {
            id: 1,
            name: 'ming',
            is_filter_result: true,
            slots: [
              {
                id: 1,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-5',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 2,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-6',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'tech',
                industries_children: 'ott streaming video',
                brands: 'mola tv',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'mola tv'
              },
              {
                id: 3,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-7',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'financials',
                industries_children: 'multifinance',
                brands: 'home credit',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'home credit'
              },
              {
                id: 4,
                id_firebase: 480,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-8',
                duplicate_ms_code: '0',
                status: 'Not Utilized',
                prices: [[0, 0, 0, 0]],
                industries: null,
                industries_children: '',
                brands: null,
                is_filter_result: true,
                utilization_status: 'Not Utilized',
                subProduct: ''
              }
            ]
          },
          {
            id: 2,
            name: 'eye',
            is_filter_result: true,
            slots: [
              {
                id: 1,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-17',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 2,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-18',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'tech',
                industries_children: 'ott streaming video',
                brands: 'mola tv',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'mola tv'
              },
              {
                id: 3,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-19',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'financials',
                industries_children: 'multifinance',
                brands: 'home credit',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'home credit'
              },
              {
                id: 4,
                id_firebase: 492,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-20',
                duplicate_ms_code: '0',
                status: 'Not Utilized',
                prices: [[0, 0, 0, 0]],
                industries: null,
                industries_children: '',
                brands: null,
                is_filter_result: true,
                utilization_status: 'Not Utilized',
                subProduct: ''
              }
            ]
          }
        ]
      },
      {
        survey_date: '04-Oct-2022',
        screen_owners: [
          {
            id: 1,
            name: 'ming',
            is_filter_result: true,
            slots: [
              {
                id: 1,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-9',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 2,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-10',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 3,
                id_firebase: 483,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-11',
                duplicate_ms_code: '0',
                status: 'Not Utilized',
                prices: [[0, 0, 0, 0]],
                industries: null,
                industries_children: '',
                brands: null,
                is_filter_result: true,
                utilization_status: 'Not Utilized',
                subProduct: ''
              },
              {
                id: 4,
                id_firebase: 484,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-12',
                duplicate_ms_code: '0',
                status: 'Not Utilized',
                prices: [[0, 0, 0, 0]],
                industries: null,
                industries_children: '',
                brands: null,
                is_filter_result: true,
                utilization_status: 'Not Utilized',
                subProduct: ''
              }
            ]
          },
          {
            id: 2,
            name: 'eye',
            is_filter_result: true,
            slots: [
              {
                id: 1,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-21',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 2,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-22',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 3,
                id_firebase: 495,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-23',
                duplicate_ms_code: '0',
                status: 'Not Utilized',
                prices: [[0, 0, 0, 0]],
                industries: null,
                industries_children: '',
                brands: null,
                is_filter_result: true,
                utilization_status: 'Not Utilized',
                subProduct: ''
              },
              {
                id: 4,
                id_firebase: 496,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-24',
                duplicate_ms_code: '0',
                status: 'Not Utilized',
                prices: [[0, 0, 0, 0]],
                industries: null,
                industries_children: '',
                brands: null,
                is_filter_result: true,
                utilization_status: 'Not Utilized',
                subProduct: ''
              }
            ]
          }
        ]
      },
      {
        survey_date: '06-Dec-2022',
        screen_owners: [
          {
            id: 1,
            name: 'ming',
            is_filter_result: true,
            slots: [
              {
                id: 1,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-1',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: '-',
                industries_children: '-',
                brands: 'No Client',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'snack video'
              },
              {
                id: 2,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-2',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 3,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-3',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'financials',
                industries_children: 'multifinance',
                brands: 'home credit',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'home credit'
              },
              {
                id: 4,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-4',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'fmcg',
                industries_children: 'food & beverage',
                brands: 'coca cola',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'coca cola'
              }
            ]
          },
          {
            id: 2,
            name: 'eye',
            is_filter_result: true,
            slots: [
              {
                id: 1,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-13',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: '-',
                industries_children: '-',
                brands: 'No Client',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'snack video'
              },
              {
                id: 2,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-14',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'government',
                industries_children: 'ngos',
                brands: 'benihbaik.com',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'benihbaik.com'
              },
              {
                id: 3,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-15',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'financials',
                industries_children: 'multifinance',
                brands: 'home credit',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'home credit'
              },
              {
                id: 4,
                id_firebase: 473,
                ms_code: '2642',
                master_code: 'tg-sd-d-7-16',
                duplicate_ms_code: '0',
                status: 'Utilized',
                prices: [[0, 0, 0, 0]],
                industries: 'fmcg',
                industries_children: 'food & beverage',
                brands: 'coca cola',
                is_filter_result: true,
                utilization_status: 'Utilized',
                subProduct: 'coca cola'
              }
            ]
          }
        ]
      }
    ],
    screen_owners: [
      {
        id: 1,
        name: 'ming',
        is_filter_result: true,
        slots: [
          {
            id: 1,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-1',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: '-',
            industries_children: '-',
            brands: 'No Client',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'snack video'
          },
          {
            id: 2,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-2',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'government',
            industries_children: 'ngos',
            brands: 'benihbaik.com',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'benihbaik.com'
          },
          {
            id: 3,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-3',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'financials',
            industries_children: 'multifinance',
            brands: 'home credit',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'home credit'
          },
          {
            id: 4,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-4',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'fmcg',
            industries_children: 'food & beverage',
            brands: 'coca cola',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'coca cola'
          }
        ]
      },
      {
        id: 2,
        name: 'eye',
        is_filter_result: true,
        slots: [
          {
            id: 1,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-13',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: '-',
            industries_children: '-',
            brands: 'No Client',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'snack video'
          },
          {
            id: 2,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-14',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'government',
            industries_children: 'ngos',
            brands: 'benihbaik.com',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'benihbaik.com'
          },
          {
            id: 3,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-15',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'financials',
            industries_children: 'multifinance',
            brands: 'home credit',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'home credit'
          },
          {
            id: 4,
            id_firebase: 473,
            ms_code: '2642',
            master_code: 'tg-sd-d-7-16',
            duplicate_ms_code: '0',
            status: 'Utilized',
            prices: [[0, 0, 0, 0]],
            industries: 'fmcg',
            industries_children: 'food & beverage',
            brands: 'coca cola',
            is_filter_result: true,
            utilization_status: 'Utilized',
            subProduct: 'coca cola'
          }
        ]
      }
    ],
    is_filter_result: true
  },
  [
    {
      id: 428,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'jakpus - small roads',
        lng: '106.8338877',
        lat: '-6.183188746',
        kabupaten_and_kotamadya: 'jakarta pusat',
        area: 'jakpus - non focus road',
        province: 'jawa barat',
        city: 'jakarta'
      },
      parent_ms_code: 'NA-028-26',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'jp-ps-d-40-1',
      latest_survey_date: '29-Nov-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'indomedia',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3730,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 2,
              id_firebase: 3730,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank bri',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank bri'
            },
            {
              id: 3,
              id_firebase: 3732,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-3',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 4,
              id_firebase: 3733,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '29-Nov-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'indomedia',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3730,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 2,
              id_firebase: 3730,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank bri',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank bri'
            },
            {
              id: 3,
              id_firebase: 3732,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-3',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 4,
              id_firebase: 3733,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '29-Nov-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'vertical',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'vertical',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '26-Sep-2022',
          screen_owners: [
            {
              id: 1,
              name: 'indomedia',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3730,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-5',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'pemda dki jakarta',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'pemda dki jakarta'
                },
                {
                  id: 2,
                  id_firebase: 3730,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-6',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 3,
                  id_firebase: 3730,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-7',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'media & entertainment',
                  industries_children: 'events (concerts, tradeshows, other)',
                  brands: 'lintas slta bima',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'lintas slta bima'
                },
                {
                  id: 4,
                  id_firebase: 3737,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-8',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        },
        {
          survey_date: '29-Nov-2022',
          screen_owners: [
            {
              id: 1,
              name: 'indomedia',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3730,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 2,
                  id_firebase: 3730,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'financials',
                  industries_children: 'banking',
                  brands: 'bank bri',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bank bri'
                },
                {
                  id: 3,
                  id_firebase: 3732,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-3',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 4,
                  id_firebase: 3733,
                  ms_code: 'NA-028-26',
                  master_code: 'jp-ps-d-40-4',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'indomedia',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3730,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 2,
              id_firebase: 3730,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank bri',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank bri'
            },
            {
              id: 3,
              id_firebase: 3732,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-3',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 4,
              id_firebase: 3733,
              ms_code: 'NA-028-26',
              master_code: 'jp-ps-d-40-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    },
    {
      id: 2688,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'bogor - kebun raya',
        lng: '106.7963214',
        lat: '-6.604179918',
        kabupaten_and_kotamadya: 'kota bogor',
        area: 'bogor - non focus road',
        province: 'jawa barat',
        city: 'bogor'
      },
      parent_ms_code: '2523',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'bg-kb-d-6-1',
      latest_survey_date: '03-Oct-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'ming',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'ngos',
              brands: 'benihbaik.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'benihbaik.com'
            },
            {
              id: 2,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'pemda bogor',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'pemda bogor'
            },
            {
              id: 3,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 4,
              id_firebase: 2762,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        },
        {
          id: 2,
          name: 'eye',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-5',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'ngos',
              brands: 'benihbaik.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'benihbaik.com'
            },
            {
              id: 2,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-6',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'pemda bogor',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'pemda bogor'
            },
            {
              id: 3,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-7',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 4,
              id_firebase: 2766,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '03-Oct-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'ming',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'ngos',
              brands: 'benihbaik.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'benihbaik.com'
            },
            {
              id: 2,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'pemda bogor',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'pemda bogor'
            },
            {
              id: 3,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 4,
              id_firebase: 2762,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        },
        {
          id: 2,
          name: 'eye',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-5',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'ngos',
              brands: 'benihbaik.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'benihbaik.com'
            },
            {
              id: 2,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-6',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'pemda bogor',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'pemda bogor'
            },
            {
              id: 3,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-7',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 4,
              id_firebase: 2766,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '03-Oct-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '03-Oct-2022',
          screen_owners: [
            {
              id: 1,
              name: 'ming',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 2759,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[58500000, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'ngos',
                  brands: 'benihbaik.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'benihbaik.com'
                },
                {
                  id: 2,
                  id_firebase: 2759,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[58500000, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'pemda bogor',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'pemda bogor'
                },
                {
                  id: 3,
                  id_firebase: 2759,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-3',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[200000000, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 4,
                  id_firebase: 2762,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-4',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[58500000, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            },
            {
              id: 2,
              name: 'eye',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 2759,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-5',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[200000000, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'ngos',
                  brands: 'benihbaik.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'benihbaik.com'
                },
                {
                  id: 2,
                  id_firebase: 2759,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-6',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[200000000, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'pemda bogor',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'pemda bogor'
                },
                {
                  id: 3,
                  id_firebase: 2759,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-7',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[200000000, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 4,
                  id_firebase: 2766,
                  ms_code: '2523',
                  master_code: 'bg-kb-d-6-8',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[200000000, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'ming',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'ngos',
              brands: 'benihbaik.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'benihbaik.com'
            },
            {
              id: 2,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'pemda bogor',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'pemda bogor'
            },
            {
              id: 3,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 4,
              id_firebase: 2762,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[58500000, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        },
        {
          id: 2,
          name: 'eye',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-5',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'ngos',
              brands: 'benihbaik.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'benihbaik.com'
            },
            {
              id: 2,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-6',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'pemda bogor',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'pemda bogor'
            },
            {
              id: 3,
              id_firebase: 2759,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-7',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 4,
              id_firebase: 2766,
              ms_code: '2523',
              master_code: 'bg-kb-d-6-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[200000000, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    },
    {
      id: 2759,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'metro pondok indah',
        lng: '106.783468',
        lat: '-6.265092',
        kabupaten_and_kotamadya: 'jakarta selatan',
        area: 'jaksel - focus road',
        province: 'jawa barat',
        city: 'jakarta'
      },
      parent_ms_code: '2539',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'js-mp-d-41-1',
      latest_survey_date: '28-Nov-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'ming',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'esqa cosmetics'
            },
            {
              id: 2,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'e-commerce',
              brands: 'lazada',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'lazada'
            },
            {
              id: 3,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'vidio',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'vidio'
            },
            {
              id: 4,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 455,
              ms_code: '2539',
              master_code: 'js-mp-d-41-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 456,
              ms_code: '2539',
              master_code: 'js-mp-d-41-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 457,
              ms_code: '2539',
              master_code: 'js-mp-d-41-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 458,
              ms_code: '2539',
              master_code: 'js-mp-d-41-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '28-Nov-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'ming',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'esqa cosmetics'
            },
            {
              id: 2,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'e-commerce',
              brands: 'lazada',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'lazada'
            },
            {
              id: 3,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'vidio',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'vidio'
            },
            {
              id: 4,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 455,
              ms_code: '2539',
              master_code: 'js-mp-d-41-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 456,
              ms_code: '2539',
              master_code: 'js-mp-d-41-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 457,
              ms_code: '2539',
              master_code: 'js-mp-d-41-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 458,
              ms_code: '2539',
              master_code: 'js-mp-d-41-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '28-Nov-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'vertical',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'vertical',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '22-Sep-2022',
          screen_owners: [
            {
              id: 1,
              name: 'ming',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-9',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'ngos',
                  brands: 'unicef',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'unicef'
                },
                {
                  id: 2,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-10',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'vidio',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'vidio'
                },
                {
                  id: 3,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-11',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'ngos',
                  brands: 'wwf',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'wwf'
                },
                {
                  id: 4,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-12',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'youtube',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'youtube music'
                },
                {
                  id: 5,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-13',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'ngos',
                  brands: 'benihbaik.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'benihbaik.com'
                },
                {
                  id: 6,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-14',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'fintech - investments atau wealth tech ',
                  brands: 'bibit',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bibit'
                },
                {
                  id: 7,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-15',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 8,
                  id_firebase: 466,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-16',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        },
        {
          survey_date: '28-Nov-2022',
          screen_owners: [
            {
              id: 1,
              name: 'ming',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: '-',
                  industries_children: '-',
                  brands: 'No Client',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'esqa cosmetics'
                },
                {
                  id: 2,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'e-commerce',
                  brands: 'lazada',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'lazada'
                },
                {
                  id: 3,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-3',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'vidio',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'vidio'
                },
                {
                  id: 4,
                  id_firebase: 451,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-4',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 5,
                  id_firebase: 455,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-5',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 456,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-6',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 457,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-7',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 458,
                  ms_code: '2539',
                  master_code: 'js-mp-d-41-8',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'ming',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'esqa cosmetics'
            },
            {
              id: 2,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'e-commerce',
              brands: 'lazada',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'lazada'
            },
            {
              id: 3,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'vidio',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'vidio'
            },
            {
              id: 4,
              id_firebase: 451,
              ms_code: '2539',
              master_code: 'js-mp-d-41-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 455,
              ms_code: '2539',
              master_code: 'js-mp-d-41-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 456,
              ms_code: '2539',
              master_code: 'js-mp-d-41-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 457,
              ms_code: '2539',
              master_code: 'js-mp-d-41-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 458,
              ms_code: '2539',
              master_code: 'js-mp-d-41-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    },
    {
      id: 2935,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'cbd - mh thamrin',
        lng: '106.8234175',
        lat: '-6.187193613',
        kabupaten_and_kotamadya: 'jakarta pusat',
        area: 'jakpus - cbd',
        province: 'jawa barat',
        city: 'jakarta'
      },
      parent_ms_code: '2649',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'js-su-d-33-1',
      latest_survey_date: '29-Nov-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'amg',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank btn',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank btn'
            },
            {
              id: 2,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank bni',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank bni'
            },
            {
              id: 3,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'food & beverage',
              brands: 'ultra jaya',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'the kotak'
            },
            {
              id: 4,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3285,
              ms_code: '2649',
              master_code: 'js-su-d-33-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3286,
              ms_code: '2649',
              master_code: 'js-su-d-33-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3287,
              ms_code: '2649',
              master_code: 'js-su-d-33-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3288,
              ms_code: '2649',
              master_code: 'js-su-d-33-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '29-Nov-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'amg',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank btn',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank btn'
            },
            {
              id: 2,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank bni',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank bni'
            },
            {
              id: 3,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'food & beverage',
              brands: 'ultra jaya',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'the kotak'
            },
            {
              id: 4,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3285,
              ms_code: '2649',
              master_code: 'js-su-d-33-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3286,
              ms_code: '2649',
              master_code: 'js-su-d-33-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3287,
              ms_code: '2649',
              master_code: 'js-su-d-33-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3288,
              ms_code: '2649',
              master_code: 'js-su-d-33-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '29-Nov-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '04-Oct-2022',
          screen_owners: [
            {
              id: 1,
              name: 'amg',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-9',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'financials',
                  industries_children: 'banking',
                  brands: 'bank bni',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bank bni'
                },
                {
                  id: 2,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-10',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'financials',
                  industries_children: 'banking',
                  brands: 'bank bri',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bri'
                },
                {
                  id: 3,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-11',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'financials',
                  industries_children: 'banking',
                  brands: 'bank btn',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'btn prioritas'
                },
                {
                  id: 4,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-12',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 5,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-13',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'g20',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'g20'
                },
                {
                  id: 6,
                  id_firebase: 3294,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-14',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 3295,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-15',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3296,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-16',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        },
        {
          survey_date: '29-Nov-2022',
          screen_owners: [
            {
              id: 1,
              name: 'amg',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'financials',
                  industries_children: 'banking',
                  brands: 'bank btn',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bank btn'
                },
                {
                  id: 2,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'financials',
                  industries_children: 'banking',
                  brands: 'bank bni',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bank bni'
                },
                {
                  id: 3,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-3',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'fmcg',
                  industries_children: 'food & beverage',
                  brands: 'ultra jaya',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'the kotak'
                },
                {
                  id: 4,
                  id_firebase: 3281,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-4',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 5,
                  id_firebase: 3285,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-5',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 3286,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-6',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 3287,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-7',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3288,
                  ms_code: '2649',
                  master_code: 'js-su-d-33-8',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'amg',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank btn',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank btn'
            },
            {
              id: 2,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'financials',
              industries_children: 'banking',
              brands: 'bank bni',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bank bni'
            },
            {
              id: 3,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'food & beverage',
              brands: 'ultra jaya',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'the kotak'
            },
            {
              id: 4,
              id_firebase: 3281,
              ms_code: '2649',
              master_code: 'js-su-d-33-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3285,
              ms_code: '2649',
              master_code: 'js-su-d-33-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3286,
              ms_code: '2649',
              master_code: 'js-su-d-33-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3287,
              ms_code: '2649',
              master_code: 'js-su-d-33-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3288,
              ms_code: '2649',
              master_code: 'js-su-d-33-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    },
    {
      id: 3224,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'gatot soebroto',
        lng: '106.821177',
        lat: '-6.233161',
        kabupaten_and_kotamadya: 'jakarta selatan',
        area: 'jaksel - cbd',
        province: 'jawa barat',
        city: 'jakarta'
      },
      parent_ms_code: '2828',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'js-gs-d-22-1',
      latest_survey_date: '05-Dec-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'infini 8 (target media)',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 2,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 3,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'e-commerce',
              brands: 'lazada',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'lazada'
            },
            {
              id: 4,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bpjs',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bpjs ketenagakerjaan'
            },
            {
              id: 5,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-5',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'google'
            },
            {
              id: 6,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-6',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'spotify'
            },
            {
              id: 7,
              id_firebase: 3794,
              ms_code: '2828',
              master_code: 'js-gs-d-22-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3795,
              ms_code: '2828',
              master_code: 'js-gs-d-22-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '05-Dec-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'infini 8 (target media)',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 2,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 3,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'e-commerce',
              brands: 'lazada',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'lazada'
            },
            {
              id: 4,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bpjs',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bpjs ketenagakerjaan'
            },
            {
              id: 5,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-5',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'google'
            },
            {
              id: 6,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-6',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'spotify'
            },
            {
              id: 7,
              id_firebase: 3794,
              ms_code: '2828',
              master_code: 'js-gs-d-22-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3795,
              ms_code: '2828',
              master_code: 'js-gs-d-22-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '05-Dec-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'vertical',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'vertical',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '28-Sep-2022',
          screen_owners: [
            {
              id: 1,
              name: 'infini 8 (target media)',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-9',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'automotive',
                  industries_children: 'motorcycle',
                  brands: 'alva motorcycles',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'alva one'
                },
                {
                  id: 2,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-10',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'bpjs',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bpjs ketenagakerjaan'
                },
                {
                  id: 3,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-11',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'classifieds auto',
                  brands: 'olx',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'olx'
                },
                {
                  id: 4,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-12',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'automotive',
                  industries_children: 'car',
                  brands: 'mitsubishi',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'x pander cross'
                },
                {
                  id: 5,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-13',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 6,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-14',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'grab',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'grab'
                },
                {
                  id: 7,
                  id_firebase: 3802,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-15',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3803,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-16',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        },
        {
          survey_date: '05-Dec-2022',
          screen_owners: [
            {
              id: 1,
              name: 'infini 8 (target media)',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 2,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'bapenda',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bapenda'
                },
                {
                  id: 3,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-3',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'e-commerce',
                  brands: 'lazada',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'lazada'
                },
                {
                  id: 4,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-4',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'bpjs',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bpjs ketenagakerjaan'
                },
                {
                  id: 5,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-5',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: '-',
                  industries_children: '-',
                  brands: 'No Client',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'google'
                },
                {
                  id: 6,
                  id_firebase: 3788,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-6',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: '-',
                  industries_children: '-',
                  brands: 'No Client',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'spotify'
                },
                {
                  id: 7,
                  id_firebase: 3794,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-7',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3795,
                  ms_code: '2828',
                  master_code: 'js-gs-d-22-8',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'infini 8 (target media)',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 2,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 3,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'e-commerce',
              brands: 'lazada',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'lazada'
            },
            {
              id: 4,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bpjs',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bpjs ketenagakerjaan'
            },
            {
              id: 5,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-5',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'google'
            },
            {
              id: 6,
              id_firebase: 3788,
              ms_code: '2828',
              master_code: 'js-gs-d-22-6',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: '-',
              industries_children: '-',
              brands: 'No Client',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'spotify'
            },
            {
              id: 7,
              id_firebase: 3794,
              ms_code: '2828',
              master_code: 'js-gs-d-22-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3795,
              ms_code: '2828',
              master_code: 'js-gs-d-22-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    },
    {
      id: 3848,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'fatmawati',
        lng: '106.798098',
        lat: '-6.245959',
        kabupaten_and_kotamadya: 'jakarta selatan',
        area: 'jaksel - focus road',
        province: 'jawa barat',
        city: 'jakarta'
      },
      parent_ms_code: '757',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'js-ss-d-19-1',
      latest_survey_date: '30-Nov-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'amg',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 2,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 3,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 4,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3189,
              ms_code: '757',
              master_code: 'js-ss-d-19-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3190,
              ms_code: '757',
              master_code: 'js-ss-d-19-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3191,
              ms_code: '757',
              master_code: 'js-ss-d-19-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3192,
              ms_code: '757',
              master_code: 'js-ss-d-19-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 9,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-33',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 10,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-34',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 11,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-35',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 12,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-36',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 13,
              id_firebase: 3221,
              ms_code: '757',
              master_code: 'js-ss-d-19-37',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 14,
              id_firebase: 3222,
              ms_code: '757',
              master_code: 'js-ss-d-19-38',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 15,
              id_firebase: 3223,
              ms_code: '757',
              master_code: 'js-ss-d-19-39',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 16,
              id_firebase: 3224,
              ms_code: '757',
              master_code: 'js-ss-d-19-40',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        },
        {
          id: 2,
          name: 'warna warni',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-17',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 2,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-18',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 3,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-19',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 4,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-20',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3205,
              ms_code: '757',
              master_code: 'js-ss-d-19-21',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3206,
              ms_code: '757',
              master_code: 'js-ss-d-19-22',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3207,
              ms_code: '757',
              master_code: 'js-ss-d-19-23',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3208,
              ms_code: '757',
              master_code: 'js-ss-d-19-24',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '30-Nov-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'amg',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 2,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 3,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 4,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3189,
              ms_code: '757',
              master_code: 'js-ss-d-19-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3190,
              ms_code: '757',
              master_code: 'js-ss-d-19-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3191,
              ms_code: '757',
              master_code: 'js-ss-d-19-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3192,
              ms_code: '757',
              master_code: 'js-ss-d-19-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 9,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-33',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 10,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-34',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 11,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-35',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 12,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-36',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 13,
              id_firebase: 3221,
              ms_code: '757',
              master_code: 'js-ss-d-19-37',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 14,
              id_firebase: 3222,
              ms_code: '757',
              master_code: 'js-ss-d-19-38',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 15,
              id_firebase: 3223,
              ms_code: '757',
              master_code: 'js-ss-d-19-39',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 16,
              id_firebase: 3224,
              ms_code: '757',
              master_code: 'js-ss-d-19-40',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        },
        {
          id: 2,
          name: 'warna warni',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-17',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 2,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-18',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 3,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-19',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 4,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-20',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3205,
              ms_code: '757',
              master_code: 'js-ss-d-19-21',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3206,
              ms_code: '757',
              master_code: 'js-ss-d-19-22',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3207,
              ms_code: '757',
              master_code: 'js-ss-d-19-23',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3208,
              ms_code: '757',
              master_code: 'js-ss-d-19-24',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '30-Nov-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '26-Sep-2022',
          screen_owners: [
            {
              id: 1,
              name: 'amg',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-9',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'healthcare',
                  industries_children: 'pharmaceuticals',
                  brands: 'rohto',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'selsun'
                },
                {
                  id: 2,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-10',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'tiket.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'tiket.com'
                },
                {
                  id: 3,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-11',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'pegadaian',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'pegadaian'
                },
                {
                  id: 4,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-12',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'media & entertainment',
                  industries_children: 'media groups',
                  brands: 'dyandra promosindo',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'synchronize'
                },
                {
                  id: 5,
                  id_firebase: 3197,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-13',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 3198,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-14',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 3199,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-15',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3200,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-16',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 9,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-41',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'healthcare',
                  industries_children: 'pharmaceuticals',
                  brands: 'rohto',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'selsun'
                },
                {
                  id: 10,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-42',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'tiket.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'tiket.com'
                },
                {
                  id: 11,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-43',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'pegadaian',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'pegadaian'
                },
                {
                  id: 12,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-44',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'media & entertainment',
                  industries_children: 'media groups',
                  brands: 'dyandra promosindo',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'synchronize'
                },
                {
                  id: 13,
                  id_firebase: 3229,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-45',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 14,
                  id_firebase: 3230,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-46',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 15,
                  id_firebase: 3231,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-47',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 16,
                  id_firebase: 3232,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-48',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            },
            {
              id: 2,
              name: 'warna warni',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-25',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'healthcare',
                  industries_children: 'pharmaceuticals',
                  brands: 'rohto',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'selsun'
                },
                {
                  id: 2,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-26',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'tiket.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'tiket.com'
                },
                {
                  id: 3,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-27',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'pegadaian',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'pegadaian'
                },
                {
                  id: 4,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-28',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'media & entertainment',
                  industries_children: 'media groups',
                  brands: 'dyandra promosindo',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'synchronize'
                },
                {
                  id: 5,
                  id_firebase: 3213,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-29',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 3214,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-30',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 3215,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-31',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3216,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-32',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        },
        {
          survey_date: '30-Nov-2022',
          screen_owners: [
            {
              id: 1,
              name: 'amg',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'tiket.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'tiket.com'
                },
                {
                  id: 2,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'fmcg',
                  industries_children: 'cosmetics & personal care',
                  brands: 'carasun',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'carasun'
                },
                {
                  id: 3,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-3',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'bapenda',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bapenda'
                },
                {
                  id: 4,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-4',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 5,
                  id_firebase: 3189,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-5',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 3190,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-6',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 3191,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-7',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3192,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-8',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 9,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-33',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'tiket.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'tiket.com'
                },
                {
                  id: 10,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-34',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'fmcg',
                  industries_children: 'cosmetics & personal care',
                  brands: 'carasun',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'carasun'
                },
                {
                  id: 11,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-35',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'bapenda',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bapenda'
                },
                {
                  id: 12,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-36',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 13,
                  id_firebase: 3221,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-37',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 14,
                  id_firebase: 3222,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-38',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 15,
                  id_firebase: 3223,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-39',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 16,
                  id_firebase: 3224,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-40',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            },
            {
              id: 2,
              name: 'warna warni',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-17',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'transversal app',
                  brands: 'tiket.com',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'tiket.com'
                },
                {
                  id: 2,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-18',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'fmcg',
                  industries_children: 'cosmetics & personal care',
                  brands: 'carasun',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'carasun'
                },
                {
                  id: 3,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-19',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'government',
                  industries_children: 'government',
                  brands: 'bapenda',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'bapenda'
                },
                {
                  id: 4,
                  id_firebase: 3185,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-20',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 5,
                  id_firebase: 3205,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-21',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 3206,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-22',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 7,
                  id_firebase: 3207,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-23',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 8,
                  id_firebase: 3208,
                  ms_code: '757',
                  master_code: 'js-ss-d-19-24',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'amg',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 2,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 3,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-3',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 4,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-4',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3189,
              ms_code: '757',
              master_code: 'js-ss-d-19-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3190,
              ms_code: '757',
              master_code: 'js-ss-d-19-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3191,
              ms_code: '757',
              master_code: 'js-ss-d-19-7',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3192,
              ms_code: '757',
              master_code: 'js-ss-d-19-8',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 9,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-33',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 10,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-34',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 11,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-35',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 12,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-36',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 13,
              id_firebase: 3221,
              ms_code: '757',
              master_code: 'js-ss-d-19-37',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 14,
              id_firebase: 3222,
              ms_code: '757',
              master_code: 'js-ss-d-19-38',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 15,
              id_firebase: 3223,
              ms_code: '757',
              master_code: 'js-ss-d-19-39',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 16,
              id_firebase: 3224,
              ms_code: '757',
              master_code: 'js-ss-d-19-40',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        },
        {
          id: 2,
          name: 'warna warni',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-17',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'transversal app',
              brands: 'tiket.com',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'tiket.com'
            },
            {
              id: 2,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-18',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'fmcg',
              industries_children: 'cosmetics & personal care',
              brands: 'carasun',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'carasun'
            },
            {
              id: 3,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-19',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'government',
              industries_children: 'government',
              brands: 'bapenda',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'bapenda'
            },
            {
              id: 4,
              id_firebase: 3185,
              ms_code: '757',
              master_code: 'js-ss-d-19-20',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 5,
              id_firebase: 3205,
              ms_code: '757',
              master_code: 'js-ss-d-19-21',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3206,
              ms_code: '757',
              master_code: 'js-ss-d-19-22',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 7,
              id_firebase: 3207,
              ms_code: '757',
              master_code: 'js-ss-d-19-23',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 8,
              id_firebase: 3208,
              ms_code: '757',
              master_code: 'js-ss-d-19-24',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    },
    {
      id: 4107,
      type: 'digital',
      location: {
        island: 'Jawa',
        route: 'cbd - sudirman',
        lng: '106.818104',
        lat: '-6.2151887',
        kabupaten_and_kotamadya: 'jakarta selatan',
        area: 'jaksel - cbd',
        province: 'jawa barat',
        city: 'jakarta'
      },
      parent_ms_code: '991',
      parent_duplicate_ms_code: '0',
      parent_master_code: 'js-su-d-66-1',
      latest_survey_date: '01-Dec-2022',
      latest_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'supra',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3414,
              ms_code: '991',
              master_code: 'js-su-d-66-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'electronics',
              industries_children: 'smartphones',
              brands: 'samsung',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'samsung'
            },
            {
              id: 2,
              id_firebase: 3414,
              ms_code: '991',
              master_code: 'js-su-d-66-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 3,
              id_firebase: 3416,
              ms_code: '991',
              master_code: 'js-su-d-66-3',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 4,
              id_firebase: 3417,
              ms_code: '991',
              master_code: 'js-su-d-66-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 5,
              id_firebase: 3418,
              ms_code: '991',
              master_code: 'js-su-d-66-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3419,
              ms_code: '991',
              master_code: 'js-su-d-66-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      active_survey_date: '01-Dec-2022',
      active_survey_screen_onwers_history: [
        {
          id: 1,
          name: 'supra',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3414,
              ms_code: '991',
              master_code: 'js-su-d-66-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'electronics',
              industries_children: 'smartphones',
              brands: 'samsung',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'samsung'
            },
            {
              id: 2,
              id_firebase: 3414,
              ms_code: '991',
              master_code: 'js-su-d-66-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 3,
              id_firebase: 3416,
              ms_code: '991',
              master_code: 'js-su-d-66-3',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 4,
              id_firebase: 3417,
              ms_code: '991',
              master_code: 'js-su-d-66-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 5,
              id_firebase: 3418,
              ms_code: '991',
              master_code: 'js-su-d-66-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3419,
              ms_code: '991',
              master_code: 'js-su-d-66-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      survey_date: '01-Dec-2022',
      asset_type_details: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      asset_type: {
        format_level_1: 'roadside',
        type_of_assets: 'digital',
        orientation: 'horizontal',
        format_level_2: 'roadside',
        format_level_3: 'roadside billboard'
      },
      markerColor: null,
      is_user_selected: false,
      survey_histories: [
        {
          survey_date: '01-Dec-2022',
          screen_owners: [
            {
              id: 1,
              name: 'supra',
              is_filter_result: true,
              slots: [
                {
                  id: 1,
                  id_firebase: 3414,
                  ms_code: '991',
                  master_code: 'js-su-d-66-1',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'electronics',
                  industries_children: 'smartphones',
                  brands: 'samsung',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'samsung'
                },
                {
                  id: 2,
                  id_firebase: 3414,
                  ms_code: '991',
                  master_code: 'js-su-d-66-2',
                  duplicate_ms_code: '0',
                  status: 'Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: 'tech',
                  industries_children: 'ott streaming video',
                  brands: 'mola tv',
                  is_filter_result: true,
                  utilization_status: 'Utilized',
                  subProduct: 'mola tv'
                },
                {
                  id: 3,
                  id_firebase: 3416,
                  ms_code: '991',
                  master_code: 'js-su-d-66-3',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 4,
                  id_firebase: 3417,
                  ms_code: '991',
                  master_code: 'js-su-d-66-4',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 5,
                  id_firebase: 3418,
                  ms_code: '991',
                  master_code: 'js-su-d-66-5',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                },
                {
                  id: 6,
                  id_firebase: 3419,
                  ms_code: '991',
                  master_code: 'js-su-d-66-6',
                  duplicate_ms_code: '0',
                  status: 'Not Utilized',
                  prices: [[0, 0, 0, 0]],
                  industries: null,
                  industries_children: '',
                  brands: null,
                  is_filter_result: true,
                  utilization_status: 'Not Utilized',
                  subProduct: ''
                }
              ]
            }
          ]
        }
      ],
      screen_owners: [
        {
          id: 1,
          name: 'supra',
          is_filter_result: true,
          slots: [
            {
              id: 1,
              id_firebase: 3414,
              ms_code: '991',
              master_code: 'js-su-d-66-1',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'electronics',
              industries_children: 'smartphones',
              brands: 'samsung',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'samsung'
            },
            {
              id: 2,
              id_firebase: 3414,
              ms_code: '991',
              master_code: 'js-su-d-66-2',
              duplicate_ms_code: '0',
              status: 'Utilized',
              prices: [[0, 0, 0, 0]],
              industries: 'tech',
              industries_children: 'ott streaming video',
              brands: 'mola tv',
              is_filter_result: true,
              utilization_status: 'Utilized',
              subProduct: 'mola tv'
            },
            {
              id: 3,
              id_firebase: 3416,
              ms_code: '991',
              master_code: 'js-su-d-66-3',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 4,
              id_firebase: 3417,
              ms_code: '991',
              master_code: 'js-su-d-66-4',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 5,
              id_firebase: 3418,
              ms_code: '991',
              master_code: 'js-su-d-66-5',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            },
            {
              id: 6,
              id_firebase: 3419,
              ms_code: '991',
              master_code: 'js-su-d-66-6',
              duplicate_ms_code: '0',
              status: 'Not Utilized',
              prices: [[0, 0, 0, 0]],
              industries: null,
              industries_children: '',
              brands: null,
              is_filter_result: true,
              utilization_status: 'Not Utilized',
              subProduct: ''
            }
          ]
        }
      ],
      is_filter_result: true
    }
  ]
]

export default {
  async FETCH_ITEMS_ACTION({ commit }, { resource }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_FETCH_ACTION', { value: true })

      axios({
        method: 'get',
        url: `${process.env.BASE_URL_API}/assets`
      })
        .then((response) => {
          const { results } = response.data

          const groupedAssets = []
          const temporaryAvailableSlot = []
          let tempSlot = {}

          let temp = 0

          results.forEach((asset) => {
            const { slots_per_survey, media_list, max_slots, route, lat, lng, district, area, province, city, url_gallery, last_survey_image } = asset
            let slotDetails = {
              id: groupedAssets.length + 1,
              is_filter_result: true,
              asset_type: {
                format_level_1: asset.ooh_type,
                type_of_assets: asset.asset_type,
                orientation: asset.orientation,
                format_level_2: asset.old_ooh_type2,
                format_level_3: asset.old_ooh_type3
              },
              location: {
                island: 'Jawa',
                route,
                lng,
                lat,
                kabupaten_and_kotamadya: district,
                area,
                province,
                city
              },
              code_number: {
                origin_asset_no: asset.asset_no,
                ms_code: asset.ms_code,
                duplicate_ms_code: asset.duplicate_ms_code // ! harusnya ini sudah engk terpakai
              },
              utilization: {
                slot_status: 'Utilized',
                // utilization_status: 'ini perlu di cek lagi nanti, kalau semua slot_status sudah ada',
                utilization_status: 'Utilized'
              }
            }

            media_list.forEach((mediaBrand) => {
              slotDetails.rate_card_excl_vat = {
                packages: {
                  one_month: Number(mediaBrand.one_month_ratecard),
                  six_month: Number(mediaBrand.six_months_ratecard),
                  three_month: Number(mediaBrand.three_months_ratecard),
                  one_year: Number(mediaBrand.one_year_ratecard)
                }
              }

              slots_per_survey.forEach((survey) => {
                const { slots, available_slot } = survey
                let surveyDateTemporary = null

                slots.forEach((slot, indexSlot) => {
                  const { survey_date, client_name, industry, subIndustry, brand } = slot
                  const assetWithSameNumber = groupedAssets.filter((storedAsset) => storedAsset.code_number.origin_asset_no === asset.asset_no)
                  const newMasterCode = `${asset.asset_no}-${assetWithSameNumber.length + 1}`
                  surveyDateTemporary = survey_date

                  if (mediaBrand.competitor === null) {
                    console.error('unknown mediaBrand ')
                    console.error(asset)
                  }

                  slotDetails = {
                    ...slotDetails,
                    code_number: {
                      ...slotDetails.code_number,
                      master_code: newMasterCode
                    },
                    competitor_details: {
                      competitor: mediaBrand.competitor.name
                    },
                    mystery_shopping_details: {
                      proposal_month: survey_date
                    },
                    advertiser: {
                      industry_level_1_parent: industry,
                      industry_level_2_details: subIndustry,
                      brands: brand,
                      company_group: client_name
                    },
                    street_survey_details: {
                      survey_date,
                      slot_status: 'Utilized',
                      asset_status: 'Utilized'
                    },
                    url_gallery,
                    last_survey_image
                  }

                  if (client_name !== 'no client') {
                    groupedAssets.push(slotDetails)
                  }
                })

                // * tambah available_slot disini, bukan di dalam looping slots
                const totalSlotDoesntHaveClient = Number(available_slot)
                if (totalSlotDoesntHaveClient > 0) {
                  _.times(totalSlotDoesntHaveClient, (index) => {
                    const assetWithSameNumber = groupedAssets.filter(({ code_number }) => code_number.origin_asset_no === asset.asset_no)
                    const newMasterCode = `${asset.asset_no}-${assetWithSameNumber.length + 1}`

                    const noClientSlot = {
                      id: groupedAssets.length + 1,
                      is_filter_result: true,
                      asset_type: {
                        format_level_1: asset.ooh_type,
                        type_of_assets: asset.asset_type,
                        orientation: asset.orientation,
                        format_level_2: asset.old_ooh_type2,
                        format_level_3: asset.old_ooh_type3
                      },
                      location: {
                        island: 'Jawa',
                        route,
                        lng,
                        lat,
                        kabupaten_and_kotamadya: district,
                        area,
                        province,
                        city
                      },
                      code_number: {
                        origin_asset_no: asset.asset_no,
                        ms_code: asset.ms_code,
                        master_code: newMasterCode,
                        duplicate_ms_code: asset.duplicate_ms_code
                      },
                      utilization: {
                        slot_status: 'Not Utilized',
                        utilization_status: 'Not Utilized'
                      },
                      rate_card_excl_vat: {
                        packages: {
                          one_month: Number(mediaBrand.one_month_ratecard),
                          six_month: Number(mediaBrand.six_months_ratecard),
                          three_month: Number(mediaBrand.three_months_ratecard),
                          one_year: Number(mediaBrand.one_year_ratecard)
                        }
                      },
                      competitor_details: {
                        competitor: mediaBrand.competitor.name
                      },
                      mystery_shopping_details: {
                        proposal_month: surveyDateTemporary
                      },
                      advertiser: {
                        industry_level_1_parent: '',
                        brands: '',
                        company_group: '',
                        industry_level_2_details: ''
                      },
                      street_survey_details: {
                        survey_date: surveyDateTemporary,
                        slot_status: 'Not Utilized',
                        asset_status: 'Not Utilized'
                      },
                      url_gallery,
                      last_survey_image
                    }

                    groupedAssets.push(noClientSlot)
                  })
                }
              })
            })
          })

          commit('SET_ITEMS_ACTION', { resource, items: groupedAssets })
          commit('SET_LOADING_FETCH_ACTION', { value: false })
          resolve()
        })
        .catch((error) => {
          console.error('error ', error)
          commit('SET_LOADING_FETCH_ACTION', { value: false })
          commit('SET_ERROR_FETCH_ACTION', { error })
          reject()
        })
    })
  }
}
