<template>
  <div class="flex flex-wrap relative">
    <Spinner :isShow="assets.isLoading" />
    <!-- <modal /> -->
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-4">
        <Modal v-model="isShowodal" :close="modalToggle">
          <div class="modal">
            <div class="grid justify-items-end">
              <button @click="modalToggle" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2">
                <i class="fas fa-times text-lg text-white" />
              </button>
            </div>

            <div class="py-5 px-3">
              <div v-for="industry in industriesList" :key="industry.id" class="flex justify-between px-2 py-2">
                <p class="flex text-gray-700">
                  <svg class="w-2 text-gray-500 mx-2" viewBox="0 0 8 8" fill="currentColor">
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  {{ industry.level2 }}
                </p>
                <p class="text-gray-500 font-thin">{{ industry.level1 }}</p>
              </div>
            </div>
          </div>
        </Modal>

        <!-- <div class="flex flex-row mb-3 pt-0">
          <search-bar />
        </div> -->
        <div class="flex">
          <maps v-if="filters.isHaveLocations" />
          <div v-else class="w-3/4" />

          <!-- FILTER  AREA -->
          <div class="filter flex flex-col w-1/4 h-screen shadow-2xl rounded mx-2 p-4 overflow-auto" id="app">
            <Datepicker v-model="selectedDate" monthPicker @update:modelValue="setFilterDate" :clearable="false" />

            <div class="flex flex-row justify-between items-center my-4 text-xs">
              <div class="text-xs">
                <p class="ml-1 py-1">Assets {{ filters.groupAssets.length }}</p>
                <p class="ml-1 py-1">Assets in Date Range {{ this.filters.totalAssetsInDateRange }}</p>
                <!-- <p class="ml-1 py-1">Assets Selected {{ filters.groupAssets.length }}</p> -->
              </div>
              <div class="h-full">
                <button
                  class="pl-3 pr-3 bg-transparent border-2 border-red-500 text-red-500 rounded-lg hover:bg-red-500 hover:text-gray-100 focus:border-4 focus:border-red-300"
                  @click="RESET_ALL_FILTERS_VALUE_ACTION"
                >
                  <p>Reset Filters</p>
                </button>
              </div>
            </div>
            <MultiOptions label="Locations" criteria="locations" :options="filters.filterCriteria.locations" />
            <options label="Asset Types" criteria="assetsTypes" :options="filters.filterCriteria.assetsTypes" />
            <MultiOptions label="OOH Types" criteria="oohTypes" :options="filters.filterCriteria.oohTypes" />
            <options label="Competitors" criteria="mediaCompetitors" :options="filters.filterCriteria.mediaCompetitors" />
            <MultiOptions label="Industries" criteria="industries" :options="filters.filterCriteria.industries" />

            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2 mt-4" @click="toggleUnselectedAssets">
              {{ toggleAssetsButtonText }}
            </button>

            <p class="text-sm mt-8">
              <button @click="modalToggle">Please click here to see all Industry list</button>
            </p>
          </div>
        </div>

        <!-- TABLE SECTION -->
        <div class="w-full mt-4 shadow-2xl rounded">
          <Table v-if="assets.isHaveAssets && filters.isHaveLocations" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import 'vue-universal-modal/dist/index.css'
  import { mapActions } from 'vuex'
  import _ from 'lodash'
  import Datepicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import moment from 'moment'

  import Options from '@/components/Options.vue'
  import MultiOptions from '@/components/MultiOptions.vue'
  import Table from '@/components/Table.vue'
  import Maps from '@/components/Maps/Maps.vue'
  import Spinner from '@/components/Spinner.vue'
  import { groupingAssets } from '@/helper/grouping'
  import { getMonthName, getMonthNumber, getLastSurveyDate } from '@/helper/calendar'
  // import SearchBar from '@/components/Maps/SearchBar.vue'

  export default {
    components: {
      Options,
      MultiOptions,
      Table,
      Maps,
      Spinner,
      Datepicker
      // SearchBar
    },
    data() {
      return {
        isShowodal: false,
        selectedDate: null,
        industriesList: []
      }
    },
    async created() {
      await this.FETCH_ITEMS_ACTION({ resource: 'assets' })

      this.GENERATE_FILTERS_OPTIONS_ACTION()
      this.RECHECK_FILTER_RESULTS_ACTION()
    },
    computed: {
      filters() {
        return this.$store.getters.filters()
      },
      assets() {
        return this.$store.getters.assets()
      },

      toggleAssetsButtonText() {
        const state = this.filters.hideUnselected
        if (state === true) {
          return 'Show all'
        }

        return 'Hide unselected'
      }
    },
    watch: {
      '$store.state.filter.selectedDate': {
        handler: function (newSelectedDate) {
          this.selectedDate = {
            month: getMonthNumber(newSelectedDate.month),
            year: String(newSelectedDate.year)
          }
        },
        immediate: true,
        deep: true
      },
      '$store.state.filter.filterCriteria.industries': {
        handler: function (industries) {
          const industriesList = []
          industries.forEach(({ value: parentIndustry, childrens }, index) => {
            if (parentIndustry === 'Not Utilized' || parentIndustry === '') {
              return true
            }

            childrens.forEach(({ industry_level_2: childrenIndustry }) => {
              industriesList.push({
                id: index + 1,
                level1: parentIndustry,
                level2: childrenIndustry
              })
            })
          })

          this.industriesList = industriesList
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      ...mapActions([
        'FETCH_ITEMS_ACTION',
        'INIT_FILTERS_LOCATIONS_ACTION',
        'RESET_ALL_FILTERS_VALUE_ACTION',
        'SET_FILTERS_VALUE_ACTION',
        'GENERATE_FILTERS_OPTIONS_ACTION',
        'RECHECK_FILTER_RESULTS_ACTION',
        'FETCH_ITEMS_DB_ACTION'
      ]),
      modalToggle() {
        this.isShowodal = !this.isShowodal
      },
      setFilterDate(selectedDate) {
        const { month, year } = selectedDate
        selectedDate = {
          month: getMonthName(month),
          year: String(year)
        }

        this.SET_FILTERS_VALUE_ACTION({
          key: 'selectedDate',
          value: selectedDate
        })

        this.RECHECK_FILTER_RESULTS_ACTION()
      },

      toggleUnselectedAssets() {
        const prevState = this.filters.hideUnselected
        this.SET_FILTERS_VALUE_ACTION({ key: 'hideUnselected', value: !prevState })
      }
    }
  }
</script>

<style scoped>
  .map {
    height: 80vh;
  }

  .filter {
    height: 80vh;
  }

  #app::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  #app::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
  }

  #app::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
  }

  .modal {
    width: 50%;
    height: 80vh;
    padding: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 20px;
    text-align: center;
    overflow-y: auto;
  }

  .modal::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  .modal::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
  }

  .modal::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
  }
</style>
