import actions from '@/store/modules/map/mapActions'
import mutations from '@/store/modules/map/mapMutations'

export default {
  state: () => ({
    maps: {
      error: '',
      address: '',
      userLocation: null,
      activeLocation: null
    }
  }),
  getters: {
    address: (state) => state.status,
    getKabupatensByProvinces: (state) => state.filterCriteria.provinces.map((province) => province.kecamatan).flat()
  },
  actions,
  mutations
}
