export default {
  // GET ALL ITEMS / ASSETS
  SET_ITEMS_ACTION(state, { resource, items }) {
    state.items[resource] = items
  },

  SET_VALUES_ACTION(state, { resource, items }) {
    state[resource] = items
  },

  SET_LOADING_FETCH_ACTION(state, { value }) {
    state.isLoading = value
  },

  SET_ERROR_FETCH_ACTION(state, { error }) {
    state.error = error
  }
}
