import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'
import Home from '../pages/Home'
import Upload from '../pages/Upload.vue'
import Unauthorized from '../pages/Unauthorized.vue'

const routes = [
  {
    path: '/',
    redirect: (to) => {
      return { path: '/home' }
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized
  }
  // {
  //   path: '*',
  //   redirect: '/login'
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const routesGuard = ['/upload', '/home', '/']
  if (routesGuard.includes(to.fullPath)) {
    if (store.state.msal.userAccount === null) {
      console.log('MSAL account is null')
    }
    if (store.state.msal.userAccount === null) {
      next('/unauthorized')
      return false
    }
  }

  next()
})

export default router
