import moment from 'moment'

const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 0:
      return 'january'
    case 1:
      return 'february'
    case 2:
      return 'march'
    case 3:
      return 'april'
    case 4:
      return 'may'
    case 5:
      return 'june'
    case 6:
      return 'july'
    case 7:
      return 'august'
    case 8:
      return 'september'
    case 9:
      return 'october'
    case 10:
      return 'november'
    case 11:
      return 'december'

    default:
      return monthNumber
  }
}

const getMonthNumber = (monthNumber) => {
  switch (monthNumber) {
    case 'january':
      return 0
    case 'february':
      return 1
    case 'march':
      return 2
    case 'april':
      return 3
    case 'may':
      return 4
    case 'june':
      return 5
    case 'july':
      return 6
    case 'august':
      return 7
    case 'september':
      return 8
    case 'october':
      return 9
    case 'november':
      return 10
    case 'december':
      return 11

    default:
      break
  }
}

const getLastSurveyDate = (assets) => {
  const allSurveyDate = assets.map((asset) => asset.latest_survey_date).sort((a, b) => moment(a).diff(moment(b)))
  const lastDate = moment(allSurveyDate[allSurveyDate.length - 1])

  return {
    month: getMonthName(lastDate.month()),
    year: lastDate.format('YYYY')
  }
}

export { getMonthName, getLastSurveyDate, getMonthNumber }
