<template>
  <div class="flex relative w-3/4 shadow-2xl rounded">
    <Spinner :isShow="isMapLoading" />
    <div class="map flex w-full" id="map"></div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'
  import { Loader } from 'google-maps'
  import moment from 'moment'
  import { toRaw } from 'vue'

  import { generateInfoWindow } from '@/components/Maps/infoWindow'
  import Spinner from '@/components/Spinner.vue'
  import { dotFormat } from '@/helper/formatter'
  import { getMonthName } from '@/helper/calendar'

  export default {
    components: {
      Spinner
    },
    data() {
      return {
        isMapLoading: true,
        google: window.google,
        map: null,
        activeInfoWindow: null,
        markers: [],
        markersPolyline: [],
        markersPolylineTemp: [],
        markersLocation: []
      }
    },
    async created() {
      await this.initMap()
      const { filters, locations } = this

      this.setUserLocation()
      this.addDataLayerPolygon(locations)

      this.generateAssetMarkers(filters.groupAssets)
    },
    computed: {
      ...mapGetters([]),
      filtersResults() {
        return this.$store.state.filter.filtersResults
      },
      filters() {
        return this.$store.getters.filters()
      },
      assets() {
        return this.$store.state.asset.items.assets
      },
      locations() {
        return this.$store.state.filter.filterCriteria.locations
      }
    },
    watch: {
      '$store.state.filter.selectedDate': {
        handler: function (selectedDate) {
          if (this.map) {
            const { groupAssets } = this.filters
            this.changeMarkersColor(groupAssets)
            // this.changeMarkersColor(dummy)
          }
        },
        immediate: true,
        deep: true
      },
      '$store.state.filter.groupAssets': {
        handler: function (groupAssets) {
          if (this.map) {
            this.changeMarkersColor(groupAssets)
            // this.changeMarkersColor(dummy)
          }
        },
        immediate: true,
        deep: true
      },

      '$store.state.filter.hideUnselected': {
        handler: function (hideUnselected) {
          const { groupAssets } = this.filters

          this.markers.map((marker) => {
            const rawMarker = toRaw(marker)
            const { parent_ms_code } = rawMarker.details

            const selectedAssetFromGroup = groupAssets.find((asset) => asset.parent_ms_code === parent_ms_code)
            const { is_filter_result } = selectedAssetFromGroup

            if (is_filter_result === false) {
              if (!rawMarker.getVisible()) {
                rawMarker.setVisible(true)
              } else {
                rawMarker.setVisible(false)
              }
            }
          })
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      ...mapActions(['setUserLocation', 'TOGGLE_SELECTED_ASSET_ACTION', 'SET_FILTERS_VALUE_ACTION']),
      initMap() {
        return new Promise((resolve) => {
          const loader = new Loader(process.env.GMAPS_API_KEY)
          loader.load().then((data) => {
            const { LatLng, MapTypeId, Map } = data.maps
            this.google = data
            this.map = new Map(document.getElementById('map'), {
              zoom: 11,
              center: new LatLng(process.env.INITIAL_LAT, process.env.INITIAL_LNG),
              mapTypeId: MapTypeId.ROADMAP,
              disableDefaultUI: true,
              mapTypeControlOptions: { mapTypeIds: [MapTypeId.ROADMAP] },
              minZoom: 7,
              maxZoom: 21,
              disableAutoPan: true,
              panControl: false,
              restriction: {
                latLngBounds: {
                  north: 5.47982086834,
                  south: -10.3599874813,
                  east: 141.03385176,
                  west: 95.2930261576
                }
              }
            })

            this.map.addListener('mousemove', () => {
              this.activeInfoWindow !== null && this.activeInfoWindow.close()
            })
            this.isMapLoading = false
            resolve()
          })
        })
      },

      showLocationOnTheMap({ longitude, latitude }) {
        const { google, map } = this
        const { LatLng, Marker, Geocoder } = google.maps

        const geocoder = new Geocoder()
        let latlng = new LatLng(latitude, longitude)

        geocoder.geocode({ latLng: latlng }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              const isOutsideIndonesia = results[results.length - 1].formatted_address !== 'Indonesia'
              if (isOutsideIndonesia) {
                latlng = new LatLng(process.env.INITIAL_LAT, process.env.INITIAL_LNG)
              }
            }
          } else {
            latlng = new LatLng(process.env.INITIAL_LAT, process.env.INITIAL_LNG)
          }

          new Marker({ position: latlng, map })
          map.panTo(latlng)
        })
      },

      addDataLayerPolygon(locations) {
        const { map, google } = this
        const { Data } = google.maps
        locations.forEach(({ childrens }) => {
          childrens.forEach(({ shapes, color, value }) =>
            map.data.add({
              geometry: new Data.Polygon([shapes]),
              properties: { color, value }
            })
          )
        })
        map.data.setStyle((feature) => {
          const color = feature.getProperty('color')
          const setActive = true
          return {
            fillColor: setActive ? color : 'transparent',
            strokeColor: setActive ? color : '#A1B0AB',
            strokeWeight: 1
          }
        })
      },

      enhanceDatalayerPolygon(locations) {
        this.map.data.setStyle((feature) => {
          const color = feature.getProperty('color')
          const value = feature.getProperty('value')
          const flattenLocations = []
          locations.forEach(({ childrens }) => {
            childrens.forEach((children) => flattenLocations.push(children))
          })
          const index = _.findIndex(flattenLocations, ['value', value])
          const { isSelected } = flattenLocations[index]
          return {
            fillColor: isSelected ? color : 'transparent',
            strokeColor: isSelected ? color : '#A1B0AB',
            strokeWeight: 1
          }
        })
      },

      removeMarkers() {
        this.markers.map((marker) => toRaw(marker).setMap(null))
        this.markers = []
      },

      isAssetsInSelectedMonth(asset) {
        const { year, month } = this.filters.selectedDate
        const { active_survey_date } = asset

        const startDateRange = moment().set({ year, month, date: 1 }).toString()
        const endDateRange = moment().set({ year, month }).endOf('month').toString()

        const surveyDate = moment(active_survey_date)
        const surveyYear = surveyDate.year()
        const surveyMonth = getMonthName(moment(active_survey_date).month())

        const isBetweenDateRange = moment().set({ year: surveyYear, month: surveyMonth, date: 1 }).isBetween(startDateRange, endDateRange)

        return isBetweenDateRange
      },

      generateAssetMarkers(assets) {
        const { map, google } = this
        const { Marker, InfoWindow } = google.maps

        let totalActive = 0
        let highindex = 0
        const markers = []
        let urlLength = 0

        assets.forEach((asset) => {
          const { location, is_filter_result } = asset
          const { lat, lng } = location
          const isAssetInSelectedDateRange = this.isAssetsInSelectedMonth(asset)

          let iconUrl = null
          if (is_filter_result && isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-green-solid.png'
            totalActive++
          } else if (!is_filter_result && isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-green-pastel.png'
            totalActive++
          } else if (is_filter_result && !isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-orange-solid.png'
          } else if (!is_filter_result && !isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-orange-pastel.png'
          }

          const marker = new Marker({
            map,
            icon: { url: iconUrl },
            position: {
              lat: Number(dotFormat(lat)) + Math.random() / 25000,
              lng: Number(dotFormat(lng))
            },
            draggable: false,
            optimized: true,
            zIndex: is_filter_result ? highindex++ : 0
          })

          marker.details = asset

          marker.addListener('click', () => {
            const selectedAsset = this.filters.groupAssets.find((grupAsset) => grupAsset.parent_ms_code === asset.parent_ms_code)
            const infowindowContent = new InfoWindow({
              content: generateInfoWindow(selectedAsset),
              disableAutoPan: true,
              pixelOffset: new google.maps.Size(0, 13)
            })
            if (this.activeInfoWindow !== null) {
              this.activeInfoWindow.close()
            }
            this.activeInfoWindow = infowindowContent
            infowindowContent.open({
              map,
              anchor: marker,
              shouldFocus: false
            })
          })

          marker.addListener('dblclick', () => {
            this.TOGGLE_SELECTED_ASSET_ACTION({ assetDetails: asset })
          })

          marker.addListener('mouseover', () => {
            if (this.activeInfoWindow !== null) {
              this.activeInfoWindow.close()
            }
          })

          markers.push(marker)
        })

        console.log('')
        console.log(urlLength)
        console.log('')

        this.markers = markers
        this.SET_FILTERS_VALUE_ACTION({ key: 'totalAssetsInDateRange', value: totalActive })
      },

      changeMarkersColor(groupAssets) {
        let totalActive = 0
        let highindex = 0

        const dummyActiveMarker = []

        this.markers.map((marker) => {
          const rawMarker = toRaw(marker)
          const { parent_ms_code } = rawMarker.details
          const selectedAssetFromGroup = groupAssets.find((asset) => asset.parent_ms_code === parent_ms_code)

          const { is_filter_result } = selectedAssetFromGroup
          const isAssetInSelectedDateRange = this.isAssetsInSelectedMonth(selectedAssetFromGroup)
          let iconUrl = null

          rawMarker.setZIndex(0)
          rawMarker.details = selectedAssetFromGroup

          if (is_filter_result && isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-green-solid.png'
            rawMarker.getVisible()
            rawMarker.setZIndex(highindex++)
            totalActive++
            dummyActiveMarker.push(selectedAssetFromGroup)
          } else if (!is_filter_result && isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-green-pastel.png'
            totalActive++
          } else if (is_filter_result && !isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-orange-solid.png'
            rawMarker.getVisible()
            rawMarker.setZIndex(highindex++)
            dummyActiveMarker.push(selectedAssetFromGroup)
          } else if (!is_filter_result && !isAssetInSelectedDateRange) {
            iconUrl = './icon/marker-orange-pastel.png'
          }

          rawMarker.setVisible(true)
          rawMarker.setIcon(iconUrl)
        })
        this.SET_FILTERS_VALUE_ACTION({ key: 'totalAssetsInDateRange', value: totalActive })
        this.SET_FILTERS_VALUE_ACTION({ key: 'hideUnselected', value: false })
      }
    }
  }
</script>

<style scoped>
  #scroll::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }
  #scroll::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
  }
  #scroll::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
  }
</style>
