<template>
  <div class="text-gray-900 bg-gray-200">
    <div v-if="isEmptyTable" class="flex w-full h-screen border items-center justify-center text-gray-500 text-lg font-bold">
      <p>No selected Assets, please select the Assets first</p>
    </div>

    <div v-else class="px-3 py-4 flex justify-center flex-wrap">
      <div class="w-full flex flex-row items-center justify-between pb-3">
        <h1 class="text-gray-900 font-semibold text-xl">Table - {{ showSlotTable ? 'Slots' : 'Screens' }}</h1>
        <div>
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2" @click="toggleTabble">
            Change to {{ !showSlotTable ? 'Slots' : 'Screens' }}
          </button>
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
            <vue3-json-excel :fields="json_fields" :json-data="assetsJson">Download Assets</vue3-json-excel>
          </button>
        </div>
      </div>

      <table class="w-full text-md bg-white shadow-md rounded mb-4 table text-gray-500 border-separate space-y-6 text-sm hover:table-fixed">
        <thead>
          <tr class="border-b custom-height text-center p-3 px-5 text-xs border bg-green-400">
            <th class="custom-height text-left p-3 border bg-gray-100" colspan="2">{{ showSlotTable ? 'SLOTS' : 'SCREENS' }}</th>
            <th colspan="3">Roadside</th>
            <th colspan="3">Public Transport</th>
            <th colspan="3">Place Based</th>
          </tr>
        </thead>

        <tbody>
          <tr class="text-gray-600 text-xs custom-height text-center border px-3">
            <td colspan="2" />

            <td class="border">Digital</td>
            <td class="border">Static</td>
            <td class="border bg-gray-300">Total</td>

            <td class="border">Digital</td>
            <td class="border">Static</td>
            <td class="border bg-gray-300">Total</td>

            <td class="border">Digital</td>
            <td class="border">Static</td>
            <td class="border bg-gray-300">Total</td>
          </tr>
          <!-- GEOGRAPHICAL -->
          <tr class="bg-green-200">
            <td class="custom-height p-3 text-gray-600 text-base" colspan="11">Geographical Breakdown</td>
          </tr>

          <tr
            v-for="({ subTotals, name }, index) in geoContent"
            :key="index"
            class="custom-height text-center text-xs border hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <td class="custom-height p-3 text-xs text-left" colspan="2">{{ name }}</td>
            <!-- Roadside -->
            <td :class="subTotals[0][0] === 0 && 'text-red-300'" class="border">
              {{ subTotals[0][0] === 0 ? 0 : subTotals[0][0] }}
            </td>
            <td :class="subTotals[0][1] === 0 && 'text-red-300'" class="border">{{ subTotals[0][1] === 0 ? 0 : subTotals[0][1] }}</td>
            <td :class="subTotals[0][0] + subTotals[0][1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{ subTotals[0][0] + subTotals[0][1] === 0 ? 0 : subTotals[0][0] + subTotals[0][1] }}
            </td>

            <!-- public transport -->
            <td :class="subTotals[1][0] === 0 && 'text-red-300'" class="border">{{ subTotals[1][0] === 0 ? 0 : subTotals[1][0] }}</td>
            <td :class="subTotals[1][1] === 0 && 'text-red-300'" class="border">{{ subTotals[1][1] === 0 ? 0 : subTotals[1][1] }}</td>
            <td :class="subTotals[1][0] + subTotals[1][1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{ subTotals[1][0] + subTotals[1][1] === 0 ? 0 : subTotals[1][0] + subTotals[1][1] }}
            </td>

            <!-- place based -->
            <td :class="subTotals[2][0] === 0 && 'text-red-300'" class="border">{{ subTotals[2][0] === 0 ? 0 : subTotals[2][0] }}</td>
            <td :class="subTotals[2][1] === 0 && 'text-red-300'" class="border">{{ subTotals[2][1] === 0 ? 0 : subTotals[2][1] }}</td>
            <td :class="subTotals[2][0] + subTotals[2][1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{ subTotals[2][0] + subTotals[2][1] === 0 ? 0 : subTotals[2][0] + subTotals[2][1] }}
            </td>
          </tr>

          <tr class="hover:bg-red-100 dark:hover:bg-red-700">
            <td class="custom-height border p-3 text-xs" colspan="2">Total</td>
            <td v-for="(totals, index) in totalGeoCotent" :key="index" class="">
              <div
                :class="totalGeoCotent[index - 1] + totalGeoCotent[index - 2] === 0 && 'text-red-300'"
                class="custom-height border p-3 text-xs text-center"
                v-if="[2, 5, 8].includes(index)"
              >
                {{ totalGeoCotent[index - 1] + totalGeoCotent[index - 2] }}
              </div>
              <div :class="totals === 0 && 'text-red-300'" class="custom-height border p-3 text-xs text-center" v-else>{{ totals }}</div>
            </td>
          </tr>

          <!-- COMPETITORS -->
          <tr class="bg-red-400">
            <td class="custom-height p-3 text-gray-600 text-base" colspan="11">Competitors Breakdown</td>
          </tr>

          <tr
            v-for="({ subTotals, name }, index) in competitorContent"
            :key="index"
            class="custom-height text-center text-xs border hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <td class="custom-height p-3 text-xs text-left" colspan="2">{{ name }}</td>
            <!-- Roadside -->
            <td :class="subTotals[0][0] === 0 && 'text-red-300'" class="border">
              {{ subTotals[0][0] === 0 ? 0 : subTotals[0][0] }}
            </td>
            <td :class="subTotals[0][1] === 0 && 'text-red-300'" class="border">{{ subTotals[0][1] === 0 ? 0 : subTotals[0][1] }}</td>
            <td :class="subTotals[0][0] + subTotals[0][1] === 0 && 'text-red-300'" class="border bg-gray-300">{{ subTotals[0][0] + subTotals[0][1] }}</td>

            <!-- public transport -->
            <td :class="subTotals[1][0] === 0 && 'text-red-300'" class="border">{{ subTotals[1][0] === 0 ? 0 : subTotals[1][0] }}</td>
            <td :class="subTotals[1][1] === 0 && 'text-red-300'" class="border">{{ subTotals[1][1] === 0 ? 0 : subTotals[1][1] }}</td>
            <td :class="subTotals[1][0] + subTotals[1][1] === 0 && 'text-red-300'" class="border bg-gray-300">{{ subTotals[1][0] + subTotals[1][1] }}</td>

            <!-- place based -->
            <td :class="subTotals[2][0] === 0 && 'text-red-300'" class="border">{{ subTotals[2][0] === 0 ? 0 : subTotals[2][0] }}</td>
            <td :class="subTotals[2][1] === 0 && 'text-red-300'" class="border">{{ subTotals[2][1] === 0 ? 0 : subTotals[2][1] }}</td>
            <td :class="subTotals[2][0] + subTotals[2][1] === 0 && 'text-red-300'" class="border bg-gray-300">{{ subTotals[2][0] + subTotals[2][1] }}</td>
          </tr>

          <tr class="hover:bg-red-100 dark:hover:bg-red-700">
            <td class="custom-height border p-3 text-xs" colspan="2">Total</td>
            <td v-for="(totals, index) in totalCompetitorContent" :key="index" class="">
              <div
                :class="totalCompetitorContent[index - 1] + totalCompetitorContent[index - 2] === 0 && 'text-red-300'"
                class="custom-height border p-3 text-xs text-center"
                v-if="[2, 5, 8].includes(index)"
              >
                {{ totalCompetitorContent[index - 1] + totalCompetitorContent[index - 2] }}
              </div>
              <div :class="totals === 0 && 'text-red-300'" class="custom-height border p-3 text-xs text-center" v-else>{{ totals }}</div>
            </td>
          </tr>
        </tbody>

        <!-- INDUSTRY -->
        <tr class="bg-blue-400">
          <td class="custom-height p-3 text-gray-600 text-base" colspan="11">
            Industry breakdown
            <span v-if="showPercentage" class="font-bold">(Share Of Voice)</span>
          </td>
        </tr>

        <tbody class="custom-height" v-for="(industry, index) in industryContent" :key="index">
          <!-- roadside -->
          <tr class="bg-gray-200 hover:bg-gray-200">
            <td class="custom-height p-3 text-xs" colspan="2">{{ industry.name }}</td>

            <td class="text-center border-2 text-xs column-width">Digital</td>
            <td class="custom-height text-center border text-xs column-width">Static</td>
            <td class="custom-height text-center border text-xs column-width bg-gray-300">Total</td>

            <!-- public transport -->
            <td class="custom-height text-center border text-xs column-width">Digital</td>
            <td class="custom-height text-center border text-xs column-width">Static</td>
            <td class="custom-height text-center border text-xs column-width bg-gray-300">Total</td>

            <!-- place based -->
            <td class="custom-height text-center border text-xs column-width">Digital</td>
            <td class="custom-height text-center border text-xs column-width">Static</td>
            <td class="custom-height text-center border text-xs column-width bg-gray-300">Total</td>
          </tr>

          <tr class="hover:bg-gray-100 dark:hover:bg-gray-700 text-center text-xs" v-for="({ name, subTotals }, index) in industry.brands" :key="index">
            <td class=""></td>
            <td class="text-left p-3 text-xs">{{ index + 1 }} - {{ name }}</td>
            <!-- Roadside -->
            <td :class="subTotals[0][0] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: subTotals[0][0],
                  total: industry.totals[0] + industry.totals[1]
                })
              }}
            </td>
            <td :class="subTotals[0][1] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: subTotals[0][1],
                  total: industry.totals[0] + industry.totals[1]
                })
              }}
            </td>
            <td :class="subTotals[0][0] + subTotals[0][1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{
                this.showNumber({
                  number: subTotals[0][0] + subTotals[0][1],
                  total: industry.totals[0] + industry.totals[1]
                })
              }}
            </td>

            <!-- public transport -->
            <td :class="subTotals[1][0] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: subTotals[1][0],
                  total: industry.totals[2] + industry.totals[3]
                })
              }}
            </td>
            <td :class="subTotals[1][1] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: subTotals[1][1],
                  total: industry.totals[2] + industry.totals[3]
                })
              }}
            </td>
            <td :class="subTotals[1][0] + subTotals[1][1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{
                this.showNumber({
                  number: subTotals[1][0] + subTotals[1][1],
                  total: industry.totals[2] + industry.totals[3]
                })
              }}
            </td>

            <!-- place based -->
            <td :class="subTotals[2][0] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: subTotals[2][0],
                  total: industry.totals[4] + industry.totals[5]
                })
              }}
            </td>
            <td :class="subTotals[2][1] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: subTotals[2][1],
                  total: industry.totals[4] + industry.totals[5]
                })
              }}
            </td>
            <td :class="subTotals[2][0] + subTotals[2][1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{
                this.showNumber({
                  number: subTotals[2][0] + subTotals[2][1],
                  total: industry.totals[4] + industry.totals[5]
                })
              }}
            </td>
          </tr>

          <tr class="hover:bg-gray-100 dark:hover:bg-gray-700 text-center text-xs custom-height">
            <td class="text-left p-3 text-xs" colspan="2">Total</td>

            <td :class="industry.totals[0] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: industry.totals[0],
                  total: industry.totals[0] + industry.totals[1]
                })
              }}
            </td>
            <td :class="industry.totals[1] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: industry.totals[1],
                  total: industry.totals[0] + industry.totals[1]
                })
              }}
            </td>
            <td :class="industry.totals[0] + industry.totals[1] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{
                this.showNumber({
                  number: industry.totals[0] + industry.totals[1],
                  total: industry.totals[0] + industry.totals[1]
                })
              }}
            </td>

            <td :class="industry.totals[2] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: industry.totals[2],
                  total: industry.totals[2] + industry.totals[3]
                })
              }}
            </td>
            <td :class="industry.totals[3] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: industry.totals[3],
                  total: industry.totals[2] + industry.totals[3]
                })
              }}
            </td>
            <td :class="industry.totals[2] + industry.totals[3] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{
                this.showNumber({
                  number: industry.totals[2] + industry.totals[3],
                  total: industry.totals[2] + industry.totals[3]
                })
              }}
            </td>

            <td :class="industry.totals[4] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: industry.totals[4],
                  total: industry.totals[4] + industry.totals[5]
                })
              }}
            </td>
            <td :class="industry.totals[5] === 0 && 'text-red-300'" class="border">
              {{
                this.showNumber({
                  number: industry.totals[5],
                  total: industry.totals[4] + industry.totals[5]
                })
              }}
            </td>
            <td :class="industry.totals[4] + industry.totals[5] === 0 && 'text-red-300'" class="border bg-gray-300">
              {{
                this.showNumber({
                  number: industry.totals[4] + industry.totals[5],
                  total: industry.totals[4] + industry.totals[5]
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    data() {
      return {
        showPercentage: false,
        showSlotTable: false,
        json_fields: {
          'MS Code': 'msCode',
          'Duplicate MS Code': 'duplicateMsCode',
          '(Sold by Multiple Companies)	Master Code': 'masterCode',
          'Survey Date': 'surveyDate',
          Competitor: 'competitor',
          'Competitor Status': 'competitorStatus',
          'Format LV.1': 'format_level_1',
          'Format LV.2': 'format_level_2',
          'Format LV.3': 'format_level_3',
          Island: 'island',
          'City (INTERNAL)': 'city',
          Province: 'province',
          'Type of assets': 'typeOfAssets',
          Orientation: 'orientation',
          'Kabupaten / Kotamadya': 'kabupaten_and_kotamadya',
          Area: 'area',
          Route: 'route',
          Lat: 'lat',
          Long: 'long',
          'Rate - 1 month': 'rate - 1 month',
          'Rate - 3 months': 'rate - 3 months',
          'Rate - 6 months': 'rate - 6 months',
          'Rate - 1 year': 'rate - 1 year',
          'Asset status': 'utilization_status',
          'Slot status': 'slot_status',
          'Umbrella brand': 'company_group',
          'Sub product': 'brands',
          'Industry 1': 'industry_level_1_parent',
          'Industry 2': 'industry_level_2_details'
        },
        assetsJson: [],

        geoContent: [],
        selectedGeoScreens: [],
        selectedGeoSlots: [],
        competitorContent: [],
        selectedCompetitorScreens: [],
        selectedCompetitorSlots: [],
        industryContent: [],
        selectedIndustriesSlots: [],
        totalCompetitorContent: [],
        totalGeoCotent: []
      }
    },

    computed: {
      isEmptyTable() {
        return this.$store.state.filter.groupAssets.length === 0
      },
      filtersResults() {
        return this.$store.state.filter.filtersResults
      }
    },
    watch: {
      '$store.state.filter.filtersResults': {
        handler: function (filtersResults) {
          const selectedGeoScreens = []
          const selectedGeoSlots = []
          const selectedCompetitorScreens = []
          const selectedCompetitorSlots = []
          let selectedIndustriesSlots = []

          filtersResults.forEach((asset) => {
            const { latest_survey_screen_onwers_history, asset_type_details, location, screen_owners } = asset

            const { kabupaten_and_kotamadya: district } = location
            const { format_level_1, type_of_assets } = asset_type_details
            const oohType = format_level_1.toLowerCase()
            const assetTypeIndex = type_of_assets.toLowerCase() === 'digital' ? 0 : 1
            const selectedScreenOwers = screen_owners.length > 0 ? screen_owners : latest_survey_screen_onwers_history

            let subTotalsIndex = 0 // roadside
            if (oohType === 'public transport') {
              subTotalsIndex = 1
            } else if (oohType === 'place based') {
              subTotalsIndex = 2
            }

            // * GEOLOCATION SCREENS
            const geoScreensIndex = selectedGeoScreens.findIndex(({ name }) => name === district)
            let geoScreen = selectedGeoScreens[geoScreensIndex]
            if (geoScreensIndex === -1) {
              geoScreen = {
                name: district,
                subTotals: [
                  [0, 0], // road side [digital, static]
                  [0, 0], // public transport [digital, static]
                  [0, 0] // place based [digital, static]
                ]
              }
            }
            geoScreen.subTotals[subTotalsIndex][assetTypeIndex]++
            geoScreensIndex === -1 && selectedGeoScreens.push(geoScreen)

            // * GEOLOCATION SLOTS
            const geoSlotsIndex = selectedGeoSlots.findIndex(({ name }) => name === district)
            let geoSlot = selectedGeoSlots[geoSlotsIndex]
            if (geoSlotsIndex === -1) {
              geoSlot = {
                name: district,
                subTotals: [
                  [0, 0], // road side [digital, static]
                  [0, 0], // public transport [digital, static]
                  [0, 0] // place based [digital, static]
                ]
              }
            }
            geoSlot.subTotals[subTotalsIndex][assetTypeIndex] += Number(selectedScreenOwers[0].slots.length)
            geoSlotsIndex === -1 && selectedGeoSlots.push(geoSlot)

            // ! FOR COMPETITORS  CALCULATION

            selectedScreenOwers.forEach((screenOwner) => {
              const { name: ownerName, slots } = screenOwner

              // * COMPETITORS SCREEN
              const compeScreenIndex = selectedCompetitorScreens.findIndex(({ name }) => name === ownerName)
              let compeScreen = selectedCompetitorScreens[compeScreenIndex]
              if (compeScreenIndex === -1) {
                compeScreen = {
                  name: ownerName,
                  subTotals: [
                    [0, 0], // road side [digital, static]
                    [0, 0], // public transport [digital, static]
                    [0, 0] // place based [digital, static]
                  ]
                }
              }
              compeScreen.subTotals[subTotalsIndex][assetTypeIndex]++
              compeScreenIndex === -1 && selectedCompetitorScreens.push(compeScreen)

              // * COMPETITORS SLOT
              const compeSlotsIndex = selectedCompetitorSlots.findIndex(({ name }) => name === ownerName)
              const totalSlots = Number(slots.length)
              let compeSlot = selectedCompetitorSlots[compeSlotsIndex]
              if (compeSlotsIndex === -1) {
                compeSlot = {
                  name: ownerName,
                  subTotals: [
                    [0, 0], // road side [digital, static]
                    [0, 0], // public transport [digital, static]
                    [0, 0] // place based [digital, static]
                  ]
                }
              }
              compeSlot.subTotals[subTotalsIndex][assetTypeIndex] += totalSlots
              compeSlotsIndex === -1 && selectedCompetitorSlots.push(compeSlot)
            })

            const { name: ownerName, slots } = selectedScreenOwers[0]
            slots.forEach((slot) => {
              const { industries: industry, brands: brand } = slot

              if (brand !== null && brand !== '-' && industry !== null) {
                // * INDUSTRY SCREEN
                const industryScreenIndex = selectedIndustriesSlots.findIndex(({ name }) => name === industry)
                let industryScreen = selectedIndustriesSlots[industryScreenIndex]
                if (industryScreenIndex === -1) {
                  industryScreen = {
                    name: industry,
                    brands: [
                      {
                        name: brand,
                        subTotals: [
                          [0, 0], // road side [digital, static,]
                          [0, 0], // public transport [digital, static]
                          [0, 0] // place based [digital, static]
                        ]
                      }
                    ]
                  }
                  industryScreen.brands[0].subTotals[subTotalsIndex][assetTypeIndex]++
                } else {
                  const brandScreenIndex = industryScreen.brands.findIndex(({ name }) => name === brand.trim())
                  let brandScreen = industryScreen.brands[brandScreenIndex]
                  if (brandScreenIndex === -1) {
                    brandScreen = {
                      name: brand.trim(),
                      subTotals: [
                        [0, 0], // road side [digital, static]
                        [0, 0], // public transport [digital, static]
                        [0, 0] // place based [digital, static]
                      ]
                    }
                  }
                  brandScreen.subTotals[subTotalsIndex][assetTypeIndex]++
                  brandScreenIndex === -1 && industryScreen.brands.push(brandScreen)
                }

                industryScreenIndex === -1 && selectedIndustriesSlots.push(industryScreen) // add parent
              }
            })
          })

          // * GEO
          this.selectedGeoScreens = selectedGeoScreens
          this.selectedGeoSlots = selectedGeoSlots

          // * COMPETITOR
          this.selectedCompetitorScreens = selectedCompetitorScreens
          this.selectedCompetitorSlots = selectedCompetitorSlots

          // * INDUSTRY
          this.industryContent = this.calculateIndustrySections(selectedIndustriesSlots)
          this.selectedIndustriesSlots = selectedIndustriesSlots

          this.showPercentage = false
          this.showSlotTable = false

          this.geoContent = selectedGeoScreens
          this.totalGeoCotent = this.calculateTotalSections(selectedGeoScreens)

          this.competitorContent = selectedCompetitorScreens
          this.totalCompetitorContent = this.calculateTotalSections(selectedCompetitorScreens)

          // * calculate excel
          this.assetsJson = this.createAssetsExcel(filtersResults)
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      calculateTotalSections(selectedSections) {
        const results = [
          0,
          0, // road side [digital, static]
          null,
          0,
          0, // public transport [digital, static]
          null,
          0,
          0, // place based [digital, static]
          null
        ]

        selectedSections.forEach(({ subTotals }) => {
          subTotals.forEach((subTotal, subTotalIndex) => {
            subTotal.forEach((total, totalIndex) => {
              if (subTotalIndex === 0) {
                totalIndex === 0 ? (results[0] += Number(total)) : (results[1] += Number(total))
              }

              if (subTotalIndex === 1) {
                totalIndex === 0 ? (results[3] += Number(total)) : (results[4] += Number(total))
              }

              if (subTotalIndex === 2) {
                totalIndex === 0 ? (results[6] += Number(total)) : (results[7] += Number(total))
              }
            })
          })
        })

        return results
      },

      calculateIndustrySections(industrySlots) {
        return industrySlots.map((industry) => {
          industry.totals = [
            [0, 0], // road side [digital, static]
            [0, 0], // public transport [digital, static]
            [0, 0] // place based [digital, static]]
          ]

          industry.brands.forEach((brand) => {
            const { subTotals } = brand
            subTotals.forEach((subTotal, subTotalIndex) => {
              subTotal.forEach((totalByType, totalByTypeIndex) => {
                industry.totals[subTotalIndex][totalByTypeIndex] += Number(totalByType)
              })
            })
          })

          industry.totals = _.flatten(industry.totals)

          return industry
        })
      },

      toggleTabble() {
        const { showSlotTable, selectedGeoSlots, selectedGeoScreens, selectedCompetitorScreens, selectedCompetitorSlots, showPercentage } = this

        if (showSlotTable === true) {
          this.geoContent = selectedGeoScreens
          this.totalGeoCotent = this.calculateTotalSections(selectedGeoScreens)
          this.competitorContent = selectedCompetitorScreens
          this.totalCompetitorContent = this.calculateTotalSections(selectedCompetitorScreens)
        } else {
          this.geoContent = selectedGeoSlots
          this.totalGeoCotent = this.calculateTotalSections(selectedGeoSlots)
          this.competitorContent = selectedCompetitorSlots
          this.totalCompetitorContent = this.calculateTotalSections(selectedCompetitorSlots)
        }

        this.showSlotTable = !this.showSlotTable
        this.showPercentage = !showPercentage
      },

      showNumber({ number, total }) {
        if (number === 0) {
          return 0
        }

        if (typeof total !== 'undefined') {
          if (this.showPercentage) {
            const percent = ((number / total) * 100).toFixed(0)
            return `${percent}%`
          }
        }

        return number
      },

      createAssetsExcel(groupAssets) {
        const assetExcelFormat = []
        groupAssets.forEach((assetGroups) => {
          assetGroups.screen_owners.forEach((screenOwner) => {
            screenOwner.slots.forEach((slot) => {
              assetExcelFormat.push({
                msCode: slot.ms_code,
                duplicateMsCode: slot.duplicate_ms_code,
                masterCode: slot.master_code,
                surveyDate: assetGroups.survey_date,
                competitor: screenOwner.name,
                competitorStatus: screenOwner.competitor_status,
                format_level_1: assetGroups.asset_type.format_level_1,
                format_level_2: assetGroups.asset_type.format_level_2,
                format_level_3: assetGroups.asset_type.format_level_3,
                island: assetGroups.location.island,
                city: assetGroups.location.city,
                province: assetGroups.location.province,
                typeOfAssets: assetGroups.asset_type.type_of_assets,
                orientation: assetGroups.asset_type.orientation,
                kabupaten_and_kotamadya: assetGroups.location.kabupaten_and_kotamadya,
                area: assetGroups.location.area,
                route: assetGroups.location.route,
                lat: parseFloat(assetGroups.location.lat),
                long: parseFloat(assetGroups.location.lng),
                'rate - 1 month': slot.prices[0][0],
                'rate - 3 months': slot.prices[0][1],
                'rate - 6 months': slot.prices[0][2],
                'rate - 1 year': slot.prices[0][3],
                utilization_status: slot.utilization_status,
                slot_status: slot.status,
                company_group: slot.brands,
                brands: slot.subProduct,
                industry_level_1_parent: slot.industries,
                industry_level_2_details: slot.industries_children
              })
            })
          })
        })

        return assetExcelFormat
      }
    }
  }
</script>

<style>
  .custom-height {
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 40px;
  }
</style>
